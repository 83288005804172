<nz-spin [nzSpinning]="loading">
  <form (ngSubmit)="addInterpreter()" #f="ngForm" novalidate>
      <nz-tabset>
        <nz-tab nzTitle="Personal Information">
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "ID_number" | translate }}</tc-form-label>
                <tc-input type="number" placeholder="{{ 'ID_number' | translate }}" pattern="(0|[1-9][0-9]*)" 
                  name="idNumber" #idNumber="ngModel" [(ngModel)]="interpreterData.idNumber"></tc-input>
                  <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!idNumber.valid && (idNumber.dirty || idNumber.touched)">
                    {{ "Invalid_input" | translate }}
                  </tc-form-description>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Firstname" | translate}} <span class="req-icon">*</span></tc-form-label>
                <tc-input placeholder="{{ 'Firstname' | translate }}" required pattern="[a-z A-Z-._äÄàÀéÉèÈöÖÜüËëçÇ -_]+$"
                name="firstName" #firstName="ngModel" [(ngModel)]="interpreterData.firstName"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!firstName.valid && (firstName.dirty || firstName.touched)">
                  <!-- <p> {{ "Firstname_required" | translate }}</p> -->
                  <p> {{ "Is_invalid" | translate }}</p>
              </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Lastname" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Lastname' | translate }}"
                  name="lastName" #lastName="ngModel" [(ngModel)]="interpreterData.lastName"></tc-input>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Gender" | translate }}</tc-form-label>
                <nz-select [disabled] ="isFormDisable" nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Select_gender' | translate }}"
                    name="gender" #gender="ngModel" [(ngModel)]="interpreterData.gender">
                    <nz-option *ngFor="let item of genderList" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
                </nz-select>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{'Date_of_birth' | translate}} ({{'Date_format' | translate}})</tc-form-label>
                <nz-date-picker nzPlaceHolder="{{'Date_of_birth' | translate}}" [nzFormat]="'MM/dd/yyyy'"
                  name="dob" #dob="ngModel" [(ngModel)]="interpreterData.dob"></nz-date-picker>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <div ngModelGroup="country">
                <tc-form-group>
                  <tc-form-label>{{ "Origin" | translate }}</tc-form-label>
                  <nz-select [disabled] ="isFormDisable" nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Origin' | translate }}"
                    name="iso" #iso="ngModel" [(ngModel)]="interpreterData.country.iso">
                    <nz-option *ngFor="let item of countries" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
                  </nz-select>
                </tc-form-group>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Email" | translate }}<span class="req-icon">*</span></tc-form-label>
                <tc-input placeholder="{{ 'Email' | translate }}" required 
                  name="email" #email="ngModel" [(ngModel)]="interpreterData.email"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="email.touched && !email.valid">
                  <p> {{ "Is_invalid" | translate }}</p>
                </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-2">
              <tc-form-group>
                <tc-form-label>{{ "Telephone" | translate }}<span class="req-icon">*</span></tc-form-label>
                <tc-input required name="countryCode" #countryCode="ngModel" [(ngModel)]="interpreterData.countryCode"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="countryCode.touched && !countryCode.valid">
                  <p> {{ "Is_invalid" | translate }}</p>
                </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-4">
              <tc-form-group>
                <tc-form-label>&nbsp;</tc-form-label>
                <tc-input required name="telephone" #telephone="ngModel" [(ngModel)]="interpreterData.telephone"
                  pattern="\s*(?:[0-9]\s*){10,12}$" (keyup) ="changeTelephoneFormat($event)"></tc-input>
                  <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!telephone.valid && (telephone.dirty || telephone.touched)">
                  <p>{{ "Telephone" | translate }} {{ "Is_invalid" | translate }}</p>
                </tc-form-description>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Home_location" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Home_location' | translate }}" (click) ="onClickAddLocation('Add Location', null)"
                  name="formattedAddress" #formattedAddress="ngModel" [(ngModel)]="interpreterData.formattedAddress"></tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Street_number" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Street_number' | translate }}"
                 name="address" #address="ngModel" [(ngModel)]="interpreterData.address"></tc-input>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Postcode" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Postcode' | translate }}"
                 name="zipcode" #zipcode="ngModel" [(ngModel)]="interpreterData.zipcode"></tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Interpreter_city" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Interpreter_city' | translate }}"
                name="city" #city="ngModel" [(ngModel)]="interpreterData.city"></tc-input>
              </tc-form-group>
            </div>
          </div>
        </nz-tab>
        <nz-tab nzTitle="Administrative Information">
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Nationality" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Nationality' | translate }}" 
                name="nationality" #nationality="ngModel" [(ngModel)]="interpreterData.nationality"></tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Permit" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Permit' | translate }}" 
                name="permit" #permit="ngModel" [(ngModel)]="interpreterData.permit"></tc-input>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Permit_validity" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Permit_validity' | translate }}" 
                name="permitValidity" #permitValidity="ngModel" [(ngModel)]="interpreterData.permitValidity"></tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Avs_number" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Avs_number' | translate }}" 
                name="avsNum" #avsNum="ngModel" [(ngModel)]="interpreterData.avsNum"></tc-input>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>IBAN</tc-form-label>
                <tc-input [placeholder]="'IBAN'" 
                name="iban" #iban="ngModel" [(ngModel)]="interpreterData.iban"></tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Name_of_bank" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Name_of_bank' | translate }}" 
                name="bankName" #bankName="ngModel" [(ngModel)]="interpreterData.bankName"></tc-input>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Account_holder" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Account_holder' | translate }}" 
                name="accountHolder" #accountHolder="ngModel" [(ngModel)]="interpreterData.accountHolder"></tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Salary_type" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Salary_type' | translate }}" 
                name="salaryType" #salaryType="ngModel" [(ngModel)]="interpreterData.salaryType"></tc-input>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>BIC</tc-form-label>
                <tc-input placeholder="BIC" name="bic" #bic="ngModel" [(ngModel)]="interpreterData.bic"></tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{'salaryLimit' | translate}}</tc-form-label>
                <tc-input placeholder="{{'salaryLimit' | translate}}" name="salaryLimit" 
                #salaryLimit="ngModel" [(ngModel)]="interpreterData.salaryLimit"></tc-input>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "interpreter_qualifications" | translate }}</tc-form-label>
                <nz-select [disabled] ="isFormDisable" nzShowSearch nzAllowClear nzPlaceHolder="{{ 'interpreter_qualifications' | translate }}"
                    name="interpreterQualificationId" #interpreterQualificationId="ngModel" [(ngModel)]="interpreterData.interpreterQualificationId">
                    <nz-option *ngFor="let item of allInterpreterQualification" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
                </nz-select>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{'mainOccupation' | translate}}</tc-form-label>
                <tc-input placeholder="{{'mainOccupation' | translate}}" name="mainOccupation" 
                #mainOccupation="ngModel" [(ngModel)]="interpreterData.mainOccupation"></tc-input>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group class="mb-md-0">
                <tc-form-label>{{ "Payment_type" | translate }}</tc-form-label>
                <nz-radio-group name="paymentType" #paymentType="ngModel" [(ngModel)]="interpreterData.paymentType">
                      <label nz-radio nzValue = "postalPayment">{{'Postal_payment' | translate}}</label>
                      <label nz-radio nzValue = "bankPayment">{{'Bank_payment' | translate}}</label>
                </nz-radio-group>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group class="mb-md-0">
                <tc-form-label>{{ "valueAddedTax" | translate }}</tc-form-label>
                <nz-radio-group name="isValueAddedTax" #isValueAddedTax="ngModel" [(ngModel)]="interpreterData.isValueAddedTax">
                  <label nz-radio [nzValue] = "true">{{'Yes' | translate}}</label>
                  <label nz-radio [nzValue] = "false">{{'No' | translate}}</label>
                </nz-radio-group>
              </tc-form-group>
            </div>
          </div>
          <div class="row" style="margin-top: 1rem;">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{'Contract_since' | translate}} ({{'Date_format_Ig' | translate}})</tc-form-label>
                <nz-date-picker nzPlaceHolder="{{'Contract_since' | translate}}" [nzFormat]="'dd/MM/yyyy'"
                  name="contractSince" #contractSince="ngModel" [(ngModel)]="interpreterData.contractSince"></nz-date-picker>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{'Juridic_protection_contract' | translate}}({{'Date_format_Ig' | translate}})</tc-form-label>
                <nz-date-picker nzPlaceHolder="{{'Juridic_protection_contract' | translate}}" [nzFormat]="'dd/MM/yyyy'"
                  name="juridicProtectionContractFromDate" #juridicProtectionContractFromDate="ngModel" [(ngModel)]="interpreterData.juridicProtectionContractFromDate">
                </nz-date-picker>
              </tc-form-group>
            </div>
          </div>
        </nz-tab>
        <nz-tab nzTitle="Qualification Information">
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Interpreter_certificate" | translate }} ({{ "Date_format_Ig" | translate }})</tc-form-label>
                <nz-date-picker nzPlaceHolder="{{'Interpreter_certificate' | translate}}" [nzFormat]="'dd/MM/yyyy'"
                  name="certificateDate" #certificateDate="ngModel" [(ngModel)]="interpreterData.certificateDate">
                </nz-date-picker>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Federal_certificate" | translate }} ({{ "Date_format_Ig" | translate}})</tc-form-label>
                <nz-date-picker nzPlaceHolder="{{'Federal_certificate' | translate}}" [nzFormat]="'dd/MM/yyyy'"
                  name="federalCertificateDate" #federalCertificateDate="ngModel" [(ngModel)]="interpreterData.federalCertificateDate">
                </nz-date-picker>
              </tc-form-group>
            </div>
          </div>
          <div ngModelGroup="modulesData">
            <div class="row">
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{'Module_1' | translate}} ({{'Date_format_Ig' | translate}})</tc-form-label>
                  <div class="wrapper">
                    <tc-checkbox [disabled] ="isFormDisable" class="check" #isModule1DateSelected="ngModel"
                      name="isModule1DateSelected" [(ngModel)]="interpreterData.modulesData.isModule1DateSelected"></tc-checkbox>
                    <nz-date-picker nzPlaceHolder="{{'Module_1' | translate}}" [nzFormat]="'dd/MM/yyyy'" #module1Date="ngModel"
                      name="module1Date" [(ngModel)]="interpreterData.modulesData.module1Date"
                      [disabled]="!isModule1DateSelected.value || isFormDisable">
                    </nz-date-picker>
                  </div>
                </tc-form-group>
              </div>
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{'Module_2' | translate}} ({{'Date_format_Ig' | translate}})</tc-form-label>
                  <div class="wrapper">
                    <tc-checkbox class="check" [disabled] ="isFormDisable" #isModule2DateSelected="ngModel"
                    name="isModule2DateSelected" [(ngModel)]="interpreterData.modulesData.isModule2DateSelected"></tc-checkbox>
                    <nz-date-picker nzPlaceHolder="{{'Module_2' | translate}}" [nzFormat]="'dd/MM/yyyy'" #module2Date="ngModel"
                      name="module2Date" [(ngModel)]="interpreterData.modulesData.module2Date"
                      [disabled]="!isModule2DateSelected.value || isFormDisable">
                    </nz-date-picker>
                  </div>
                </tc-form-group>
              </div>
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{'Module_3' | translate}} ({{'Date_format_Ig' | translate}})</tc-form-label>
                  <div class="wrapper">
                    <tc-checkbox [disabled] ="isFormDisable" #isModule3DateSelected="ngModel"
                    name="isModule3DateSelected" [(ngModel)]="interpreterData.modulesData.isModule3DateSelected" class="check"></tc-checkbox>
                    <nz-date-picker nzPlaceHolder="{{'Module_3' | translate}}" [nzFormat]="'dd/MM/yyyy'" #module3Date="ngModel"
                      name="module3Date" [(ngModel)]="interpreterData.modulesData.module3Date"
                      [disabled]="!isModule3DateSelected.value || isFormDisable">
                    </nz-date-picker>
                  </div>
                </tc-form-group>
              </div>
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{'Module_4' | translate}} ({{'Date_format_Ig' | translate}})</tc-form-label>
                  <div class="wrapper">
                    <tc-checkbox [disabled] ="isFormDisable" #isModule4DateSelected="ngModel"
                      name="isModule4DateSelected" [(ngModel)]="interpreterData.modulesData.isModule4DateSelected" class="check"></tc-checkbox>
                    <nz-date-picker nzPlaceHolder="{{'Module_4' | translate}}" [nzFormat]="'dd/MM/yyyy'" #module4Date="ngModel"
                      name="module4Date" [(ngModel)]="interpreterData.modulesData.module4Date"
                      [disabled]="!isModule4DateSelected.value || isFormDisable">
                    </nz-date-picker>
                  </div>
                </tc-form-group>
              </div>
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{'Module4a' | translate}} ({{'Date_format_Ig' | translate}})</tc-form-label>
                  <div class="wrapper">
                    <tc-checkbox [disabled] ="isFormDisable" #isModule4Selected="ngModel"
                      name="isModule4Selected" [(ngModel)]="interpreterData.modulesData.isModule4Selected" class="check"></tc-checkbox>
                    <nz-date-picker nzPlaceHolder="{{'Module4a' | translate}}" [nzFormat]="'dd/MM/yyyy'" #module4="ngModel"
                      name="module4" [(ngModel)]="interpreterData.modulesData.module4"
                      [disabled]="!isModule4Selected.value || isFormDisable">
                    </nz-date-picker>
                  </div>
                </tc-form-group>
              </div>
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{'Module_5' | translate}} ({{'Date_format_Ig' | translate}})</tc-form-label>
                  <div class="wrapper">
                    <tc-checkbox [disabled] ="isFormDisable" #isModule5DateSelected="ngModel"
                    name="isModule5DateSelected" [(ngModel)]="interpreterData.modulesData.isModule5DateSelected" class="check"></tc-checkbox>
                    <nz-date-picker nzPlaceHolder="{{'Module_5' | translate}}" [nzFormat]="'dd/MM/yyyy'" #module5Date="ngModel"
                      name="module5Date" [(ngModel)]="interpreterData.modulesData.module5Date"
                      [disabled]="!isModule5DateSelected.value || isFormDisable">
                    </nz-date-picker>
                  </div>
                </tc-form-group>
              </div>
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{'Module_6' | translate}} ({{'Date_format_Ig' | translate}})</tc-form-label>
                  <div class="wrapper">
                    <tc-checkbox [disabled] ="isFormDisable" #isModule6DateSelected="ngModel"
                    name="isModule6DateSelected" [(ngModel)]="interpreterData.modulesData.isModule6DateSelected" class="check"></tc-checkbox>
                    <nz-date-picker nzPlaceHolder="{{'Module_6' | translate}}" [nzFormat]="'dd/MM/yyyy'" #module6Date="ngModel"
                      name="module6Date" [(ngModel)]="interpreterData.modulesData.module6Date"
                      [disabled]="!isModule6DateSelected.value || isFormDisable">
                    </nz-date-picker>
                  </div>
                </tc-form-group>
              </div>
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{'Module_7' | translate}} ({{'Date_format_Ig' | translate}})</tc-form-label>
                  <div class="wrapper">
                    <tc-checkbox [disabled] ="isFormDisable" #isModule7DateSelected="ngModel"
                    name="isModule7DateSelected" [(ngModel)]="interpreterData.modulesData.isModule7DateSelected" class="check"></tc-checkbox>
                    <nz-date-picker nzPlaceHolder="{{'Module_7' | translate}}" [nzFormat]="'dd/MM/yyyy'" #module7Date="ngModel"
                      name="module7Date" [(ngModel)]="interpreterData.modulesData.module7Date"
                      [disabled]="!isModule7DateSelected.value || isFormDisable">
                    </nz-date-picker>
                  </div>
                </tc-form-group>
              </div>
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{'Module_8' | translate}} ({{'Date_format_Ig' | translate}})</tc-form-label>
                  <div class="wrapper">
                    <tc-checkbox [disabled] ="isFormDisable" #isModule8DateSelected="ngModel"
                    name="isModule8DateSelected" [(ngModel)]="interpreterData.modulesData.isModule8DateSelected" class="check"></tc-checkbox>
                    <nz-date-picker nzPlaceHolder="{{'Module_8' | translate}}" [nzFormat]="'dd/MM/yyyy'" #module8Date="ngModel"
                      name="module8Date" [(ngModel)]="interpreterData.modulesData.module8Date"
                      [disabled]="!isModule8DateSelected.value || isFormDisable">
                    </nz-date-picker>
                  </div>
                </tc-form-group>
              </div>
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{'Module_9' | translate}} ({{'Date_format_Ig' | translate}})</tc-form-label>
                  <div class="wrapper">
                    <tc-checkbox [disabled] ="isFormDisable" #isModule9DateSelected="ngModel"
                    name="isModule9DateSelected" [(ngModel)]="interpreterData.modulesData.isModule9DateSelected" class="check"></tc-checkbox>
                    <nz-date-picker nzPlaceHolder="{{'Module_9' | translate}}" [nzFormat]="'dd/MM/yyyy'" #module9Date="ngModel"
                      name="module9Date" [(ngModel)]="interpreterData.modulesData.module9Date"
                      [disabled]="!isModule9DateSelected.value || isFormDisable">
                    </nz-date-picker>
                  </div>
                </tc-form-group>
              </div>
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{'Module_10' | translate}} ({{'Date_format_Ig' | translate}})</tc-form-label>
                  <div class="wrapper">
                    <tc-checkbox [disabled] ="isFormDisable" #isModule10DateSelected="ngModel"
                      name="isModule10DateSelected" [(ngModel)]="interpreterData.modulesData.isModule10DateSelected" class="check"></tc-checkbox>
                    <nz-date-picker nzPlaceHolder="{{'Module_10' | translate}}" [nzFormat]="'dd/MM/yyyy'" #module10Date="ngModel"
                      name="module10Date" [(ngModel)]="interpreterData.modulesData.module10Date"
                      [disabled]="!isModule10DateSelected.value || isFormDisable">
                    </nz-date-picker>
                  </div>
                </tc-form-group>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Interpreter_exam" | translate }} ({{ "Date_format_Ig" | translate}})</tc-form-label>
                <nz-date-picker nzPlaceHolder="{{'Interpreter_exam' | translate}}" [nzFormat]="'dd/MM/yyyy'" #examDate="ngModel"
                  name="examDate" [(ngModel)]="interpreterData.examDate">
                </nz-date-picker>
              </tc-form-group>
            </div>
          </div>
          <tc-card title="{{'Other_qualification_experience' | translate}}" class="mb-0"></tc-card>
          <div class="row">
            <div class="col-md-6">
              <tc-form-group>
                <tc-textarea [disabled] ="isFormDisable" placeholder="{{'Other_qualification_experience' | translate}}" 
                #otherQualification ="ngModel" name="otherQualification" [(ngModel)]="interpreterData.otherQualification"></tc-textarea>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{'Training_Registry_office' | translate}}</tc-form-label>
                <div class="wrapper">
                  <tc-checkbox [disabled] ="isFormDisable" #isTrainingCivilRegistryOffice="ngModel"
                  name="isTrainingCivilRegistryOffice" [(ngModel)]="interpreterData.isTrainingCivilRegistryOffice" class="check"></tc-checkbox>
                  <nz-date-picker nzPlaceHolder="{{'Training_Registry_office' | translate}}" [nzFormat]="'dd/MM/yyyy'"
                    name="trainingCivilRegistryOffice" [(ngModel)]="interpreterData.trainingCivilRegistryOffice"
                    [disabled]="!isTrainingCivilRegistryOffice.value || isFormDisable" #trainingCivilRegistryOffice="ngModel">
                  </nz-date-picker>
                </div>
              </tc-form-group>
            </div>
          </div>
          <div class="row" *ngIf="qualificationData.length > 0 && interpreterData.interpreterGroup">
            <div class="col col-md-12">
              <tc-form-label>{{'Qualifications' | translate}}</tc-form-label>
            </div>
            <div class="col col-md-4" *ngFor="let item of qualificationData;let i = index">
              <tc-form-group>
                <div class="wrapper">
                  <tc-checkbox name="item.id" [(ngModel)]="item.isSelected" class="check" [disabled] ="isFormDisable"></tc-checkbox>
                  <tc-form-label>{{item.label}}</tc-form-label>
                </div>
              </tc-form-group>
            </div>
          </div>
        </nz-tab>
        <nz-tab nzTitle="Salary Information">
          <tc-card title="{{ 'Session_salary' | translate }}" class="mb-0" style="margin-top: 1rem;"></tc-card>
          <div class="row">
            <div class="col col-md-4">
              <tc-form-group>
                <tc-form-label>{{'Video_per_minute' | translate}}<span class="req-icon">*</span></tc-form-label>
                <tc-input type="number" placeholder="{{'Video_per_minute' | translate}}" required #sessionCostPerMinVideo="ngModel"
                  name="sessionCostPerMinVideo" [(ngModel)]="interpreterData.sessionCostPerMinVideo"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="sessionCostPerMinVideo.touched && !sessionCostPerMinVideo.valid">
                <p> {{ "Is_required" | translate }}</p>
              </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-4">
              <tc-form-group>
                <tc-form-label>{{'Telephone_per_minute' | translate}}<span class="req-icon">*</span></tc-form-label>
                <tc-input type="number" placeholder="{{'Telephone_per_minute' | translate}}" required #sessionCostPerMinVoice="ngModel"
                name="sessionCostPerMinVoice" [(ngModel)]="interpreterData.sessionCostPerMinVoice"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="sessionCostPerMinVideo.touched && !sessionCostPerMinVideo.valid">
                  <p> {{ "Is_required" | translate }}</p>
              </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-4">
              <tc-form-group>
                <tc-form-label>{{'Inperson_per_hour' | translate}}<span class="req-icon">*</span></tc-form-label>
                <tc-input type="number" placeholder="{{ 'Inperson_per_hour' | translate }}" required #sessionCostPerHrInperson="ngModel"
                name="sessionCostPerHrInperson" [(ngModel)]="interpreterData.sessionCostPerHrInperson"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="sessionCostPerHrInperson.touched && !sessionCostPerHrInperson.valid">
                <p> {{ "Is_required" | translate }}</p>
              </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-4">
              <tc-form-group>
                <tc-form-label>{{'Intercultural_Mediation_perHour' | translate}}<span class="req-icon">*</span></tc-form-label>
                <tc-input type="number" placeholder="{{'Intercultural_Mediation_perHour' | translate}}" required #sessionCostPerMinMediation="ngModel"
                name="sessionCostPerMinMediation" [(ngModel)]="interpreterData.sessionCostPerMinMediation"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="sessionCostPerMinMediation.touched && !sessionCostPerMinMediation.valid">
                  <p> {{ "Is_required" | translate }}</p>
              </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-4">
              <tc-form-group>
                <tc-form-label>{{'Course_Management_perHour' | translate}}<span class="req-icon">*</span></tc-form-label>
                <tc-input type="number" placeholder="{{'Course_Management_perHour' | translate}}" required #sessionCostperMinCourse="ngModel"
                name="sessionCostperMinCourse" [(ngModel)]="interpreterData.sessionCostperMinCourse"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="sessionCostPerMinMediation.touched && !sessionCostPerMinMediation.valid">
                  <p> {{ "Is_required" | translate }}</p>
              </tc-form-description>
              </tc-form-group>
            </div>
          </div>
          <tc-form-label style="margin-top: 1rem;">{{ "Salary_for_minimum_session_duration" | translate }}</tc-form-label>
          <div class="row">
            <div class="col col-md-4">
              <tc-form-group>
                <tc-form-label>{{ "VIDEO" | translate }}<span class="req-icon">*</span></tc-form-label>
                <tc-input type="number" placeholder="{{ 'VIDEO' | translate }}"  required #minBillingSalaryVideo="ngModel"
                name="minBillingSalaryVideo" [(ngModel)]="interpreterData.minBillingSalaryVideo"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="minBillingSalaryVideo.touched && !minBillingSalaryVideo.valid">
                  <p> {{ "Is_required" | translate }}</p>
                </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-4">
              <tc-form-group>
                <tc-form-label>{{ "TELEPHONE" | translate }}<span class="req-icon">*</span></tc-form-label>
                <tc-input type="number" placeholder="{{ 'TELEPHONE' | translate }}" required #minBillingSalaryVoice="ngModel"
                name="minBillingSalaryVoice" [(ngModel)]="interpreterData.minBillingSalaryVoice"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="minBillingSalaryVoice.touched && !minBillingSalaryVoice.valid">
                  <p> {{ "Is_required" | translate }}</p>
                </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-4">
              <tc-form-group>
                <tc-form-label>{{ "INPERSON" | translate }}<span class="req-icon">*</span></tc-form-label>
                <tc-input type="number" placeholder="{{ 'INPERSON' | translate }}" required #minBillingSalaryInperson="ngModel"
                name="minBillingSalaryInperson" [(ngModel)]="interpreterData.minBillingSalaryInperson"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="minBillingSalaryInperson.touched && !minBillingSalaryInperson.valid">
                  <p> {{ "Is_required" | translate }}</p>
                </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-4">
              <tc-form-group>
                <tc-form-label>{{'Intercultural_Mediation' | translate}} <span class="req-icon">*</span></tc-form-label>
                <tc-input type="number" placeholder="{{'Intercultural_Mediation' | translate}}" required #minBillingSalaryMediation="ngModel"
                name="minBillingSalaryMediation" [(ngModel)]="interpreterData.minBillingSalaryMediation"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="minBillingSalaryMediation.touched && !minBillingSalaryMediation.valid">
                  <p> {{ "Is_required" | translate }}</p>
              </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-4">
              <tc-form-group>
                <tc-form-label>{{'Course_Management' | translate}}<span class="req-icon">*</span></tc-form-label>
                <tc-input type="number" placeholder="{{'Course_Management' | translate}}" required #minBillingSalaryCourse="ngModel"
                name="minBillingSalaryCourse" [(ngModel)]="interpreterData.minBillingSalaryCourse"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="minBillingSalaryCourse.touched && !minBillingSalaryCourse.valid">
              <p> {{ "Is_required" | translate }}</p>
              </tc-form-description>
              </tc-form-group>
            </div>
          </div>
          <tc-form-label style="margin-top: 1rem;">{{ "Travelling_expenses" | translate }}</tc-form-label>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Interpreter_travel_cost" | translate }}<span class="req-icon">*</span></tc-form-label>
                <tc-input placeholder="{{ 'Interpreter_travel_cost' | translate }}" required #sessionTravelCost="ngModel"
                name="sessionTravelCost" [(ngModel)]="interpreterData.sessionTravelCost"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="sessionTravelCost.touched && !sessionTravelCost.valid">
                  <p> {{ "Is_required" | translate }}</p>
                </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Billing_travel_cost" | translate }}<span class="req-icon">*</span></tc-form-label>
                <tc-input placeholder="{{ 'Billing_travel_cost' | translate }}" required #billingTravelCost="ngModel"
                name="billingTravelCost" [(ngModel)]="interpreterData.billingTravelCost"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="billingTravelCost.touched && !billingTravelCost.valid">
                  <p> {{ "Is_required" | translate }}</p>
                </tc-form-description>
              </tc-form-group>
            </div>
          </div>
        </nz-tab>
        <nz-tab nzTitle="Account Information And Availability">
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Interpreter_group" | translate }}<span class="req-icon">*</span></tc-form-label>
                <nz-select required [disabled] ="isFormDisable" nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Interpreter_group' | translate }}"
                  name="interpreterGroup" #interpreterGroup="ngModel" [(ngModel)]="interpreterData.interpreterGroup.id" required
                  (ngModelChange)="onSelectInterpreterGroup($event)">
                  <nz-option *ngFor="let item of interpreterGroups" [nzValue]="item.id" [nzLabel]="item.value.name"></nz-option>
                </nz-select>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="interpreterGroup.touched && !interpreterGroup.valid"> 
                  {{ "Interpreter_group_required" | translate }} 
                </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <div ngModelGroup="credentials">
                <tc-form-group>
                  <tc-form-label>{{ "User_id" | translate }}<span class="req-icon">*</span></tc-form-label>
                  <tc-input placeholder="{{ 'User_id' | translate }}" required #userId="ngModel"
                  name="userId" [(ngModel)]="interpreterData.credentials.userId"></tc-input>
                  <tc-form-description [tcColor]="'#e24d4d'" *ngIf="userId.touched && !userId.valid">
                    <p> {{ "User_id_required" | translate }}</p>
                  </tc-form-description>
                </tc-form-group>
              </div>
            </div>
          </div>
          <div class="row form-group" *ngIf="dynamicFields.length > 0 && interpreterData.interpreterGroup && role === 'IG Admin'">
            <div class="col col-md-6" *ngFor="let item of dynamicFields;let i = index">
              <tc-form-group>
                <tc-form-label>{{item.label}}</tc-form-label>
                <tc-input name="item[i].id" [(ngModel)]="item.fieldValue" [disabled]="true"></tc-input>
              </tc-form-group>
            </div>
          </div>
          <div ngModelGroup="passwords" appCheckPassword #passwords="ngModelGroup">
            <div class="row">
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{ "Password" | translate}}<span class="req-icon">*</span></tc-form-label>
                  <tc-input placeholder="{{ 'Password' | translate }}" #password="ngModel" [required] = "isMode === 'create'"
                  name="password" [(ngModel)]="interpreterData.credentials.password"></tc-input>
                </tc-form-group>
              </div>
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{ "Confirm_password" | translate}}<span class="req-icon">*</span></tc-form-label>
                  <tc-input placeholder="{{ 'Confirm_password' | translate }}" [required] = "isMode === 'create'"
                  name="confirmPassword" #confirmPassword="ngModel" ngModel></tc-input>
                  <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!confirmPassword.valid && (confirmPassword.dirty || confirmPassword.touched)">
                    <div *ngIf="confirmPassword.errors.required">{{'Confirm_password_required' | translate}}</div>
                  </tc-form-description>
                  <tc-form-description [tcColor]="'#e24d4d'" *ngIf="passwords.errors?.passwordCheck && (confirmPassword.dirty || confirmPassword.touched)">
                    {{'Password_not_match' | translate}}
                  </tc-form-description>
                </tc-form-group>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-4">
              <tc-form-group>
                <tc-checkbox [disabled] ="isFormDisable" name="isActive" #isActive="ngModel" 
                [(ngModel)]="interpreterData.isActive">{{ "Activate" | translate}}</tc-checkbox>
              </tc-form-group>
            </div>
            <div class="col col-md-4" *ngIf="role !== 'IG Manager'">
              <tc-form-group>
                <tc-checkbox [disabled] ="isFormDisable" name="emailVerified" #emailVerified="ngModel" 
                [(ngModel)]="interpreterData.emailVerified">Email Verified</tc-checkbox>
              </tc-form-group>
            </div>
            <div class="col col-md-4" *ngIf="role !== 'IG Manager'">
              <tc-form-group>
                <tc-checkbox [disabled] ="isFormDisable" name="telephoneVerified" #telephoneVerified="ngModel" 
                [(ngModel)]="interpreterData.telephoneVerified">Telephone Verified</tc-checkbox>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6" *ngIf="role !== 'IG Manager'">
              <tc-form-group>
                <tc-form-label>{{'Priority' | translate}} <span class="req-icon">*</span></tc-form-label>
                <tc-input type="number" placeholder="{{'Priority' | translate}}" name="priority" #priority="ngModel" 
                [(ngModel)]="interpreterData.priority"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!priority.valid && priority.touched">
                  <p> {{ "Priority_required" | translate }}</p>
              </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{'languageLevel' | translate}}</tc-form-label>
                <nz-select [disabled] ="isFormDisable" nzShowSearch nzAllowClear nzPlaceHolder="{{ 'languageLevel' | translate }}"
                    name="levelThree" #levelThree="ngModel" [(ngModel)]="interpreterData.levelThree">
                    <nz-option *ngFor="let item of languageOneLevelList" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
                </nz-select>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-12">
              <tc-form-label>{{'Language' | translate}} <span class="req-icon">*</span></tc-form-label>
              <div class="row" *ngFor="let language of interpreterData.languagesSkills;let i = index">
                <div class="col col-md-3">
                  <tc-form-group>
                    <nz-select [disabled] ="isFormDisable" nzShowSearch nzAllowClear nzPlaceHolder="{{'Select_language_one' | translate}}"
                      name="languageOne_{{i}}" [(ngModel)]="interpreterData.languagesSkills[i].languageOne.isoCode">
                      <nz-option *ngFor="let t of languageOne" [nzValue]="t.value" [nzLabel]="t.label"></nz-option>
                    </nz-select>
                  </tc-form-group>
                </div>
                <div class="col col-md-2" *ngIf="role !== 'IG Manager'">
                  <tc-form-group>
                    <nz-select [disabled] ="isFormDisable" nzShowSearch nzAllowClear nzPlaceHolder="{{'level' | translate}}"
                      name="level_{{i}}" [(ngModel)]="interpreterData.languagesSkills[i].levelOne">
                      <nz-option *ngFor="let m of languageLevel" [nzValue]="m.value" [nzLabel]="m.label"></nz-option>
                    </nz-select>
                  </tc-form-group>
                </div>
                <div class="col col-md-3" *ngIf="interpreterData.languagesSkills[i].languageOne.isoCode === 'English'">
                  <tc-form-group>
                    <nz-select [disabled] ="isFormDisable" nzShowSearch nzAllowClear nzPlaceHolder="{{'Select_language_two' | translate}}"
                      name="languageTwo_{{i}}" [(ngModel)]="interpreterData.languagesSkills[i].languageTwo.isoCode">
                      <nz-option *ngFor="let o of languageTwo" [nzValue]="o.isoCode" [nzLabel]="o.english"></nz-option>
                    </nz-select>
                  </tc-form-group>
                </div>
                <div class="col col-md-3" *ngIf="interpreterData.languagesSkills[i].languageOne.isoCode === 'German'">
                  <tc-form-group>
                    <nz-select [disabled] ="isFormDisable" nzShowSearch nzAllowClear nzPlaceHolder="{{'Select_language_two' | translate}}"
                      name="languageTwo_{{i}}" [(ngModel)]="interpreterData.languagesSkills[i].languageTwo.isoCode">
                      <nz-option *ngFor="let o of languageTwo" [nzValue]="o.isoCode" [nzLabel]="o.german"></nz-option>
                    </nz-select>
                  </tc-form-group>
                </div>
                <div class="col col-md-3" *ngIf="interpreterData.languagesSkills[i].languageOne.isoCode === 'Italian'">
                  <tc-form-group>
                    <nz-select [disabled] ="isFormDisable" nzShowSearch nzAllowClear nzPlaceHolder="{{'Select_language_two' | translate}}"
                      name="languageTwo_{{i}}" [(ngModel)]="interpreterData.languagesSkills[i].languageTwo.isoCode">
                      <nz-option *ngFor="let o of languageTwo" [nzValue]="o.isoCode" [nzLabel]="o.italian"></nz-option>
                    </nz-select>
                  </tc-form-group>
                </div>
                <div class="col col-md-3" *ngIf="interpreterData.languagesSkills[i].languageOne.isoCode === 'French'">
                  <tc-form-group>
                    <nz-select [disabled] ="isFormDisable" nzShowSearch nzAllowClear nzPlaceHolder="{{'Select_language_two' | translate}}"
                      name="languageTwo_{{i}}" [(ngModel)]="interpreterData.languagesSkills[i].languageTwo.isoCode">
                      <nz-option *ngFor="let o of languageTwo" [nzValue]="o.isoCode" [nzLabel]="o.french"></nz-option>
                    </nz-select>
                  </tc-form-group>
                </div>
                <div class="col col-md-2" *ngIf="role !== 'IG Manager' && interpreterData.languagesSkills[i].languageTwo.isoCode">
                  <tc-form-group>
                    <nz-select [disabled] ="isFormDisable" nzShowSearch nzAllowClear nzPlaceHolder="{{'level' | translate}}"
                      name="levelTwo_{{i}}" [(ngModel)]="interpreterData.languagesSkills[i].levelTwo">
                      <nz-option *ngFor="let i of languageLevel" [nzValue]="i.value" [nzLabel]="i.label"></nz-option>
                    </nz-select>
                  </tc-form-group>
                </div>
                <div class="col col-md-2">
                  <button type="button" tc-button [afterIcon]="'icofont-minus'" [view]="'warning'" [square]="true" (click)= "onRemoveLanguage(i)" [disabled] ="isFormDisable"></button>
                </div>
              </div>
            </div>
            <div class="col col-md-12">
              <button type="button" tc-button [disabled] ="isFormDisable" [afterIcon]="'icofont-plus'" [view]="'info'" [square]="true" (click)= "onAddLanguageOption()"></button>
              <span>&nbsp;{{'Add_language_skills' | translate}}</span>
            </div>
          </div>
          <tc-card [title]="'Availability'" class="mb-0"></tc-card>
          <div class="row">
            <div class="col-md-2">
              <tc-form-group>
                <tc-switcher [disabled] ="isFormDisable" name="isVideoCallActive" #isVideoCallActive="ngModel" 
                [(ngModel)]="interpreterData.isVideoCallActive">{{ "VIDEO" | translate }}<span class="req-icon">*</span></tc-switcher>
              </tc-form-group>
            </div>
            <div class="col-md-2">
              <tc-form-group>
                <tc-switcher [disabled] ="isFormDisable" name="isVoiceCallActive" #isVoiceCallActive="ngModel" 
                [(ngModel)]="interpreterData.isVoiceCallActive">{{ "TELEPHONE" | translate }}<span class="req-icon">*</span></tc-switcher>
              </tc-form-group>
            </div>
            <div class="col-md-2">
              <tc-form-group>
                <tc-switcher [disabled] ="isFormDisable" name="isInpersonActive" #isInpersonActive="ngModel" 
                [(ngModel)]="interpreterData.isInpersonActive">{{ "INPERSON" | translate }}<span class="req-icon">*</span></tc-switcher>
              </tc-form-group>
            </div>
            <div class="col-md-3">
              <tc-form-group>
                <tc-switcher [disabled] ="isFormDisable" name="isMediationActive" #isMediationActive="ngModel" 
                [(ngModel)]="interpreterData.isMediationActive">{{'Intercultural_Mediation' | translate}}<span class="req-icon">*</span></tc-switcher>
              </tc-form-group>
            </div>
            <div class="col-md-3">
              <tc-form-group>
                <tc-switcher [disabled] ="isFormDisable" name="isCourseActive" #isCourseActive="ngModel" 
                [(ngModel)]="interpreterData.isCourseActive">{{'Course_Management' | translate}}<span class="req-icon">*</span></tc-switcher>
              </tc-form-group>
            </div>
          </div>
        </nz-tab>
      </nz-tabset> 
    <div *nzModalFooter class="actions justify-content-between">
        <button tc-button type="button" [view]="'error'" (click)="closeModal()"> 
          {{isFormDisable ? ("Ok" | translate) : ("Cancel" | translate) }}
        </button>
        <button tc-button [view]="'info'" [disabled]="!f.valid" (click)="addInterpreter()" *ngIf="isMode !== 'view'">
          {{isMode === "create" ? ("Create" | translate) : ("Update" | translate) }}
        </button>
    </div>
  </form>
</nz-spin>
