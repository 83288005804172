

<form [formGroup]="recalculateSessionForm" >
  <tc-card title="{{ 'Recalculate_sessions' | translate }}" class="mb-0"  [outline]="true" [view]="'info'">
    <div class="row">
      <div class="col col-md-4">
        <tc-form-group>
          <tc-form-label>{{ "Session_id" | translate }}</tc-form-label>
          <tc-input placeholder="{{ 'Session_id' | translate }}" formControlName="sessionId"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-4">
        <tc-form-group>
          <tc-form-label>&nbsp;</tc-form-label>
          <tc-checkbox formControlName="currentBillingRule">{{ "Current_billing_rule" | translate }}</tc-checkbox>
        </tc-form-group>
      </div>
      <div class="col col-md-4">
        <tc-form-label>&nbsp;</tc-form-label>
        <button tc-button 
          [beforeIcon]="'icofont-plus-circle'"
          [view]="'accent'"
          (click)="recalculateCost()"
          [disabled]="!recalculateSessionForm.get('sessionId').value">
          {{ "Recalculate" | translate }}
        </button>
      </div>
    </div>
  </tc-card>
</form>

<form [formGroup]="sessionsByInterpreterForm" >
  <tc-card
    title="{{ 'Recalculate_sessions_by_interpreter' | translate }}"
    class="mb-0 box-padding"  [outline]="true" [view]="'info'"
  >
    <div class="row">
      <div class="col col-md-3">
        <tc-form-group>
          <tc-form-label>{{ "Interpreter" | translate }}</tc-form-label>
          <tc-select
            placeholder="{{ 'Interpreter' | translate }}"
            [options]="interpreterData"
            [multiple]="true"
            formControlName="interpreters"
          ></tc-select>
        </tc-form-group>
        
        <!-- <nz-select
        nzShowSearch
        nzAllowClear
        [nzMode]="'multiple'"
        nzPlaceHolder="{{ 'Interpreter' | translate }}"
        >
          <nz-option *ngFor="let interpreter of interpreterData"  nzLabel="{{interpreter.label}}" nzValue="{{interpreter.value}}"></nz-option>
        </nz-select> -->
      </div>
      <div class="col col-md-3">
        <tc-form-group>
          <tc-form-label>{{ "Time_period" | translate }}</tc-form-label>
          <nz-range-picker
            formControlName="dateRange"
          ></nz-range-picker>
        </tc-form-group>
      </div>
      <div class="col col-md-3">
        <tc-form-group>
          <tc-form-label>&nbsp;</tc-form-label>
          <tc-checkbox formControlName="currentBillingRule">{{ "Current_billing_rule" | translate }}</tc-checkbox>
        </tc-form-group>
      </div>
      <div class="col col-md-3">
        <tc-form-label>&nbsp;</tc-form-label>
        <button tc-button
        [beforeIcon]="'icofont-plus-circle'"
        [view]="'accent'" 
        [disabled]="!sessionsByInterpreterForm.get('interpreters').value || !sessionsByInterpreterForm.get('dateRange').value"
        (click)="recalculateCostInterpreter()">
          {{ "Recalculate" | translate }}
        </button>
      </div>
    </div>
  </tc-card>
</form>

<div *ngIf="isLoading" [ngClass]="{ loaded: pageData?.loaded }">
  <div class="app-loader"><i class="icofont-spinner-alt-4 rotate"></i></div>
</div>
<form [formGroup]="sessionsByOrgForm">
  <tc-card  [outline]="true" [view]="'info'"
  title="{{ 'Recalculate_sessions_by_organization' | translate }}"
  class="mb-0 box-padding"
>
  <div class="row">
    <div class="col col-md-3">
      <tc-form-group>
        <tc-form-label>{{ "Organization" | translate }}</tc-form-label>
        <tc-select
          placeholder="{{ 'Organization' | translate }}"
          [options]="organisationData"
          formControlName="orgId"
          [ngModel]="organizationId"
          (ngModelChange)="getUserAccount($event)"
        ></tc-select>
      </tc-form-group>
    </div>
    <div class="col col-md-2" *ngIf="sessionsByOrgForm.get('orgId').value">
      <tc-form-group>
        <tc-form-label>{{ "User_account" | translate }}</tc-form-label>
        <tc-select
          placeholder="{{ 'User_account' | translate }}"
          [options]="userAccountData"
          formControlName="userAccountId"
        ></tc-select>
      </tc-form-group>
    </div>
    <div class="col col-md-3">
      <tc-form-group>
        <tc-form-label>{{ "Time_period" | translate }}</tc-form-label>
        <nz-range-picker
        formControlName="dateRange"
        ></nz-range-picker>
      </tc-form-group>
    </div>
    <div class="col col-md-2">
      <tc-form-group>
        <tc-form-label>&nbsp;</tc-form-label>
        <tc-checkbox forControlName="currentBillingRule">{{ "Current_billing_rule" | translate }}</tc-checkbox>
      </tc-form-group>
    </div>
    <div class="col col-md-1">
      <tc-form-label>&nbsp;</tc-form-label>
      <button tc-button 
        [beforeIcon]="'icofont-plus-circle'"
        [view]="'accent'"
        (click)="recalculateCostByOrganization()"
        [disabled]="!sessionsByOrgForm.get('orgId').value || !sessionsByOrgForm.get('dateRange').value"
        >
        {{ "Recalculate" | translate }}
      </button>
    </div>
  </div>
</tc-card>
</form>

<form [formGroup]="tvaForm" >
<tc-card [title]="'TVA (in %)'" class="mb-0 box-padding"  [outline]="true" [view]="'info'">
  <div class="row">
    <div class="col col-md-4">
      <tc-form-group>
        <tc-form-label>TVA (in %)</tc-form-label>
        <tc-input formControlName="tvaInPercent"></tc-input>
      </tc-form-group>
    </div>
    <div class="col col-md-4">
      <tc-form-group>
        <tc-form-label>{{ "From_date" | translate }}</tc-form-label>
        <nz-date-picker
          formControlName="fromDate"
        ></nz-date-picker>
      </tc-form-group>
    </div>
    <div class="col col-md-4">
      <tc-form-label>&nbsp;</tc-form-label>
      <button tc-button [beforeIcon]="'icofont-plus-circle'" [view]="'accent'" (click)="submitTvaInPercent()">
        {{ "Recalculate" | translate }}
      </button>
    </div>
  </div>
</tc-card>
</form>
