import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {

  transform(value: number): string {
    let seconds = Math.floor((value / 1000) % 60);
    let minutes = Math.floor((value / (1000 * 60)) % 60);
    let hours = Math.floor((value / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? 0 + hours : hours;
    minutes = (minutes < 10) ? 0 + minutes : minutes;
    seconds = (seconds < 10) ? 0 + seconds : seconds;

    return `${hours} : ${minutes} : ${seconds}`;
  }
}
