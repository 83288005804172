import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {

    private readonly apiHost: string;
    private readonly uiHost: string;

    public constructor() {
        if (environment['apiHost']) {
            this.apiHost = environment['apiHost'];
        }
        if (environment['apiPort']) {
            this.apiHost = `${this.apiHost}:${environment['apiPort']}`;
        }
        if (environment['apiRoutePath']) {
            this.apiHost = this.apiHost + environment['apiRoutePath'];
        }
        if (environment['uiHost']) {
            this.uiHost = environment['uiHost'];
        }
        if (environment['uiPort']) {
            this.uiHost = `${this.uiHost}:${environment['uiPort']}`;
        }
    }

    public getAPIHost() {
        return this.apiHost.trim();
    }

    public getUIHost() {
        return this.uiHost.trim();
    }
}
