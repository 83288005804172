import { SETTINGS } from './settings';
import * as npm from '../../package.json';

export const environment = {
    production: false,
    env: 'staging',
    apiHost: 'https://api.staging.bhaasha.ch',
    apiPort: '',
    apiRoutePath: '/api/',
    uiHost: 'http://localhost',
    uiPort: '4200',
    backend: 'old',
    appSettings: SETTINGS,
    googleMapApiKey: 'AIzaSyDC9NlCcnNoHxTPsrIw4dhtLZagL8cxz0c',
    version: npm.version
  };
