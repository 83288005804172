import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { organizationPath } from './organization.path';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private readonly httpClientService: HttpClientService) {
  }
  public getOrganizations(orgQuery: string): Observable<{}> {
    return this.httpClientService.getServerRequest(organizationPath.getUrl('getDataUrl') + orgQuery);
  }
  public deleteOrgTableData(orgId: number): Observable<{}> {
    return this.httpClientService.deleteServerRequest(`${organizationPath.getUrl('getDataUrl')}/${orgId}`);
  }
  public getOrgTableData(): Observable<{}> {
    return this.httpClientService.getServerRequest(organizationPath.getUrl('getDataUrl') + '?limit=25&offset=1');
  }

  public getFilterOrganizations(orgQuery: string, organizations: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${organizationPath.getUrl('getDataUrl')}${orgQuery}&orgName=${organizations}`);
  }
  public searchTextFilter(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(organizationPath.getUrl('searchTextFilter') + query);
  }
}
