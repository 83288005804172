import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { HttpService } from '../../../services/http/http.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { Subscription } from 'rxjs';
import { NzI18nService, NzNotificationService, en_US, fr_FR, de_DE, it_IT } from 'ng-zorro-antd';
import { HomeService } from './home.service';
import { Router } from '@angular/router';
import { UtilityService } from '../../../shared/services/utility.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BasePageComponent implements OnInit, OnDestroy {
  totalSessions: object = {};
  subscriber: Subscription;
  dateRange: any;
  setQueryData = {};
  isLoading = false;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService, private readonly i18n: NzI18nService,
    private readonly homeService: HomeService, private readonly router: Router,
    private readonly utilityService: UtilityService, private readonly nzNotification: NzNotificationService,
  ) {
    super(store, httpSv);

    this.pageData = {
      title: '',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Bhaasha Home'
        }
      ]
    };
    const currentDate = new Date();
    this.dateRange = [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
    ];
  }

  ngOnInit() {
    super.ngOnInit();

    // set datepicker initial time and that will be current month
    this.getdetailsofSessions(this.dateRange);
    // change datepicker language
    const localeSet = { en: en_US, fr: fr_FR, de: de_DE, it: it_IT };
    const lang = localStorage.getItem('transLang');
    this.i18n.setLocale(localeSet[lang ? lang : 'fr']);
  }
  // This methos will invoke when date will change
  onChange(result: Date) {
    this.getdetailsofSessions(result);
  }

  // This method is used to get session details according to selected time interval
 public getdetailsofSessions(Date: object) {
   this.isLoading = true;
   this.setQueryData = {
      fromDate: Date[0].getTime(),
      toDate: Date[1].getTime()
    };
   const queryParams = this.utilityService.createQuery(this.setQueryData);
   this.subscriber = this.homeService.getTotalSessionDetails(queryParams).subscribe((response) => {
      if (response && response['isSuccess']) {
        this.totalSessions = response['data'];
        this.isLoading = false;
      }
    }, error => {
      this.nzNotification.error('error', error['error'].message);
      this.isLoading = false;
    });
  }

  /*
    Function to go to the sessions page when clicking on respective session box
    Requirement - We have created a HomeService which saves the sessionType and Date which
   will be then used in sessionController for showing the selected sessionType and date.
 */
  public sessionClicked(sessionType: string) {
    let queryParams = {};
    if (sessionType === 'CONFIRMED' || sessionType === 'COMPLETED') {
      queryParams = {startTime: this.dateRange[0].getTime(), endTime: this.dateRange[1].getTime(), status: sessionType };
    } else if (sessionType === 'STOPPED') {
      queryParams = { status: sessionType};
    } else if (sessionType === 'PENDING_LESS_THAN_48') {
      queryParams = { status: 'PENDING', startTime: this.dateRange[0].getTime(), endTime: new Date().getTime() + 172800000 };
    } else if (sessionType === 'PENDING_GREATER_THAN_48') {
      queryParams = { status: 'PENDING', startTime: new Date().getTime() + 172800000,
      endTime: this.dateRange[1].setFullYear(this.dateRange[1].getFullYear() + 1) };
    } else if (sessionType === 'REFUSED_LESS_THAN_48') {
      queryParams = { status: 'REFUSED', startTime: this.dateRange[0].getTime(), endTime: new Date().getTime() + 172800000 };
    } else if (sessionType === 'REFUSED_GREATER_THAN_48') {
      queryParams = { status: 'REFUSED', startTime: new Date().getTime() + 172800000,
        endTime: this.dateRange[1].setFullYear(this.dateRange[1].getFullYear() + 1) };
    } else if (sessionType === 'TOVALIDATE') {
      queryParams = { status: 'MISSED', locked: false};
    } else if (sessionType === 'NONVALIDATED') {
      queryParams = { status: 'MISSED', locked: true };
    }
    this.router.navigate(['horizontal/sessions'], {queryParams});
}

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
