import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataServiceService {
  private readonly notify = new Subject<any>();
  private readonly response = new Subject<any>();

  // Notification Observable
  notifyObservable = this.notify.asObservable();

  // Response Observable
  responseObservable = this.response.asObservable();

  constructor() {}

  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }


  public resposeData(data: any) {
    if (data) {
      this.response.next(data);
    }
  }
}
