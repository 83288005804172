import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CommonService {
  public width = 1200;
  public constructor(private readonly http: HttpClient) { }
  public getCountry() {
    return this.http.get('../../../assets/data/country.json');
  }
  public getCountryCode() {
    return this.http.get('../../../assets/data/countryCode.json');
  }
  public getFirstLanguage() {
    return this.http.get('../../../assets/data/firstLanguages.json');
  }
  public getSecondLanguage() {
    return this.http.get('../../../assets/data/secondLanguages.json');
  }
  public getLanguageLevels() {
    return this.http.get('../../../assets/data/languageLevels.json');
  }
  public getCantonData() {
    return this.http.get('../../../assets/data/canton.json');
  }
  public getInterpreterQualification() {
    return this.http.get('../../../assets/data/interpreterQualification.json');
  }
  public getModalWidth() {
    return { width: this.width };
  }
}
