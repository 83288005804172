import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { ViewUserAccountService } from '../view-user-account/view-user-account.service';
import { CommonService } from '../../../shared/services/common.service';
import { NzModalRef, NzNotificationService } from 'ng-zorro-antd';
import { ModalDataService } from '../../../shared/services/modal-data.service';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.scss'],
})
export class AddNewUserComponent implements OnInit, OnDestroy {
  subscriber: Subscription;
  modeUserData: any;
  userForm: FormGroup;
  userAccountId: number;
  countryCode: string;
  isMode = 'create';
  modalFor: string;
  userData: any;
  changedValues: any;
  prefix = [
    { label: 'Mr', value: 'Mr' },
    { label: 'Mrs', value: 'Mrs' },
  ];
  loading = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly viewUserService: ViewUserAccountService,
    private readonly commonService: CommonService,
    private readonly notification: NzNotificationService,
    private readonly modal: NzModalRef,
    private readonly modalDataService: ModalDataService,
  ) {}

  ngOnInit() {
    const modalData = this.modalDataService.getData();
    this.getCountryCode();
    this.userAccountId = modalData['modalInfo']['userAccountId'];
    this.modalFor = modalData['modalInfo']['modalFor'];
    if (modalData['modalData']) {
      this.isMode = 'update';
      this.userData = modalData['modalData'];
    } else {
      this.isMode = 'create';
    }
    this.userFormInit(this.userData);
  }

  // get country code
  private getCountryCode() {
    this.subscriber = this.commonService.getCountryCode().subscribe((res) => {
      this.countryCode = res[0].value;
    });
  }

  // Initialize User Form Data
  public userFormInit(data: any) {
    this.userForm = this.formBuilder.group({
      id: [data ? data.id : ''],
      prefix: [data ? data.prefix : ''],
      firstName: [ data ? data.firstName : '', [ Validators.pattern('[0-9 a-zA-Z-._äÄàÀéÉèÈöÖ]+$'), Validators.required]],
      lastName: [ data ? data.lastName : '', [ Validators.required, Validators.pattern('[0-9 a-zA-Z-._äÄàÀéÉèÈöÖ]+$')]],
      telephone: [data ? data.telephone : '', [Validators.pattern('[0-9]{10}')]],
      email: [data ? data.email : '', [Validators.email]],
      function: [data ? data.function : ''],
      mobileNumber: [data ? data.mobileNumber : '']
    });
  }

  // Close User Modal
  closeModal(response?: any) {
    this.userForm.reset();
    this.modal.destroy(response);
  }

  // Submit User Data
  submitUserData(form: FormGroup) {
    this.loading = true;
    this.changedValues = this.getChanges();
    if (this.isMode === 'create') {
      const userData = { user: this.changedValues, userAccount: { id: this.userAccountId }};
      userData.user['countryCode'] = this.countryCode;
      if (this.modalFor === 'Medical') {
        userData.user['role'] = 'MEDICAL_PROFESSIONAL';
      } else if (this.modalFor === 'Other') {
        userData.user['role'] = 'OTHER_PROFESSIONAL';
      }
      this.subscriber = this.viewUserService.addUser(userData).subscribe(response => {
        this.responseMessage(response, this.modalFor);
        this.loading = false;
      });
    } else {
      this.compareAndReplace();
      const userData = { user: this.userData, userAccount: { id: this.userAccountId }};
      userData.user['countryCode'] = this.countryCode;
      this.subscriber = this.viewUserService.updateUser(userData, this.userData.id).subscribe(response => {
        this.responseMessage(response, this.modalFor);
        this.loading = false;
      });
    }
  }

  // compare old values and if changes replace them
  private compareAndReplace() {
    Object.keys(this.changedValues).forEach((i) => {
        this.userData[i] = this.changedValues[i];
    });
  }

  // To check changes in form and get only changed values
  private getChanges(): any {
    const updatedValues: any = {};
    this.getUpdates(this.userForm, updatedValues);
    return updatedValues;
  }

  // To get updated values from the form
  private getUpdates( formItem: FormGroup | FormArray | FormControl, updatedValues: any, name?: string) {
    if (formItem instanceof FormControl) {
      if (name && formItem.dirty) {
        updatedValues[name] = formItem.value;
      }
    } else {
      for (const formControlName in formItem.controls) {
        if (formItem.controls.hasOwnProperty(formControlName)) {
          const formControl = formItem.controls[formControlName];
          if (formControl instanceof FormControl) {
            this.getUpdates(formControl, updatedValues, formControlName);
          } else if (
            formControl instanceof FormArray &&
            formControl.dirty &&
            formControl.controls.length > 0
          ) {
            updatedValues[formControlName] = [];
            this.getUpdates(formControl, updatedValues[formControlName]);
          } else if (formControl instanceof FormGroup && formControl.dirty) {
            updatedValues[formControlName] = {};
            this.getUpdates(formControl, updatedValues[formControlName]);
          }
        }
      }
    }
  }

  private responseMessage(res: any, responseFor: string) {
    if (res['isSuccess']) {
      this.notification.success('success', res['message']);
      this.closeModal({res: res['data'], responseFor});
    }
  }

  ngOnDestroy() {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }
}
