import { ActionReducerMap } from '@ngrx/store';
import * as fromPageData from './reducers/page-data.reducer';
import * as fromAppSettings from './reducers/app-settings.reducer';

export interface AppState {
  pageData: fromPageData.IPageData;
  appSettings: fromAppSettings.IAppSettings;
}

export const appReducer: ActionReducerMap<AppState> = {
  pageData: fromPageData.pageDataReducer,
  appSettings: fromAppSettings.appSettingsReducer,
};
