<nz-spin [nzSpinning]="loading">
  <form (ngSubmit)="addEditSession()" #f="ngForm" novalidate>
    <div class="row">
      <!-- Follow_up_session -->
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "Session_type" | translate}}<span class="req-icon">*</span></tc-form-label>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Session_type' | translate }}" required
            name="sessionType" #sessionType="ngModel" [(ngModel)]="sessionData.sessionType">
            <nz-option *ngFor="let item of sessionTypeData" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
          </nz-select>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!sessionType.valid && (sessionType.dirty || sessionType.touched)">
              {{ "Session_type" | translate}} {{ "Is_required" | translate }}
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "Session_status" | translate }}<span class="req-icon">*</span></tc-form-label>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Session_status' | translate }}" required
            [disabled] = "mode === 'Edit_sessions'"
            name="status" #status="ngModel" [(ngModel)]="sessionData.status" (ngModelChange)="onSelectSessionType($event)">
            <nz-option *ngFor="let item of sessionStatusData" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
          </nz-select>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!status.valid && (status.dirty || status.touched)">
            <p>{{ "Session_status" | translate}} {{ "Is_required" | translate }}</p>
          </tc-form-description>
        </tc-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-6">
        <tc-form-group ngModelGroup="userAccount">
          <tc-form-label>{{ "User_account" | translate}}<span class="req-icon">*</span></tc-form-label>
          <nz-select [disabled]="mode === 'Edit_sessions' || mode === 'Follow_up_session'" nzServerSearch nzShowSearch nzAllowClear
            nzPlaceHolder="{{ 'User_account' | translate }}" (nzOnSearch)="onSearchSelect($event, 'userAccount')" name="userAccountId"
            (ngModelChange)="onSelectUser($event)" required #userAccountId="ngModel" [(ngModel)]="sessionData.userAccount.id">
            <ng-container *ngFor="let item of userData">
              <nz-option *ngIf="!isLoading" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
            </ng-container>
            <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
              <i nz-icon nzType="loading" class="loading-icon"></i> Loading Data...
            </nz-option>
          </nz-select>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!userAccountId.valid && (userAccountId.dirty || userAccountId.touched)">
            <p> {{ "User_account" | translate }} {{ "Is_required" | translate }}</p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-4">
        <tc-form-group ngModelGroup="toLanguage">
          <tc-form-label>{{ "Language" | translate }}<span class="req-icon">*</span></tc-form-label>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Language' | translate }}" required
            [disabled] = "mode === 'Edit_sessions' || mode === 'Follow_up_session'"
            name="isoCode" #isoCode="ngModel" [(ngModel)]="sessionData.toLanguage.isoCode" (ngModelChange)="onSelectLanguage($event)">
            <nz-option *ngFor="let item of languageData" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
          </nz-select>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!isoCode.valid && (isoCode.dirty || isoCode.touched)">
            <p>{{ "Is_required" | translate }} </p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-2">
        <tc-form-label>&nbsp;</tc-form-label>
        <button tc-button [afterIcon]="'icofont-filter'" [tcBgColor]="'#9e9e9e'"
        (click)="addFilter('Add Filter', null)" [disabled]="!sessionData.toLanguage.isoCode">
          {{'Filters' | translate}}
				</button>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-6" *ngIf="sessionData.toLanguage.isoCode !== '' && getInterpreter.length > 0">
        <tc-form-group>
          <tc-form-label>{{ "Interpreter" | translate}}<span class="req-icon">*</span></tc-form-label>
          <!-- <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Interpreter' | translate }}" 
            [disabled]="(sessionData.toLanguage.isoCode === '') || (mode === 'Edit_sessions' || mode === 'Follow_up_session')"
            [nzMode]="sessionData.status === 'PENDING' ? 'multiple' : 'default'" name="interpreterList" #interpreterList="ngModel"
            [(ngModel)]="sessionData.interpreterList" (ngModelChange)="onSelectInterpreter($event)">
            <nz-option *ngFor="let item of getInterpreter" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
          </nz-select> -->
          <tc-select placeholder="{{ 'Interpreter' | translate }}" [options]="getInterpreter" required
          [disabled]="(sessionData.toLanguage.isoCode === '') || (mode === 'Edit_sessions' || mode === 'Follow_up_session')"
          [multiple]="sessionData.status === 'PENDING' ? true : false" name="interpreterList" #interpreterList="ngModel"
          [(ngModel)]="sessionData.interpreterList" (ngModelChange)="onSelectInterpreter($event)"
          [filter]="true" [tcShape]="5" [bgColor]="['#fff', '#fff','#fff']"
        ></tc-select>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!interpreterList.valid && (interpreterList.dirty || interpreterList.touched)">
            <p> {{ "Is_required" | translate }} </p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "From_date" | translate }}<span class="req-icon">*</span></tc-form-label>
          <nz-date-picker required name="dt" #dt="ngModel" [(ngModel)]="sessionData.dt"></nz-date-picker>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!dt.valid && (dt.dirty || dt.touched)">
            <p>{{ "Is_required" | translate }}</p>
          </tc-form-description>
        </tc-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "time" | translate}}<span class="req-icon">*</span></tc-form-label>
          <nz-time-picker [nzMinuteStep] = "15" [nzHourStep]="1" required name="st" #st="ngModel" [(ngModel)]="sessionData.st" nzFormat="HH:mm"></nz-time-picker>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!st.valid && (st.dirty || st.touched)">
            <p>{{ "Is_required" | translate }}</p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "Duration" | translate}}<span class="req-icon">*</span></tc-form-label>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Select_duration' | translate }}" required
            name="duration" #duration="ngModel" [(ngModel)]="sessionData.duration">
            <nz-option *ngFor="let item of selectDuration" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
          </nz-select>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!duration.valid && (duration.dirty || duration.touched)">
            <p>{{ "Is_required" | translate }}</p>
          </tc-form-description>
        </tc-form-group>
      </div>
    </div>
    <div class="row" *ngIf="sessionData.userAccount.id !== 0">
      <div class="col col-md-5">
        <tc-form-group ngModelGroup="owner">
          <tc-form-label>{{ "User" | translate}}<span class="req-icon">*</span></tc-form-label>
          <nz-select nzServerSearch nzShowSearch nzAllowClear nzPlaceHolder="{{ 'User' | translate }}" required
            [disabled] = "mode === 'Follow_up_session'" name="userId" #userId="ngModel"
             [(ngModel)]="sessionData.owner.id" (nzOnSearch)="onSearchSelect($event, 'user')">
            <nz-option *ngFor="let item of roleUser" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
          </nz-select>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!userId.valid && (userId.dirty || userId.touched)">
            <p>{{ "Is_required" | translate }}</p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-1">
        <tc-form-label>&nbsp;</tc-form-label>
        <button tc-button [afterIcon]="'icofont-plus'" [view]="'accent'" [square]="true" [outline]="true"
          (click)="onClickAddUser('Add User', null, 'User')"></button>
      </div>
      <div class="col col-md-5">
        <tc-form-group ngModelGroup="user">
          <tc-form-label>{{ "Doctor" | translate}}</tc-form-label>
          <nz-select nzServerSearch nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Doctor' | translate }}"
            [disabled] = "mode === 'Follow_up_session'" name="doctorId" #doctorId="ngModel"
             [(ngModel)]="sessionData.user.id" (nzOnSearch)="onSearchSelect($event, 'doctor')">
            <nz-option *ngFor="let item of doctorUser" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
          </nz-select>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!doctorId.valid && (doctorId.dirty || doctorId.touched)">
            <p> {{ "Is_required" | translate }} </p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-1">
        <tc-form-label>&nbsp;</tc-form-label>
        <button tc-button [afterIcon]="'icofont-plus'" [view]="'accent'" [square]="true"
          [outline]="true" (click)="onClickAddUser('Add Medical Professional', null, 'Medical')"></button>
      </div>
      <div class="col col-md-5">
        <tc-form-group ngModelGroup="patient">
          <tc-form-label *ngIf="currentLanguage === 'en'">{{patientLabelData?.labelNameEn}}
            <span class="req-icon" *ngIf="patientLabelData?.isRequired">*</span>
          </tc-form-label>
          <tc-form-label *ngIf="currentLanguage === 'fr'">{{patientLabelData?.labelNameEn}}
            <span class="req-icon"*ngIf="patientLabelData?.isRequired">*</span>
          </tc-form-label>
          <tc-form-label *ngIf="currentLanguage === 'de'">{{patientLabelData?.labelNameEn}}
            <span class="req-icon" *ngIf="patientLabelData?.isRequired">*</span>
          </tc-form-label>
          <tc-form-label *ngIf="currentLanguage === 'it'">{{patientLabelData?.labelNameEn}}
            <span class="req-icon" *ngIf="patientLabelData?.isRequired">*</span>
          </tc-form-label>
          <nz-select nzServerSearch nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Patient' | translate }}" [required] = "patientLabelData?.isRequired"
            [disabled] = "mode === 'Follow_up_session'" name="patientId" #patientId="ngModel"
             [(ngModel)]="sessionData.patient.id" (nzOnSearch)="onSearchSelect($event, 'patient')">
            <nz-option *ngFor="let item of patientUser" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
          </nz-select>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!patientId.valid && (patientId.dirty || patientId.touched)">
            <p> {{ "Is_required" | translate }} </p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-1">
        <tc-form-label>&nbsp;</tc-form-label>
        <button tc-button [afterIcon]="'icofont-plus'" [view]="'accent'" [square]="true" [outline]="true"
          (click)="onClickAddPatient('Add Patient', null)"></button>
      </div>
      <div class="col col-md-6" *ngIf="paymentAuthorityData && paymentAuthorityData.isShow === true">
        <tc-form-group>
          <tc-form-label>{{ paymentAuthorityData.name}}<span class="req-icon" *ngIf="paymentAuthorityData.isRequired">*</span></tc-form-label>
          <nz-select nzServerSearch nzShowSearch nzAllowClear nzPlaceHolder="{{ paymentAuthorityData.name}}" [required]="paymentAuthorityData.isRequired"
            name="paymentAuthorityOption" #paymentAuthorityOption="ngModel" [(ngModel)]="sessionData.paymentAuthorityOption.id" 
            (nzOnSearch)="onSearchSelect($event, 'payment')">
            <nz-option *ngFor="let item of paymentAuthorityData.paymentAuthorityOptions" [nzValue]="item.id" [nzLabel]="item.paymentOption"></nz-option>
          </nz-select>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!paymentAuthorityOption.valid && (paymentAuthorityOption.dirty || paymentAuthorityOption.touched)">
            {{paymentAuthorityData.name}} {{ "Is_required" | translate }}
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="categoryData && categoryData.isShow === true">
        <tc-form-group>
          <tc-form-label>{{ categoryData.name}}<span class="req-icon" *ngIf="categoryData.isRequired">*</span></tc-form-label>
          <nz-select nzServerSearch nzShowSearch nzAllowClear nzPlaceHolder="{{ categoryData.name}}" [required] ="categoryData.isRequired"
            name="categoryOption" #categoryOption="ngModel" [(ngModel)]="sessionData.categoryOption.id" (nzOnSearch)="onSearchSelect($event, 'category')">
            <nz-option *ngFor="let item of categoryData.categoryOptions" [nzValue]="item.id" [nzLabel]="item.categoryOption"></nz-option>
          </nz-select>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!categoryOption.valid && (categoryOption.dirty || categoryOption.touched)">
            {{categoryData.name}} {{ "Is_required" | translate }}
          </tc-form-description>
        </tc-form-group>
      </div>
    </div>
    <div class="row" *ngIf="sessionData.sessionType === 'INPERSON' || sessionData.sessionType === 'INTERCULTURALMEDIATION' ||
      sessionData.sessionType === 'COURSEMANAGEMENT'">
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "Location" | translate}}<span class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{ 'Location' | translate }}" (click) ="onClickAddLocation('Add Location', null)"
            name="formattedAddress" #formattedAddress="ngModel" [(ngModel)]="sessionData.formattedAddress">
          </tc-input>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!formattedAddress.valid && (formattedAddress.dirty || formattedAddress.touched)">
            <p> {{ "Is_required" | translate }}</p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "Location_details" | translate }}</tc-form-label>
          <tc-input placeholder="{{ 'Location_details' | translate }}" name="locationDetails" #locationDetails="ngModel" 
            [(ngModel)]="sessionData.locationDetails">
          </tc-input>
        </tc-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "Subject" | translate}}<span class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{ 'Subject' | translate }}" name="title" #title="ngModel" required
          [(ngModel)]="sessionData.title"></tc-input>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!title.valid && (title.dirty || title.touched)">
            <p> {{ "Is_required" | translate }} </p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{"Comments_to_interpreter" | translate}}</tc-form-label>
          <tc-input placeholder="{{ 'Comments_to_interpreter' | translate }}" name="purpose" #purpose="ngModel" [(ngModel)]="sessionData.purpose"></tc-input>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!purpose.valid && (purpose.dirty || purpose.touched)">
            <p> {{ "Is_required" | translate }} </p>
          </tc-form-description>
        </tc-form-group>
      </div>
    </div>
    <div class="row" *ngIf="sessionData.sessionType === 'INTERCULTURALMEDIATION'">
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "person_Involved_In_Conversation" | translate}}<span class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{ 'Comments_to_interpreter' | translate }}" name="personInvolvedInConversation" 
          #personInvolvedInConversation="ngModel" [(ngModel)]="sessionData.personInvolvedInConversation"></tc-input>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!personInvolvedInConversation.valid && (personInvolvedInConversation.dirty || personInvolvedInConversation.touched)">
            <p> {{ "Is_required" | translate }} </p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "other_Involved_Positions" | translate}}<span class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{ 'Comments_to_interpreter' | translate }}" name="otherInvolvedPositions" 
          #otherInvolvedPositions="ngModel" [(ngModel)]="sessionData.otherInvolvedPositions"></tc-input>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!otherInvolvedPositions.valid && (otherInvolvedPositions.dirty || otherInvolvedPositions.touched)">
            <p> {{ "Is_required" | translate }} </p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "reason_For_Coversation" | translate}}<span class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{ 'reason_For_Coversation' | translate }}" name="reasonForConversation" 
          #reasonForConversation="ngModel" [(ngModel)]="sessionData.reasonForConversation"></tc-input>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!reasonForConversation.valid && (reasonForConversation.dirty || reasonForConversation.touched)">
            <p> {{ "Is_required" | translate }} </p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "goal_of_conversation" | translate}}<span class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{ 'goal_of_conversation' | translate }}" name="goalOfConversation" 
          #goalOfConversation="ngModel" [(ngModel)]="sessionData.goalOfConversation"></tc-input>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!goalOfConversation.valid && (goalOfConversation.dirty || goalOfConversation.touched)">
            <p> {{ "Is_required" | translate }} </p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "expectations_of_intercultural_mediation" | translate}}<span class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{ 'expectations_of_intercultural_mediation' | translate }}" name="expectationOfMediation" 
          #expectationOfMediation="ngModel" [(ngModel)]="sessionData.expectationOfMediation"></tc-input>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!expectationOfMediation.valid && (expectationOfMediation.dirty || expectationOfMediation.touched)">
            <p> {{ "Is_required" | translate }} </p>
          </tc-form-description>
        </tc-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "Internal_comments" | translate }}</tc-form-label>
          <tc-input placeholder="{{ 'Internal_comments' | translate }}" name="internalComments" 
            #internalComments="ngModel" [(ngModel)]="sessionData.internalComments"></tc-input>
          <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!internalComments.valid && (internalComments.dirty || internalComments.touched)">
            <p> {{ "Is_required" | translate }} </p>
          </tc-form-description>
        </tc-form-group>
      </div>
    </div>
    <div *nzModalFooter class="actions justify-content-between">
      <button tc-button [type]="'button'" [view]="'error'" (click)="closeModal()">
        {{ "Cancel" | translate }}
      </button>
      <button tc-button [view]="'info'" (click)="addEditSession()">
        {{ mode === "Create_session" ? ("Create" | translate) : ("Update" | translate) }}
      </button>
      <button *ngIf="mode === 'follow'" tc-button [view]="'info'" (click)="addEditSession()">
        {{ "Follow_up_session" | translate }}
      </button>
    </div>
  </form>
</nz-spin>
