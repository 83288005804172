import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { viewUserAccount } from './view-user-account.path';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ViewUserAccountService {
  public constructor(private readonly httpClientService: HttpClientService) {}

  public getUserAccount(userAccountId: number): Observable<{}> {
    return this.httpClientService.getServerRequest(
      viewUserAccount.getUrl('getUsers') + userAccountId
    );
  }

  // Get User according to Roles
  public getUserAccountRoles(queryStrings: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${viewUserAccount.getUrl('getUser')}${queryStrings}`);
  }

  // Get location for user account
  public getUserListData(userAccountId: number, type: string, query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${viewUserAccount.getUrl('getLocation')}${userAccountId}/${type}?${query}`);
  }
  // get patient form data
  public getPatientForm(orgId: number): Observable<{}> {
    return this.httpClientService.getServerRequest(viewUserAccount.getUrl('patientForm') + orgId);
  }

  // Add new user API request
  public addUser( validatedData: object): Observable<{}> {
    return this.httpClientService.postServerRequest(viewUserAccount.getUrl('users'), validatedData);
  }

  // Update user account
  public updateUser( validatedData: object, userId: number): Observable<{}> {
    return this.httpClientService.putServerRequest(`${viewUserAccount.getUrl('users')}/${userId}`, validatedData);
  }

  // Delete User Account
  public deleteUser(userId: number): Observable<{}> {
    return this.httpClientService.deleteServerRequest(`${viewUserAccount.getUrl('users')}/${userId}`);
     }
  // Delete Common Service
  public deleteCommon(removeId: number, type: string): Observable<{}> {
    return this.httpClientService.deleteServerRequest(`${viewUserAccount.getUrl('getLocation')}${type}/${removeId}`);
  }

  // Update Patient Form Data
  public updatePatient( validatedData: object): Observable<{}> {
    return this.httpClientService.putServerRequest(`${viewUserAccount.getUrl('getLocation')}patient`, validatedData);
  }
  // Add Patient Form Data
  public addPatient( validatedData: object): Observable<{}> {
    return this.httpClientService.postServerRequest(`${viewUserAccount.getUrl('getLocation')}patient`, validatedData);
  }
  // Add Location Data
  public addLocation( validatedData: object): Observable<{}> {
    return this.httpClientService.postServerRequest(`${viewUserAccount.getUrl('locations')}`, validatedData);
  }
  // Add Location Data
  public updateLocation( validatedData: object): Observable<{}> {
    return this.httpClientService.putServerRequest(`${viewUserAccount.getUrl('locations')}`, validatedData);
  }

}
