import { environment } from '../../../../environments/environment';
const path = 'organizations/';

export const urls = {
    postDatURL: 'organizations',
    updateDataURL : path,
    getQualification : path
};

export const oldUrls = {
    postDatURL: 'v4/organizations',
    updateDataURL : `v4/${path}`,
    getQualification : `v4/${path}`

};
export const newOrganization = {
    getUrl(urlName: string) {
       if (environment.backend === 'old') {
           return oldUrls[urlName];
       } else {
           return urls[urlName];
       }
   }
};
