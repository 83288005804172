<!-- <tc-dropdown class="item">
  <tc-dropdown-button>
    <tc-icon [iconClass]="'icofont icofont-notification'" [tcFontSize]="26"></tc-icon>
    <tc-badge *ngIf="notifications && notifications.length" [view]="'error'" [size]="'sm'">
      {{ notifications.length }}
    </tc-badge>
  </tc-dropdown-button> -->

  <!-- <tc-dropdown-content [align]="'right'" [animation]="'fadeInUp'" [offset]="[10, 0]">
    <div class="menu-header">
      <h4 class="h5 menu-title mt-0 mb-0">Notifications</h4>

      <a href="#" (click)="$event.preventDefault(); notifications = []" [tcColor]="['#ed5564', '#8E333C']">Clear All</a>
    </div>

    <ul class="list">
      <ng-container *ngIf="notifications && notifications.length">
        <li *ngFor="let item of notifications">
          <a href="#">
            <span class="icon {{ item.icon }}"></span>

            <div class="content">
              <span class="desc">{{ item.title }}</span>

              <span class="date">{{ item.date }}</span>
            </div>
          </a>
        </li>
      </ng-container>

      <li *ngIf="!notifications || (notifications.length === 0)">
        <span class="empty-item">No notifications</span>
      </li>
    </ul>

    <div class="menu-footer" *ngIf="notifications && notifications.length">
      <a
        tc-button
        href="#"
        (click)="$event.preventDefault()"
        [view]="'accent'"
        [block]="true"
        [afterIcon]="'icofont-tasks-alt'"
      >
        View all notifications
      </a>
    </div>
  </tc-dropdown-content>
</tc-dropdown> -->

<tc-dropdown class="item" [close]="closeDropdown">
  <tc-dropdown-button>
    <div class="d-flex align-items-center">
      <tc-avatar [src]="" class="mr-1"></tc-avatar>
      <i class="icofont-simple-down"></i>
    </div>
  </tc-dropdown-button>

  <tc-dropdown-content [align]="'right'" [animation]="'fadeInUp'" [offset]="[10, 0]" [width]="180">
    <!-- <ul class="list">
      <li>
        <a href="#" (click)="goTo($event, 'edit-account')" class="align-items-center">
          <span class="icon icofont-ui-home"></span>
          Edit account
        </a>
      </li>

      <li>
        <a href="#" (click)="goTo($event, 'user-profile')" class="align-items-center">
          <span class="icon icofont-ui-user"></span>
          User profile
        </a>
      </li>

      <li>
        <a href="#" (click)="goTo($event, 'events-calendar')" class="align-items-center">
          <span class="icon icofont-ui-calendar"></span>
          Calendar
        </a>
      </li> -->
<ul class="list">
      <li>
        <a href="#" (click)="goToSettings($event)" class="align-items-center">
          <span class="icon icofont-ui-settings"></span>
          Settings
        </a>
      </li>

      <li>
        <a href="#" (click)="logout($event)" class="align-items-center">
          <span class="icon icofont-logout"></span>
          Log Out
        </a>
      </li>
    </ul>
  </tc-dropdown-content>
</tc-dropdown>
