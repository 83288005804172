<nz-spin [nzSpinning]="loading">
    <div class="row">
        <div class="col-sm-12">
            <tc-form-group>
                <tc-form-label>Export Format</tc-form-label>
                <tc-radio [value]="exportType" [direction]="'horizontal'" [(ngModel)]="exportType" (ngModelChange)="onclickExportType($event)">
                    <tc-radio-option [value]="'new'">With Filters</tc-radio-option>
                    <tc-radio-option [value]="'old'">Without Filters</tc-radio-option>
                    </tc-radio>
            </tc-form-group>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <tc-form-group>
                <tc-form-label>Select Type</tc-form-label>
                <tc-radio [direction]="'horizontal'" [(ngModel)]="selectType" (ngModelChange)="onclickSelectType($event)">
                    <tc-radio-option *ngIf="role ==='IG Manager'" [value]="'organization'">Organization</tc-radio-option>
                    <tc-radio-option [value]="'client'">User Account</tc-radio-option>
                    <tc-radio-option [value]="'interpreter'">Interpreter</tc-radio-option>
                    </tc-radio>
            </tc-form-group>
        </div>
        <div class="col-sm-6" *ngIf="exportType === 'old'">
            <tc-form-label>Time Period</tc-form-label>
            <nz-range-picker nz-input placeholder="Search" [(ngModel)]="timePeriod"></nz-range-picker>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6" *ngIf="selectType === 'organization' && exportType === 'old' && role ==='IG Manager'">
            <tc-form-label>Organization</tc-form-label>
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Organization" [(ngModel)] = "organizationId" (ngModelChange)="onSelectDropdown($event)">
                <ng-container *ngFor="let item of organizations">
                    <nz-option *ngIf="!isLoading" [nzValue]="item.id" [nzLabel]="item.name"></nz-option>
                </ng-container>
                  <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i> Loading Data...
                  </nz-option>
            </nz-select>
        </div>
        <div class="col-sm-6" *ngIf="selectType === 'client'">
            <tc-form-label>User Account</tc-form-label>
            <nz-select nzServerSearch nzShowSearch nzAllowClear nzPlaceHolder="Select User Account" [(ngModel)] = "userAccountId" 
                (nzOnSearch)="onSearchSelect($event, 'userAccount')" (ngModelChange)="onSelectDropdown($event)">
                <ng-container *ngFor="let item of userAccounts">
                    <nz-option *ngIf="!isLoading" [nzValue]="item.id" [nzLabel]="item.name"></nz-option>
                </ng-container>
                  <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i> Loading Data...
                  </nz-option>
            </nz-select>
        </div>
        <div class="col-sm-6" *ngIf="selectType === 'interpreter'">
            <tc-form-label>Interpreter</tc-form-label>
            <nz-select nzServerSearch nzShowSearch nzAllowClear nzPlaceHolder="Select Interpreter" [(ngModel)] = "interpreterId" 
                (nzOnSearch)="onSearchSelect($event, 'interpreter')" (ngModelChange)="onSelectDropdown($event)">
                <ng-container *ngFor="let item of interpreters">
                    <nz-option *ngIf="!isLoading" [nzValue]="item.id" [nzLabel]="item.name"></nz-option>
                  </ng-container>
                  <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i> Loading Data...
                  </nz-option>
            </nz-select>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <tc-form-group>
                <tc-form-label>{{'File_format' | translate}} {{selectType}}</tc-form-label>
                    <nz-radio-group [value]="docType" [(ngModel)]="docType" (ngModelChange)="onclickDocType($event)">
                        <label nz-radio nzValue="pdf">PDF</label>
                        <label nz-radio nzValue="csv">CSV</label>
                        <label nz-radio nzValue="AbacusXML-FR"
                        *ngIf="(role !== 'Admin' && selectType === 'interpreter'  && role != 'Interpreter')">XML</label>
                        <label nz-radio nzValue="AbacusXML-Invoice" 
                            *ngIf ="((role == 'Admin' || role == 'IG Manager' || role == 'Manager') && (selectType === 'client' || selectType === 'organization'))">AbacusXML-Invoice
                        </label>
                        <label *ngIf ="(role == 'IG Manager' && selectType === 'interpreter')" nz-radio nzValue="swissSalary">Swiss Salary</label>
                        <label *ngIf ="((csvExportFormat?.selected === true) && role == 'IG Manager' && selectType === 'interpreter')" nz-radio nzValue="SalarySummary">Salary Summary</label>
                        <label *ngIf ="((isShowMyCompanyExport === true) && role == 'IG Manager' && selectType === 'organization')" nz-radio nzValue="implingord">MyCompany</label>
                    </nz-radio-group>
            </tc-form-group>
        </div>
    </div>
    <div class="row" *ngIf="(role !== 'Admin' && role !== 'Interpreter') && docType == 'implingord'">
        <div class="col-sm-12">
            <tc-form-label>{{'Export_format' | translate}}</tc-form-label>
            <nz-radio-group [value]="exportFormat" [(ngModel)]="exportFormat">
                <label nz-radio nzValue="individualSessionBilling">{{'Individual_session_billing' | translate}}</label>
                <label nz-radio nzValue="groupedAllSessionBilling">{{'Grouped_session_billing' | translate}}</label>
                <label nz-radio nzValue="groupedByCustomerAccount">{{'Grouped_by_customer_account' | translate}}</label>
                <label nz-radio nzValue="groupedByPatient">{{'Grouped_by_Patient' | translate}}</label>
                <label nz-radio nzValue="groupedByPaymentAuthority">{{'Grouped_by_payment_authority' | translate}}</label>
            </nz-radio-group>
        </div>
    </div>
    <div class="row" *ngIf="(role !== 'Admin' && role !== 'Manager') && docType == 'AbacusXML-FR' && selectType === 'interpreter' && XmlFormatOptions.length > 0">
        <div class="col-sm-12">
            <tc-form-label>{{'Export_format' | translate}}</tc-form-label>
            <nz-radio-group [value]="exportFormat" [(ngModel)]="exportFormat" *ngFor="let item of XmlFormatOptions">
                <label nz-radio nzValue="{{item.xmlFormat.name}}">{{item.label ? item.label : item.xmlFormat.name}}</label>
            </nz-radio-group>
        </div>
    </div>
    <div class="row" *ngIf="(role !== 'Admin' && role !== 'Interpreter') && docType == 'pdf' && pdfFormatOptions.length > 0">
        <div class="col-sm-12">
            <tc-form-label>{{'Export_format' | translate}}</tc-form-label>
            <nz-radio-group [value]="exportFormat" [(ngModel)]="exportFormat" >
                <label *ngFor="let item of pdfFormatOptions" nz-radio nzValue="{{item.pdfFormat.name}}">{{item.label ? item.label : item.pdfFormat.name}}</label>
                <label *ngIf="!pdfFormatOptions" nz-radio nzValue="MultipleSession">{{'Multiple_session' | translate}}</label>
                <label *ngIf="!pdfFormatOptions" nz-radio nzValue="SingleSession">{{'Single_session' | translate}}</label>
            </nz-radio-group>
        </div>
    </div>
</nz-spin>
<div *nzModalFooter>
    <button [disabled] = "!(userAccountId || interpreterId || organizationId)" nz-button class="login-form-button" (click)="onClickExport()">{{'Exporter' | translate}}</button>
    <button nz-button nzType="primary" (click)="cancelModal()">{{'Cancel' | translate}}</button>
</div>