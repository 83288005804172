export class Organization {
  name = '';
  domainName: string;
  manager = {
    country: {
      iso: '',
    },
    countryCode: '',
    firstName: '',
    lastName: '',
    email: '',
    credentials: {
      userId: '',
      password: ''
    },
    address: '',
    city: '',
    zipcode: '',
    telephone: ''
  };
  isInpersonSessionDistanceLimit = -1;
  inPersonSessionDistanceLimit = 0;
  isInterpreterTelephoneShow = false;
  isInterpreterAddressShow = true;
  interpreterAvailabilityDependent = false;
  isInterpreterEmailShow = false;
  isLocationMandatory = false;
  isUserAllowed = false;
  isCourseManagementSessionEnabled = false;
  isValidationEnabled = false;
  isPatientOriginRequired = false;
  isPatientOriginMandatory = false;
  isOriginGenderRequired = false;
  showInterpretersExport = false;
  enableDuration = false;
  showNotAvailableInterpreters = false;
  isInterculturalMediationSessionEnabled = false;
  isFollowUpNotAllowed = false;
  enableFollowupMediation = false;
  enableFollowupCourse = false;
  showOtherQualifications = false;
  isSessionLimitAppliedUsers = false; // label changed from Apply Session Limit For Users to Block new bookings
  timeDelay = 30;
  zConstant = 1;
  mycBaseTarifNo = 2034;
  mycReducedTarifNo = 2041;
  addressKey = '';
  travelCostItemNumber = '';
  qualifications: [
    {
      id: number,
      isFixedQualificationEnabled: boolean
      isSelected: boolean,
      label: string
    }
  ];
  allBhaashaInterpreterGroupSelected: boolean;
  bucketTermsConditionFolder: string;
  categories: [];
  dynamicField: {};
  defaultQualification: {};
  feedbackValidationForms: [];
  id: number;
  isCancellationDefaultInput: boolean;
  isEnableTermsAndCondition: boolean;
  isRescheduleDefaultInput: boolean;
  paymentAuthorities: [];
  sessionCancellationReasons: [];
  sessionRescheduleReasons: [];
  sessionCancellationMessage: string;
  userGuide: string;
  kmsLimit = 'unlimitedKm';
}
