export class Patient {
    'prefix': string;
    'lastName': string;
    'dob': string;
    'category': string;
    'evamNumber': string;
    'firstName': string;
    'ippNumber': string;
    'email': string;
    'telephone': string;
    'combinedNumber': string;
    'countryCode': string;
    'country': string;
    'gender': string;
    'patientGroup': string;
    'patientInsurance': string;
    'patientGkvInsurance': string;
    'patientOtherInsurance': string;
    'id': string;
}
