import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { UIModule } from '../ui/ui.module';
import { LayoutModule } from '../layout/layout.module';
import { MainPageComponent } from './main';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { HomeComponent } from './dashboard/home/home.component';
import { PageSignInComponent } from './sign-in/sign-in.component';
import { PageSignUpComponent } from './sign-up/sign-up.component';
import { Page404Component } from './page-404/page-404.component';
import { Page500Component } from './page-500/page-500.component';
import { StoreModule } from '@ngrx/store';
import { pageDataReducer } from '../store/reducers/page-data.reducer';
import { appSettingsReducer } from '../store/reducers/app-settings.reducer';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UIModule,
    LayoutModule,
    NgZorroAntdModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({
      pageData: pageDataReducer,
      appSettings: appSettingsReducer,
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapApiKey,
      language: localStorage.getItem('mapLanguage') || 'en',
    }),
  ],
  declarations: [
    MainPageComponent,
    HomeComponent,
    PageSignInComponent,
    PageSignUpComponent,
    Page404Component,
    Page500Component
  ],
  exports: [ ],
  entryComponents: [ ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PagesModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
