export class UserAccount {
    organization: any;
    department = {
        id: 0
    };
    userAccountManager = {
        firstName: '',
        lastName: '',
        email: '',
        countryCode: '+41',
        credentials: {
            userId: '',
            password: '',
        },
        address: '',
        city: '',
        telephone: '',
        zipcode: '',
        country: {
            iso: ''
        },
        isSelected: false
    };
    hourLimitValue: number;
    minuteLimitValue: number;
    institution: string;
    addressKey: string;
    costCenter: string;
    userAccountStatus = 'INACTIVE';
    customerSince: number;
    postalAddress: string;
    isNewsletterSelected = false;
    isChristmasCardSelected = false;
    isContractCustomer = false;
    enableDefaultQualification = false;
    isSessionLimitApplied = false;
    sessionLimitValue: number;
    branch = {
        id: 0
    };
    branchOption = {
        id: 0
    };
    branchOptionSubOption = {
        id: 0
    };
    billedTravelCost: number;
    isMultipleBilling = false;
    townShip: string;
    activity: string;
    isTrainingApplied = false;
    trainingArea: string;
    facility: string;
    otherFacility: string;
    jobTitle: string;
    enableVoiceValidationEmail = false;
    enableInPersonValidationEmail = false;
    enableVideoValidationEmail = false;
    additionalEmailAddress: string;
    billingName: string;
    pdfBillingAddress = {
        address: '',
        nameOverFlow: '',
        department: '',
        street: '',
        houseNumber: '',
        postal: '',
        zipCode: '',
        city: '',
        countryCode: ''
    };
    billingEmail: string;
    billingTemplateName: string;
    latitude: number;
    longitude: number;
    id: number;
}
