<nz-spin [nzSpinning]="loading">
  <div class="row">
    <div class="col-sm-8">
      <tc-form-group>
        <tc-input
          placeholder="Enter a Location"
          [(ngModel)]="address"
        ></tc-input>
      </tc-form-group>
    </div>
    <div class="col-md-4">
      <span class="trans-lang">
        <button
          tc-button
          (click)="selectLang('en')"
          [ngClass]="{ active: transLangButton === 'en' }"
        >
          EN
        </button>
        <button
          tc-button
          (click)="selectLang('fr')"
          [ngClass]="{ active: transLangButton === 'fr' }"
        >
          FR
        </button>
        <button
          tc-button
          (click)="selectLang('de')"
          [ngClass]="{ active: transLangButton === 'de' }"
        >
          DE
        </button>
        <button
          tc-button
          (click)="selectLang('it')"
          [ngClass]="{ active: transLangButton === 'it' }"
        >
          IT
        </button>
      </span>
    </div>
  </div>
  <agm-map [latitude]="lat" [longitude]="lng">
    <agm-marker
      [latitude]="lat"
      [longitude]="lng"
      [markerDraggable]="true"
      (dragEnd)="markerDragEnd($event)"
    ></agm-marker>
  </agm-map>
  <div *nzModalFooter class="actions justify-content-between">
    <button
      tc-button
      [type]="'button'"
      [view]="'error'"
      (click)="closeLocationModal()"
    >
      {{ "Cancel" | translate }}
    </button>
    <button tc-button [view]="'info'" (click)="submitLocationData()">
      {{
        isMode === "create" ? ("Select" | translate) : ("Update" | translate)
      }}
    </button>
  </div>
</nz-spin>
