import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { ViewUserAccountService } from '../view-user-account/view-user-account.service';
import { NzModalRef, NzNotificationService } from 'ng-zorro-antd';
import { ModalDataService } from '../../../shared/services/modal-data.service';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss'],
})
export class AddLocationComponent implements OnInit, OnDestroy {
  lat: number;
  lng: number;
  zoom: number;
  address: string;
  locationData: any;
  selectedData: any;
  private geoCoder;
  transLangButton = 'fr';
  isMode = 'create';
  mapAction: string;
  subscription: Subscription;
  loading = false;

  constructor(
    private readonly mapsAPILoader: MapsAPILoader,
    private readonly viewUserService: ViewUserAccountService,
    private readonly notification: NzNotificationService,
    private readonly modal: NzModalRef,
    private readonly modalDataService: ModalDataService,
  ) { }

  ngOnInit() {
    const modalData = this.modalDataService.getData();
    this.selectedData = modalData['selectedData'];
    this.mapAction = modalData['modalInfo']['action'];
    if (modalData['modalData']) {
      this.isMode = 'update';
      this.locationData = modalData['modalData'];
    } else {
      this.isMode = 'create';
    }
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
    this.openLocationModal(this.locationData);
  }

  // Open Location Modal
  public openLocationModal(data?: any) {
    if (data) {
      this.address = this.locationData.location.formattedAddress;
      this.lat = this.locationData.location.latitude;
      this.lng = this.locationData.location.longitude;
    } else {
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
      });
    }
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.lat, this.lng);
      });
    }
  }

  // when languages will change then this method will invoke use to change language and set for ahead process.
  selectLang(lang: string) {
    this.transLangButton = lang;
    localStorage.setItem('mapLanguage', this.transLangButton);
    this.closeLocationModal();
    setTimeout(() => {
      this.openLocationModal(this.locationData);
    }, 500);
  }

  // Close Location Modal
  public closeLocationModal(response?: any) {
    this.modal.destroy(response);
  }

  // Mouse Marker Drag event
  markerDragEnd($event: MouseEvent) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    this.getAddress(this.lat, this.lng);
  }

  // Getting Address
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  submitLocationData() {
    this.loading = true;
    if (this.isMode === 'update') {
      this.locationData.location.formattedAddress = this.address;
      this.locationData.location.latitude = this.lat;
      this.locationData.location.longitude = this.lng;
      if (this.locationData.userAccount) {
        Object.keys(this.locationData.userAccount).forEach((i) => { delete this.locationData.userAccount[i]; });
      }
      this.locationData.userAccount.id = this.selectedData.userId;
      this.subscription = this.viewUserService.updateLocation(this.locationData).subscribe((res) => {
        this.responseMessage(res);
      });
    } else if (this.selectedData.orgId === '' || this.selectedData.userId === '') {
      const res = { isSuccess: true,
        data: { formattedAddress: this.address, latitude: this.lat, longitude: this.lng }, message: 'Location Selection Sucess'
      };
      this.responseMessage(res);
    } else {
      const location = { formattedAddress: this.address, latitude: this.lat, longitude: this.lng, organization: {
          id: this.selectedData.orgId }
      };
      const userAccount = { id: this.selectedData.userId };
      const data = { location, userAccount };
      this.subscription = this.viewUserService.addLocation(data).subscribe((res) => {
        this.responseMessage(res);
      });
    }
  }

  private responseMessage(res: any) {
    if (res['isSuccess']) {
      this.closeLocationModal({ res: res['data'], responseFor: 'Location' });
      this.notification.success('success', res['message']);
      this.loading = true;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
