import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { homeUrls } from '../home/home.path';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  public constructor(private readonly httpClientService: HttpClientService) {
  }

  // get tottal session details
  public getTotalSessionDetails(query: any): Observable<{}>   {
    return this.httpClientService.getServerRequest(homeUrls.getUrl('getTotalSessionDetails') + query);
  }

}
