import { environment } from '../../../../environments/environment';

export const urls = {
    getCalendarSessions: 'interpreters/busy-events/'
};

export const oldUrls = {
    getCalendarSessions: 'v4/interpreters/busy-events/'
};
export const agendaUrls = {
    getUrl(urlName: string) {
       if (environment.backend === 'old') {
           return oldUrls[urlName];
       } else {
           return urls[urlName];
       }
   }
};
