<ng-container *ngIf="type === 'horizontal'">
  <div class="left-part d-flex align-items-center">
    <span class="navbar-button bg animated-bg d-lg-none"></span>
    <span class="sk-logo bg animated-bg d-none d-lg-block"></span>
    <span class="search d-none d-md-block bg animated-bg"></span>
  </div>

  <div class="right-part d-flex align-items-center">
    <div class="icon-box">
      <span class="icon bg animated-bg"></span>
      <span class="badge"></span>
    </div>
    <span class="avatar bg animated-bg"></span>
  </div>
</ng-container>

<ng-container *ngIf="type === 'vertical'">
  <div class="top-part">
    <div class="sk-logo bg animated-bg"></div>
    <div class="sk-menu">
      <span class="sk-menu-item menu-header bg-1 animated-bg"></span>
      <span class="sk-menu-item bg animated-bg w-75"></span>
      <span class="sk-menu-item bg animated-bg w-80"></span>
      <span class="sk-menu-item bg animated-bg w-50"></span>
      <span class="sk-menu-item bg animated-bg w-75"></span>
      <span class="sk-menu-item bg animated-bg w-50"></span>
      <span class="sk-menu-item bg animated-bg w-60"></span>
    </div>
    <div class="sk-menu">
      <span class="sk-menu-item menu-header bg-1 animated-bg"></span>
      <span class="sk-menu-item bg animated-bg w-60"></span>
      <span class="sk-menu-item bg animated-bg w-40"></span>
      <span class="sk-menu-item bg animated-bg w-60"></span>
      <span class="sk-menu-item bg animated-bg w-40"></span>
      <span class="sk-menu-item bg animated-bg w-40"></span>
      <span class="sk-menu-item bg animated-bg w-40"></span>
      <span class="sk-menu-item bg animated-bg w-40"></span>
    </div>
    <div class="sk-menu">
      <span class="sk-menu-item menu-header bg-1 animated-bg"></span>
      <span class="sk-menu-item bg animated-bg w-60"></span>
      <span class="sk-menu-item bg animated-bg w-50"></span>
    </div>
    <div class="sk-button animated-bg w-90"></div>
  </div>

  <div class="bottom-part">
    <div class="sk-menu">
      <span class="sk-menu-item bg-1 animated-bg w-60"></span>
      <span class="sk-menu-item bg-1 animated-bg w-80"></span>
    </div>
  </div>

  <div class="horizontal-menu">
    <span class="sk-menu-item bg animated-bg"></span>
    <span class="sk-menu-item bg animated-bg"></span>
    <span class="sk-menu-item bg animated-bg"></span>
    <span class="sk-menu-item bg animated-bg"></span>
    <span class="sk-menu-item bg animated-bg"></span>
    <span class="sk-menu-item bg animated-bg"></span>
  </div>
</ng-container>
