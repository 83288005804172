
export const SETTINGS = {
  boxed: false, // boolean
  layout: 'horizontal', // horizontal | vertical
  sidebarBg: '#FBFBFB', // string
  sidebarColor: '#1F2022', // string
  sidebarOpened: false, // boolean
  topbarBg: '#ffffff', // string
  topbarColor: '#1F2022'// string
};
