import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { IOption } from '../../../ui/interfaces/option';
import { RecalculateService } from './recalculate.service';
import { Subscription } from 'rxjs';
import { UtilityService } from '../../../shared/services/utility.service';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { DataServiceService } from '../../../shared/services/data-share.service';

@Component({
  selector: 'app-recalculate',
  templateUrl: './recalculate.component.html',
  styleUrls: ['./recalculate.component.scss'],
})
export class RecalculateComponent extends BasePageComponent implements OnInit, OnDestroy {
  dateRange: Date[];
  date: Date;
  userAccountData: IOption[];
  interpreterData: IOption[];
  organisationData: IOption[];
  subscriber: Subscription;
  limit = -1;
  offset = 1;
  organizationId: number;
  recalculateSessionForm: FormGroup;
  sessionsByOrgForm: FormGroup;
  tvaForm: FormGroup;
  sessionsByInterpreterForm: FormGroup;
  isLoading: boolean;
  tvaId: number;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    private readonly recalculateService: RecalculateService,
    private readonly utilityService: UtilityService,
    private readonly formBuilder: FormBuilder,
    private readonly notificationService: NzNotificationService,
  ) {
    super(store, httpSv);

    this.pageData = {
      title: 'Recalculate Sessions',
      loaded: true,
      breadcrumbs: [{ title: 'Recalculate Sessions'}],
    };
    this.recalculateSessionForm = this.formBuilder.group({ sessionId: [''], currentBillingRule: [false] });
    this.sessionsByInterpreterForm = this.formBuilder.group({ interpreters: [''], dateRange: [''], currentBillingRule: [false] });
    this.sessionsByOrgForm = this.formBuilder.group({ orgId: [''], dateRange: [''], currentBillingRule: [false], userAccountId: [''] });
    this.tvaForm = this.formBuilder.group({ tvaInPercent: [''], fromDate: [''] });
  }

  ngOnInit() {
    super.ngOnInit();
    this.getInterpreters();
    this.getOrganisation();
    this.getTvaInPercent();
  }

  // Interpreter get method
  getInterpreters() {
    const queryParams = this.utilityService.createQuery({
      limit: this.limit,
      offset: this.offset,
    });
    this.subscriber = this.recalculateService
      .getDataList(queryParams, 'getInterpreter').pipe(map((resData) => {
          return {
            data: resData['response']['interpreters'].map((res) => {
              return {
                label: res.fullName,
                value: res.id,
              };
            }),
          };
        })
      )
      .subscribe((res) => {
        this.interpreterData = res.data;
      });
  }

  // Organisation get API call
  getOrganisation() {
    const queryParams = this.utilityService.createQuery({
      limit: this.limit,
      offset: this.offset,
    });
    this.subscriber = this.recalculateService
      .getDataList(queryParams, 'getOrg')
      .pipe(
        map((resData) => {
          return {
            data: resData['data']['organizations'].map((res) => {
              return {
                label: res.name,
                value: res.id,
              };
            }),
          };
        })
      )
      .subscribe((res) => {
        this.organisationData = res.data;
      });
  }

  // Get TVA % on page Load
  // getTvaData() {
  //   this.subscriber = this.recalculateService.getTva().subscribe((res) => {
  //     console.log(res);
  //   });
  // }

  // get Tva in percent
  getTvaInPercent() {
    this.isLoading = true;
    this.recalculateService.getTva().subscribe((response) => {
      if (response && response['isSuccess']) {
        this.isLoading = false;
        this.tvaForm = this.formBuilder.group({
          tvaInPercent: [response['data']['tvaInPercent']],
          fromDate: [new Date(response['data']['fromDate'])]
        });
        this.tvaId = response['data']['id'];
      }
    });
  }
  // Get user account based on orgID
  getUserAccount(orgId: any) {
    if(!orgId){
      return;
    }
    const queryParams = this.utilityService.createQuery({
      limit: this.limit,
      offset: this.offset,
    });
    this.subscriber = this.recalculateService
      .getUserAccount(queryParams, orgId)
      .pipe(
        map((resData) => {
          return {
            data: resData['data']['userAccounts'].map((res) => {
              return {
                label: res.userAccountManager.fullName,
                value: res.id,
              };
            }),
          };
        })
      )
      .subscribe((res) => {
        this.userAccountData = res.data;
      });
  }


  // get user account list
  // getUserAccountList(orgId) {
  //   const query = this.utilityService.createQuery({ limit: -1, offset: 1 });
  //   this.recalculateService.getUserAccount(query, orgId).subscribe((response) => {
  //     if (response && response['isSuccess']) {
  //     this.userAccountData = response['data']['userAccounts'];
  //     }
  //   });
  // }
  // Recalculate Session by interpreter
  // recalculateSessionByInterpreter(data: any) {}

  // Tva in %
  // tvaInPercent(data: any) {}
 // recalculate cost
 recalculateCost() {
  const session = {
    sessionId: this.recalculateSessionForm.value.sessionId,
    currentBillingRule: this.recalculateSessionForm.value.currentBillingRule
  };
  for (const i in this.recalculateSessionForm.controls) {
    if (this.recalculateSessionForm.controls.hasOwnProperty(i)) {
      this.recalculateSessionForm.controls[i].markAsDirty();
      this.recalculateSessionForm.controls[i].updateValueAndValidity();
    }
    
  // tslint:disable-next-line: align
  } if (this.recalculateSessionForm.valid) {
    this.subscriber = this.recalculateService.recalculateCost(session).subscribe((response) => {
      this.getResponse(response);
    });
  }
} 

recalculateCostInterpreter() {
  const interpreter = {
    currentBillingRule: this.sessionsByInterpreterForm.value.currentBillingRule,
    interpreters: ((this.sessionsByInterpreterForm.value.interpreters).join()),
    fromDate: new Date(this.sessionsByInterpreterForm.value.dateRange[0]).getTime(),
    toDate: new Date(this.sessionsByInterpreterForm.value.dateRange[1]).getTime(),
  };
  for (const i in this.sessionsByInterpreterForm.controls) {
    if (this.sessionsByInterpreterForm.controls.hasOwnProperty(i)) {
      this.sessionsByInterpreterForm.controls[i].markAsDirty();
      this.sessionsByInterpreterForm.controls[i].updateValueAndValidity();
    }
  }
  if (this.sessionsByInterpreterForm.valid) {
    const queryParams = this.utilityService.createQuery({
      interpreters: interpreter.interpreters, fromDate: interpreter.fromDate,
      toDate: interpreter.toDate, currentBillingRule: interpreter.currentBillingRule
    });
    this.subscriber = this.recalculateService.recalculateCostForOrg(interpreter, queryParams).subscribe((response) => {
      this.getResponse(response);
    });
  }
}

recalculateCostByOrganization() {
    const organization = {
      currentBillingRule: this.sessionsByOrgForm.value.currentBillingRule,
      orgId: this.sessionsByOrgForm.value.orgId,
      fromDate: new Date(this.sessionsByOrgForm.value.dateRange[0]).getTime(),
      toDate: new Date(this.sessionsByOrgForm.value.dateRange[1]).getTime(),
      userAccountId: this.sessionsByOrgForm.value.userAccountId
    };
    for (const i in this.sessionsByOrgForm.controls) {
      if (this.sessionsByOrgForm.controls.hasOwnProperty(i)) {
        this.sessionsByOrgForm.controls[i].markAsDirty();
        this.sessionsByOrgForm.controls[i].updateValueAndValidity();
      }
    }
    if (this.sessionsByOrgForm.valid) {
      const queryParams = this.utilityService.createQuery({
        orgId: organization.orgId,
        fromDate: organization.fromDate,
        toDate: organization.toDate,
        currentBillingRule: organization.currentBillingRule, 
        userAccountId: organization.userAccountId
      });
      this.subscriber = this.recalculateService.recalculateCostForOrg(organization, queryParams).subscribe((response) => {
        this.getResponse(response);
      });
    }
  }
  submitTvaInPercent() {
    const tvaData = {
      tvaInPercent: this.tvaForm.value.tvaInPercent,
      fromDate: new Date(this.tvaForm.value.fromDate).getTime(),
      id: this.tvaId
    };
    for (const i in this.tvaForm.controls) {
      if (this.tvaForm.controls.hasOwnProperty(i)) {
        this.tvaForm.controls[i].markAsDirty();
        this.tvaForm.controls[i].updateValueAndValidity();
      }
    }
    if (this.tvaForm.valid) {
      this.subscriber = this.recalculateService.updateTvaInPercent(tvaData).subscribe((response) => {
        this.getResponse(response);
      });
    }
  }
  // get interpreter list
  // getInterpreter() {
  //   const query = this.utilityService.createQuery({ limit: -1, offset: 1 });
  //   this.recalculateService.getDataList(query, 'getInterpreter').subscribe((response) => {
  //     if (response && response['isSuccess']) {
  //     this.interpreters = response['response']['interpreters'];
  //     }
  //   });
  // }

  // get interpreter list
  // getOrganization() {
  //   const query = this.utilityService.createQuery({ limit: -1, offset: 1 });
  //   this.recalculateService.getDataList(query, 'getOrg').subscribe((response) => {
  //     if (response && response['isSuccess']) {
  //     this.organizations = response['data']['organizations'];
  //     }
  //   });
  // }
  // chnageOrganization(id: number) {
  //   this.organizationSelected = true;
  //   this.getUserAccountList(id);
  // }


  private getResponse(response: any) {
    if (response && response['isSuccess']) {
      this.notificationService.success('Success', response['message']);
    } else {
      this.notificationService.success('Error', response['error']);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }
}
