import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { HttpService } from '../../../services/http/http.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { AssignInterpreterService } from './interpreter.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { DownloadFileService } from '../../../shared/services/download-file.service';
import { NzModalService, NzNotificationService, NzTableQueryParams } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { TCModalService } from '../../../ui/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalDataService } from 'src/app/shared/services/modal-data.service';
import { ViewEditInterpreterComponent } from '../view-edit-interpreter/view-edit-interpreter.component';


interface InterpreterTableColumns {
  idNumber: { value: string, visible: boolean };
  firstName: { value: string, visible: boolean };
  lastName: { value: string, visible: boolean };
  languageTwo: { value: string, visible: boolean };
  telephone: { value: string, visible: boolean };
  email: { value: string, visible: boolean };
  address: { value: string, visible: boolean };
  origin: { value: string, visible: boolean };
}

@Component({
  selector: 'app-interpreters',
  templateUrl: './interpreters.component.html',
  styleUrls: ['./interpreters.component.scss'],
})
export class InterpretersComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild('modalBody', { static: true }) modalBody: ElementRef<any>;
  @ViewChild('modalFooter', { static: true }) modalFooter: ElementRef<any>;
  public role: string;
  public subscriber: Subscription;
  public row: any;
  public columnList = [];
  public selectedColumns = [];
  public managerData: any;
  public loading = false;
  public interpretersList: any[] = [];
  totalInterpreters = 0;
  pageSize = 25;
  pageIndex = 1;
  params: NzTableQueryParams;
  searchQuery: string;
  searchValue: InterpreterTableColumns = {
    idNumber: { value: '', visible: false },
    firstName: { value: '', visible: false },
    lastName: { value: '', visible: false },
    languageTwo: { value: '', visible: false },
    telephone: { value: '', visible: false },
    email: { value: '', visible: false },
    address: { value: '', visible: false },
    origin: { value: '', visible: false }
  };
  public gender = [];

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    private readonly assignInterpreterService: AssignInterpreterService,
    private readonly utilityService: UtilityService,
    private readonly downloadFileService: DownloadFileService,
    private readonly nzNotification: NzNotificationService,
    private readonly modalService: TCModalService,
    private readonly translateService: TranslateService,
    private readonly modalDataService: ModalDataService,
    private readonly nzModalService: NzModalService,
  ) {
    super(store, httpSv);

    this.pageData = {
      title: 'Interpreters', loaded: true,
      breadcrumbs: [{ title: 'Interpreters' }]
    };
    this.interpretersList = [];
    this.getTableData();
  }

  ngOnInit() {
    super.ngOnInit();
    this.role = localStorage.getItem('role');
    if (this.role === 'Manager') {
      this.managerData = JSON.parse(localStorage.getItem('data'));
    }
    this.getInterpreters(this.pageIndex, this.pageSize, null, null);
    this.columnRoleData();
    this.intitalDropdownValue();
  }

  private getTableData() {
    this.columnList = [
      { value: this.getTranslations('ID_number'), label: this.getTranslations('ID_number'), selected: true },
      { value: this.getTranslations('Firstname'), label: this.getTranslations('Firstname'), selected: true },
      { value: this.getTranslations('Lastname'), label: this.getTranslations('Lastname'), selected: true },
      { value: this.getTranslations('Language_two'), label: this.getTranslations('Language_two'), selected: true },
      { value: this.getTranslations('Telephone'), label: this.getTranslations('Telephone'), selected: true },
      { value: this.getTranslations('Email'), label: this.getTranslations('Email'), selected: true },
      { value: this.getTranslations('Gender'), label: this.getTranslations('Gender'), selected: true },
      { value: this.getTranslations('Address'), label: this.getTranslations('Address'), selected: true },
      { value: this.getTranslations('Origin'), label: this.getTranslations('Origin'), selected: true },
      { value: this.getTranslations('Action'), label: this.getTranslations('Action'), selected: true },
    ];
    this.gender = [
      { value: this.getTranslations('Male'), text: this.getTranslations('Male') },
      { value: this.getTranslations('Female'), text: this.getTranslations('Female') },
      { value: this.getTranslations('Other'), text: this.getTranslations('Other') },
    ];
  }

  private columnRoleData() {
    if (this.role === 'Admin') {
      this.columnList[8].disabled = true; // origin
    }
    if (this.role === 'Manager') {
      this.columnList[6].disabled = true; // gender
      this.columnList[0].disabled = true; // id number
      this.columnList[9].disabled = true; // action
    }
    if (this.role === 'IG Manager') {
      this.columnList[6].disabled = true; // gender
    }
    this.columnList[4].disabled = true; // telephone
    this.columnList[5].disabled = true; // email
    this.columnList[7].disabled = true; // address
    if (this.role !== 'Manager' || (this.managerData && this.managerData.isInterpreterTelephoneShow)){
      this.columnList[4].disabled = false; // telephone
    }
    if (this.role !== 'Manager' || (this.managerData && this.managerData.isInterpreterEmailShow)){
      this.columnList[5].disabled = false; // email
    }
    if (this.role !== 'Manager' || (this.managerData && this.managerData.isInterpreterAddressShow)){
      this.columnList[7].disabled = false; // address
    }
  }

  private intitalDropdownValue() {
    const tableValues = [];
    this.columnList.forEach((i) => {
      tableValues.push(i.value);
    });
    this.selectedColumns = tableValues;
  }
  onSelectcolumn(columnName: Array<string>) {
    const results = this.columnList.filter(({ value: id1 }) => !columnName.some((id2) => id2 === id1));
    if (results.length > 0) {
      this.columnList.forEach((i, index) => {
        this.columnList[index].selected = true;
        results.forEach((column) => {
          if (i.value === column.value) {
            this.columnList[index].selected = !this.columnList[index].selected;
          }
        });
      });
    } else {
      this.columnList.forEach((i, index) => {
        this.columnList[index].selected = true;
      });
    }
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.params = params;
    const { pageSize, pageIndex, sort, filter } = params;
    let filterString;
    let filterSort;
    if (filter.find(item => item.value.length > 0)) {
      filterString = this.utilityService.dropdownFilterQueryParam(filter);
    }
    if (sort.find(item => item.value !== null)) {
      filterSort = `sortBy=${this.utilityService.sortingFilterQueryParam(sort)}`;
    }
    this.getInterpreters(pageIndex, pageSize, filterSort, filterString);
  }

  search(columnName: string): void {
    const searchValue = {...this.searchValue};
    this.searchQuery  = this.utilityService.searchFilterQueryParam(searchValue);
    this.onQueryParamsChange(this.params);
    this.searchValue[columnName].visible = false;
  }

  reset(columnName: string): void {
    this.searchValue[columnName].visible = false;
    this.searchValue[columnName].value = '';
    this.search(columnName);
  }

  private getTranslations(key: string) {
    let translation;
    this.translateService.get(key).subscribe((res: string) => {
      translation = res;
    });
    return translation;
  }

  // Close Warning Modal
  closeModal() {
    this.modalService.close();
  }

  submitWarningModalData(row: any) {
    this.removeInterpreterData(row);
  }

  openWarningModalData(row: any) {
    this.row = row;
    this.openModal(this.modalBody, null, this.modalFooter, { width: 600 });
  }

  // Open Warning Modal
  openModal<T>(body: any, header: any = null, footer: any = null, options: any = null) {
    this.modalService.open({ body, header, footer, options });
  }

  // This method will use to get the interpreter list
  getInterpreters(pageIndex: number, pageSize: number, shortingString: string, filterString: string) {
    this.loading = true;
    let queryParams;
    queryParams = this.utilityService.createQueryParams({ limit: pageSize, offset: pageIndex });
    let queryParamArray: Array<string> = [this.searchQuery, filterString, shortingString, queryParams];
    queryParamArray = queryParamArray.filter((x) => {
      if (x) {
        return x.length > 0;
      }
    });
    const finalQueryParam = `?${queryParamArray.join('&')}`;
    this.subscriber = this.assignInterpreterService.getInterpreters(finalQueryParam).subscribe((response) => {
      if (response) {
        this.interpretersList = response['response']['interpreters'];
        this.totalInterpreters = response['response']['totalInterpreters'];
        this.loading = false;
      }
    }, (error) => {
      this.loading = false;
      this.nzNotification.success('error', error['error'].message);
    });
  }

  // Open User Modal
  openInterpreterModalService(action: string, data: any) {
    this.modalDataService.setData('interpreterModalInfo', action);
    this.modalDataService.setData('interpreterModalData', data);
    this.translateService.get(action).subscribe((title) => {
      const modal = this.nzModalService.create({
        nzTitle: title,
        nzClosable: false,
        nzContent: ViewEditInterpreterComponent,
        nzWidth: 1200,
        nzBodyStyle: {
          height: '800px',
          overflow: 'auto'
        }
      });
      modal.afterClose.subscribe((res) => {
        if (res) {
          this.getInterpreters(this.pageIndex, this.pageSize, null, null);
        }
      });
    });
  }

  // This method will download the list of all the interpreter in csv format
  exportInterpreterData() {
    const { pageSize, pageIndex, sort, filter } = this.params;
    let filterString;
    let filterSort;
    if (filter.find(item => item.value.length > 0)) {
      filterString = this.utilityService.dropdownFilterQueryParam(filter);
    }
    if (sort.find(item => item.value !== null)) {
      filterSort = `sortBy=${this.utilityService.sortingFilterQueryParam(sort)}`;
    }
    let queryParams;
    queryParams = this.utilityService.createQueryParams({ limit: pageSize, offset: pageIndex });
    let queryParamArray: Array<string> = [this.searchQuery, filterString, filterSort, queryParams];
    queryParamArray = queryParamArray.filter((x) => {
      if (x) {
        return x.length > 0;
      }
    });
    const finalQueryParam = `?exportFor=interpreter&${queryParamArray.join('&')}`;
    // const query = this.utilityService.createQuery({ exportFor: '' }) + 'interpreter';
    this.assignInterpreterService.exportAllInterpreters(finalQueryParam).subscribe((response) => {
      if (response['isSuccess']) {
        this.loading = false;
        this.downloadFileService.downloadFile(response['data']['filePath'], response['data']['fileName']);
        this.nzNotification.success('success', response['message']);
      } else {
        this.loading = false;
        this.nzNotification.success('error', response['error']);
      }
    }, (error) => {
      this.loading = false;
      this.nzNotification.success('error', error.error.message);
    }
    );
  }

  // Remove Interpreter Data
  removeInterpreterData(row: any) {
    this.subscriber = this.assignInterpreterService.deleteInterpreter(row.id).subscribe((res) => {
      if (res && res['isSuccess']) {
        this.modalService.close();
        this.nzNotification.success('Success', res['message']);
        this.interpretersList = this.interpretersList.filter((item: any) => item.id !== row.id);
      }
    }, (error) => {
      this.loading = false;
      this.nzNotification.success('error', error['error'].message);
    });
  }

  // copy email address on clipboard
  copyEmailOnClipboard() {
    this.assignInterpreterService.getEmailsOfInterpreters().subscribe((response) => {
      if (response['isSuccess']) {
        let data;
        data = JSON.parse(JSON.stringify(response['data']['emails']));
        const copyElement = document.createElement('textarea');
        copyElement.style.position = 'fixed';
        copyElement.style.opacity = '0';
        copyElement.textContent = data;
        const body = document.getElementsByTagName('body')[0];
        body.appendChild(copyElement);
        copyElement.select();
        document.execCommand('copy');
        body.removeChild(copyElement);
        this.nzNotification.success('success', response['message']);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriber.unsubscribe();
  }
}
