<nz-spin [nzSpinning]="loading">
  <div class="row" *ngIf="role === 'Admin' && !(session.status === 'CONFIRMED' || session.status === 'STOPPED')">
    <div class="col-sm-12">
      <h5 class="modal-title">{{'Edit_sessions' | translate}}</h5>
    </div>
  </div>
  <tc-form-group *ngIf="role=='IG Manager' && ( session.status !== 'RESCHEDULED' && session.status !== 'CANCELLED')">
    <nz-radio-group name="editType" #editType="ngModel" [(ngModel)]="session.editType">
      <label nz-radio nzValue = "editDuration"> {{'Edit_sessions' | translate}}</label>
    </nz-radio-group>
  </tc-form-group>
  <tc-form-group *ngIf="role=='Admin' && (session.status === 'CONFIRMED' || session.status === 'STOPPED')">
    <nz-radio-group name="editType" #editType="ngModel" [(ngModel)]="session.editType">
      <label nz-radio nzValue = "editConfirmedDuration"> {{'Validate_duration' | translate}}</label>
    </nz-radio-group>
  </tc-form-group>
  <form (ngSubmit)="updateRecalculate()" #f="ngForm" novalidate >
    <div class="row" *ngIf="session.status !== 'RESCHEDULED' && session.status !== 'CANCELLED'">
      <div class="col-sm-12">
        <tc-form-label>{{ "Duration" | translate }} (HH : MM)<span class="req-icon">*</span></tc-form-label>
      </div>
      <div class="col col-md-6">
        <tc-form-group>
          <tc-input required name="totalSessionHour" #totalSessionHour="ngModel" [(ngModel)]="session.totalSessionHour"
          placeholder="'HH'" [disabled]="session.editType === 'editDate'"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-6">
        <tc-form-group>
          <tc-input required name="totalSessionMinutes" #totalSessionMinutes="ngModel" [(ngModel)]="session.totalSessionMinutes"
          placeholder="'MM'" [disabled]="session.editType === 'editDate'"></tc-input>
        </tc-form-group>
      </div>
    </div>
    <tc-form-group *ngIf="role=='Admin' && (session.status == 'CONFIRMED' || session.status == 'STOPPED')">
      <nz-radio-group name="editType" #editType="ngModel" [(ngModel)]="session.editType">
        <label nz-radio nzValue = "editDate"> {{'Edit_date_time' | translate}}</label>
      </nz-radio-group>
    </tc-form-group>
    <div class="row" *ngIf="(role === 'Admin') && (session.status == 'CONFIRMED' || session.status == 'COMPLETED' || session.status == 'STOPPED' || session.status == 'LATE CANCELLATION' || session.status == 'LATE RESCHEDULING')">
      <div class="col col-md-4">
        <tc-form-group>
          <tc-form-label>{{ "Created_date_time" | translate}}<span class="req-icon">*</span>
          </tc-form-label>
          <nz-date-picker placeholder="{{ 'Created_date_time' | translate}}" required name="createdDate" #createdDate="ngModel" 
          [(ngModel)]="session.createdDate" [disabled]="session.editType === 'editConfirmedDuration'"></nz-date-picker>
        </tc-form-group>
      </div>
      <div class="col col-md-4">
        <tc-form-group>
          <tc-form-label>HH<span class="req-icon">*</span>
          </tc-form-label>
          <tc-input placeholder="'HH'" required name="createdHour" #createdHour="ngModel" 
          [disabled]="session.editType === 'editConfirmedDuration'" [(ngModel)]="session.createdHour"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-4">
        <tc-form-group>
          <tc-form-label>MM<span class="req-icon">*</span>
          </tc-form-label>
          <tc-input placeholder="'MM'" required name="createdMinutes" #createdMinutes="ngModel" 
          [disabled]="session.editType === 'editConfirmedDuration'" [(ngModel)]="session.createdMinutes"></tc-input>
        </tc-form-group>
      </div>
    </div>
    <div class="row" *ngIf="(role === 'Admin') && (session.status == 'CONFIRMED' || session.status == 'COMPLETED' || session.status == 'STOPPED' || session.status == 'LATE CANCELLATION'|| session.status == 'LATE RESCHEDULING')">
      <div class="col col-md-4">
        <tc-form-group>
          <tc-form-label>Booked Date/Time<span class="req-icon">*</span>
          </tc-form-label>
          <nz-date-picker placeholder="'Booked Date/Time'" required name="bookingDate" #bookingDate="ngModel" 
          [disabled]="session.editType === 'editConfirmedDuration'" [(ngModel)]="session.bookingDate"></nz-date-picker>
        </tc-form-group>
      </div>
      <div class="col col-md-4">
        <tc-form-group>
          <tc-form-label>HH<span class="req-icon">*</span>
          </tc-form-label>
          <tc-input placeholder="'HH'" required name="bookingHour" #bookingHour="ngModel" 
          [disabled]="session.editType === 'editConfirmedDuration'" [(ngModel)]="session.bookingHour"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-4">
        <tc-form-group>
          <tc-form-label>MM<span class="req-icon">*</span>
          </tc-form-label>
          <tc-input placeholder="'MM'" required name="bookingMinutes" #bookingMinutes="ngModel" 
          [disabled]="session.editType === 'editConfirmedDuration'" [(ngModel)]="session.bookingMinutes"></tc-input>
        </tc-form-group>
      </div>
    </div>
    <div class="row" *ngIf="role == 'IG Manager' && session.status != 'MISSED' && session.status != 'CONFIRMED' && session.status != 'STOPPED'">
      <div class="col-md-12">
        <tc-form-group>
          <nz-radio-group name="editType" #editType="ngModel" [(ngModel)]="session.editType">
            <label nz-radio nzValue = "editCost"> {{'Edit_session_cost' | translate}}</label>
          </nz-radio-group>
        </tc-form-group>
      </div>
      <div class="col-md-12">
          <tc-form-label>{{ "Interpreter_cost" | translate }}</tc-form-label>
      </div>
      <div class="col col-md-3">
          <tc-form-group>
            <tc-form-label>{{ "Travel_cost" | translate }}<span class="req-icon">*</span></tc-form-label>
            <tc-input placeholder="{{ 'Travel_cost' | translate }}" required name="sessionTravelCost" #sessionTravelCost="ngModel" 
            [disabled]="session.editType === 'editDuration'" [(ngModel)]="session.sessionTravelCost"
            pattern="(?:[0-9]+(?:\.[0-9]{0,5})?)?$" required></tc-input>
          </tc-form-group>
      </div>
      <div class="col col-md-3">
          <tc-form-group>
            <tc-form-label>{{ "Session_cost" | translate }}<span class="req-icon">*</span></tc-form-label>
            <tc-input placeholder="{{ 'Session_cost' | translate }}" required name="sessionCostForInterpreter" 
            #sessionCostForInterpreter="ngModel" [(ngModel)]="session.sessionCostForInterpreter"
            pattern="(?:[0-9]+(?:\.[0-9]{0,5})?)?$" required [disabled]="session.editType === 'editDuration'"></tc-input>
          </tc-form-group>
      </div>
      <div class="col col-md-3">
          <tc-form-group>
            <tc-form-label>{{ "Supplementary_cost" | translate }}<span class="req-icon">*</span></tc-form-label>
            <tc-input placeholder="{{ 'Supplementary_cost' | translate }}" required name="supplementaryCostForInterpreter" #supplementaryCostForInterpreter="ngModel" [(ngModel)]="session.supplementaryCostForInterpreter"
            pattern="(?:[0-9]+(?:\.[0-9]{0,5})?)?$" required [disabled]="session.editType === 'editDuration'"></tc-input>
          </tc-form-group>
      </div>
      <div class="col col-md-3">
          <tc-form-group>
            <tc-form-label>{{ "Reduced_rate" | translate }}<span class="req-icon">*</span></tc-form-label>
            <tc-input placeholder="{{ 'Reduced_rate' | translate }}" required name="salaryAmountForUnusedTime" #salaryAmountForUnusedTime="ngModel" [(ngModel)]="session.salaryAmountForUnusedTime"
            pattern="(?:[0-9]+(?:\.[0-9]{0,5})?)?$" required [disabled]="session.editType === 'editDuration'"></tc-input>
          </tc-form-group>
      </div>
      <div class="col col-md-3">
          <tc-form-group>
            <tc-form-label>Total</tc-form-label>
            <tc-input  placeholder="Total" required name="totalCostForInterpreter" #totalCostForInterpreter="ngModel" [(ngModel)]="session.totalCostForInterpreter"
            pattern="(?:[0-9]+(?:\.[0-9]{0,5})?)?$" required [disabled]="session.editType === 'editDuration'"></tc-input>
          </tc-form-group>
      </div>
      <div class="col-md-12">
        <tc-form-label>{{ "User_account_cost" | translate }}</tc-form-label>
      </div>
      <div class="col col-md-3">
        <tc-form-group>
          <tc-form-label>{{ "Travel_cost" | translate }}<span class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{ 'Travel_cost' | translate }}" required name="sessionTravelCostForUser" #sessionTravelCostForUser="ngModel" [(ngModel)]="session.sessionTravelCostForUser"
          pattern="(?:[0-9]+(?:\.[0-9]{0,5})?)?$" required [disabled]="session.editType === 'editDuration'"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-3">
        <tc-form-group>
          <tc-form-label>{{ "Session_cost" | translate }}<span class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{ 'Session_cost' | translate }}" required name="sessionCostForUser" #sessionCostForUser="ngModel" [(ngModel)]="session.sessionCostForUser"
          pattern="(?:[0-9]+(?:\.[0-9]{0,5})?)?$" required [disabled]="session.editType === 'editDuration'"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-3">
        <tc-form-group>
          <tc-form-label>{{ "Supplementary_cost" | translate }}<span class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{ 'Supplementary_cost' | translate }}" required name="supplementaryCostForUser" #supplementaryCostForUser="ngModel" [(ngModel)]="session.supplementaryCostForUser"
          pattern="(?:[0-9]+(?:\.[0-9]{0,5})?)?$" required [disabled]="session.editType === 'editDuration'"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-3">
        <tc-form-group>
          <tc-form-label>{{ "Reduced_rate" | translate }}<span class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{ 'Reduced_rate' | translate }}" required name="billedAmountForUnusedTime" #billedAmountForUnusedTime="ngModel" [(ngModel)]="session.billedAmountForUnusedTime"
          pattern="(?:[0-9]+(?:\.[0-9]{0,5})?)?$" required [disabled]="session.editType === 'editDuration'"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-3">
        <tc-form-group>
          <tc-form-label>Total</tc-form-label>
          <tc-input placeholder="Total" required name="totalCostForUser" #totalCostForUser="ngModel" [(ngModel)]="session.totalCostForUser"
          pattern="(?:[0-9]+(?:\.[0-9]{0,5})?)?$" required [disabled]="session.editType === 'editDuration'"></tc-input>
        </tc-form-group>
      </div>
    </div>
    <div *nzModalFooter class="actions justify-content-end">
      <button tc-button [view]="'info'" *ngIf="session.editType == 'editCost'" (click)="updateSessionCost()">
        {{ "Save_changes" | translate }}
      </button>
      <button tc-button [view]="'info'" *ngIf="session.editType == 'editDuration' || role == 'Admin' || role == 'Manager'"
        (click)="updateRecalculate()">
        {{ "Recalculate" | translate }}
      </button>
      <button tc-button [view]="'info'" (click)="closeModal()">
        {{ "Cancel" | translate }}
      </button>
    </div>
  </form>
</nz-spin>
