import { environment } from '../../../../environments/environment';

export const urls = {
    getBarStatistics: 'statistics/bar',
    organizations: 'organizations',
    interpreterGroups: 'interpreter-grps',
    getPieChartData: 'statistics/pie',
    progress: 'statistics/progress',
    donut: 'statistics/donut',
    getBranchesData: 'branches',
    getCategoryPieChartData: 'statistics/sessions/category/pie',
    getQualificationPieChartData: 'statistics/pie/interpreter-qualification',
    getFilterQualificationPieChartData: 'statistics/pie/filter-interpreter-qualification',
    getCantonPieChartData: 'statistics/sessions/pie',
};

export const oldUrls = {
    getBarStatistics: 'v4/statistics/bar',
    organizations: 'v4/organizations',
    interpreterGroups: 'v4/interpreter-grps',
    getPieChartData: 'v4/statistics/pie',
    progress: 'v4/statistics/progress',
    donut: 'v4/statistics/donut',
    getBranchesData: 'v4/branches',
    getCategoryPieChartData: 'v4/statistics/sessions/category/pie',
    getQualificationPieChartData: 'v4/statistics/pie/interpreter-qualification',
    getFilterQualificationPieChartData: 'v4/statistics/pie/filter-interpreter-qualification',
    getCantonPieChartData: 'v4/statistics/sessions/pie',
};
export const statisticsPath = {
    getUrl(urlName: string) {
       if (environment.backend === 'old') {
           return oldUrls[urlName];
       } else {
           return urls[urlName];
       }
   }
};
