import { environment } from '../../../../environments/environment';

export const urls = {
    getDataUrl: 'organizations/',
    getTotalSessionDetails : 'dashboard-sessions'
};

export const oldUrls = {
    getDataUrl: 'v4/organizations/',
    getTotalSessionDetails : 'v5/dashboard-sessions'
};
export const homeUrls = {
    getUrl(urlName: string) {
       if (environment.backend === 'old') {
           return oldUrls[urlName];
       } else {
           return urls[urlName];
       }
   }
};
