<form [formGroup]="loginForm" novalidate>
  <tc-form-group>
    <tc-input [placeholder]="'Login'" formControlName="login"></tc-input>
  </tc-form-group>

  <tc-form-group>
    <tc-input [placeholder]="'Password'" [type]="'password'" formControlName="pass"></tc-input>
  </tc-form-group>

  <tc-form-group>
    <tc-switcher [value]="true">Remember me</tc-switcher>
  </tc-form-group>

  <div class="actions justify-content-between">
    <button tc-button [view]="'accent'" [beforeIcon]="'icofont-login'">
      Login
    </button>
  </div>
</form>
