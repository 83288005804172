import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from './../../app.service';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {

    public constructor(private readonly httpClient: HttpClient, private readonly appService: AppService) {
    }

    public getServerRequest(url: string) {
        return this.httpClient.get(this.appService.getAPIHost() + url);
    }

    public postServerRequest(url: string, data: object) {
        return this.httpClient.post(this.appService.getAPIHost() + url, data);
    }

    public putServerRequest(url: string, data: object) {
        return this.httpClient.put(this.appService.getAPIHost() + url, data);
    }

    public deleteServerRequest(url: string) {
        return this.httpClient.delete(this.appService.getAPIHost() + url);
    }
}
