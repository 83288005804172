import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { userAccountUrls } from './user-account.path';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserAccountService {
  public constructor(private readonly httpClientService: HttpClientService) { }

  public getUserAccounts(queryParams: any): Observable<{}> {
    return this.httpClientService.getServerRequest(userAccountUrls.getUrl('getuserData') + queryParams);
  }

  public exportAllUserAccountData(queryForExportAllData): Observable<{}> {
    return this.httpClientService.getServerRequest(userAccountUrls.getUrl('exportAllData') + queryForExportAllData);
  }

  // Export Customer XML
  public exportCustomerXmlData(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(userAccountUrls.getUrl('customerXmlExport') + query);
  }

  public deleteUserAccount(userAccountId: number): Observable<{}> {
    return this.httpClientService.deleteServerRequest(userAccountUrls.getUrl('deleteUserAccount') + userAccountId);
  }

  public deleteDepartment(deptId: number): Observable<{}> {
    return this.httpClientService.deleteServerRequest(userAccountUrls.getUrl('deleteDepartment') + deptId);
  }
  public searchTextFilter(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(userAccountUrls.getUrl('searchTextFilter') + query);
  }

  public getFilterUserAccounts(userQuery: string, userAccounts: string): Observable<{}> {
    return this.httpClientService.getServerRequest(
      `${userAccountUrls.getUrl('getuserData')}${userQuery}&userAccountName=${userAccounts}`
    );
  }

  // Get Export Format On Starting For IG Admin
  public getExportFormat(): Observable<{}> {
    return this.httpClientService.getServerRequest(`${userAccountUrls.getUrl('exportFormat')}`);
  }
}
