<nz-spin [nzSpinning]="loading">
  <div class="row">
    <div class="col-sm-6">
      <tc-form-group>
        <tc-checkbox [(ngModel)]="user">{{ "User" | translate }}</tc-checkbox>
      </tc-form-group>
    </div>
    <div class="col-sm-6">
      <tc-form-group>
        <tc-checkbox [(ngModel)]="interpreter">{{"Interpreter" | translate}}</tc-checkbox>
      </tc-form-group>
    </div>
  </div>
</nz-spin>
<div *nzModalFooter>
    <button [disabled] ="!user || !interpreter" nz-button class="login-form-button" (click)="onClickSend()">{{'OK' | translate}}</button>
    <button nz-button nzType="primary" (click)="cancelModal()">{{'Cancel' | translate}}</button>
</div>
