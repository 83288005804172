import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { HttpService } from '../../../services/http/http.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { UtilityService } from '../../../shared/services/utility.service';
import { Subscription } from 'rxjs';
import { UserAccountService } from './user-account.service';
import { DownloadFileService } from '../../../shared/services/download-file.service';
import { NzModalService, NzNotificationService, NzTableQueryParams } from 'ng-zorro-antd';
import { IOption } from '../../../ui/interfaces/option';
import { TCModalService } from '../../../ui/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalDataService } from 'src/app/shared/services/modal-data.service';
import { AddUserAccountComponent } from '../add-user-account/add-user-account.component';

interface UserAccountTableColumns {
  customerName: { value: string, visible: boolean };
  firstName: { value: string, visible: boolean };
  lastName: { value: string, visible: boolean };
  fullName: { value: string, visible: boolean };
  email: { value: string, visible: boolean };
  organizationName: { value: string, visible: boolean };
  address: { value: string, visible: boolean };
  departmentName: { value: string, visible: boolean };
  telephone: { value: string, visible: boolean };
}

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],
})
export class UserAccountComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild('modalBody', { static: true }) modalBody: ElementRef<any>;
  @ViewChild('modalFooter', { static: true }) modalFooter: ElementRef<any>;
  role: string;
  subscriber: Subscription;
  row: any;
  public columnList = [];
  public selectedColumns = [];
  userAccountData: any[] = [];
  public loading = false;
  totalUserAccount = 0;
  pageSize = 25;
  pageIndex = 1;
  params: NzTableQueryParams;
  searchQuery: string;
  customerExportFormat: any;

  searchValue: UserAccountTableColumns = {
    customerName: { value: '', visible: false },
    firstName: { value: '', visible: false },
    lastName: { value: '', visible: false },
    fullName: { value: '', visible: false },
    email: { value: '', visible: false },
    organizationName: { value: '', visible: false },
    address: { value: '', visible: false },
    departmentName: { value: '', visible: false },
    telephone: { value: '', visible: false },
  };

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    private readonly utilityService: UtilityService,
    private readonly userAccountService: UserAccountService,
    private readonly notification: NzNotificationService,
    private readonly downloadFileService: DownloadFileService,
    private readonly modalService: TCModalService,
    private readonly translateService: TranslateService,
    private readonly modalDataService: ModalDataService,
    private readonly nzModalService: NzModalService,
  ) {
    super(store, httpSv);
    this.pageData = {
      title: 'User Account',
      loaded: true,
      breadcrumbs: [{ title: 'User Account' }],
    };
    this.userAccountData = [];
    this.columnList = [
      { value: 'firstName', text: this.getTranslations('Firstname'), selected: true },
      { value: 'lastName', text: this.getTranslations('Lastname'), selected: true },
      { value: 'email', text: this.getTranslations('Email'), selected: true },
      { value: 'organization', text: this.getTranslations('Organization'), selected: true },
      { value: 'department', text: this.getTranslations('Department'), selected: true },
      { value: 'action', text: this.getTranslations('Action'), selected: true },
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    this.role = localStorage.getItem('role');
    this.getUsersAccount(this.pageIndex, this.pageSize, null, null);
    this.columnRoleData();
    this.intitalDropdownValue();
    if (this.role === 'IG Manager') {
      this.getCustomerExportFormatData();
    }
  }

  private columnRoleData() {
    if (this.role !== 'Admin') {
      this.columnList.splice(this.columnList.length + 1, 0,
        { value: 'customerName', text: this.getTranslations('Customer_number'), selected: true },
        { value: 'fullName', text: this.getTranslations('Name'), selected: true },
        { value: 'address', text: this.getTranslations('Address'), selected: true },
        { value: 'telephone', text: this.getTranslations('Telephone'), selected: true },
      );
      this.columnList[0].disabled = true; //firstname
      this.columnList[1].disabled = true; //lastname
      this.columnList[2].disabled = true; //email
      this.columnList[6].disabled = this.role === 'Manager' ? true : false; // Customer Number
    }
  }

  onSelectcolumn(columnName: Array<string>) {
    const results = this.columnList.filter(({ value: id1 }) => !columnName.some((id2) => id2 === id1));
    if (results.length > 0) {
      this.columnList.forEach((i, index) => {
        this.columnList[index].selected = true;
        results.forEach((column) => {
          if (i.value === column.value) {
            this.columnList[index].selected = !this.columnList[index].selected;
          }
        });
      });
    } else {
      this.columnList.forEach((i, index) => {
        this.columnList[index].selected = true;
      });
    }
  }

  private intitalDropdownValue() {
    const tableValues = [];
    this.columnList.forEach((i) => {
      tableValues.push(i.value);
    });
    this.selectedColumns = tableValues;
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.params = params;
    const { pageSize, pageIndex, sort, filter } = params;
    let filterString;
    let filterSort;
    if (filter.find(item => item.value.length > 0)) {
      filterString = this.utilityService.dropdownFilterQueryParam(filter);
    }
    if (sort.find(item => item.value !== null)) {
      filterSort = `sortBy=${this.utilityService.sortingFilterQueryParam(sort)}`;
    }
    this.getUsersAccount(pageIndex, pageSize, filterSort, filterString);
  }

  search(columnName: string): void {
    const searchValue = { ...this.searchValue };
    this.searchQuery = this.utilityService.searchFilterQueryParam(searchValue);
    this.onQueryParamsChange(this.params);
    this.searchValue[columnName].visible = false;
  }

  reset(columnName: string): void {
    this.searchValue[columnName].visible = false;
    this.searchValue[columnName].value = '';
    this.search(columnName);
  }

  private getTranslations(key: string) {
    let translation;
    this.translateService.get(key).subscribe((res: string) => {
      translation = res;
    });
    return translation;
  }

  // Close Warning Modal
  closeModal() {
    this.modalService.close();
  }

  submitWarningModalData(row: any) {
    this.removeUserData(row);
  }

  openWarningModalData(row: any) {
    this.row = row;
    this.openModal(this.modalBody, null, this.modalFooter, { width: 600 });
  }

  // Open Warning Modal
  private openModal<T>(body: any, header: any = null, footer: any = null, options: any = null) {
    this.modalService.open({ body, header, footer, options });
  }

  private getCustomerExportFormatData() {
    this.subscriber = this.userAccountService.getExportFormat().subscribe((res) => {
      if (res['isSuccess']) {
        this.customerExportFormat = res['data'];
      } else {
        this.notification.error('Error', res['error']);
      }
    });
  }

  // get user account data
  getUsersAccount(pageIndex: number, pageSize: number, shortingString: string, filterString: string) {
    this.loading = true;
    let queryParams;
    queryParams = this.utilityService.createQueryParams({ limit: pageSize, offset: pageIndex });
    let queryParamArray: Array<string> = [this.searchQuery, filterString, shortingString, queryParams];
    queryParamArray = queryParamArray.filter((x) => {
      if (x) {
        return x.length > 0;
      }
    });
    const finalQueryParam = `?${queryParamArray.join('&')}`;
    this.subscriber = this.userAccountService.getUserAccounts(finalQueryParam).subscribe((response) => {
      if (response) {
        this.totalUserAccount = response['data']['totalUserAccounts'];
        this.userAccountData = response['data']['userAccounts'];
        this.loading = false;
      }
    }, (error) => {
      this.loading = false;
      console.log(error);
      this.notification.error('Error', error.error);
    });
  }

  // Open User Modal
  openUserAccountFormService(action: string, data: any) {
    const modalData = {
      orgData: null,
      action
    };
    let modal;
    this.modalDataService.setData('addEditUserAccount', data);
    this.modalDataService.setData('modalData', modalData);
    this.translateService.get(action).subscribe((title) => {
      modal = this.nzModalService.create({
        nzTitle: title,
        nzClosable: false,
        nzContent: AddUserAccountComponent,
        nzWidth: 1200,
        nzBodyStyle: {
          height: '800px',
          overflow: 'auto'
        }
      });
    });
      modal.afterClose.subscribe((res) => {
        if (res) {
          this.getUsersAccount(this.pageIndex, this.pageSize, null, null);
        }
      });
  }

  removeUserData(row: any) {
    this.subscriber = this.userAccountService.deleteUserAccount(row.id).subscribe((res) => {
      if (res && res['isSuccess']) {
        this.modalService.close();
        this.userAccountData = this.userAccountData.filter(
          (item: any) => item.id !== row.id
        );
      }
    });
  }

  public exportAllCustomerData() {
    this.loading = true;
    let queryParams;
    if (this.customerExportFormat) {
      let customerExportFormatName;
      this.customerExportFormat.forEach((element) => {
        if (element.selected) {
          customerExportFormatName = element.name
        }
      });
      queryParams = this.utilityService.createQuery({ exportName: customerExportFormatName, limit: 0, offset: 0 });
    } else {
      queryParams = this.utilityService.createQuery({ limit: 0, offset: 0 });
    }
    this.userAccountService.exportCustomerXmlData(queryParams).subscribe((response) => {
      this.downloadFileHelper(response);
    });
  }

  //  export user accounts in csv formats
  public exportUsers() {
    this.loading = true;
    const queryParams = this.utilityService.createQuery({ exportFor: 'user' });
    this.userAccountService.exportAllUserAccountData(queryParams).subscribe((response) => {
      this.downloadFileHelper(response);
    });
  }
  // export userdata according to roles
  public exportUserAccountData() {
    this.loading = true;
    const { pageSize, pageIndex, sort, filter } = this.params;
    let filterString;
    let filterSort;
    if (filter.find(item => item.value.length > 0)) {
      filterString = this.utilityService.dropdownFilterQueryParam(filter);
    }
    if (sort.find(item => item.value !== null)) {
      filterSort = `sortBy=${this.utilityService.sortingFilterQueryParam(sort)}`;
    }
    let queryParams;
    queryParams = this.utilityService.createQueryParams({ limit: pageSize, offset: pageIndex });
    let queryParamArray: Array<string> = [this.searchQuery, filterString, filterSort, queryParams];
    queryParamArray = queryParamArray.filter((x) => {
      if (x) {
        return x.length > 0;
      }
    });
    const finalQueryParam = `?exportFor=user&${queryParamArray.join('&')}`;
    this.subscriber = this.userAccountService.exportAllUserAccountData(finalQueryParam).subscribe((response) => {
      this.downloadFileHelper(response);
    });
  }

  // Download File Helper
  private downloadFileHelper(response: any) {
    if (response && response['isSuccess']) {
      const downloadData = response['data'];
      this.downloadFileService.downloadFile(downloadData.filePath, downloadData.fileName);
      this.notification.success('success', response['message']);
    } else {
      this.notification.success('error', response['error']);
    }
    this.loading = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }
}
