import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { Observable } from 'rxjs';
import { sessionExport } from './session-export.path';

@Injectable({
  providedIn: 'root',
})
export class SessionExportService {
  constructor(private readonly httpClientService: HttpClientService) { }

  // service session table filter data
  public getSession(docType: string, exportData: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionExport.getUrl('exportSession')}/${docType}${exportData}`);
  }

  // service session table filter data
  public getSwissSalary(exportData: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionExport.getUrl('exportSession')}/swiss-salary${exportData}`);
  }

  // Get List Of Organizations
  public getOrganizations(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionExport.getUrl('organizations')}${query}`);
  }

  // Get List Of User Account
  public getUserAccounts(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionExport.getUrl('userAccounts')}${query}`);
  }

  // Get List Of User Account
  public getInterpreters(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionExport.getUrl('interpreters')}${query}`);
  }

  // Old Normal Export
  public getExportData(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionExport.getUrl('export')}${query}`);
  }

  // Old XML Export
  public getXmlExport(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionExport.getUrl('xmlExport')}${query}`);
  }

  // Get MY Company Export Format
  public getMyCompanyExportFormat(): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionExport.getUrl('myComapnyExportFormat')}`);
  }

  // Get CSV Export Format
  public getCSVExportFormat(id: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionExport.getUrl('csvExportFormat')}?interpreterGroupId=${id}`);
  }

  // Get CSV Export Format
  public getPdfExportFormat(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionExport.getUrl('pdfExportFormat')}${query}`);
  }

  // Get XML Export Format
  public getXMLExportFormat(id: string): Observable<{}> {
    if (id) {
      return this.httpClientService.getServerRequest(`${sessionExport.getUrl('xmlExportFormat')}?interpreterGroupId=${id}`);
    } else {
      return this.httpClientService.getServerRequest(`${sessionExport.getUrl('xmlExportFormat')}`);
    }
  }
}
