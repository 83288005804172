import { environment } from '../../../../environments/environment';

export const urls = {
    getRecalculateSessions: 'sessions/',
    recalculateCost: 'sessions/recalculate',
    updateTva: 'tva',
    getInterpreter: 'interpreters',
    getOrg : 'organizations'
};

export const oldUrls = {
    getRecalculateSessions: 'v4/sessions/',
    recalculateCost: 'v4/sessions/recalculate',
    updateTva: 'v4/tva',
    getInterpreter: 'v4/interpreters',
    getOrg : 'v4/organizations'
};
export const recalculateSession = {
    getUrl(urlName: string) {
        if (environment.backend === 'old') {
            return oldUrls[urlName];
        } else {
            return urls[urlName];
        }
    }
};
