import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { Subscription } from 'rxjs';
import { SessionService } from './sessions.service';
import { NzModalService, NzNotificationService, NzTableQueryParams } from 'ng-zorro-antd';
import { UtilityService } from '../../../shared/services/utility.service';
import { TCModalService } from '../../../ui/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalDataService } from '../../../shared/services/modal-data.service';
import { SessionExportComponent } from '../session-export/session-export.component';
import { CreateEditSessionComponent } from '../create-edit-session/create-edit-session.component';
import { SessionModelComponent } from '../session-model/session-model.component';
import { SessionRecalculateModelComponent } from '../session-recalculate-model/session-recalculate-model.component';
import { ActivatedRoute } from '@angular/router';
import { ExportSessionData, SessionTableColumns } from './session-table.data';
import { ChangeAssignInterpreterComponent } from '../change-assign-interpreter/change-assign-interpreter.component';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss'],
})

export class SessionsComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild('modalBody', { static: true }) modalBody: ElementRef<any>;
  @ViewChild('modalFooter', { static: true }) modalFooter: ElementRef<any>;
  @ViewChild('changeInterpreterModalHeader', { static: true }) changeInterpreterModalHeader: ElementRef<any>;
  @ViewChild('changeInterpreterModalBody', { static: true }) changeInterpreterModalBody: ElementRef<any>;
  @ViewChild('changeInterpreterModalFooter', { static: true }) changeInterpreterModalFooter: ElementRef<any>;
  @ViewChild('sessionTable', { static: true }) sessionTable: ElementRef<any>;
  public subscriber: Subscription;
  public role: string;
  session: any;
  public columnList = [];
  public selectedColumns = [];
  sessionData: any[] = [];
  totalSessionCount = 0;
  loading = true;
  pageSize = 25;
  pageIndex = 1;
  sessionStatus = [];
  sessionTypes = [];
  sessionMethods = [];
  params: NzTableQueryParams;
  searchQuery: string;
  searchValue: SessionTableColumns = {
    id: { value: '', visible: false }, createdDate: { value: '', visible: false },
    bookedDate: { value: [], visible: false }, bookingDate: { value: '', visible: false }, modifiedDate: { value: [], visible: false },
    updatedDate: { value: '', visible: false }, fromLanguage: { value: '', visible: false }, toLanguage: { value: '', visible: false },
    organization: { value: '', visible: false }, userAccount: { value: '', visible: false }, interpreter: { value: '', visible: false },
    patient: { value: '', visible: false }
  };
  isDateRangeselected = false;
  listOfSelection = [];
  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();
  userAccountList: { id: string, name: string }[];
  interpreterList: { id: string, name: string }[];
  changedInterpreterId: string;
  changedInterpreterList: any[];
  assignInterpreterLoading = false;
  dashboardStatus = '';

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    private readonly sessionService: SessionService,
    private readonly notification: NzNotificationService,
    private readonly utilityService: UtilityService,
    private readonly modalService: TCModalService,
    private readonly translateService: TranslateService,
    private readonly modalDataService: ModalDataService,
    private readonly nzModalService: NzModalService,
    private readonly route: ActivatedRoute,
  ) {
    super(store, httpSv);
    this.pageData = { title: 'Sessions', loaded: true, breadcrumbs: [{ title: 'Sessions' }] };
    this.sessionData = [];
    this.getTableData();
  }

  ngOnInit() {
    super.ngOnInit();
    this.role = localStorage.getItem('role');
    this.applyDasboardFilters();
    this.columnRoleData();
    this.intitalDropdownValue();
  }
  applyDasboardFilters() {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length !== 0) {
        const { status, startTime, endTime, locked } = params;
        if (startTime && endTime) {
          this.searchQuery = `bookingDate=${startTime},${endTime}`;
          this.searchValue.bookedDate.value = [new Date(parseInt(startTime)), new Date(parseInt(endTime))];
        }
        if (locked !== undefined) {
          this.searchQuery = `locked=${locked}`;
        }
        this.sessionStatus.forEach((ob, i) => {
          if (ob.value === status) {
            this.sessionStatus[i].byDefault = true;
          }
        });
      }
    });
  }
  private getTableData() {
    this.sessionStatus = [
      { text: this.getTranslations('All'), value: 'PENDING,CONFIRMED,CANCELLED,TOVALIDATE,COMPLETED,MISSED,REFUSED,STOPPED,RESCHEDULED' },
      { text: this.getTranslations('SENT'), value: 'PENDING' }, { text: this.getTranslations('CONFIRMED'), value: 'CONFIRMED' },
      { text: this.getTranslations('CANCELLED'), value: 'CANCELLED' },
      { text: this.getTranslations('canceledLate'), value: 'LATE' },
      { text: this.getTranslations('RESCHEDULED'), value: 'RESCHEDULED' },
      { text: this.getTranslations('IN PROGRESS'), value: 'INPROGRESS' }, { text: this.getTranslations('TOVALIDATE'), value: 'TOVALIDATE' },
      { text: this.getTranslations('COMPLETED'), value: 'COMPLETED' }, { text: this.getTranslations('REFUSED'), value: 'REFUSED' },
      { text: this.getTranslations('STOPPED'), value: 'STOPPED' }, { text: this.getTranslations('MISSED'), value: 'MISSED' },
      { text: this.getTranslations('DELETED'), value: 'DELETED' }];
    this.sessionTypes = [{ value: 'SCHEDULED', text: 'SCHEDULED' }, { value: 'IMMEDIATE', text: 'IMMEDIATE' }];
    this.sessionMethods = [{ value: 'INPERSON', text: this.getTranslations('INPERSON') },
    { value: 'TELEPHONE', text: this.getTranslations('TELEPHONE') }, { value: 'VIDEO', text: this.getTranslations('VIDEO') },
    { value: 'INTERCULTURALMEDIATION', text: this.getTranslations('INTERCULTURALMEDIATION') },
    { value: 'COURSEMANAGEMENT', text: this.getTranslations('COURSEMANAGEMENT') }];
    this.columnList = [{ value: 'id', text: this.getTranslations('Session_id'), selected: true },
    { value: 'bookingDate', text: this.getTranslations('Booked_date'), selected: true },
    { value: 'toLanguage', text: this.getTranslations('Language_two'), selected: true },
    { value: 'updatedDate', text: this.getTranslations('Modified_date'), selected: true },
    { value: 'userAccount', text: this.getTranslations('User_account'), selected: true },
    { value: 'interpreter', text: this.getTranslations('Interpreter'), selected: true },
    { value: 'duration', text: this.getTranslations('Duration'), selected: true },
    { value: 'status', text: this.getTranslations('Status'), selected: true },
    { value: 'createdDate', text: this.getTranslations('Created_date'), selected: true },
    { value: 'method', text: this.getTranslations('Method'), selected: true },
    { value: 'actions', text: this.getTranslations('Action'), selected: true },
    ];
  }

  private getTranslations(key: string) {
    let translation;
    this.translateService.get(key).subscribe((res: string) => {
      translation = res;
    });
    return translation;
  }

  //  column 11, 12, 13 are doubled.
  private columnRoleData() {
    if (this.role === 'Admin') {
      this.columnList.splice(this.columnList.length + 1, 0,
        { value: 'fromLanguage', text: this.getTranslations('Language'), selected: true },
        { value: 'organization', text: this.getTranslations('Organization'), selected: true },
        { value: 'sessionCategory', text: this.getTranslations('Type'), selected: true },
      );
      this.columnList[8].disabled = true; // created date
    } else if (this.role === 'Manager' || this.role === 'IG Manager') {
      this.columnList.splice(this.columnList.length + 1, 0,
        { value: 'patient', text: this.getTranslations('Patient'), selected: true },
        { value: 'startTime', text: this.getTranslations('Start_time'), selected: true },
        { value: 'sessionImage', text: this.getTranslations('Validation'), selected: true },
      );
      this.columnList[3].disabled = true; // modified date
    }
  }

  private intitalDropdownValue() {
    const tableValues = [];
    this.columnList.forEach((i) => {
      tableValues.push(i.value);
    });
    this.selectedColumns = tableValues;
  }

  onSelectcolumn(columnName: Array<string>) {
    const results = this.columnList.filter(({ value: id1 }) => !columnName.some((id2) => id2 === id1));
    if (results.length > 0) {
      this.columnList.forEach((i, index) => {
        this.columnList[index].selected = true;
        results.forEach((column) => {
          if (i.value === column.value) {
            this.columnList[index].selected = !this.columnList[index].selected;
          }
        });
      });
    } else {
      this.columnList.forEach((i, index) => {
        this.columnList[index].selected = true;
      });
    }
  }

  onAllChecked(value: boolean): void {
    this.sessionData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.sessionData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.sessionData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.params = params;
    const { pageSize, pageIndex, sort, filter } = params;
    let filterString;
    let filterSort;
    if (filter.find(item => item.value.length > 0)) {
      filterString = this.utilityService.dropdownFilterQueryParam(filter);
    }
    if (sort.find(item => item.value !== null)) {
      filterSort = `sortBy=${this.utilityService.sortingFilterQueryParam(sort)}`;
    }
    this.getSessionData(pageIndex, pageSize, filterSort, filterString);
  }

  search(columnName: string): void {
    let filterColumnData;
    filterColumnData = { ...this.searchValue };
    if (columnName === 'bookedDate') {
      filterColumnData[columnName].value = filterColumnData[columnName].value.map((i) => i.getTime());
      filterColumnData['bookingDate'].value = filterColumnData[columnName].value.toString();
      this.isDateRangeselected = true;
      delete filterColumnData[columnName];
    }
    if (columnName === 'modifiedDate') {
      filterColumnData[columnName].value = filterColumnData[columnName].value.map((i) => i.getTime());
      filterColumnData['updatedDate'].value = filterColumnData[columnName].value.toString();
      this.isDateRangeselected = true;
      delete filterColumnData[columnName];
    }
    this.searchQuery = this.utilityService.searchFilterQueryParam(filterColumnData);
    this.onQueryParamsChange(this.params);
    this.searchValue[columnName].visible = false;
  }

  reset(columnName: string): void {
    this.searchValue[columnName].visible = false;
    if (columnName === 'bookedDate') {
      this.searchValue[columnName].value = [];
      this.isDateRangeselected = false;
      this.searchValue['bookingDate'].value = '';
    } else if (columnName === 'modifiedDate') {
      this.searchValue[columnName].value = [];
      this.isDateRangeselected = false;
      this.searchValue['updatedDate'].value = '';
    } else {
      this.searchValue[columnName].value = '';
    }
    this.search(columnName);
  }

  // get all session data
  getSessionData(pageIndex: number, pageSize: number, shortingString: string, filterString: string) {
    this.loading = true;
    let queryParams;
    queryParams = this.utilityService.createQueryParams({ limit: pageSize, offset: pageIndex });
    let queryParamArray: Array<string> = [this.searchQuery, filterString, shortingString, queryParams];
    queryParamArray = queryParamArray.filter((x) => {
      if (x) {
        return x.length > 0;
      }
    });
    const finalQueryParam = `?${queryParamArray.join('&')}`;
    this.subscriber = this.sessionService.getServiceTableData(finalQueryParam).subscribe((resData) => {
      if (resData['isSuccess']) {
        this.sessionData = resData['data']['bookSessions'];
        this.getTotalUserAccount(this.sessionData);
        this.getTotalInterpreters(this.sessionData);
        this.totalSessionCount = resData['data']['totalSessionsCount'];
        this.loading = false;
      } else {
        this.sessionData = [];
        this.totalSessionCount = 0;
        this.loading = false;
        this.notification.error('error', resData['message']);
      }
    });
  }
  // Get Total User Account
  private getTotalUserAccount(sessionData: any[]) {
    const userAccounts = sessionData.map((i) => ({ id: i.userAccount.id, name: i.userAccount.userAccountManager.fullName }));
    this.userAccountList = this.getUniqueData(userAccounts);
  }
  // Get Total Interpreters
  private getTotalInterpreters(sessionData: any[]) {
    let interpreters = sessionData;
    interpreters = interpreters.filter((i) => i.interpreter);
    interpreters = interpreters.map((i) => ({ id: i.interpreter.id, name: i.interpreter.fullName }));
    this.interpreterList = this.getUniqueData(interpreters);
  }

  private getUniqueData(item: { id: string, name: string }[]) {
    const result = [];
    const map = new Map();
    for (const i of item) {
      if (!map.has(i.id)) {
        map.set(i.id, true);
        result.push({
          id: i.id,
          name: i.name
        });
      }
    }
    return result;
  }
  // Open Export Modal Service
  openExportModalService() {
    const sessionExportData: ExportSessionData = {
      filter: { ...this.params },
      searchValues: { ...this.searchValue }
    };
    this.modalDataService.setData('sessionExportData', sessionExportData);
    this.modalDataService.setData('userAccountList', this.userAccountList);
    this.modalDataService.setData('interpreterList', this.interpreterList);
    const modal = this.nzModalService.create({
      nzTitle: 'Export Modal',
      nzClosable: false,
      nzContent: SessionExportComponent,
      nzWidth: 1000,
    });
    modal.afterClose.subscribe((res) => {
      console.log(res);
    });
  }
  // Open User Modal
  openSessionFormService(action: string, data: any) {
    let modal;
    this.modalDataService.setData('sessionServiceData', data);
    this.modalDataService.setData('sessionActions', action);
    this.translateService.get(action).subscribe((title) => {
      if (action === 'View_session') {
        modal = this.nzModalService.create({ nzClosable: false, nzContent: SessionModelComponent, nzWidth: 900 });
      } else if (action === 'Recalculate_sessions') {
        modal = this.nzModalService.create({
          nzTitle: title, nzClosable: false, nzContent: SessionRecalculateModelComponent, nzWidth: 1200
        });
      } else if (action === 'Create_session' || action === 'Follow_up_session') {
        modal = this.nzModalService.create({ nzTitle: title, nzClosable: false, nzContent: CreateEditSessionComponent, nzWidth: 1200 });
      }
      modal.afterClose.subscribe((res) => {
        if (res) {
          this.onQueryParamsChange(this.params);
        }
      });
    });
  }
  // Close Warning Modal
  closeModal() {
    this.modalService.close();
  }
  submitWarningModalData() {
    this.removeSessionData(this.session);
  }
  openWarningModalData(row: any) {
    this.session = row;
    this.openModal(this.modalBody, null, this.modalFooter, { width: 600 });
  }
  openChangeInterpreterModal(row: any) {
    this.modalDataService.setData('session', row);
    const modal = this.nzModalService.create({
      nzTitle: 'Assign Interpreter Modal',
      nzClosable: false,
      nzContent: ChangeAssignInterpreterComponent,
      nzWidth: 1000,
    });
    modal.afterClose.subscribe((res) => {
      console.log(res);
    });
  }

  // Open Warning Modal
  private openModal<T>(body: any, header: any = null, footer: any = null, options: any = null) {
    this.modalService.open({ body, header, footer, options });
  }

  changeInterpreter() {
    this.assignInterpreterLoading = true;
    this.sessionService.assignInterpreter(this.session.id, this.changedInterpreterId).subscribe((res) => {
      if (res['isSuccess']) {
        this.notification.success('success', res['message']);
        this.assignInterpreterLoading = false;
        this.closeModal();
        this.onQueryParamsChange(this.params);
      } else {
        this.notification.error('error', res['message']);
        this.assignInterpreterLoading = false;
        this.closeModal();
      }
    });
  }
  private removeSessionData(row: any) {
    this.subscriber = this.sessionService.deleteSession(row.id, row.status).subscribe((res) => {
      if (res && res['isSuccess']) {
        this.modalService.close();
        this.sessionData = this.sessionData.filter(
          (item: any) => item.id !== row.id
        );
        this.notification.success('success', res['message']);
      }
    });
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }
}
