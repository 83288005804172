import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { sessionsPath } from './sessions.path';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private readonly httpClientService: HttpClientService) { }

  // delete Session Table Data
  public deleteServiceTableData(orgId: number): Observable<{}> {
    return this.httpClientService.deleteServerRequest(sessionsPath.getUrl('getDataUrl') + orgId);
  }

  // Get Session Common Filter Data API
  public searchTextFilter(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(sessionsPath.getUrl('searchTextFilter') + query);
  }

  // Get All Filter
  public getServiceTableData(queryParams): Observable<{}> {
    return this.httpClientService.getServerRequest(sessionsPath.getUrl('getSessions') + queryParams);
  }

  // service session table filter data
  public getSessions(orgQuery: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionsPath.getUrl('getSessions')}?${orgQuery}`);
  }
  // service interpreter list based on language
  public getInterpreterBasedOnLanguage(orgQuery: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionsPath.getUrl('getChangedInterpreters')}?${orgQuery}`);
  }

  // Get assign interpreter List
  public getPendingAssignInterpreters(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionsPath.getUrl('changeInterpreterList')}?${query}`);
  }

  // Get assign interpreter List
  public getAvailableInterpreters(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${sessionsPath.getUrl('availableInterpreters')}?${query}`);
  }

  // Update Confirmed Interpreters
  public updateConfirmedInterpreter(query: string, detail: any): Observable<{}> {
    return this.httpClientService.putServerRequest(`${sessionsPath.getUrl('changeInterpreterList')}/${query}`, detail);
  }

  // Resend Invitations to Interpreters
  public resendInvitationsInterpreter(query: string, detail: any): Observable<{}> {
    return this.httpClientService.putServerRequest(`v4/${query}/resend-invitations`, detail);
  }

  // Get Interpreters Qualifications
  public getQualificationSkills(query: string, detail: any): Observable<{}> {
    return this.httpClientService.postServerRequest(`${sessionsPath.getUrl('interpreterQualification')}?${query}`, detail);
  }

  // Delete session Table Filter data
  public deleteSession(sessionId: number, sessionType: string): Observable<{}> {
    return this.httpClientService.deleteServerRequest(`${sessionsPath.getUrl('getBookedSessions')}/${sessionId}/${sessionType}`);
  }

  // Assign Interpreter For Pending Session
  public assignInterpreter(sessionId: number, interpreterId: string): Observable<{}> {
    return this.httpClientService.postServerRequest(
      `${sessionsPath.getUrl('assignInterpreter')}/${sessionId}/interpreters/${interpreterId}`, null);
  }
}
