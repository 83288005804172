export class SessionRecalucalte {
    totalSessionHour: string;
    totalSessionMinutes: string;
    totalSessionSeconds: string;
    createdDate: Date;
    createdHour: number;
    createdMinutes: number;
    bookingDate: Date;
    bookingHour: number;
    bookingMinutes: number;
    sessionTravelCost: any;
    sessionCostForInterpreter: any;
    supplementaryCostForInterpreter: any;
    salaryAmountForUnusedTime: any;
    totalCostForInterpreter: any;
    sessionTravelCostForUser: any;
    sessionCostForUser: any;
    supplementaryCostForUser: any;
    billedAmountForUnusedTime: any;
    totalCostForUser: any;
    status: string;
    editType: string;
    totalSessionTime: number;
    id: string;
    endTime: number;
    startTime: number;
}
