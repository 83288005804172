import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { Observable } from 'rxjs';
import { recalculateSession } from './recalculate.path';

@Injectable({
  providedIn: 'root',
})
export class RecalculateService {
  public constructor(private readonly httpClientService: HttpClientService) {}

  // get tottal session details
  public recalculateCost(sessionData: object): Observable<{}> {
    return this.httpClientService.putServerRequest(
      `${recalculateSession.getUrl('getRecalculateSessions')}${
        sessionData['sessionId']
      }/set-cost?currentBillingRule=${sessionData['currentBillingRule']}`,
      sessionData
    );
  }

  public recalculateCostForOrg(
    recalculateData: object,
    query: string
  ): Observable<{}> {
    return this.httpClientService.putServerRequest(
      recalculateSession.getUrl('recalculateCost') + query,
      recalculateData
    );
  }

  public recalculateSessionCost(queryString: string): Observable<{}> {
    return this.httpClientService.putServerRequest(
      recalculateSession.getUrl('recalculateCost') + queryString,
      null
    );
  }

  public updateTvaInPercent(tvaData: object): Observable<{}> {
    return this.httpClientService.putServerRequest(
      recalculateSession.getUrl('updateTva'),
      tvaData
    );
  }

  public getTva(): Observable<{}> {
    return this.httpClientService.getServerRequest(
      recalculateSession.getUrl('updateTva')
    );
  }

  public getDataList(query: string, type: string): Observable<{}> {
    return this.httpClientService.getServerRequest(recalculateSession.getUrl(type) + query);
  }

  public getUserAccount(query: string, orgId: number): Observable<{}> {
    return this.httpClientService.getServerRequest(
      `${recalculateSession.getUrl('getOrg')}/${orgId}/user-accounts/${query}`
    );
  }
}
