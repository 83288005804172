<!-- Warning Modal -->
<ng-container>
  <ng-template #modalBody>
    <p>Are you sure you want to delete {{ "Organization" | translate }}?</p>
  </ng-template>

  <ng-template #modalFooter>
    <div class="actions justify-content-between">
      <button tc-button [view]="'error'" (click)="closeModal()">Close</button>
      <button
        tc-button
        [view]="'success'"
        (click)="submitWarningModalData(row)"
      >
        Submit
      </button>
    </div>
  </ng-template>
</ng-container>
<div class="row search_row">
  <div class="col-md-3">
    <button
    tc-button
    [beforeIcon]="'icofont-plus-circle'"
    [view]="'accent'"
    (click)="openOrganizationFormService('Create_organization', null)"
    >
    {{ "Add_a_new_organization" | translate }}
  </button>
</div>
<div class="col-md-3 offset-md-6">
  <nz-select nzAllowClear 
      nzMode="multiple" nzPlaceHolder="'Show/Hide Table Column'" [(ngModel)] = "selectedColumns" 
      (ngModelChange)="onSelectcolumn($event)">
      <ng-container *ngFor="let item of columnList">
        <nz-option [nzValue]="item.value" [nzLabel]="item.text"></nz-option>
      </ng-container>
    </nz-select>
</div>
</div>
<nz-table
  #organizationTable
  nzShowSizeChanger
  [nzData]="organization"
  [nzFrontPagination]="false"
  [nzLoading]="loading"
  [nzTotal]="totalOrganizations"
  [nzPageSize]="pageSize"
  [nzPageIndex]="pageIndex"
  (nzQueryParams)="onQueryParamsChange($event)"
  [nzScroll]="{ x: '1100px', y: '65vh' }"
  nzSize="small"
>
  <thead>
    <tr>
      <th
        nzCustomFilter
        nzColumnKey="name"
        nzSortPriority="true"
        nzSortFn="true"
        *ngIf="columnList[0].selected"
      >
        {{ "Organization" | translate }}
        <nz-filter-trigger
          [(nzVisible)]="searchValue.name.visible"
          [nzActive]="searchValue.name.value.length > 0"
          [nzDropdownMenu]="name"
        >
          <i nz-icon nzType="search"></i>
        </nz-filter-trigger>
      </th>
      <th
        nzCustomFilter
        nzColumnKey="firstName"
        nzSortPriority="true"
        nzSortFn="true"
        *ngIf="columnList[1].selected"
      >
        {{ "Manager_firstname" | translate }}
        <nz-filter-trigger
          [(nzVisible)]="searchValue.firstName.visible"
          [nzActive]="searchValue.firstName.value.length > 0"
          [nzDropdownMenu]="firstName"
        >
          <i nz-icon nzType="search"></i>
        </nz-filter-trigger>
      </th>
      <th
        nzCustomFilter
        nzColumnKey="lastName"
        nzSortPriority="true"
        nzSortFn="true"
        *ngIf="columnList[2].selected"
      >
        {{ "Manager_lastname" | translate }}
        <nz-filter-trigger
          [(nzVisible)]="searchValue.lastName.visible"
          [nzActive]="searchValue.lastName.value.length > 0"
          [nzDropdownMenu]="lastName"
        >
          <i nz-icon nzType="search"></i>
        </nz-filter-trigger>
      </th>
      <th
        nzCustomFilter
        nzColumnKey="email"
        nzSortPriority="true"
        nzSortFn="true"
        *ngIf="columnList[3].selected"
      >
        {{ "Manager_email" | translate }}
        <nz-filter-trigger
          [(nzVisible)]="searchValue.email.visible"
          [nzActive]="searchValue.email.value.length > 0"
          [nzDropdownMenu]="email"
        >
          <i nz-icon nzType="search"></i>
        </nz-filter-trigger>
      </th>
      <th nzColumnKey="actions" nzRight nzWidth="150px" *ngIf="columnList[4].selected">
        {{ "Action" | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of organizationTable.data">
      <td *ngIf="columnList[0].selected">{{ data.name }}</td>
      <td *ngIf="columnList[1].selected">{{ data.manager.firstName }}</td>
      <td *ngIf="columnList[2].selected">{{ data.manager.lastName }}</td>
      <td *ngIf="columnList[3].selected">{{ data.manager.email }}</td>
      <td nzRight nzWidth="150px" *ngIf="columnList[4].selected">
        <a
          href="#"
          [routerLink]="['/', 'organizations', 'view-organization', data.id]"
          tc-button
          [afterIcon]="'icofont-external-link'"
          [square]="true"
          [tcShape]="500"
          [size]="'sm'"
        ></a>
        <button
          tc-button
          [afterIcon]="'icofont-ui-edit'"
          [view]="'info'"
          [square]="true"
          [tcShape]="500"
          [size]="'sm'"
          (click)="openOrganizationFormService('Edit_organization', data)"
        ></button>
        <button
          tc-button
          [afterIcon]="'icofont-ui-delete'"
          [view]="'error'"
          [square]="true"
          [tcShape]="500"
          [size]="'sm'"
          (click)="openWarningModalData(data)"
        ></button>
      </td>
    </tr>
  </tbody>
</nz-table>
<nz-dropdown-menu #name="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input
        type="text"
        nz-input
        placeholder="Search"
        [(ngModel)]="searchValue.name.value"
      />
      <button
        nz-button
        nzSize="small"
        nzType="primary"
        (click)="search('name')"
        class="search-button"
      >
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset('name')">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #firstName="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input
        type="text"
        nz-input
        placeholder="Search"
        [(ngModel)]="searchValue.firstName.value"
      />
      <button
        nz-button
        nzSize="small"
        nzType="primary"
        (click)="search('firstName')"
        class="search-button"
      >
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset('firstName')">
        Reset
      </button>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #lastName="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input
        type="text"
        nz-input
        placeholder="Search"
        [(ngModel)]="searchValue.lastName.value"
      />
      <button
        nz-button
        nzSize="small"
        nzType="primary"
        (click)="search('lastName')"
        class="search-button"
      >
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset('lastName')">
        Reset
      </button>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #email="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input
        type="text"
        nz-input
        placeholder="Search"
        [(ngModel)]="searchValue.email.value"
      />
      <button
        nz-button
        nzSize="small"
        nzType="primary"
        (click)="search('email')"
        class="search-button"
      >
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset('email')">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
