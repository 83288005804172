import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { HttpService } from '../../../services/http/http.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { OrganizationService } from './organization.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilityService } from '../../../shared/services/utility.service';
import { TCModalService } from '../../../ui/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService, NzTableQueryParams } from 'ng-zorro-antd';
import { ModalDataService } from '../../../shared/services/modal-data.service';
import { NewOrganizationComponent } from '../new-organization/new-organization.component';

interface OrganizationTableColumns {
  name: { value: string, visible: boolean };
  firstName: { value: string, visible: boolean };
  lastName: { value: string, visible: boolean };
  email: { value: string, visible: boolean };
}

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild('modalBody', { static: true }) modalBody: ElementRef<any>;
  @ViewChild('modalFooter', { static: true }) modalFooter: ElementRef<any>;
  public subscriber: Subscription;
  public row: any;
  public columnList = [];
  public selectedColumns = [];
  organization: any[] = [];
  totalOrganizations = 1;
  loading = true;
  pageSize = 25;
  pageIndex = 1;
  params: NzTableQueryParams;
  searchQuery: string;
  searchValue: OrganizationTableColumns = {
    name: { value: '', visible: false },
    firstName: { value: '', visible: false },
    lastName: { value: '', visible: false },
    email: { value: '', visible: false }
  };

  public constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    private readonly orgService: OrganizationService,
    private readonly queryService: UtilityService,
    private readonly modalService: TCModalService,
    private readonly translateService: TranslateService,
    private readonly modalDataService: ModalDataService,
    private readonly nzModalService: NzModalService,
  ) {
    super(store, httpSv);

    this.pageData = {
      title: 'Organizations',
      loaded: true,
      breadcrumbs: [
        { title: 'Organization', route: 'organizations' },
        { title: 'Organizations' },
      ],
    };
    this.organization = [];
    this.columnList = [
      { value: 'name', text: this.getTranslations('Organization'), selected: true },
      { value: 'firstName', text: this.getTranslations('Manager_firstname'), selected: true },
      { value: 'lastName', text: this.getTranslations('Manager_lastname'), selected: true },
      { value: 'email', text: this.getTranslations('Manager_email'), selected: true },
      { value: 'action', text: this.getTranslations('Action'), selected: true },
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOrganizations(this.pageIndex, this.pageSize, null);
    this.intitalDropdownValue();
  }

  private intitalDropdownValue() {
    const tableValues = [];
    this.columnList.forEach((i) => {
      tableValues.push(i.value);
    });
    this.selectedColumns = tableValues;
  }

  // Close Warning Modal
  closeModal() {
    this.modalService.close();
  }

  submitWarningModalData(row: any) {
    this.removeOrgData(row);
  }

  openWarningModalData(row: any) {
    this.row = row;
    this.openModal(this.modalBody, null, this.modalFooter, { width: 600 });
  }

  // Open Warning Modal
  private openModal<T>(body: any, header: any = null, footer: any = null, options: any = null, data: any = null) {
    this.modalService.open({ body, header, footer, options });
  }

  private getTranslations(key: string) {
    let translation;
    this.translateService.get(key).subscribe((res: string) => {
      translation = res;
    });
    return translation;
  }

  onSelectcolumn(columnName: Array<string>) {
    const results = this.columnList.filter(({ value: id1 }) => !columnName.some((id2) => id2 === id1));
    if (results.length > 0) {
      this.columnList.forEach((i, index) => {
        this.columnList[index].selected = true;
        results.forEach((column) => {
          if (i.value === column.value) {
            this.columnList[index].selected = !this.columnList[index].selected;
          }
        });
      });
    } else {
      this.columnList.forEach((i, index) => {
        this.columnList[index].selected = true;
      });
    }
  }

  search(columnName: string): void {
    const searchValue = { ...this.searchValue };
    this.searchQuery = this.queryService.searchFilterQueryParam(searchValue);
    this.onQueryParamsChange(this.params);
    this.searchValue[columnName].visible = false;
  }

  reset(columnName: string): void {
    this.searchValue[columnName].visible = false;
    this.searchValue[columnName].value = '';
    this.search(columnName);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.params = params;
    const { pageSize, pageIndex, sort} = params;
    let filterSort;
    if (sort.find(item => item.value !== null)) {
      filterSort = `sortBy=${this.queryService.sortingFilterQueryParam(sort)}`;
    }
    this.getOrganizations(pageIndex, pageSize, filterSort);
  }

  // get all organization data
  public getOrganizations(pageIndex: number, pageSize: number, shortingString: string) {
    this.loading = true;
    let queryParams;
    queryParams = this.queryService.createQueryParams({ limit: pageSize, offset: pageIndex });
    let queryParamArray: Array<string> = [this.searchQuery, shortingString, queryParams];
    queryParamArray = queryParamArray.filter((x) => {
      if (x) {
        return x.length > 0;
      }
    });
    const finalQueryParam = `?${queryParamArray.join('&')}`;
    this.subscriber = this.orgService.getOrganizations(finalQueryParam).pipe(map((resData) => {
      this.totalOrganizations = resData['data']['totalOrganizations'];
      return {
        data: resData['data']['organizations']
      };
    })
    ).subscribe(
      (res) => {
        if (res) {
          this.organization = res.data;
          this.loading = false;
        }
      }, (error) => {
        this.loading = false;
      }
    );
  }

  openOrganizationFormService(action: string, data: any) {
    this.modalDataService.setData('addEditOrganization', data);
    this.translateService.get(action).subscribe((title) => {
      const modal = this.nzModalService.create({
        nzTitle: title,
        nzClosable: false,
        nzContent: NewOrganizationComponent,
        nzWidth: 1200,
        nzBodyStyle: {
          height: '800px',
          overflow: 'auto'
        }
      });
      modal.afterClose.subscribe((res) => {
        if (res) {
          this.getOrganizations(this.pageIndex, this.pageSize, null);
        }
      });
    });
  }

  // remove Organization Data
  public removeOrgData(row: any) {
    this.subscriber = this.orgService.deleteOrgTableData(row.id).subscribe((res) => {
      if (res && res['isSuccess']) {
        this.modalService.close();
        this.organization = this.organization.filter(
          (item: any) => item.id !== row.id
        );
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriber.unsubscribe();
  }
}
