import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { HttpService } from '../../../services/http/http.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';
import { UtilityService } from '../../../shared/services/utility.service';
import { StatisticsService } from './statistics.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  role: string;
  chartColors: any;
  limit = -1;
  offset = 0;
  subscription: Subscription;
  organisationList: any;
  interpreterGroupList: any;
  mode = 'Day';
  fromDate: number;
  toDate: number;
  branchFromDate: number;
  branchToDate: number;
  categoryFromDate: number;
  categoryToDate: number;
  filterQualificationFromDate: number;
  filterQualificationToDate: number;
  languageFromDate: number;
  languageToDate: number;
  cantonFromDate: number;
  cantonToDate: number;
  viewType = 'Hours';
  pieType = 'language';
  interpreterGroupId = '';
  pieInterpreterId = '';
  organizationId = '';
  pieOrgId = '';
  dateRange: Date[];
  branchDateRange: Date[];
  categoryDateRange: Date[];
  languageDateRange: Date[];
  cantonDateRange: Date[];
  filterQualificationDateRange: Date[];
  sessionType = [
    { label: 'Hours', value: 'Hours' },
    { label: 'Sessions', value: 'Sessions' },
  ];

  horizontalTopInterpreter: any[];
  horizontalTopClients: any[];
  ngxChartColors: any;
  doughnutChartLabels: string[];
  doughnutChartData: number[];
  doughnutChartType: string;
  doughnutChartColors: any;
  acceptanceRate: any;
  averageResponseTime: any;

  barChartOptions: any;
  barChartColors: any;
  barChartLabels: string[];
  barChartType: string;
  barChartLegend: boolean;
  barChartData: any[];

  pieChartLabels: string[];
  pieChartData: number[];
  pieChartType: string;
  pieChartColors: any;
  pieChartBhaashaGroupLabels: string[];
  pieChartBhaashaGroupData: number[];
  pieChartBhaashaGroupColors: any;
  pieChartSessionTypeLabels: string[];
  pieChartSessionTypeData: number[];
  pieChartSessionLanguageLabels: string[];
  pieChartSessionLanguageData: number[];
  pieChartSessionLanguageColors: any;
  pieChartBranchLabels: string[] = [];
  pieChartBranchData: number[] = [];
  pieChartSubBranchLabels: string[] = [];
  pieChartSubBranchData: number[] = [];
  pieChartCategoryLabels: string[] = [];
  pieChartCategoryData: number[] = [];
  pieChartQualificationLabels: string[] = [];
  pieChartQualificationData: number[] = [];
  pieChartFilterQualificationLabels: string[] = [];
  pieChartFilterQualificationData: number[] = [];
  pieChartCantonLabels: string[] = [];
  pieChartCantonData: number[] = [];

  sessionLanguageValue = [];
  sessionLanguageLabel = [];
  sessionTypeValue = [];
  sessionTypeLabel = [];
  bhaashaGroupValue = [];
  bhaashaGroupLabel = [];
  branches = [];
  branchId = '';
  monthNames= [ 'January', 'February', 'March', 'April', 'May', 'June', 
  'July', 'August', 'September', 'October', 'November', 'December' ];
  currentMonth: string;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    private readonly queryService: UtilityService,
    private readonly statisticsService: StatisticsService,
    ) {
    super(store, httpSv);
    this.pageData = { title: 'Statistics', loaded: true, breadcrumbs: [{ title: 'Statistics'}]};
    this.getChartColor();
    this.barChartOptions = { scaleShowVerticalLines: true, responsive: true };
    this.barChartType = 'bar';
    this.barChartLegend = true;
    this.pieChartData = [300, 500, 100];
    this.pieChartType = 'pie';
    this.pieChartSessionTypeLabels = [];
    this.pieChartSessionTypeData = [];
    this.pieChartSessionLanguageColors = [{ backgroundColor: [ '#336cfb', '#b7ce63', '#e9e165', '#ed5564', '#64B5F6', '#1f2022', '#ed5564'],
      }, {borderColor: [ '#336cfb', '#b7ce63', '#e9e165', '#ed5564', '#64B5F6', '#1f2022', '#ed5564']}];
    this.ngxChartColors = { domain: ['#336cfb', '#b7ce63', '#e9e165', '#ed5564', '#64B5F6', '#1f2022']};
    this.horizontalTopInterpreter = [];
    this.horizontalTopClients = [];
    this.doughnutChartLabels = [];
    this.doughnutChartData = [];
    this.doughnutChartType = 'doughnut';
    this.doughnutChartColors = [ { backgroundColor: ['#ed5564', '#e9e165', '#64B5F6'] }, { borderColor: ['#ed5564', '#e9e165', '#64B5F6']}];
  }

  private getChartColor() {
    this.chartColors = [
      { backgroundColor: 'transparent', borderColor: '#b7ce63', pointBackgroundColor: '#b7ce63', pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff', pointHoverBorderColor: '#b7ce63',
      }, { backgroundColor: 'transparent', borderColor: '#64B5F6', pointBackgroundColor: '#64B5F6', pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff', pointHoverBorderColor: '#64B5F6',
      }, { backgroundColor: 'transparent', borderColor: '#ed5564', pointBackgroundColor: '#ed5564', pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff', pointHoverBorderColor: '#ed5564',
      },
    ];
    this.barChartColors = [{ backgroundColor: '#e9e165', borderColor: '#e9e165', pointBackgroundColor: '#e9e165', pointBorderColor: '#e9e165',
        pointHoverBackgroundColor: '#e9e165', pointHoverBorderColor: '#e9e165' }];
    this.pieChartLabels = ['Download Sales', 'In-Store Sales', 'Mail Sales'];
    this.pieChartColors = [{ backgroundColor: ['#336cfb', '#b7ce63', '#ed5564', '#e9e165', '#e762e1']},
    { borderColor: ['#336cfb', '#b7ce63', '#ed5564', '#64B5F6', '#f34231'] }];
    this.pieChartBhaashaGroupLabels = [];
    this.pieChartBhaashaGroupData = [];
    this.pieChartBhaashaGroupColors = [{ backgroundColor: ['#336cfb', '#b7ce63', '#e9e165', '#ed5564', '#64B5F6', '#a56561', '#b76565', '#c60754', '#d99775', '#e86599', '#ff00ff', '#ffa07a', '#e9967a', '#fa8072', '#f08080', '#cd5c5c', '#000080', '#008080', '#00ffff', '#00ff00']},
      { borderColor: ['#336cfb', '#b7ce63', '#e9e165', '#ed5564', '#64B5F6', '#a56b31', '#b71165', '#c60725', '#d9cd25', '#e80899', '#808000', '#800000', '#808080', '#c0c0c0c', '#ff6347', '#8b0000', '#fa8072', '#8B0000', '#ffefd5', '#32cd32'] }];
    this.pieChartSessionLanguageLabels = [];
    this.pieChartSessionLanguageData = [];
  }

  ngOnInit() {
    super.ngOnInit();
    this.intializeData();
    if (localStorage.getItem('role') === 'Admin') {
      this.getOrganisations();
      this.getInterpreterGroup();
    }
    this.getBarChartData('Day');
    this.getPieChartData();
    this.getBranches();
    this.getBranchPieChartData();
    this.getCategoryPieChartData();
    this.getQualificationPieChartData();
    this.getFilterQualificationPieChartData();
    if (this.role === 'Manager' || this.role === 'IG Manager') {
      this.getProgressData();
      this.getAcceptanceResponseTime();
    }
  }

private intializeData() {
  this.role = localStorage.getItem('role');
  this.barChartData = [{ data: [], label: '' }];
  this.fromDate = this.getDateParts(new Date()).yearFromDate.getTime();
  this.toDate = this.getDateParts(new Date()).yearToDate.getTime();
  this.currentMonth = `${this.monthNames[new Date(this.fromDate).getMonth()]}, ${new Date(this.fromDate).getFullYear()}`;
  this.dateRange = [new Date(this.fromDate), new Date(this.toDate)];
  this.branchFromDate = this.getDateParts(new Date()).monthFromDate.getTime();
  this.branchToDate = this.getDateParts(new Date()).monthToDate.getTime();
  this.branchDateRange = [new Date(this.fromDate), new Date(this.toDate)];
  this.categoryFromDate = this.getDateParts(new Date()).monthFromDate.getTime();
  this.categoryToDate = this.getDateParts(new Date()).monthToDate.getTime();
  this.categoryDateRange = [new Date(this.fromDate), new Date(this.toDate)];
  this.filterQualificationFromDate = this.getDateParts(new Date()).monthFromDate.getTime();
  this.filterQualificationToDate = this.getDateParts(new Date()).monthToDate.getTime();
  this.filterQualificationDateRange = [new Date(this.fromDate), new Date(this.toDate)];
  this.languageFromDate = this.getDateParts(new Date()).monthFromDate.getTime();
  this.languageToDate = this.getDateParts(new Date()).monthToDate.getTime();
  this.languageDateRange = [new Date(this.fromDate), new Date(this.toDate)];
  this.cantonFromDate = this.getDateParts(new Date()).monthFromDate.getTime();
  this.cantonToDate = this.getDateParts(new Date()).monthToDate.getTime();
  this.cantonDateRange = [new Date(this.fromDate), new Date(this.toDate)];
}

  private getCommonGroups(groupType: string) {
    const queryParams = this.queryService.createQuery({ limit: this.limit, offset: this.offset});
    this.subscription = this.statisticsService.getGroups(queryParams, groupType).pipe(map((resData) => {
        return {
            data: resData['data'][groupType].map((res) => {
              return {
                label: res.name,
                value: res.id,
              };
            }),
          };
        })).subscribe((resData) => {
        if (groupType === 'interpreterGroups') {
          this.interpreterGroupList = resData.data;
        } else if (groupType === 'organizations') {
          this.organisationList = resData.data;
        }
      });
  }

  // Get Interpreter Group
  getInterpreterGroup() {
    this.getCommonGroups('interpreterGroups');
  }

  // Get Organisations
  getOrganisations() {
    this.getCommonGroups('organizations');
  }

  // to split the date according to the current date
  getDateParts(date) {
    const month = new Date(date).getMonth();
    const day = new Date(date).getDate();
    const year = new Date(date).getFullYear();
    const fullDate = date;
    const first = new Date(date).getDate() - new Date(date).getDay(); // to do
    const last = first + 6;
    const dayFromDate = date;
    const dayToDate = date;
    const weekFromDate = new Date(new Date(date).setDate(first));
    const weekToDate = new Date(new Date(date).setDate(last));
    const monthFromDate = new Date(new Date(date).getFullYear(), new Date(date).getMonth(), 1);
    const monthToDate = new Date( new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0);
    const yearFromDate = new Date(new Date(date).getFullYear(), 0, 1);
    const yearToDate = new Date(new Date(date).getFullYear(), 11, 31);
    const yearStartDate = new Date(2017, 0, 1);
    return { month, day, year, fullDate, first, last, dayFromDate, dayToDate, weekFromDate, weekToDate, monthFromDate,
      monthToDate, yearFromDate, yearToDate, yearStartDate
    };
  }

  // Filter From and to date according to graph selected Mode
  private getFromToTime(mode: string) {
    if (mode === 'Day') {
      this.mode = 'Day';
      this.fromDate = this.getDateParts(new Date(this.fromDate)).monthFromDate.getTime();
      this.toDate = this.getDateParts(new Date()).monthToDate.getTime();
    } else if (mode === 'Year') {
      this.mode = 'Year';
      this.fromDate = this.getDateParts(new Date(this.fromDate)).yearStartDate.getTime();
      this.toDate = this.getDateParts(new Date(this.fromDate)).yearToDate.getTime();
    } else if (mode === 'Month') {
      this.mode = 'Month';
      this.fromDate = this.getDateParts(new Date(this.fromDate)).yearFromDate.getTime();
      this.toDate = this.getDateParts(new Date(this.fromDate)).yearToDate.getTime();
    }
  }
  getAdjacentMonth(direction: string){
    const currentFromDate: Date = new Date(this.fromDate);
    const currentToDate: Date = new Date(this.toDate);
    if (direction === 'previous'){
      this.fromDate = currentFromDate.setMonth(currentFromDate.getMonth() - 1);
      this.toDate = currentToDate.setMonth(currentToDate.getMonth() - 1);
    } else{
      this.fromDate = currentFromDate.setMonth(currentFromDate.getMonth() + 1);
      this.toDate = currentToDate.setMonth(currentToDate.getMonth() + 1);
    }
    this.currentMonth = `${this.monthNames[new Date(this.fromDate).getMonth()]}, ${new Date(this.fromDate).getFullYear()}`;
    this.getBarChartData({mode: this.mode});
  }
  // Get Statisctic bar chart Data
  getBarChartData(data: any) {
    if (this.interpreterGroupId === null) {
      this.interpreterGroupId = '';
    } else if (this.organizationId === null) {
      this.organizationId = '';
    } else if (data.mode) {
      this.getFromToTime(data.mode);
    }
    const queryParams = this.queryService.createQuery({ mode: this.mode, fromDate: this.fromDate,
      toDate: this.toDate, viewType: this.viewType, interpreterGroupId: this.interpreterGroupId,
      organizationId: this.organizationId,
    });
    this.subscription = this.statisticsService.getBarStatistics(queryParams).pipe(map((resData) => {
          return {
            data: resData['data']['data'],
            labels: resData['data']['labels'],
          };
        })
      ).subscribe((barData) => {
        this.barChartData = [{ data: barData.data, label: this.mode }];
        this.barChartLabels = barData.labels;
      });
  }

  // Get User/Interpreter progress Data
  getProgressData(dateRange?: any) {
    let fromDate = this.fromDate;
    let toDate = this.toDate;
    if (dateRange && dateRange.length > 0) {
      fromDate = this.getDateParts(dateRange['0']).fullDate.getTime();
      toDate = this.getDateParts(dateRange['1']).fullDate.getTime();
    }
    const queryParams = this.queryService.createQuery({
      fromDate,
      toDate,
      viewType: this.viewType,
    });
    this.subscription = this.statisticsService.getProgressData(queryParams).pipe(map((resData) => {
      return {
        interpreters: resData['data']['interpreters'].map((response) => this.returnResponse(response)),
        userAccounts: resData['data']['userAccounts'].map((res) => this.returnResponse(res)),
      };
    })
  ).subscribe((res) => {
    this.horizontalTopInterpreter = res.interpreters;
    this.horizontalTopClients = res.userAccounts;
    });
  }

  private returnResponse(res) {
    return {
      name: res.name,
      value: res.percent,
      total: res.total
    };
  }

  // Get Acceptance/ Response time
  getAcceptanceResponseTime(dateRange?: any) {
    let fromDate = this.fromDate;
    let toDate = this.toDate;
    if (dateRange && dateRange.length > 0) {
      fromDate = this.getDateParts(dateRange['0']).fullDate.getTime();
      toDate = this.getDateParts(dateRange['1']).fullDate.getTime();
    }
    const queryParams = this.queryService.createQuery({
      fromDate,
      toDate,
      viewType: this.viewType,
    });
    this.subscription = this.statisticsService.getAcceptanceRate(queryParams).pipe(map((resData) => {
      return {
        donutData: resData['data']['donutData'],
        acceptanceRate: resData['data']['acceptanceRate'],
        averageResponseTime: resData['data']['averageResponseTime']
      };
    })
  ).subscribe((res) => {
    this.acceptanceRate = res.acceptanceRate;
    this.averageResponseTime = res.averageResponseTime;
    this.doughnutChartLabels = res.donutData.map((res) => {
      return res.label;
    });
    this.doughnutChartData = res.donutData.map((res) => {
      return res.value;
    });
    });
  }

  private getQueryParams(data: any) {
    if (this.pieInterpreterId === null) {
      this.pieInterpreterId = '';
    }
    if (this.pieOrgId === null) {
      this.pieOrgId = '';
    }
    if (data && data.date && (data.date).length > 0) {
      this.fromDate = this.getDateParts(data.date['0']).fullDate.getTime();
      this.toDate = this.getDateParts(data.date['1']).fullDate.getTime();
    }
    const queryParams = this.queryService.createQuery({
      fromDate: this.fromDate,
      toDate: this.toDate,
      pie: data.pieType,
      interpreterId: this.pieInterpreterId,
      orgId: this.pieOrgId,
    });
    return {
      queryParams,
    };
  }

  getBranches(){
    this.subscription = this.statisticsService.getBranches('').pipe(map((resData) => {
      return {
        data: resData['data'].map(branch => ({label: branch.name, value: branch.id}))
      };
    })).subscribe((res) => {
      this.branches = res.data;
      this.branchId = res.data.length > 0 ? res.data[0].value : '';
    });
  }
  // branch and sub-branch Pie Chart
  getBranchPieChartData(branchData?: any){
    if (branchData && branchData.length > 0) {
      this.branchFromDate = this.getDateParts(branchData['0']).fullDate.getTime();
      this.branchToDate = this.getDateParts(branchData['1']).fullDate.getTime();
    }
    const queryParams = this.queryService.createQuery({
      fromDate: this.branchFromDate,
      toDate: this.branchToDate,
      viewType: this.viewType,
      pie: 'branch',
    });
    const branchValue = [];
    const branchLabel = [];
    this.subscription = this.statisticsService.getCommonPieChartData(queryParams, 'getPieChartData')
      .pipe(map((resData) => {
        if(resData['isSuccess']){
        return { data: resData['data']['statisticsData'].map((res) => {
          branchValue.push(res.averagePercentage);
          branchLabel.push(`${res.xdata}, ${res.averagePercentage.toFixed(1)}% total sessions: ${res.totalSession} total hours: ${(res.totalSessionHours/3600000).toFixed(2)}`);
        
        })};
        }
        })).subscribe((res) => {
          this.pieChartBranchData = branchValue;
          this.pieChartBranchLabels = branchLabel;
        }
    );
    this.getSubBranchPieChartData();
  }

  getSubBranchPieChartData(){
    const queryParams = this.queryService.createQuery({
      fromDate: this.branchFromDate,
      toDate: this.branchToDate,
      viewType: this.viewType,
      pie: 'sub-branch',
      branchId: this.branchId,
    });
    const subBranchValue = [];
    const subBranchLabel = [];
    this.subscription = this.statisticsService.getCommonPieChartData(queryParams, 'getPieChartData')
      .pipe(map((resData) => {
        if(resData['isSuccess']){
        return { data: resData['data']['statisticsData'].map((res) => {
          subBranchValue.push(res.averagePercentage);
          subBranchLabel.push(`${res.xdata}, ${res.averagePercentage.toFixed(1)}% total sessions: ${res.totalSession} total hours: ${(res.totalSessionHours/3600000).toFixed(2)}`);
        })};
        }
        })).subscribe((res) => {
          this.pieChartSubBranchData = subBranchValue;
          this.pieChartSubBranchLabels = subBranchLabel;
        }
      );
  }


  // filter qualification Pie Chart
  getFilterQualificationPieChartData(dateRange?: any) {
    if (dateRange && dateRange.length > 0) {
      this.filterQualificationFromDate = this.getDateParts(dateRange['0']).fullDate.getTime();
      this.filterQualificationToDate = this.getDateParts(dateRange['1']).fullDate.getTime();
    }
    const queryParams = this.queryService.createQuery({
      fromDate: this.filterQualificationFromDate,
      toDate: this.filterQualificationToDate,
    });
    const filterQualificationValue = [];
    const filterQualificationLabel = [];
    this.subscription = this.statisticsService.getCommonPieChartData(queryParams, 'getFilterQualificationPieChartData')
      .pipe(map((resData) => {
        if(resData['isSuccess']){
        return { data: resData['data']['statisticsData'].map((res) => {
          filterQualificationValue.push(res.averagePercentageForInPerson);
          filterQualificationLabel.push(res.xdata);
        })};
        }
        })).subscribe((res) => {
          this.pieChartFilterQualificationData = filterQualificationValue;
          this.pieChartFilterQualificationLabels = filterQualificationLabel;
        }
    );
  }


  // Qualification Pie Chart
  getQualificationPieChartData(){
    const qualificationValue = [];
    const qualificationLabel = [];
    this.subscription = this.statisticsService.getQualificationPieChartData()
      .pipe(map((resData) => {
        if(resData['isSuccess']){
        return { data: resData['data']['statisticsData'].map((res) => {
          qualificationValue.push(res.averagePercentage);
          qualificationLabel.push(`${res.xdata}, total interpreters: ${res.totalSession}`);
        })};
        }
        })).subscribe((res) => {
          this.pieChartQualificationData = qualificationValue;
          this.pieChartQualificationLabels = qualificationLabel;
        }
    );
  }

  // category Pie Chart
  getCategoryPieChartData(categoryDateRange?: any){
    if (categoryDateRange && categoryDateRange.length > 0) {
      this.categoryFromDate = this.getDateParts(categoryDateRange['0']).fullDate.getTime();
      this.categoryToDate = this.getDateParts(categoryDateRange['1']).fullDate.getTime();
    }
    const queryParams = this.queryService.createQuery({
      fromDate: this.categoryFromDate,
      toDate: this.categoryToDate,
      viewType: this.viewType,
    });
    const categoryValue = [];
    const categoryLabel = [];
    this.subscription = this.statisticsService.getCommonPieChartData(queryParams, 'getCategoryPieChartData')
      .pipe(map((resData) => {
        if (resData['isSuccess']){
        return { data: resData['data']['statisticsData'].map((res) => {
          categoryValue.push(res.averagePercentage);
          categoryLabel.push(`${res.xdata}, ${res.averagePercentage.toFixed(1)}% total sessions: ${res.totalSession} total hours: ${(res.totalSessionHours/3600000).toFixed(2)}`);
        })};
        }
        })).subscribe((res) => {
          this.pieChartCategoryData = categoryValue;
          this.pieChartCategoryLabels = categoryLabel;
        }
    );
  }
  getCantonPieChartData(data: any){
    const queryParamsCanton = this.getQueryParams({ viewType: this.viewType, date: data});
    const cantonLabel = [];
    const cantonValue = [];
    this.subscription = this.statisticsService.getCommonPieChartData(queryParamsCanton.queryParams, 'getCantonPieChartData')
    .pipe(map((resData) => {
      if (resData['isSuccess']){
        return { data: resData['data']['statisticsData'].map((res) => {
          cantonValue.push(res.averagePercentage);
          cantonLabel.push(`${res.xdata}, ${res.averagePercentage.toFixed(1)}% total sessions: ${res.totalSession} total hours: ${(res.totalSessionHours/3600000).toFixed(2)}`);
          return { value: res.averagePercentage, label: res.xdata }; }), };
      }
      })).subscribe((res) => {
      this.pieChartCantonLabels = cantonLabel;
      this.pieChartCantonData = cantonValue; 
    });
  }
  getLanguagePieChartData(data: any){
    this.pieType = 'language';
    const queryParams = this.getQueryParams({ pieType: this.pieType, date: data });
    this.sessionLanguageValue = [];
    this.sessionLanguageLabel = [];

    this.subscription = this.statisticsService.getCommonPieChartData(queryParams.queryParams, 'getPieChartData')
      .pipe(map((resData) => {
        if (resData['isSuccess']){
        return { data: resData['data']['statisticsData'].map((res) => {
          this.sessionLanguageValue.push(res.averagePercentage);
          this.sessionLanguageLabel.push(
            `${res.xdata}, ${res.averagePercentage.toFixed(1)}% total sessions: ${res.totalSession} total hours: 
            ${(res.totalSessionHours / 3600000).toFixed(2)}`);
        })};
        }
        })).subscribe((res) => {
          this.pieChartSessionLanguageData = this.sessionLanguageValue;
          this.pieChartSessionLanguageLabels = this.sessionLanguageLabel;
        }
      );
  }
  // session-typr, language and bhaasha-group Pie Chart
  getPieChartData(data?: any) {
    this.getLanguagePieChartData(data);
    if (localStorage.getItem('role') === 'Admin') {
      this.pieType = 'sessionType';
      const queryParamsSession = this.getQueryParams({ pieType: this.pieType, date: data });
      this.sessionTypeValue = [];
      this.sessionTypeLabel = [];
      this.subscription = this.statisticsService.getCommonPieChartData(queryParamsSession.queryParams, 'getPieChartData')
      .pipe(map((resData) => {
        if (resData['isSuccess']) {
          return {
            data: resData['data']['statisticsData'].map((res) => {
              this.sessionTypeValue.push(res.averagePercentage);
              this.sessionTypeLabel.push(res.xdata); 
          })};
        }
        })).subscribe((res) => {
          this.pieChartSessionTypeLabels = this.sessionTypeLabel;
          this.pieChartSessionTypeData = this.sessionTypeValue;
        });
      this.pieType = 'bhaashaGroup';
      const queryParamsGroup = this.getQueryParams({ pieType: this.pieType, date: data});
      this.bhaashaGroupLabel = [];
      this.bhaashaGroupValue = [];
      this.subscription = this.statisticsService.getCommonPieChartData(queryParamsGroup.queryParams, 'getPieChartData')
      .pipe(map((resData) => {
        if (resData['isSuccess']) {
          return { data: resData['data']['statisticsData'].map((res) => {
            this.bhaashaGroupValue.push(res.averagePercentage);
            this.bhaashaGroupLabel.push(res.xdata);
            return { value: res.averagePercentage, label: res.xdata }; }), };
        }
        })).subscribe((res) => {
        this.pieChartBhaashaGroupLabels = this.bhaashaGroupLabel;
        this.pieChartBhaashaGroupData = this.bhaashaGroupValue; });
    }
    this.getCantonPieChartData(data);
    if (this.role === 'Manager' || this.role === 'IG Manager') {
      this.getProgressData(data);
      this.getAcceptanceResponseTime(data);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }
}
