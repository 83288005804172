import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { Observable } from 'rxjs';
import { newUserAccountUrls } from './add-user-account.path';

@Injectable({
  providedIn: 'root',
})
export class NewUserAccountService {
  public constructor(private readonly httpClientService: HttpClientService) {}

  public createUserAccount(userData: any): Observable<{}> {
    return this.httpClientService.postServerRequest( newUserAccountUrls.getUrl('addUserData') , userData );
  }

  public updateUserAccount(userData: any, userId: number): Observable<{}> {
    return this.httpClientService.putServerRequest(newUserAccountUrls.getUrl('deleteUserAccount') + userId, userData);
  }

  public getBranchOptions(branchId: number): Observable<{}> {
    return this.httpClientService.getServerRequest(`${newUserAccountUrls.getUrl('branchOptions')}${branchId}/options`);
  }

  public getBranchSubOptions(subBranchId: number): Observable<{}> {
    return this.httpClientService.getServerRequest(`${newUserAccountUrls.getUrl('branchOptions')}${subBranchId}/sub-options`);
  }

  public getOrganizationsList(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${newUserAccountUrls.getUrl('organizations')}${query}`);
  }
    /*************************/
  public updateDepartment(queryForExportAllData): Observable<{}> {
    return this.httpClientService.getServerRequest(
      newUserAccountUrls.getUrl('exportAllData') + queryForExportAllData
    );
  }

  public deleteDepartment(userAccountId: number): Observable<{}> {
    return this.httpClientService.deleteServerRequest(
      newUserAccountUrls.getUrl('deleteUserAccount') + userAccountId
    );
  }

  public getDepartmentsByOrgnization(orgId: number, query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(
      `${newUserAccountUrls.getUrl('getOrgDepartment')}${orgId}/departments${query}`);
  }

  public getBranches(): Observable<{}> {
    return this.httpClientService.getServerRequest(newUserAccountUrls.getUrl('branches'));
  }
  public languagebasedFilter(query : string): Observable<{}> {
    return this.httpClientService.getServerRequest(newUserAccountUrls.getUrl('language') + query);
  }
}
