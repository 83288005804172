import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { Store } from '@ngrx/store';
import dayGridPlugin from '@fullcalendar/daygrid';
import { FullCalendarComponent } from '@fullcalendar/angular';

import { TCModalService } from '../../../ui/services/modal/modal.service';
import { Content } from '../../../ui/interfaces/modal';
import { AgendaService } from './agenda.service';
import { UtilityService } from '../../../../../src/app/shared/services/utility.service';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  @ViewChild('calendar', { static: true })
  calendarComponent: FullCalendarComponent;
  @ViewChild('modalBody', { static: true }) modalBodyTpl: TemplateRef<any>;
  @ViewChild('modalFooter', { static: true }) modalFooterTpl: TemplateRef<any>;
  headerOptions: any;
  calendarEvents: any[];
  eventBody: any;
  calendarPlugins: any[];

  constructor(
    store: Store<IAppState>, httpSv: HttpService, private readonly tcModalmodal: TCModalService,
    private readonly agendaService: AgendaService, private readonly utilityService: UtilityService
  ) {
    super(store, httpSv);
    this.pageData = { title: 'Events calendar', loaded: true,
      breadcrumbs: [{ title: 'Apps', route: 'default-dashboard'}, { title: 'Service pages', route: 'default-dashboard'},
        { title: 'Events calendar'}]};
    const desc = 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
    this.calendarEvents = [
      { title: 'Appointment', color: '#9d709a', start: this.setDate(0), end: this.setDate(0, 1), desc},
      { title: 'Appointment', color: '#349d68', start: this.setDate(0, 2), end: this.setDate(0, 3), desc },
      { title: 'Appointment', start: this.setDate(1, -1), end: this.setDate(1, 3), desc },
      { title: 'Appointment', color: '#ed5564', start: this.setDate(1), end: this.setDate(1, 3), desc },
      { title: 'Appointment', color: '#ed9661', start: this.setDate(1, -3), end: this.setDate(1, -2), desc},
      { title: 'Appointment', color: '#64B5F6', textColor: '#000', start: this.setDate(3, -5), end: this.setDate(4), desc}];
    this.calendarPlugins = [dayGridPlugin];
  }

  getCalendarSessionData() {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
  const startMonthDate = new Date(y, m, 1);
  const lastMonthDate = new Date(y, m + 1, 1);
    const startDate = new Date(startMonthDate).getTime();
    const endDate = new Date(lastMonthDate).getTime();
     this.agendaService.getInterpretersCalendarSession(startDate , endDate).subscribe((res) => {
     })
  }
  ngOnInit() {
    super.ngOnInit();
    this.getCalendarSessionData();
    this.headerOptions = {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek,dayGridDay',
    };
    this.setLoaded();
  }

  //  To get Calendar sessions and we are calling function which creates array to show busy
  // public getCalendarSession() {
  //   const queryParams = '1590962400000/1590962400000';
  //   this.agendaService.getInterpretersCalendarSession(queryParams).subscribe((response) =>{
  //     if (response && response['isSuccess']) {
  //       console.log('success');
  //     }
  //   })
  // }

  setDate(day: number, hour = 0) {
    const date = new Date();

    date.setDate(date.getDate() + day);
    date.setHours(date.getHours() + hour);

    return date;
  }

  dateFormat(date: any) {
    return date.toString().slice(4, 21);
  }

  eventClick(data: any) {
    this.eventBody = {
      title: data.event.title,
      color: data.event.backgroundColor,
      from: this.dateFormat(data.event.start),
      to: this.dateFormat(data.event.end),
      desc: data.event.extendedProps.desc,
    };
    this.openModal(this.modalBodyTpl, null, this.modalFooterTpl);
  }

  // open modal window
  openModal<T>(
    body: Content<T>,
    header: Content<T> = null,
    footer: Content<T> = null,
    options: any = null
  ) {
    this.tcModalmodal.open({
      body,
      header,
      footer,
      options,
    });
  }

  // close tcModalmodal window
  closeModal() {
    this.tcModalmodal.close();
    this.eventBody = {};
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
