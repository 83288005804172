<nz-spin [nzSpinning]="isLoading">
<div nz-row class="row" nzType="flex" nzJustify="space-around">
    <div nz-col nzSpan="6"  (click)="sessionClicked('PENDING_LESS_THAN_48')">
      <nz-card class="icon-class icon1-bg-color card-align">
        <em class="far fa-clock icon-1"></em>
        <div class="total-session">{{totalSessions['pendingLessThan48Sessions']}}</div>
        <div class="session-type">{{'Pending_sessions' | translate}} &lt; 48</div>
        <div class="view-text">
          <em class="fa fa-arrow-circle-right"></em>
        </div>
      </nz-card>
    </div>
    <div nz-col nzSpan="6"  (click)="sessionClicked('PENDING_GREATER_THAN_48')">
      <nz-card class="icon-class icon1-bg-color card-align">
        <em class="far fa-clock icon-1"></em>
        <div class="total-session">{{totalSessions['pendingGreaterThan48Sessions']}}</div>
        <div class="session-type">{{'Pending_sessions' | translate}} &gt; 48</div>
        <div class="view-text">
          <em class="fa fa-arrow-circle-right"></em>
        </div>
      </nz-card>
    </div>
    <div nz-col nzSpan="6" (click)="sessionClicked('TOVALIDATE')">
      <nz-card class="icon-class icon2-bg-color card-align">
        <em class="far fa-calendar-times icon-2"></em>
        <div class="total-session">{{totalSessions['unlockedMissedSessions']}}</div>
        <div class="session-type">{{'To_validated_sessions' | translate}}</div>
        <div class="view-text">
          <em class="fa fa-arrow-circle-right"></em>
        </div>
      </nz-card>
    </div>

    <div nz-col nzSpan="6" (click)="sessionClicked('NONVALIDATED')">
      <nz-card class="icon-class icon2-bg-color card-align">
        <em class="far fa-calendar-times icon-2"></em>
        <div class="total-session">{{totalSessions['lockedMissedSessions']}}</div>
        <div class="session-type">{{'Non_validated_sessions' | translate}}</div>
        <div class="view-text">
          <em class="fa fa-arrow-circle-right"></em>
        </div>
      </nz-card>
    </div>
    <!-- <div nz-col nzSpan="6" (click)="sessionClicked('STOPPED')">
      <nz-card class="icon-class icon3-bg-color card-align">
        <em class="fa fa-calendar-week icon-3"></em>
      <div class="total-session">{{totalSessions['stoppedSessions']}}</div>
      <div class="session-type">{{'Stopped_sessions' | translate}}</div>
      <div class="session-type session">{{'Incompletely_validated' | translate}}</div>
      <div class="view-text">
        <em class="fa fa-arrow-circle-right"></em>
      </div>
      </nz-card>
    </div> -->
  </div>
  <div nz-row>
  <!-- <div class="custom-calendar">
    <nz-range-picker name="range" [(ngModel)]="dateRange" (ngModelChange)="onChange($event)"></nz-range-picker>
  </div> -->
  </div>
  <div nz-row class="row" nzType="flex" nzJustify="space-around" (click)="sessionClicked('CONFIRMED')" style="margin-top: 1rem;">
    <div nz-col nzSpan="6">
      <nz-card class="icon-class icon4-bg-color card-align">
        <em class="far fa-calendar-check icon-4"></em>
        <div class="total-session">{{totalSessions['confirmedSessions']}}</div>
        <div class="session-type">{{'Confirmed_sessions'| translate}}</div>
        <div class="view-text">
          <em class="fa fa-arrow-circle-right"></em>
        </div>
      </nz-card>
    </div>
    <div nz-col nzSpan="6" (click)="sessionClicked('COMPLETED')">
      <nz-card class="icon-class icon5-bg-color card-align">
        <em class="fa fa-check icon-5"></em>
      <div class="total-session">{{totalSessions['completedSessions']}}</div>
      <div class="session-type">{{'Completed_sessions' | translate}}</div>
      <div class="view-text">
        <em class="fa fa-arrow-circle-right"></em>
      </div>
      </nz-card>
    </div>
    <div nz-col nzSpan="6" (click)="sessionClicked('REFUSED_LESS_THAN_48')">
      <nz-card class="icon-class icon6-bg-color card-align">
        <em class="fa fa-times icon-6"></em>
      <div class="total-session">{{totalSessions['refusedLessThan48Sessions']}}</div>
      <div class="session-type">{{'Refused_sessions' | translate}} &lt; 48</div>
      <div class="view-text">
        <em class="fa fa-arrow-circle-right"></em>
      </div>
      </nz-card>
    </div>
    <div nz-col nzSpan="6" (click)="sessionClicked('REFUSED_GREATER_THAN_48')">
      <nz-card class="icon-class icon6-bg-color card-align">
        <em class="fa fa-times icon-6"></em>
      <div class="total-session">{{totalSessions['refusedGreaterThan48Sessions']}}</div>
      <div class="session-type">{{'Refused_sessions' | translate}} &gt; 48</div>
      <div class="view-text">
        <em class="fa fa-arrow-circle-right"></em>
      </div>
      </nz-card>
    </div>
  </div>
</nz-spin>
  