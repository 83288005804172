import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalDataService {
    private readonly data = {};
    public constructor() { }
    setData(option, value) {
        this.data[option] = value;
    }
    getData() {
        return this.data;
    }
}
