import { environment } from '../../../../environments/environment';

export const urls = {
    getOrgDepartment: 'org/',
    exportAllData : 'profile-export',
    deleteUserAccount : 'user-accounts/',
    addUserData: 'user-accounts',
    branches: 'branches',
    branchOptions: 'branch/',
    organizations: 'organizations',
    language: 'interpreters/language-based'
};

export const oldUrls = {
    getOrgDepartment: 'v4/org/',
    exportAllData : 'v4/profile-export',
    deleteUserAccount : 'v4/user-accounts/',
    addUserData: 'v4/user-accounts',
    branches: 'v4/branches',
    branchOptions: 'v4/branch/',
    organizations: 'v4/organizations',
    language: 'v4/interpreters/language-based'
};
export const newUserAccountUrls = {
    getUrl(urlName: string) {
       if (environment['backend'] === 'old') {
           return oldUrls[urlName];
       } else {
           return urls[urlName];
       }
   }
};
