import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Login } from './login.model';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
token: Observable<Login[]>;
constructor(private readonly router: Router) {}
canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  if (localStorage.getItem('token')) {
      return true;
  }
  this.router.navigate(['']);
  return false;
}
}
