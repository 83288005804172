import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { NewSessionService } from './create-edit-session.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormGroup, NgForm } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '../../../shared/services/utility.service';
import { NzModalRef, NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { ModalDataService } from 'src/app/shared/services/modal-data.service';
import { AddNewUserComponent } from '../../userAccount/add-new-user/add-new-user.component';
import { AddNewPatientComponent } from '../../userAccount/add-new-patient/add-new-patient.component';
import { AddLocationComponent } from '../../userAccount/add-location/add-location.component';
import { AddFilterComponent } from '../../userAccount/add-filter/add-filter.component';
import { Session } from './session.interface';

@Component({
  selector: 'app-create-edit-session',
  templateUrl: './create-edit-session.component.html',
  styleUrls: ['./create-edit-session.component.scss'],
})
export class CreateEditSessionComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('f', { static: false }) session: NgForm;
  currentLanguage: string;
  sessionData = new Session();
  sessionForm: FormGroup;
  subscription: Subscription;
  languageData: any;
  locationData: any;
  roleUser = [];
  role: string;
  patientUser: any;
  doctorUser: any;
  userData: any;
  userAccountData = [];
  getInterpreter = [];
  selectedUserData = { orgId: null, userId: null, interpreter: null };
  patientFormData: any[];
  patientLabelData: any;
  paymentAuthorityData: any;
  categoryData: any;
  filterData: {};
  mode: string;
  sessionId: number;
  sessionTypeData = [
    { label: this.getTranslations('INPERSON'), value: 'INPERSON' },
    { label: this.getTranslations('VIDEO'), value: 'VIDEO' },
    { label: this.getTranslations('TELEPHONE'), value: 'TELEPHONE' },
    { label: this.getTranslations('INTERCULTURALMEDIATION'), value: 'INTERCULTURALMEDIATION' },
    { label: this.getTranslations('COURSEMANAGEMENT'), value: 'COURSEMANAGEMENT' },
  ];
  sessionStatusData = [
    { label: this.getTranslations('SENT'), value: 'PENDING' },
    { label: this.getTranslations('CONFIRMED'), value: 'CONFIRMED' },
    { label: this.getTranslations('COMPLETED'), value: 'COMPLETED' },
  ];
  selectDuration = [
    { label: `15 ${this.getTranslations('Minute')}`, value: 15 },
    { label: `30 ${this.getTranslations('Minute')}`, value: 30 },
    { label: `45 ${this.getTranslations('Minute')}`, value: 45, },
    { label: `1 ${this.getTranslations('Hour')}`, value: 60 },
    { label: `1 ${this.getTranslations('Hour')} 15 ${this.getTranslations('Minute')}`, value: 75 },
    { label: `1 ${this.getTranslations('Hour')} 30 ${this.getTranslations('Minute')}`, value: 90 },
    { label: `1 ${this.getTranslations('Hour')} 45 ${this.getTranslations('Minute')}`, value: 105 },
    { label: `2 ${this.getTranslations('Hours')}`, value: 120 },
    { label: `2 ${this.getTranslations('Hours')} 15 ${this.getTranslations('Minute')}`, value: 135 },
    { label: `2 ${this.getTranslations('Hours')} 30 ${this.getTranslations('Minute')}`, value: 150 },
    { label: `2 ${this.getTranslations('Hours')} 45 ${this.getTranslations('Minute')}`, value: 165 },
    { label: `3 ${this.getTranslations('Hours')}`, value: 180 },
    { label: `3 ${this.getTranslations('Hours')} 15 ${this.getTranslations('Minute')}`, value: 195 },
    { label: `3 ${this.getTranslations('Hours')} 30 ${this.getTranslations('Minute')}`, value: 210 },
    { label: `3 ${this.getTranslations('Hours')} 45 ${this.getTranslations('Minute')}`, value: 225 },
    { label: `${this.getTranslations('Half_Day')}`, value: 240 },
    { label: `6 ${this.getTranslations('Hours')}`, value: 360 },
    { label: `${this.getTranslations('Full_Day')}`, value: 480 },
  ];
  loading = true;
  searchChange$ = new BehaviorSubject('');
  searchType: string;
  isLoading = false;
  qualification = [];
  selectedLanguage = '';
  isLanguageSelected = false;
  interpreterIds = [];

  constructor(
    private readonly newSessionService: NewSessionService,
    private readonly modal: NzModalRef,
    private readonly translateService: TranslateService,
    private readonly utilityService: UtilityService,
    private readonly notification: NzNotificationService,
    private readonly modalDataService: ModalDataService,
    private readonly nzModalService: NzModalService,
  ) {
    this.patientFormData = [];
  }

  ngOnInit() {
    this.currentLanguage = localStorage.getItem('transLang');
    this.getSelectedLanguage(this.currentLanguage);
    this.role = localStorage.getItem('role');
    const sessionData = this.modalDataService.getData();
    this.mode = sessionData['sessionActions'];
    this.getUserData();
    if (sessionData['sessionServiceData']) {
      this.sessionData = JSON.parse(JSON.stringify(sessionData['sessionServiceData']));
      if (!this.sessionData.user) {
        Object.assign(this.sessionData, { user: { id: '' } });
      }
      if (!this.sessionData.patient) {
        Object.assign(this.sessionData, { patient: { id: '' } });
      }
      if (this.sessionData.interpreter) {
        this.sessionData.interpreterList = this.sessionData.interpreter.id;
        this.selectedUserData.orgId = this.sessionData.organization.id;
        this.onSelectLanguage(this.sessionData.toLanguage.isoCode);
      }
      if (this.sessionData['paymentAuthorityOptionsData']) {
        this.sessionData.paymentAuthorityOption = this.sessionData['paymentAuthorityOptionsData'];
      } else {
        Object.assign(this.sessionData, { paymentAuthorityOption: { id: 0 } });
      }
      if (this.sessionData['categoryOptionsData']) {
        this.sessionData.categoryOption = this.sessionData['categoryOptionsData'];
      } else {
        Object.assign(this.sessionData, { categoryOption: { id: 0 } });
      }
      this.sessionData.st = new Date(this.sessionData.startTime);
      this.sessionData.dt = new Date(this.sessionData.bookingDate);
      this.sessionId = this.sessionData?.id;
    }
    if (this.mode !== 'Create_session') {
      const value = {
        id: this.sessionData?.userAccount?.['id'],
        organization: this.sessionData?.organization?.['id']
      };
      this.onSelectUser(value);
    }
  }

  private getSelectedLanguage(lang: string) {
    switch (lang) {
      case 'en':
        this.selectedLanguage = 'English';
        break;
      case 'fr':
        this.selectedLanguage = 'French';
        break;
      case 'it':
        this.selectedLanguage = 'Italian';
        break;
      default:
        this.selectedLanguage = 'German';
    }
  }

  private getTranslations(key: string) {
    let translation;
    this.translateService.get(key).subscribe((res: string) => {
      translation = res;
    });
    return translation;
  }

  // getting user data
  private getUserData(searchString?: any) {
    this.isLoading = true;
    let searchText;
    if (searchString) {
      searchText = searchString;
    } else {
      searchText = '';
    }
    const queryParam = this.utilityService.createQuery({ searchText, limit: 25, offset: 1 });
    this.subscription = this.newSessionService.getUserData(queryParam).pipe(map((resData) => {
      return {
        data: resData['data']['userAccounts'].map((res) => {
          this.userAccountData.push(res);
          return {
            label: res.userAccountManager.fullName,
            value: res.id,
          };
        }),
      };
    })).subscribe((res) => {
      this.userData = res.data;
      this.isLoading = false;
      this.loading = false;
    });
  }

  onSearchSelect(value: string, type: string) {
    this.searchType = type;
    this.searchChange$.next(value);
  }

  ngAfterViewInit() {
    this.searchChange$.asObservable().pipe(
      filter(Boolean), debounceTime(1500), distinctUntilChanged(),
      tap((event) => {
        if (this.searchType === 'userAccount') {
          this.getUserData(event);
        } else if (this.searchType === 'user') {
          this.getRoleUser(this.selectedUserData.userId, event);
        } else if (this.searchType === 'doctor') {
          this.getMedicalRole(this.selectedUserData.userId, 'medical', event);
        } else if (this.searchType === 'patient') {
          this.getPatientData(this.selectedUserData.userId, event);
        } else if (this.searchType === 'payment') {
          this.getPaymentAuthority(this.selectedUserData.orgId, event);
        } else if (this.searchType === 'category') {
          this.getCategoryData(this.selectedUserData.orgId, event);
        }
      })
    ).subscribe();
  }

  onSelectUser(value: any): void {
    if (value) {
      if (this.mode === 'Edit_sessions' || this.mode === 'Follow_up_session') {
        this.selectedUserData.userId = value.id;
        this.selectedUserData.orgId = value.organization;
      } else {
        this.userAccountData.forEach((i) => {
          if (i.id === value) {
            this.selectedUserData.userId = i.id;
            this.selectedUserData.orgId = i.organization.id;
          }
        });
      }
      this.resetValueOnSelectUserAccount();
      this.getPatientFormData(this.selectedUserData.orgId);
      // Getting User Role
      this.getRoleUser(this.selectedUserData.userId);
      // Getting Medical Data for role user
      this.getPatientData(this.selectedUserData.userId);
      // getting medical role
      this.getMedicalRole(this.selectedUserData.userId, 'medical');
      // Getting Language According to User
      this.getLanguage(this.selectedUserData.orgId);
      // Getting Payment Authority Data
      this.getPaymentAuthority(this.selectedUserData.orgId);
      // Getting category Data
      this.getCategoryData(this.selectedUserData.orgId);
      // Get Patient Label Data
      this.getPatientLabelData(this.selectedUserData.orgId);
    }
  }

  private resetValueOnSelectUserAccount() {
    this.sessionData.toLanguage.isoCode = '';
    this.sessionData.interpreterList = '';
    this.sessionData.gender = '';
    this.sessionData.origin = '';
    this.sessionData.qualificationSkillId = [];
    this.sessionData.user.id = '';
    this.sessionData.doctor.id = '';
    this.sessionData.patient.id = '';
    this.sessionData.otherUser.id = '';
  }

  onSelectInterpreter(value: any) {
    if (value && this.sessionData.status) {
      if (this.sessionData.status === 'PENDING' && value[0] === 0) {
        const selectedValues = [];
        this.getInterpreter.forEach((i) => {
          if (i.value !== 0) {
            selectedValues.push(i.value);
          }
        });
        this.sessionData.interpreterList = selectedValues;
      }
    }
  }

  onSelectSessionType(value: any) {
    if (value === 'PENDING') {
      if (this.getInterpreter.length > 0 && this.getInterpreter[0].value !== 0) {
        this.getInterpreter.unshift({ label: this.getTranslations('All'), value: 0 });
      }
      this.sessionData.interpreterList = [];
    } else {
      if (this.getInterpreter.length > 0 && this.getInterpreter[0].value === 0) {
        this.getInterpreter.unshift();
      }
      this.sessionData.interpreterList = '';
    }
  }

  // Get Patient Form Data
  private getPatientFormData(orgId: number) {
    this.loading = true;
    this.subscription = this.newSessionService.getPatientForm(orgId).pipe(map((resData) => {
      return {
        data: resData['data']['formData'],
      };
    })
    ).subscribe((res) => {
      this.loading = false;
      this.patientFormData = res.data;
    });
  }
  // Get Patient Label Data
  private getPatientLabelData(orgId: number) {
    this.loading = true;
    this.subscription = this.newSessionService.getPatientLable(orgId).pipe(map((resData) => {
      return {
        data: resData['data']['orgLabel'],
      };
    })
    ).subscribe((res) => {
      this.loading = false;
      this.patientLabelData = res.data;
    });
  }

  // Get Payment Authority Data
  private getPaymentAuthority(orgId: number, searchString?: any) {
    this.loading = true;
    let searchText;
    if (searchString) {
      searchText = searchString;
    } else {
      searchText = '';
    }
    const queryString = this.utilityService.createQuery({ searchText, limit: 10, offset: 1 });
    this.subscription = this.newSessionService.getPaymentAuthority(orgId, queryString).subscribe((res) => {
      this.paymentAuthorityData = res['response']['paymentAuthority'];
      this.loading = false;
    });
  }

  // Get Payment Authority Data
  private getCategoryData(orgId: number, searchString?: any) {
    this.loading = true;
    let searchText;
    if (searchString) {
      searchText = searchString;
    } else {
      searchText = '';
    }
    const queryString = this.utilityService.createQuery({ searchText, limit: 10, offset: 1 });
    this.subscription = this.newSessionService.getCategoryData(orgId, queryString).subscribe((res) => {
      this.categoryData = res['response']['category'];
      this.loading = false;
    });
  }

  // Getting Role User Data
  private getRoleUser(userId: number, searchString?: any) {
    this.loading = true;
    let searchText;
    let queryString;
    if (searchString) {
      searchText = searchString;
      queryString = this.utilityService.createQuery({ role: 'user', searchText, limit: -1, offset: 1 });
    } else {
      searchText = '';
      queryString = this.utilityService.createQuery({ role: 'user', searchText, limit: 10, offset: 1 });
    }
    this.subscription = this.newSessionService.getRoleUserData(userId, queryString).pipe(map((resData) => {
      return {
        data: resData['data']['users'].map((res) => {
          return {
            label: res.fullName,
            value: res.id,
          };
        }),
      };
    })).subscribe((resData) => {
      this.roleUser = resData.data;
      this.loading = false;
    });
  }

  // Getting medical Data for Role User
  private getPatientData(userId: number, searchString?: any) {
    this.loading = true;
    let searchText;
    let queryString;
    if (searchString) {
      searchText = searchString;
      queryString = this.utilityService.createQuery({ searchText, limit: -1, offset: 1 });
    } else {
      searchText = '';
      queryString = this.utilityService.createQuery({ searchText, limit: 10, offset: 1 });
    }
    this.subscription = this.newSessionService.getUserPatient(userId, queryString).pipe(map((resData) => {
      return {
        data: resData['data']['patients'].map((res) => {
          return {
            label: res.isShowValue,
            value: res.id,
          };
        }),
      };
    })).subscribe((resData) => {
      this.patientUser = resData.data;
      this.loading = false;
    });
  }

  // Private Get Medical Role
  private getMedicalRole(userId: number, type: string, searchString?: any) {
    this.loading = true;
    let searchText;
    let queryString;
    if (searchString) {
      searchText = searchString;
      queryString = this.utilityService.createQuery({ role: type, searchText, limit: -1, offset: 1 });
    } else {
      searchText = '';
      queryString = this.utilityService.createQuery({ role: type, searchText, limit: 10, offset: 1 });
    }
    this.subscription = this.newSessionService.getRoleUserData(userId, queryString).pipe(map((resData) => {
      return {
        data: resData['data']['users'].map((res) => {
          return {
            label: res.fullName,
            value: res.id,
          };
        }),
      };
    })).subscribe((resData) => {
      this.doctorUser = resData.data;
      this.loading = false;
    });
  }

  // Getting Language According to User
  private getLanguage(orgId: number) {
    this.loading = true;
    this.subscription = this.newSessionService.getLanguage(orgId).pipe(map((resData) => {
      return {
        data: resData['data']['patientLanguages'].map((res) => {
          return {
            label: res.name,
            value: res.name,
          };
        }),
      };
    })).subscribe((response) => {
      this.languageData = response.data;
      this.loading = false;
    });
  }

  private getSessionAvaliability(session: string) {
    let sessionType: string;
    let availability: boolean;
    switch (session) {
      case 'INPERSON':
        sessionType = 'inPersonAvailability';
        availability = true;
        break;
      case 'VIDEO':
        sessionType = 'videoCallAvailability';
        availability = true;
        break;
      case 'TELEPHONE':
        sessionType = 'voiceCallAvailability';
        availability = true;
        break;
      case 'COURSEMANAGEMENTON':
        sessionType = 'courseAvailability';
        availability = true;
        break;
      case 'INTERCULTURALMEDIATION':
        sessionType = 'mediationAvailability';
        availability = true;
        break;
    }
    return { sessionType, availability };
  }

  // On Select Patient Language
  public onSelectLanguage(value: any) {
    this.sessionData.interpreterList = '';
    this.isLanguageSelected = true;
    this.loading = true;
    if (value) {
      let sessionType;
      let availability;
      if (this.sessionData.sessionType) {
        const returnData = this.getSessionAvaliability(this.sessionData.sessionType);
        sessionType = returnData.sessionType;
        availability = returnData.availability;
      }
      let query;
      const searchText = '';
      // tslint:disable-next-line: max-line-length
      if ((this.role === 'Admin') && (this.qualification !== undefined) && (this.sessionData.origin !== undefined) && (this.sessionData.gender !== undefined)) {
        query = this.utilityService.createQuery(
          {
            orgId: this.selectedUserData.orgId, searchText, limit: 7, offset: 1, fromLang: this.selectedLanguage, toLang: value,
            [sessionType]: availability, origin: this.sessionData.origin, gender: this.sessionData.gender,
            qualificationSkillIds: this.qualification
          });
      } else {
        query = this.utilityService.createQuery(
          {
            searchText, limit: 7, offset: 1, fromLang: this.selectedLanguage, toLang: value,
            [sessionType]: availability, origin: this.sessionData.origin, gender: this.sessionData.gender,
            qualificationSkillIds: this.qualification
          });
      }
      this.subscription = this.newSessionService.getInterpreter(query).pipe(map((resData) => {
        return {
          data: resData['data']['interpreters'].map((res) => {
            return {
              label: res.fullName,
              value: res.id,
            };
          }),
        };
      })).subscribe((res) => {
        this.getInterpreter = res.data;
        this.getInterpreter.forEach((val) => {
          this.interpreterIds.push({ id: val.value });
        });
        if (this.sessionData.status === 'PENDING') {
          this.getInterpreter.unshift({ label: this.getTranslations('All'), value: 0 });
        }
        this.loading = false;
      });
    }
  }

  // close modal window
  closeModal(response?: any) {
    this.session.reset();
    this.modal.destroy(response);
  }

  // Open New User/ Doctor Modal
  onClickAddUser(action: string, data: any, modalFor: string) {
    let modal;
    const modalInfo = {
      action,
      modalFor,
      userAccountId: this.selectedUserData.userId
    };
    this.modalDataService.setData('modalInfo', modalInfo);
    this.modalDataService.setData('modalData', data);
    this.translateService.get(action).subscribe((title) => {
      modal = this.nzModalService.create({
        nzTitle: title,
        nzClosable: false,
        nzContent: AddNewUserComponent,
        nzWrapClassName: 'vertical-center-modal',
        nzWidth: 1000
      });
    });
    modal.afterClose.subscribe((res) => {
      if (res && res.responseFor === 'User') {
        this.getRoleUser(this.selectedUserData.userId);
        this.session.form.get('owner').patchValue({
          userId: res.res.id
        });
      } else if (res && res.responseFor === 'Medical') {
        this.getMedicalRole(this.selectedUserData.userId, 'medical');
        this.session.form.get('user').patchValue({
          doctorId: res.res.id
        });
      }
    });
  }

  // On click add patient
  onClickAddPatient(action: string, data: any) {
    let modal;
    const modalInfo = {
      action,
      userAccountId: this.selectedUserData.userId,
      formData: this.patientFormData
    };
    this.modalDataService.setData('modalInfo', modalInfo);
    this.modalDataService.setData('modalData', data);
    this.translateService.get(action).subscribe((title) => {
      modal = this.nzModalService.create({
        nzTitle: title,
        nzClosable: false,
        nzContent: AddNewPatientComponent,
        nzWrapClassName: 'vertical-center-modal',
        nzWidth: 1000
      });
    });
    modal.afterClose.subscribe((res) => {
      if (res && res.responseFor === 'Patient') {
        this.getPatientData(this.selectedUserData.userId);
        this.session.form.get('patient').patchValue({
          patientId: res.res.id
        });
      }
    });
  }

  // On click apply filter
  addFilter(action: string, data: any) {
    let modal;
    const modalInfo = {
      action,
      userAccountId: this.selectedUserData.userId,
      formData: this.filterData,
      orgId: this.selectedUserData.orgId,
      toLanguage: this.sessionData.toLanguage,
      interpreterIds: this.interpreterIds,
      fromLanguage: this.selectedLanguage
    };
    this.modalDataService.setData('modalInfo', modalInfo);
    this.modalDataService.setData('modalData', data);
    this.translateService.get(action).subscribe((title) => {
      modal = this.nzModalService.create({
        nzTitle: title,
        nzClosable: false,
        nzContent: AddFilterComponent,
        nzWrapClassName: 'vertical-center-modal',
        nzWidth: 1000
      });
    });
    modal.afterClose.subscribe((res) => {
      if (res) {
        this.loading = true;
        this.sessionData.gender = res.gender;
        this.sessionData.origin = res.origin;
        this.qualification = res.qualificationIds;
        this.sessionData.qualificationSkillId = res.qualificationIds;
        this.onSelectLanguage(this.sessionData.toLanguage.isoCode);
      }
    });
  }

  // On Click Location Modal
  onClickAddLocation(action: string, data: any) {
    let modal;
    const modalInfo = {
      action
    };
    const selectedData = {
      userId: '',
      orgId: ''
    };
    this.modalDataService.setData('modalInfo', modalInfo);
    this.modalDataService.setData('selectedData', selectedData);
    this.modalDataService.setData('modalData', data);
    this.translateService.get(action).subscribe((title) => {
      modal = this.nzModalService.create({
        nzTitle: title,
        nzClosable: false,
        nzContent: AddLocationComponent,
        nzWrapClassName: 'vertical-center-modal',
        nzWidth: 1000
      });
    });
    modal.afterClose.subscribe((res) => {
      if (res && res.responseFor === 'Location') {
        this.locationData = res.res;
        this.session.form.patchValue({
          formattedAddress: res.res.formattedAddress
        });
      }
    });
  }

  changeMilisecondsToTime(time) {
    if (time) {
      const num = time;
      const hours = (num / 60);
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.round(minutes);
      const value = `0${rhours}:${rminutes}:00`; // '0' + rhours + ':' + rminutes + ':' + '00';
      return value;
    }
  }
  changeToDate(date) {
    if (date) {
      return new Date(date);
    }
  }
  // fill Form Value
  private fillFormData(response: any, name: string) {
    let returnValue = '';
    if (response && response.interpreter) {
      if (name === 'interpreter') {
        this.selectedUserData.interpreter = response.interpreter?.id;
        returnValue = response.interpreter.fullName;
      }
    }
    return returnValue;
  }

  changeToMiliSeconds(time) {
    const changedTime = (time.split(':').reduce((acc, time) => (60 * acc) + + time)) * 1000;
    return changedTime;
  }

  addEditSession() {
    this.loading = true;
    if (!this.session.valid) {
      this.loading = false;
      return;
    } else {
      if (this.locationData) {
        this.sessionData.longitude = this.locationData.longitude;
        this.sessionData.latitude = this.locationData.latitude;
      }
      const newSessionDate = new Date(this.sessionData.dt);
      newSessionDate.setHours(this.sessionData.st.getHours());
      newSessionDate.setMinutes(this.sessionData.st.getMinutes());
      newSessionDate.setSeconds(this.sessionData.st.getSeconds());
      this.sessionData.totalSessionTime = (this.sessionData.duration * 60 * 1000);
      if (!(this.sessionData.interpreterList instanceof Array)) {
        this.sessionData.interpreterList = [this.sessionData.interpreterList];
      }
      this.sessionData.bookingDate = newSessionDate.getTime(),
        this.sessionData.startTime = newSessionDate.getTime(),
        this.sessionData.endTime = newSessionDate.getTime() + this.sessionData.totalSessionTime;
      if (this.sessionData.status !== 'PENDING') {
        this.sessionData.sessionStartTime = newSessionDate.getTime();
        this.sessionData.sessionEndTime = newSessionDate.getTime() + this.sessionData.totalSessionTime;
      }
      if (this.sessionData.paymentAuthorityOption.id === 0) {
        this.sessionData.paymentAuthorityOption = undefined;
      } else {
        this.paymentAuthorityData.paymentAuthorityOptions.forEach((i) => {
          if (i.id === this.sessionData.paymentAuthorityOption.id) {
            this.sessionData.paymentAuthorityOption = i;
          }
        });
      }
      if (this.sessionData.categoryOption.id === 0) {
        this.sessionData.categoryOption = undefined;
      } else {
        this.categoryData.categoryOptions.forEach((i) => {
          if (i.id === this.sessionData.categoryOption.id) {
            this.sessionData.categoryOption = i;
          }
        });
      }
      this.sessionData.paymentAuthorityOptionId =
        this.sessionData.paymentAuthorityOption ? this.sessionData.paymentAuthorityOption.id : undefined,
        this.sessionData.categoryOptionId = this.sessionData.categoryOption ? this.sessionData.categoryOption.id : undefined,
        this.sessionData.dt = undefined;
      this.sessionData.duration = undefined;
      this.sessionData.st = undefined;
      if (this.sessionData.doctor && this.sessionData.doctor.id === '') {
        this.sessionData.doctor = undefined;
      }
      if (this.sessionData.otherUser && this.sessionData.otherUser.id === '') {
        this.sessionData.otherUser = undefined;
      }
      if (this.sessionData.patient && this.sessionData.patient.id === '') {
        this.sessionData.patient = undefined;
      }
      if (this.sessionData.user.id === '') {
        this.sessionData.user = undefined;
      }
      if (this.mode === 'Create_session') {
        this.sessionData.sessionFilters.gender = this.sessionData.gender;
        this.sessionData.sessionFilters.origin = this.sessionData.origin;
        this.sessionData.sessionFilters.qualification = this.qualification.toString();
        this.newSessionService.createNewSession(this.sessionData).subscribe((res) => {
          this.getFormSubmitResponse(res);
        }, error => {
          this.notification.error('Error', error['error']);
          this.loading = false;
        });
      } else if (this.mode === 'Edit_sessions') {
        delete this.sessionData.sessionFilters;
        this.newSessionService.updateSession(this.sessionData, this.sessionId).subscribe((res) => {
          this.getFormSubmitResponse(res);
        }, error => {
          this.notification.error('Error', error['error']['error']);
          this.loading = false;
        });
      } else {
        delete this.sessionData.sessionFilters;
        this.newSessionService.followUpSession(this.sessionData).subscribe((res) => {
          this.getFormSubmitResponse(res);
        }, error => {
          this.notification.error('Error', error['error']['error']);
          this.loading = false;
        });
      }
    }
  }

  private getFormSubmitResponse(res: any) {
    if (res['isSuccess']) {
      this.notification.success('Success', res['message']);
      this.loading = false;
      this.closeModal(res);
    } else {
      this.notification.error('Error', res['error']);
      this.loading = false;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
