<nz-spin [nzSpinning]="loading">
  <form [formGroup]="userForm" novalidate>
    <div class="row">
      <div class="col col-md-3">
        <tc-form-group>
          <tc-form-label>Prefix</tc-form-label>
          <tc-select
            [placeholder]="'Prefix'"
            [options]="prefix"
            formControlName="prefix"
          ></tc-select>
        </tc-form-group>
      </div>
      <div class="col col-md-4">
        <tc-form-group>
          <tc-form-label
            >{{ "Firstname" | translate
            }}<span class="req-icon">*</span></tc-form-label
          >
          <tc-input
            placeholder="{{ 'Firstname' | translate }}"
            formControlName="firstName"
          ></tc-input>
          <tc-form-description
            [tcColor]="'#e24d4d'"
            *ngIf="
              (userForm.get('firstName').touched ||
                userForm.get('firstName').dirty) &&
              userForm.get('firstName').invalid
            "
          >
            <p *ngIf="userForm.get('firstName').errors?.required">
              {{ "Firstname_required" | translate }}
            </p>
            <p *ngIf="userForm.get('firstName').errors?.pattern">
              {{ "Invalid_input" | translate }}
            </p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-5">
        <tc-form-group>
          <tc-form-label
            >{{ "Lastname" | translate
            }}<span class="req-icon">*</span></tc-form-label
          >
          <tc-input
            placeholder="{{ 'Lastname' | translate }}"
            formControlName="lastName"
          ></tc-input>
          <tc-form-description
            [tcColor]="'#e24d4d'"
            *ngIf="
              (userForm.get('lastName').touched ||
                userForm.get('lastName').dirty) &&
              userForm.get('lastName').invalid
            "
          >
            <p *ngIf="userForm.get('lastName').errors?.required">
              {{ "Lastname_required" | translate }}
            </p>
            <p *ngIf="userForm.get('lastName').errors?.pattern">
              {{ "Invalid_input" | translate }}
            </p>
          </tc-form-description>
        </tc-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-6" *ngIf="modalFor === 'User'">
        <tc-form-group>
          <tc-form-label
            >{{ "Telephone" | translate
            }}<span class="req-icon">*</span></tc-form-label
          >
          <tc-input
            type="number"
            placeholder="{{ 'Telephone' | translate }}"
            formControlName="telephone"
          ></tc-input>
          <tc-form-description
            [tcColor]="'#e24d4d'"
            *ngIf="
              (userForm.get('telephone').touched ||
                userForm.get('telephone').dirty) &&
              userForm.get('telephone').invalid
            "
          >
            <p *ngIf="userForm.get('telephone').errors?.required">
              {{ "Telephone_required" | translate }}
            </p>
            <p *ngIf="userForm.get('telephone').errors?.pattern">
              {{ "Invalid_input" | translate }}
            </p>
          </tc-form-description>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="modalFor === 'User'">
        <tc-form-group>
          <tc-form-label
            >{{ "Email" | translate
            }}<span class="req-icon">*</span></tc-form-label
          >
          <tc-input
            placeholder="{{ 'Email' | translate }}"
            formControlName="email"
          ></tc-input>
          <tc-form-description
            [tcColor]="'#e24d4d'"
            *ngIf="
              (userForm.get('email').touched || userForm.get('email').dirty) &&
              userForm.get('email').invalid
            "
          >
            <p *ngIf="userForm.get('email').errors?.required">
              {{ "Email_required" | translate }}
            </p>
            <p *ngIf="userForm.get('email').errors?.email">
              {{ "Invalid_input" | translate }}
            </p>
          </tc-form-description>
        </tc-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-6" *ngIf="modalFor === 'Medical' || modalFor === 'Other'">
        <tc-form-group>
          <tc-form-label>{{ "Telephone" | translate }}</tc-form-label>
          <tc-input
            type="number"
            placeholder="{{ 'Telephone' | translate }}"
            formControlName="telephone"
          ></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="modalFor === 'Medical' || modalFor === 'Other'">
        <tc-form-group>
          <tc-form-label>{{ "Email" | translate }}</tc-form-label>
          <tc-input
            placeholder="{{ 'Email' | translate }}"
            formControlName="email"
          ></tc-input>
        </tc-form-group>
      </div>
    </div>
    <div class="row" *ngIf="modalFor === 'Medical' || modalFor === 'Other'">
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "Function" | translate }}</tc-form-label>
          <tc-input
            placeholder="{{ 'Function' | translate }}"
            formControlName="function"
          ></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-6">
        <tc-form-group>
          <tc-form-label>{{ "Mobile_number" | translate }}</tc-form-label>
          <tc-input
            placeholder="{{ 'Mobile_number' | translate }}"
            formControlName="mobileNumber"
          ></tc-input>
        </tc-form-group>
      </div>
    </div>
    <input type="hidden" formControlName="id" />
    <div *nzModalFooter class="actions justify-content-between">
      <button
        tc-button
        [type]="'button'"
        [view]="'error'"
        (click)="closeModal()"
      >
        {{ "Cancel" | translate }}
      </button>
      <button
        tc-button
        [view]="'info'"
        [disabled]="!userForm.valid"
        (click)="submitUserData(userForm)"
      >
        {{
          isMode === "create" ? ("Create" | translate) : ("Update" | translate)
        }}
      </button>
    </div>
  </form>
</nz-spin>
