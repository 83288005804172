import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { Observable } from 'rxjs';
import { agendaUrls } from './agenda.path';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {
  public constructor(private readonly httpClientService: HttpClientService) {
  }

  // get calendar session details
  public getInterpretersCalendarSession(startTime, endTime): Observable<{}>   {
    return this.httpClientService.getServerRequest(`${agendaUrls.getUrl('getCalendarSessions')}${startTime}/${endTime}`);
  }

}
