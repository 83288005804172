import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { VerticalLayoutComponent } from '../layout/vertical';
import { HorizontalLayoutComponent } from '../layout/horizontal';
import { PublicLayoutComponent } from '../layout/public';

import { OrganizationComponent } from '../pages/organization/organization';
import { UserAccountComponent } from '../pages/userAccount/user-account/user-account.component';
import { InterpretersComponent } from '../pages/interpreter/interpreters';
import { SessionsComponent } from '../pages/sessions/sessions/sessions.component';
import { StatisticsComponent } from '../pages/statistics/statistics';
import { RecalculateComponent } from '../pages/recalculate/recalculate';
import { HomeComponent } from '../pages/dashboard/home/home.component';
import { AuthGuard } from '../pages/authentication/login/login.guard';
import { PageSignInComponent } from '../pages/sign-in';
import { PageSignUpComponent } from '../pages/sign-up';
import { Page404Component } from '../pages/page-404';
import { Page500Component } from '../pages/page-500';
import { AgendaComponent } from '../pages/agenda/agenda/agenda.component';


const VERTICAL_ROUTES: Routes = [
  {
    path: 'organizations',
    component: OrganizationComponent,
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'user-account',
    component: UserAccountComponent
  },
  {
    path: 'assigned',
    component: InterpretersComponent
  },
  {
    path: 'sessions',
    component: SessionsComponent
  },
  {
    path: 'statistics',
    component: StatisticsComponent
  },
  {
    path: 'recalculate-sessions',
    component: RecalculateComponent
  },
  {
    path: 'agenda',
    component: AgendaComponent
  },
];

const PUBLIC_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../pages/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: 'sign-in', component: PageSignInComponent },
  { path: 'sign-up', component: PageSignUpComponent },
  { path: 'page-404', component: Page404Component },
  { path: 'page-500', component: Page500Component },
];

export const ROUTES: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: PUBLIC_ROUTES,
  },
  {
    path: 'vertical',
    component: VerticalLayoutComponent,
    children: VERTICAL_ROUTES,
    canActivate: [AuthGuard]
  },
  {
    path: 'horizontal',
    component: HorizontalLayoutComponent,
    children: VERTICAL_ROUTES,
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    component: HorizontalLayoutComponent,
    loadChildren: () =>
      import('../pages/settings/setting.module').then(
        (m) => m.SettingModule
      ),
      canActivate: [AuthGuard]
  },
  {
    path: 'organizations',
    component: HorizontalLayoutComponent,
    loadChildren: () =>
      import('../pages/organization/organization.module').then(
        (m) => m.OrganizationModule
      ),
      canActivate: [AuthGuard]
  },
  {
    path: 'sessions',
    component: HorizontalLayoutComponent,
    loadChildren: () =>
      import('../pages/sessions/sessions.module').then(
        (m) => m.SessionsModule
      ),
      canActivate: [AuthGuard]
  },
  {
    path: 'interpreter',
    component: HorizontalLayoutComponent,
    loadChildren: () =>
      import('../pages/interpreter/interpreter.module').then(
        (m) => m.InterpreterModule
      ),
      canActivate: [AuthGuard]
  },
  {
    path: 'user-account',
    component: HorizontalLayoutComponent,
    loadChildren: () =>
      import('../pages/userAccount/user-account.module').then(
        (m) => m.UserAccountModule
      ),
      canActivate: [AuthGuard]
  },
  {
    path: 'group',
    component: HorizontalLayoutComponent,
    loadChildren: () =>
      import('../pages/groups/groups.module').then(
        (m) => m.GroupsModule
      ),
      canActivate: [AuthGuard]
  },
  {
    path: 'statistic',
    component: HorizontalLayoutComponent,
    loadChildren: () =>
      import('../pages/statistics/statistics.module').then(
        (m) => m.StatisticsModule
      ),
      canActivate: [AuthGuard]
  },
  {
    path: 'mapping',
    component: HorizontalLayoutComponent,
    loadChildren: () =>
      import('../pages/orgInterpreterMapping/orgInterpreterMapping.module').then(
        (m) => m.OrgInterpreterMappingModule
      ),
      canActivate: [AuthGuard]
  },
  {
    path: 'billing-rules',
    component: HorizontalLayoutComponent,
    loadChildren: () =>
      import('../pages/billingRuleTemplate/billing-rule-template.module').then(
        (m) => m.BillingRuleTemplateModule
      ),
      canActivate: [AuthGuard]
  },
  {
    path: 'agenda',
    component: HorizontalLayoutComponent,
    loadChildren: () =>
      import('../pages/agenda/agenda.module').then(
        (m) => m.AgendaModule
      ),
      canActivate: [AuthGuard]
  },
  {
    path: 'recalculate-sessions',
    component: HorizontalLayoutComponent,
    loadChildren: () =>
      import('../pages/recalculate/recalculate.module').then(
        (m) => m.RecalculateModule
      ),
      canActivate: [AuthGuard]
  },
  {
    path: 'public',
    component: PublicLayoutComponent,
    children: PUBLIC_ROUTES,
  },
  {
    path: '**',
    component: VerticalLayoutComponent,
    children: VERTICAL_ROUTES,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
      scrollPositionRestoration: 'enabled'
  })
  ],
  exports: [RouterModule],
  declarations: [],
  providers: [AuthGuard]
})
export class RoutingModule {}
