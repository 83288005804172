<nz-spin [nzSpinning]="loading">
    <div class="row">
        <div class="col col-md-6">
            <tc-form-label>{{'Interpreters' | translate}}</tc-form-label>
            <nz-select nzPlaceHolder="{{'Interpreters' | translate}}" (ngModelChange)="onSelectDropdown($event)"
                name="selectedInterpreter" [(ngModel)]="selectedInterpreter" *ngIf="session.status !== 'CONFIRMED'">
                <nz-option *ngFor="let i of interpreterList" [nzValue]="i.id" [nzLabel]="i.fullName"></nz-option>
            </nz-select>
            <nz-select nzPlaceHolder="{{'Interpreters' | translate}}" (ngModelChange)="onSelectDropdown($event)" 
                *ngIf="session.status === 'CONFIRMED'" nzMode="multiple"
                name="selectedInterpreter" [(ngModel)]="allInterpretersId">
                <nz-option *ngFor="let i of interpreterList" [nzValue]="i.id" [nzLabel]="i.fullName"></nz-option>
            </nz-select>
        </div>
        <div class="col col-md-2" *ngIf="session.status === 'CONFIRMED'">
            <tc-form-label>&nbsp;</tc-form-label>
            <button tc-button [afterIcon]="'icofont-filter'" [tcBgColor]="'#9e9e9e'" (click)="addFilter('Add Filter', null)">
                {{'Filters' | translate}}
            </button>
          </div>
        <div class="col col-md-6" *ngIf="session.status === 'CONFIRMED'">
              <tc-form-group>
                <tc-form-label>{{ "Cancellation_interpreter_reason" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Cancellation_interpreter_reason' | translate }}"
                  name="Cancellation_interpreter_reason" [(ngModel)]="reasonToCancel"
                ></tc-input>
              </tc-form-group>
        </div>
        <div class="col col-md-6" *ngIf="session.status === 'CONFIRMED'">
            <tc-form-group>
                <tc-form-label>&nbsp;</tc-form-label>
              <tc-checkbox name="sendEmail" [(ngModel)]="sendConfirmationEmail.status">{{'Send_Email' | translate}}</tc-checkbox>
            </tc-form-group>
        </div>
        <div class="col col-md-6" *ngIf="session.status === 'PENDING' || session.status === 'REFUSED'">
            <tc-form-group>
                <tc-form-label>&nbsp;</tc-form-label>
              <tc-checkbox name="resendToAll" [(ngModel)]="isResendSessionToAll" (ngModelChange) = "isChangeCheckClicked()"
              >{{'resendSessionToAllInterpreters' | translate}}</tc-checkbox>
            </tc-form-group>
        </div>
    </div>
    <nz-modal [(nzVisible)]="isVisible" nzTitle="{{ 'resendInvitation' | translate }}" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
        <nz-select nzPlaceHolder="{{'Interpreters' | translate}}" (ngModelChange)="onSelectDropdown($event)" nzMode="multiple"
            name="resendInterpreter" [(ngModel)]="resendInterpretersId">
            <nz-option *ngFor="let i of availableInterpreterList" [nzValue]="i.id" [nzLabel]="i.fullName"></nz-option>
        </nz-select>
    </nz-modal>
    <div *nzModalFooter>
        <button *ngIf="session.status === 'CONFIRMED'" nz-button class="login-form-button" (click)="updateInterpreterToSession()">{{'Update' | translate}}</button>
        <button *ngIf="session.status === 'PENDING'" nz-button class="login-form-button" (click)="assignInterpreterToSession()">{{'Assign' | translate}}</button>
        <button nz-button nzType="primary" (click)="cancelModal()">{{'Cancel' | translate}}</button>
    </div>
</nz-spin>