import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  private readonly host: string;

  public constructor() { }

  // this method is used when we want to make whole url (localhost://www.example.com?a=1&b=2)
  public createRequestURL(routhPath: string, queryParams: object) {
    let url = '';
    const queryParamString = this.createQueryParams(queryParams);
    if (queryParamString) {
      url = `${routhPath}?${queryParamString}`;
    }
    return url;
  }

  // This method is used when only need to get query params
  public createQuery(queryParams: object) {
    let url = '';
    const queryParamString = this.createQueryParams(queryParams);
    if (queryParamString) {
      url = '?' + queryParamString;
    }
    return url;
  }

  public createQueryParams(data: object): string {
    Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
    return Object.keys(data).map((key) => {
      return [key, data[key]].map(encodeURIComponent).join('=');
    }).join('&');
  }

  public searchFilterQueryParam(searchValue: object): string {
    const newObject = searchValue;
    this.cleanObject(newObject);
    return Object.keys(newObject).map((key) => {
      return [key, newObject[key]['value']].map(encodeURIComponent).join('=');
    }).join('&');
  }

  public dropdownFilterQueryParam(filter: Array<{ key: string, value: Array<string> }>): string {
    const filtered = filter.filter((x) => {
      return x.value.length > 0 && x.key !== 'actions';
    } );
    return filtered.map((i) => {
      if (i.value.length > 0) {
        return [i.key, i.value.toString()].map(encodeURIComponent).join('=');
      }
    }).join('&');
  }

  public sortingFilterQueryParam(sort: Array<{ key: string, value: string }>): string {
    const filteredSort = sort.filter(x => x.value);
    return filteredSort.map((i) => {
      return [i.key, i.value.toString()].map(encodeURIComponent).join(' ');
    }).join(',');
  }

  public createFilterQueryParams(data: object): string {
    return Object.keys(data).filter((i) => {
      if (data[i]) {
        return true;
      }
      return false;
    }).map((key) => {
      return [key, data[key]].map(encodeURIComponent).join('=');
    }).join('&');
  }


  // Helper Function
  private cleanObject(object: object): object {
    const modifiedObject = object;
    for (const propName in modifiedObject) {
      if (modifiedObject[propName]['value'] === null || modifiedObject[propName]['value'] === undefined ||
        modifiedObject[propName]['value'] === '' || modifiedObject[propName]['value'].length <= 0) {
        delete modifiedObject[propName];
      }
    }
    return modifiedObject;
  }
}
