import { Injectable } from '@angular/core';
import { statisticsPath } from './statistics.path';
import { Observable } from 'rxjs';
import { HttpClientService } from '../../../shared/services/http-client.service';

@Injectable({ providedIn: 'root' })
export class StatisticsService {
  constructor(private readonly httpClientService: HttpClientService) {}

  // Get Bar chart Data
  public getBarStatistics(barStatisctics: string): Observable<{}> {
    return this.httpClientService.getServerRequest(
      statisticsPath.getUrl('getBarStatistics') + barStatisctics
    );
  }

  // Get Groups Data
  public getGroups(InterpreterGroupQuery: string, type: string): Observable<{}> {
    return this.httpClientService.getServerRequest(
      statisticsPath.getUrl(type) + InterpreterGroupQuery,
    );
  }
// Get branches
  public getBranches(PieStatisctics: string): Observable<{}> {
    return this.httpClientService.getServerRequest('v4/branches');
  }
// Get qualification chart data
public getQualificationPieChartData(): Observable<{}> {
  return this.httpClientService.getServerRequest(
    statisticsPath.getUrl('getQualificationPieChartData'));
}

  // Get Common Pie chart Data method
  public getCommonPieChartData(PieStatisctics: string, pieChartName: string): Observable<{}> {
    return this.httpClientService.getServerRequest(statisticsPath.getUrl(pieChartName) + PieStatisctics);
  }

  // Get User/Interpreter Data Progress
  public getProgressData(progressQuery: string): Observable<{}> {
    return this.httpClientService.getServerRequest(statisticsPath.getUrl('progress') + progressQuery);
  }

  // Get Acceptance Rate & response time
  public getAcceptanceRate(acceptanceQuery: string): Observable<{}> {
    return this.httpClientService.getServerRequest(statisticsPath.getUrl('donut') + acceptanceQuery);
  }
}
