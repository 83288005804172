import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../shared/services/common.service';
import { map } from 'rxjs/operators';
import { AssignInterpreterService } from '../interpreters/interpreter.service';
import { NzModalRef, NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { TranslateService } from '@ngx-translate/core';
import { ModalDataService } from '../../../shared/services/modal-data.service';
import { AddLocationComponent } from '../../userAccount/add-location/add-location.component';
import { Interpreter } from '../interpreters/interpreters.interface';

@Component({
  selector: 'app-view-edit-interpreter',
  templateUrl: './view-edit-interpreter.component.html',
  styleUrls: ['./view-edit-interpreter.component.scss'],
})
export class ViewEditInterpreterComponent implements OnInit, OnDestroy {
  @ViewChild('f', { static: false }) interpreter: NgForm;
  public subscriber: Subscription;
  public interpreterGroups: any;
  public countries: any;
  public countryCode: any;
  public localLanguage: string;
  public isMode = 'create';
  public languageOne: any;
  public languageTwo: any;
  public languageLevel: any;
  role: string;
  locationData: any;
  interpreterData = new Interpreter();
  selectedUserData = { orgId: '', userId: '' };
  qualificationData = [];
  dynamicFields = [];
  genderList = [
    { label: 'Male', value: 'MALE' },
    { label: 'Female', value: 'FEMALE' },
  ];
  languageOneLevelList = [
    { label: 'A1', value: 'A1' },
    { label: 'A2', value: 'A2' },
    { label: 'B1', value: 'B1' },
    { label: 'B2', value: 'B2' },
    { label: 'C1', value: 'C1' },
    { label: 'C2', value: 'C2' },
  ];
  allInterpreterQualification = [];
  loading = true;
  invitationKey: string;
  isFormDisable = false;

  constructor(
    private readonly modal: NzModalRef,
    private readonly commonService: CommonService,
    private readonly interpreterService: AssignInterpreterService,
    private readonly notification: NzNotificationService,
    private readonly translate: TranslateService,
    private readonly modalDataService: ModalDataService,
    private readonly nzModalService: NzModalService,
  ) { }

  ngOnInit() {
    this.getInitialData();
    const interpreter = this.modalDataService.getData();
    if (interpreter['interpreterModalData']) {
      this.isMode = 'update';
      this.interpreterData = JSON.parse(JSON.stringify(interpreter['interpreterModalData']));
      if (!this.interpreterData.dynamicFields) {
        Object.assign(this.interpreterData, { dynamicFields: [] });
      } else {
        this.dynamicFields = this.interpreterData['dynamicFields'];
      }
      this.qualificationData = this.interpreterData['qualificationSkill'];
      if (!this.interpreterData.country) { Object.assign(this.interpreterData, { country: { iso: '' } }); }
      if (!this.interpreterData.interpreterGroup) { Object.assign(this.interpreterData, { interpreterGroup: { id: 0 } }); }
      this.interpreterData.sessionCostPerHrInperson = (this.interpreterData.sessionCostPerMinInperson * 60);
      this.interpreterData.sessionCostPerMinMediation = this.interpreterData.sessionCostPerMinMediation * 60;
      this.interpreterData.sessionCostPerMinMediation = Math.round((this.interpreterData.sessionCostPerMinMediation) * 100000) / 100000;
      this.interpreterData.sessionCostperMinCourse = this.interpreterData.sessionCostperMinCourse * 60;
      this.interpreterData.sessionCostperMinCourse = Math.round((this.interpreterData.sessionCostperMinCourse) * 100000) / 100000;
      this.interpreterData.sessionCostPerHrInperson = Math.round((this.interpreterData.sessionCostPerHrInperson) * 100000) / 100000;
      if (interpreter['interpreterModalInfo'] === 'View_interpreter') {
        this.isMode = 'view';
        this.isFormDisable = true;
      }
    } else {
      this.isMode = 'create';
    }
  }

  private getInitialData() {
    this.localLanguage = localStorage.getItem('transLang');
    this.role = localStorage.getItem('role');
    this.getCountryCode();
    this.preRequisiteData();
    this.getListOfCountries();
    this.getFirstLanguage();
    this.getLanguageLevel();
    this.getSecondLanguage();
    this.getInterpreterQulaification();
  }

  // get country code
  private getCountryCode() {
    this.subscriber = this.commonService.getCountryCode().subscribe((res) => {
      this.countryCode = res[0].value;
    });
  }

  // Get Language level
  private getLanguageLevel() {
    this.subscriber = this.commonService.getLanguageLevels().pipe(map((resData) => {
      return {
        response: resData[this.localLanguage].map((res) => {
          return {
            label: res.languageLevelName,
            value: res.languageLevelId
          };
        })
      };
    })).subscribe((res) => {
      this.languageLevel = res.response;
    });
  }

  // Get Interpreter Qualification
  private getInterpreterQulaification() {
    this.subscriber = this.commonService.getInterpreterQualification().pipe(map((resData) => {
      return {
        response: resData[this.localLanguage].map((res) => {
          return {
            label: res.name,
            value: res.interpreterQualificationId
          };
        })
      };
    })).subscribe((res) => {
      this.allInterpreterQualification = res.response;
    });
  }

  // get first languages
  private getFirstLanguage() {
    this.subscriber = this.commonService.getFirstLanguage().pipe(map((resData) => {
      return {
        response: resData[this.localLanguage].map((res) => {
          return {
            label: res.languageName,
            value: res.isoCode
          };
        })
      };
    }))
      .subscribe((res) => {
        this.languageOne = res.response;
      });
  }

  // get country list
  public getListOfCountries() {
    this.subscriber = this.commonService.getCountry().pipe(map((resData) => {
      return {
        response: resData[this.localLanguage].map((res) => {
          return {
            label: res.countryName,
            value: res.countryId,
          };
        }),
      };
    })
    ).subscribe((res) => {
      this.countries = res.response;
    });
  }

  // Get prerequisite interpreter Data
  public preRequisiteData() {
    this.loading = true;
    this.interpreterService.preRequisiteData().pipe(map((resData) => {
      this.invitationKey = resData['response']['invitationKey'];
      return {
        data: resData['response']['interpreterGroups'].map((res) => {
          return {
            value: res,
            id: res.id
          };
        })
      };
    })).subscribe((resData) => {
      this.interpreterGroups = resData.data;
      this.loading = false;
    });
  }

  // Get Single Interpreter
  private getSingleInterprer(interpreterId: string) {
    this.loading = true;
    this.interpreterService.getInterpreter(interpreterId).subscribe((resData) => {
      if (resData['isSuccess']) {
        if (this.role === 'Admin') {
          this.interpreterData = JSON.parse(JSON.stringify(resData['response']));
          this.dynamicFields = resData['response']['dynamicFields'];
          this.qualificationData = resData['response']['qualificationSkill'];
        } else {
          this.interpreterData = JSON.parse(JSON.stringify(resData['data']));
          this.dynamicFields = resData['data']['dynamicFields'];
          this.qualificationData = resData['data']['qualificationSkill'];
        }
        if (!this.interpreterData.country) {
          Object.assign(this.interpreterData, { country: { iso: '' } });
        }
        this.interpreterData.sessionCostPerHrInperson = (this.interpreterData.sessionCostPerMinInperson * 60);
        this.interpreterData.sessionCostPerMinMediation = this.interpreterData.sessionCostPerMinMediation * 60;
        this.interpreterData.sessionCostPerMinMediation = Math.round((this.interpreterData.sessionCostPerMinMediation) * 100000) / 100000;
        this.interpreterData.sessionCostperMinCourse = this.interpreterData.sessionCostperMinCourse * 60;
        this.interpreterData.sessionCostperMinCourse = Math.round((this.interpreterData.sessionCostperMinCourse) * 100000) / 100000;
        this.interpreterData.sessionCostPerHrInperson = Math.round((this.interpreterData.sessionCostPerHrInperson) * 100000) / 100000;
      }
      this.loading = false;
    });
  }

  // get second languages
  getSecondLanguage() {
    this.loading = true;
    this.subscriber = this.commonService.getSecondLanguage().subscribe((res) => {
      this.languageTwo = res;
      this.loading = false;
    });
  }

  public changeTelephoneFormat(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      this.interpreterData.telephone = event.target['value'];
    } else {
      if (event.target['value'].length === 3 || event.target['value'].length === 7 || event.target['value'].length === 10) {
        this.interpreterData.telephone = event.target['value'] + ' ';
      }
    }
  }

  // close modal window
  public closeModal(response?: any) {
    this.modal.destroy(response);
    this.interpreter.reset();
  }

  // On Select Interpreter Group
  onSelectInterpreterGroup(event: any) {
    if (event) {
      this.interpreterGroups.forEach((i) => {
        if (i.id === event) {
          this.dynamicFields = i.value.dynamicFields;
          this.qualificationData = i.value.qualifications;
        }
      });
    }
  }

  // On adding feedback form Options
  onAddLanguageOption() {
    this.interpreterData.languagesSkills.push({
      languageOne: {
        isoCode: ''
      },
      languageTwo: {
        isoCode: ''
      },
      levelOne: '',
      levelTwo: ''
    });
  }

  onRemoveLanguage(pos: number) {
    this.interpreterData.languagesSkills.splice(pos, 1);
  }

  // On Click Location Modal
  onClickAddLocation(action: string, data: any) {
    let modal;
    const modalInfo = {
      action
    };
    const selectedData = this.selectedUserData;
    this.modalDataService.setData('modalInfo', modalInfo);
    this.modalDataService.setData('selectedData', selectedData);
    this.modalDataService.setData('modalData', data);
    this.translate.get(action).subscribe((title) => {
      modal = this.nzModalService.create({
        nzTitle: title,
        nzClosable: false,
        nzContent: AddLocationComponent,
        nzWrapClassName: 'vertical-center-modal',
        nzWidth: 1000
      });
    });
    modal.afterClose.subscribe((res) => {
      if (res && res.responseFor === 'Location') {
        this.locationData = res.res;
        this.interpreter.form.patchValue({
          formattedAddress: res.res.formattedAddress
        });
        this.interpreterData.latitude = res.res.latitude;
        this.interpreterData.longitude = res.res.longitude;
      }
    });
  }

  private getDate(time: any) {
    if (time) {
      return new Date(time).getTime();
    } else {
      return undefined;
    }
  }

  addInterpreter() {
    this.loading = true;
    if (!this.interpreter.valid) {
      return;
    } else {
      this.checkResponseOnSubmit();
      if (this.interpreterData.interpreterGroup.id === 0) {
        this.interpreterData.interpreterGroup = undefined;
      } else {
        this.interpreterGroups.forEach((i) => {
          if (i.id === this.interpreterData.interpreterGroup.id) {
            this.interpreterData.interpreterGroup = i.value;
          }
        });
      }
      if (this.isMode === 'create') {
        this.subscriber = this.interpreterService.createInterpreter(this.interpreterData).subscribe((res) => {
          this.getResponse(res);
        });
      } else if (this.isMode === 'update') {
        this.subscriber = this.interpreterService.updateInterpreter(this.interpreterData, this.interpreterData.id).subscribe((res) => {
          this.getResponse(res);
        });
      }
    }
  }

  private checkResponseOnSubmit() {
    this.interpreterData.invitationKey = this.invitationKey;
    this.interpreterData.sessionCostPerMinMediation = (+this.interpreterData.sessionCostPerMinMediation) / 60;
    this.interpreterData.sessionCostperMinCourse = (+this.interpreterData.sessionCostperMinCourse) / 60;
    this.interpreterData.sessionCostPerMinInperson = this.interpreterData.sessionCostPerHrInperson / 60;
    this.interpreterData.trainingCivilRegistryOffice = this.getDate(this.interpreterData.trainingCivilRegistryOffice);
    this.interpreterData.certificateDate = this.getDate(this.interpreterData.certificateDate);
    this.interpreterData.contractSince = this.getDate(this.interpreterData.contractSince);
    this.interpreterData.dob = this.getDate(this.interpreterData.dob);
    this.interpreterData.examDate = this.getDate(this.interpreterData.examDate);
    this.interpreterData.federalCertificateDate = this.getDate(this.interpreterData.federalCertificateDate);
    this.interpreterData.juridicProtectionContractFromDate = this.getDate(this.interpreterData.juridicProtectionContractFromDate);
    if (this.interpreterData.modulesData) {
      Object.keys(this.interpreterData.modulesData).forEach((i) => {
        if ((i === 'module1Date') || (i === 'module2Date') || (i === 'module3Date') || (i === 'module4Date') || (i === 'module4')
          || (i === 'module5Date') || (i === 'module6Date') || (i === 'module7Date') || (i === 'module8Date')
          || (i === 'module9Date') || (i === 'module10Date')) {
          this.interpreterData.modulesData[i] = this.getDate(this.interpreterData.modulesData[i]);
        }});
    }
    this.interpreterData.dynamicFields = this.dynamicFields;
    const qualificationSkillsData = this.qualificationData;
    this.interpreterData.qualificationSkill = [];
    if (qualificationSkillsData.length > 0) {
      qualificationSkillsData.forEach((val) => {
        if (val.isSelected) {
          this.interpreterData.qualificationSkill.push({ id: val.id, label: val.label, qualification: val, isSelected: val.isSelected });
        }});
    }}

  private getResponse(res: any) {
    if (res['isSuccess']) {
      this.notification.success('success', res['message']);
      this.closeModal(res);
    }
    this.interpreter.reset();
    this.loading = false;
  }

  ngOnDestroy() {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }
}
