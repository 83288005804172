import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { ModalDataService } from '../../../shared/services/modal-data.service';
import { CreateEditSessionComponent } from '../create-edit-session/create-edit-session.component';
import { SendEmailsModelComponent } from '../send-emails-model/send-emails-model.component';
import { SessionModalService } from './session-model.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-session-model',
  templateUrl: './session-model.component.html',
  styleUrls: ['./session-model.component.scss']
})
export class SessionModelComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  sessionData: any;
  modalName: string;
  loading = false;
  role: string;
  uploadme = false;
  interpreterDetailsData: any;
  userDetailsData: any;
  userSessionData: any;
  locationDetailsData: any;
  preferredLanguage: string;

  constructor(
    private readonly sessionService: SessionModalService,
    private readonly notification: NzNotificationService,
    private readonly translate: TranslateService,
    private readonly modal: NzModalRef,
    private readonly modalDataService: ModalDataService,
    private readonly translateService: TranslateService,
    private readonly nzModalService: NzModalService,
  ) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.preferredLanguage = localStorage.getItem('transLang');
    const modalName = this.modalDataService.getData();
    this.modalName = modalName['sessionActions'];
    this.getSession(modalName['sessionServiceData']);
  }

  private getSession(sessionId: number) {
    this.loading = true;
    this.subscription = this.sessionService.getSession(sessionId).subscribe((res) => {
      if (res) {
        this.sessionData = res['data'];
        this.interpreterDetailsData = this.sessionData.interpreter;
        this.userDetailsData = this.sessionData.user;
        if (this.sessionData.userSessionData) {
          this.userSessionData = this.sessionData.userSessionData;
        }
        this.locationDetailsData = this.sessionData.location;
        this.getSessionName(this.sessionData.status);
        this.loading = false;
      } else {
        this.notification.success('error', res['error']);
        this.loading = false;
        this.modal.destroy();
      }
    });
  }

  private getSessionName(sessionType: string) {
    let sessionName;
    const sessionNames = [{ name: 'PENDING', value: 'View_pending_session' }, { name: 'CONFIRMED', value: 'View_confirmed_session' },
    { name: 'CANCELLED', value: 'View_cancelled_session' }, { name: 'RESCHEDULED', value: 'View_rescheduled_session' },
    { name: 'PROGRESS', value: 'View_inProgress_session' }, { name: 'VALIDATE', value: 'View_toValidate_session' },
    { name: 'COMPLETED', value: 'View_completed_session' }, { name: 'REFUSED', value: 'View_refused_session' },
    { name: 'STOPPED', value: 'View_stopped_session' }, { name: 'MISSED', value: 'View_missed_session' },
    { name: 'LATE CANCELLATION', value: 'viewLatecancelledsession' }, { name: 'LATE RESCHEDULING', value: 'viewLateRescheduledsession' },
    { name: 'SENT', value: 'View_sent_session' }];
    sessionNames.forEach((i) => {
      if (i.name === sessionType) {
        sessionName = i.value;
      }
    });
    if (sessionName) {
      this.translate.get(sessionName).subscribe((text: string) => {
        this.modalName = text;
      });
    }
  }

  // Open User Modal
  openEditSessionModal(action: string) {
    let modal;
    this.modalDataService.setData('sessionServiceData', this.sessionData);
    this.modalDataService.setData('sessionActions', action);
    setTimeout(() => {
      this.translateService.get(action).subscribe((title) => {
        modal = this.nzModalService.create({
          nzTitle: title,
          nzClosable: false,
          nzContent: CreateEditSessionComponent,
          nzWidth: 1200
        });
      });
      modal.afterClose.subscribe((res) => { });
    }, 500);
    this.modal.destroy();
  }
  openSendEmailModal(action: string) {
    let modal;
    this.modalDataService.setData('sessionServiceData', this.sessionData);
    this.translateService.get(action).subscribe((title) => {
      modal = this.nzModalService.create({
        nzTitle: title,
        nzClosable: false,
        nzContent: SendEmailsModelComponent,
        nzWidth: 600
      });
      modal.afterClose.subscribe((res) => { });
    });
  }

  onExportSessionDetail() {
    const div = document.getElementById('content');
    const options = {
      background: 'white',
      scale: 3
    };
    html2canvas(div, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');
      const doc = new jsPDF('l', 'mm', 'a4', true);

      // Add image Canvas to PDF
      const bufferX = 5;
      const bufferY = 5;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

      return doc;
    }).then((doc) => {
      doc.save('VoirSession.pdf');
    });
  }

  // close modal window
  closeModal() {
    this.modal.destroy();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
