import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '../../../shared/services/common.service';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NzModalRef, NzNotificationService } from 'ng-zorro-antd';
import { NewOrganizationService } from './new-organization.service';
import { ModalDataService } from '../../../shared/services/modal-data.service';
import { Organization } from './organization.interface';
@Component({
  selector: 'app-new-organization',
  templateUrl: './new-organization.component.html',
  styleUrls: ['./new-organization.component.scss'],
})
export class NewOrganizationComponent implements OnInit, OnDestroy {
  @ViewChild('f', { static: false }) organization: NgForm;
  public countries: any = [];
  public countryCode: string;
  subscriber: Subscription;
  public isMode = 'create';
  localLanguage: string;
  loading = false;
  public qualifications: any = [];
  public organizationData = new Organization();

  constructor(
    private readonly commonService: CommonService,
    private readonly orgService: NewOrganizationService,
    private readonly notification: NzNotificationService,
    private readonly modal: NzModalRef,
    private readonly modalDataService: ModalDataService,
  ) { }

  ngOnInit() {
    this.localLanguage = localStorage.getItem('transLang');
    this.getListOfCountries();
    this.getCountryCode();
    const organization = this.modalDataService.getData();
    if (organization['addEditOrganization']) {
      this.organizationData = JSON.parse(JSON.stringify(organization['addEditOrganization']));
      this.isMode = 'update';
      this.organizationData.timeDelay = this.organizationData.timeDelay / 60000;
      if (this.organizationData.inPersonSessionDistanceLimit === -1) {
        this.organizationData.kmsLimit = 'unlimitedKm';
      } else {
        this.organizationData.kmsLimit = 'KmLimit';
      }
      this.getQualification(this.organizationData);
    } else {
      this.isMode = 'create';
    }
  }

  // get country code
  private getCountryCode() {
    this.subscriber = this.commonService.getCountryCode().subscribe((res) => {
      this.countryCode = res[0].value;
    });
  }

  // get country list
  private getListOfCountries() {
    this.subscriber = this.commonService.getCountry().pipe(map((resData) => {
      return {
        response: resData[this.localLanguage].map((res) => {
          return {
            name: res.countryName,
            iso: res.countryId,
          };
        }),
      };
    })).subscribe((res) => {
      this.countries = res.response;
    });
  }

  getQualification(organizationData) {
    this.loading = true;
    this.orgService.getQualifications(organizationData.id).subscribe((response) => {
      if (response['isSuccess']) {
        this.qualifications = response['data']['qualifications'];
        this.loading = false;
      } else {
        this.loading = false;
        this.notification.error('Error', response['message']);
      }
    });
  }
  // close modal window
  public closeModal(isSuccess: boolean) {
    this.modal.destroy(isSuccess);
  }

  // Add/Edit Organization
  public addOrganization() {
    this.loading = true;
    this.organizationData.manager.countryCode = this.countryCode;
    if (this.organization.valid) {
      if (this.organizationData.kmsLimit === 'unlimitedKm') {
        this.organizationData.isInpersonSessionDistanceLimit = -1;
        this.organizationData.inPersonSessionDistanceLimit = -1;
      }
      delete this.organizationData.kmsLimit;
      this.organizationData.timeDelay = (this.organizationData.timeDelay * 60 * 1000);
      if (this.isMode === 'create') {
        this.subscriber = this.orgService.sendFormData(this.organizationData).subscribe((res) => { this.getResponse(res); });
      } else if (this.isMode === 'update') {
        this.organizationData.qualifications = this.qualifications;
        this.subscriber = this.orgService.updateFormData(this.organizationData, this.organizationData.id).subscribe((res) => {
          this.getResponse(res);
        });
      }
    }
  }

  private getResponse(res: any) {
    if (res['isSuccess']) {
      this.notification.success('success', res['message']);
    } else {
      this.notification.success('error', res['message']);
    }
    this.loading = false;
    this.organization.reset();
    this.closeModal(res['isSuccess']);
  }

  ngOnDestroy() {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }
}
