<div class="row">
  <div class="offset-md-8 col-md-4">
    <tc-form-group>
      <tc-select [options]="sessionType" [selected]="viewType" [(ngModel)]="viewType" (ngModelChange)="getBarChartData($event)"></tc-select>
    </tc-form-group>
  </div>
</div>

<tc-card title="{{'Session_hours_evolution' | translate}}" [outline]="true" [view]="'info'" class="m-t-35">
  <div class="row">
    <div class="col-12 col-md-3" *ngIf="role === 'Admin'">
      <tc-form-group>
        <tc-form-label>{{'Interpreter_group' | translate}}</tc-form-label>
        <tc-select placeholder="{{'All' | translate}}" [options]="interpreterGroupList" [(ngModel)]="interpreterGroupId"
          (ngModelChange)="getBarChartData($event)" [allowClear]="true"></tc-select>
      </tc-form-group>
    </div>
    <div class="col-12 col-md-3" *ngIf="role === 'Admin'">
      <tc-form-group>
        <tc-form-label>{{'Organization' | translate}}</tc-form-label>
        <tc-select placeholder="{{'All' | translate}}" [options]="organisationList" [(ngModel)]="organizationId"
          (ngModelChange)="getBarChartData($event)" [allowClear]="true"></tc-select>
      </tc-form-group>
    </div>
    </div>
    <div class="row">

    <div class="col-12 col-md-4 date-picker">
      <nz-button-group [nzSize]="'large'">
        <button nz-button nzType="primary" (click)="getAdjacentMonth('previous')"><i nz-icon nzType="left"></i></button>
        <button nz-button nzType="primary" (click)="getAdjacentMonth('next')"><i nz-icon nzType="right"></i></button>
      </nz-button-group>
    </div>

    <span class="current-month">
      {{currentMonth}}
    </span>
    <div class="col-12 col-md-4 offset-md-2">
      <span class="trans-lang">
        <button tc-button [ngClass]="{ active: mode === 'Day' }" (click)="getBarChartData({ mode: 'Day' })">{{'Day' | translate}}</button>
        <!-- <button tc-button [ngClass]="{ active: mode === 'Week' }" (click)="getBarChartData({ mode: 'Week' })">{{'Week' | translate}}</button> -->
        <button tc-button [ngClass]="{ active: mode === 'Month' }" (click)="getBarChartData({ mode: 'Month' })">{{'Month' | translate}}</button>
        <button tc-button [ngClass]="{ active: mode === 'Year' }" (click)="getBarChartData({ mode: 'Year' })">{{'Year' | translate}}</button>
      </span>
    </div>
  </div>
<div class="row">
  <div class="col-12 col-md-12">
    <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [colors]="barChartColors"
      [legend]="barChartLegend" [chartType]="barChartType"></canvas>
  </div>
</div>
</tc-card >
<tc-card [outline]="true" [view]="'info'">
<div class="row pie-row">
  <div class="col-12 col-md-3" *ngIf="role === 'Admin'">
    <tc-form-group>
      <tc-form-label>{{'Interpreter_group' | translate}}</tc-form-label>
      <tc-select placeholder="{{'All' | translate}}" [options]="interpreterGroupList" [(ngModel)]="pieInterpreterId"
        (ngModelChange)="getPieChartData($event)" [allowClear]="true"></tc-select>
    </tc-form-group>
  </div>
  <div class="col-12 col-md-3" *ngIf="role === 'Admin'">
    <tc-form-group>
      <tc-form-label>{{'Organization' | translate}}</tc-form-label>
      <tc-select placeholder="{{'All' | translate}}" [options]="organisationList" [(ngModel)]="pieOrgId" (ngModelChange)="getPieChartData($event)"
        [allowClear]="true"></tc-select>
    </tc-form-group>
  </div>
  <div class="col-12 col-md-3 date-picker">
    <tc-form-group>
      <nz-range-picker [(ngModel)]="dateRange" (ngModelChange)="getPieChartData($event)"></nz-range-picker>
    </tc-form-group>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-12" *ngIf="role === 'Admin'">
    <tc-card title="{{'Statistics_of_session_type' | translate}}"[outline]="true" [view]="'info'">
      <h4 *ngIf="pieChartSessionTypeData.length === 0"> {{ 'No_data_to_show' | translate}}</h4>
      <canvas baseChart [data]="pieChartSessionTypeData" [labels]="pieChartSessionTypeLabels"
        [chartType]="pieChartType" [colors]="pieChartColors"></canvas>
    </tc-card>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-12" *ngIf="role === 'Admin'">
    <tc-card title="{{'Statistics_of_group' | translate}}"  [outline]="true" [view]="'info'">
      <h4 *ngIf="pieChartBhaashaGroupData.length === 0"> {{ 'No_data_to_show' | translate}}</h4>
      <canvas baseChart [data]="pieChartBhaashaGroupData" [labels]="pieChartBhaashaGroupLabels"
        [chartType]="pieChartType" [colors]="pieChartBhaashaGroupColors"></canvas>
    </tc-card>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-12">
    <tc-form-group *ngIf="role === 'Admin'">
      <nz-range-picker [(ngModel)]="languageDateRange" (ngModelChange)="getLanguagePieChartData($event)"></nz-range-picker>
    </tc-form-group>
    <tc-card title="{{'Patient_language_statistics' | translate}}" [outline]="true" [view]="'info'">
      <h4 *ngIf="pieChartSessionLanguageData.length === 0"> {{ 'No_data_to_show' | translate}}</h4>
      <canvas baseChart [data]="pieChartSessionLanguageData" [labels]="pieChartSessionLanguageLabels"
        [chartType]="pieChartType" [colors]="pieChartBhaashaGroupColors"></canvas>
      </tc-card>
  </div>
</div>
</tc-card>

<!-- other admins charts -->
<tc-card [outline]="true" title="{{'Organisation_user_accounts' | translate}} / {{'Interpreters' | translate}}" *ngIf="role === 'Manager' || role === 'IG Manager'">
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="chart-label">{{'Top_interpreters' | translate}}</div>
        <div class="chat-container container-h-300">
          <!-- <ngx-charts-bar-horizontal [scheme]="ngxChartColors" [results]="horizontalTopInterpreter" [gradient]="false" [barPadding]="10"
            [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false" [showYAxisLabel]="false"
            [roundEdges]="false"></ngx-charts-bar-horizontal> -->
            <ng-container *ngFor="let interpreter of horizontalTopInterpreter">
              <span class="chart-value">{{interpreter.name}} </span><nz-progress nzStatus="active" [nzPercent]="interpreter.value"></nz-progress>
              <div class="chart-value" style="text-align: center;">{{interpreter.total}}</div>
            </ng-container>
        </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="chart-label">{{'Top_clients_org_admin' | translate}}</div>
        <div class="chat-container container-h-300">
            <ng-container *ngFor="let client of horizontalTopClients">
              <span class="chart-value">{{client.name}} </span><nz-progress nzStatus="active" [nzPercent]="client.value"></nz-progress>
              <div class="chart-value" style="text-align: center;">{{client.total}}</div>
            </ng-container>
        </div>
    </div>
  </div>
</tc-card>
<tc-card [outline]="true" title="{{'Acception_rate_refused_time' | translate}}" *ngIf="role === 'Manager' || role === 'IG Manager'">
  <div class="row">
    <div class="col-12 col-md-12">
      <tc-card>
        <canvas
          baseChart
          [data]="doughnutChartData"
          [labels]="doughnutChartLabels"
          [chartType]="doughnutChartType"
          [colors]="doughnutChartColors"
        ></canvas>
      </tc-card>
    </div>
  </div>
  <div class="row">
    <div class="col col-md-6">
      <h1>{{ acceptanceRate }}</h1>
      <p style="font-size: 1.1rem;">{{'Emails_you_sent_received_replies' | translate}}</p>
    </div>
    <div class="col col-md-4 offset-md-2">
      <h1>{{ averageResponseTime | minuteSeconds}}</h1>
      <p style="font-size: 1.1rem;">{{'The_median_response_time' | translate}}</p>
    </div>
  </div>
</tc-card>

<div class="row">
  <div class="col-12 col-md-12">
    <tc-form-group>
      <nz-range-picker [(ngModel)]="cantonDateRange" (ngModelChange)="getCantonPieChartData($event)"></nz-range-picker>
    </tc-form-group>
    <tc-card title="{{'statistics_of_canton' | translate}}"[outline]="true" [view]="'info'">
      <h4 *ngIf="pieChartCantonData.length === 0"> {{ 'No_data_to_show' | translate}}</h4>
      <canvas baseChart [data]="pieChartCantonData" [labels]="pieChartCantonLabels"
        [chartType]="pieChartType" [colors]="pieChartBhaashaGroupColors"></canvas>
    </tc-card>
  </div>
</div>

<!-- branch and sub-branch chart -->
<tc-card [outline]="true" [view]="'info'" *ngIf="role !== 'Manager'">
      <tc-form-group>
        <nz-range-picker [(ngModel)]="branchDateRange" (ngModelChange)="getBranchPieChartData($event)"></nz-range-picker>
      </tc-form-group>
  <div class="row">
    <div class="col-12 col-md-12">
      <tc-card title="{{'statistics_of_branch' | translate}}"[outline]="true" [view]="'info'">
        <canvas baseChart [data]="pieChartBranchData" [labels]="pieChartBranchLabels"
          [chartType]="pieChartType" [colors]="pieChartColors"></canvas>
      </tc-card>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-12" *ngIf="role === 'Admin'">
      <tc-form-group>
        <tc-form-label>{{'Branch' | translate}}</tc-form-label>
        <tc-select placeholder="{{'Branch' | translate}}" [options]="branches" [(ngModel)]="branchId" (ngModelChange)="getSubBranchPieChartData()"></tc-select>
      </tc-form-group>
      <tc-card title="{{'statistics_of_subbranch' | translate}}"[outline]="true" [view]="'info'">
        <h4 *ngIf="pieChartSubBranchData.length === 0"> {{ 'No_data_to_show' | translate}}</h4>
        <canvas baseChart [data]="pieChartSubBranchData" [labels]="pieChartSubBranchLabels"
          [chartType]="pieChartType" [colors]="pieChartColors"></canvas>
      </tc-card>
    </div>
  </div>
</tc-card>




<!-- category chart -->
<tc-card [outline]="true" [view]="'info'" *ngIf="role !== 'Manager'">
    <tc-form-group>
      <nz-range-picker [(ngModel)]="categoryDateRange" (ngModelChange)="getCategoryPieChartData($event)"></nz-range-picker>
    </tc-form-group>
  <div class="row">
    <div class="col-12 col-md-12" *ngIf="role === 'Admin'">
      <tc-card title="{{'statistics_of_category' | translate}}"[outline]="true" [view]="'info'">
        <h4 *ngIf="pieChartCategoryData.length === 0"> {{ 'No_data_to_show' | translate}}</h4>
        <canvas baseChart [data]="pieChartCategoryData" [labels]="pieChartCategoryLabels"
          [chartType]="pieChartType" [colors]="pieChartBhaashaGroupColors"></canvas>
      </tc-card>
    </div>
  </div>
</tc-card>



<!-- interpreter Qualification chart -->
<tc-card *ngIf="role !== 'Manager'" [outline]="true" [view]="'info'" title="{{'statistics_of_interpreter_distribution' | translate}}"[outline]="true" [view]="'info'">
  <h4 *ngIf="pieChartQualificationData.length === 0"> {{ 'No_data_to_show' | translate}}</h4>
  <canvas baseChart [data]="pieChartQualificationData" [labels]="pieChartQualificationLabels"
    [chartType]="pieChartType" [colors]="pieChartBhaashaGroupColors"></canvas>
</tc-card>


<!-- interpreter filter qualification chart -->
<tc-card *ngIf="role !== 'Manager'" [outline]="true" [view]="'info'">
    <tc-form-group>
      <nz-range-picker [(ngModel)]="filterQualificationDateRange" (ngModelChange)="getFilterQualificationPieChartData($event)"></nz-range-picker>
    </tc-form-group>
  <div class="row">
    <div class="col-12 col-md-12">
      <tc-card title="{{'statistics_of_interpreter_qualification' | translate}}"[outline]="true" [view]="'info'">
        <h4 *ngIf="pieChartFilterQualificationData.length === 0"> {{ 'No_data_to_show' | translate}}</h4>
        <canvas baseChart [data]="pieChartFilterQualificationData" [labels]="pieChartFilterQualificationLabels"
          [chartType]="pieChartType" [colors]="pieChartBhaashaGroupColors"></canvas>
      </tc-card>
    </div>
  </div>
</tc-card>
