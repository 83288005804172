export class Interpreter {
  interpreterGroup = {
    id: 0
  };
  credentials = {
    userId: '',
    password: '',
  };
  isActive = false;
  emailVerified = false;
  telephoneVerified = false;
  priority = 1;
  levelThree: string;
  languagesSkills = [
    {
      languageOne: {
          isoCode: ''
      },
      languageTwo: {
          isoCode: ''
      },
      levelOne: '',
      levelTwo: ''
    },
  ];
  contractSince: number;
  juridicProtectionContractFromDate: number;
  firstName: string;
  lastName: string;
  email: string;
  countryCode = '+41';
  telephone: string;
  gender: string;
  formattedAddress: string;
  latitude: string;
  longitude: string;
  address: string;
  zipcode: string;
  city: string;
  country = {
    iso: '',
  };
  idNumber: string;
  dynamicFields = [];
  dob: number;
  iban: string;
  bic: string;
  bankName: string;
  accountHolder: string;
  permit: string;
  permitValidity: string;
  nationality: string;
  avsNum: string;
  salaryType: string;
  mainOccupation: string;
  interpreterQualificationId: string;
  salaryLimit: number;
  paymentType: string;
  isValueAddedTax = false;
  sessionCostPerMinVideo = 0;
  sessionCostPerMinVoice = 0;
  sessionCostPerHrInperson = 0;
  sessionCostPerMinMediation = 0;
  sessionCostperMinCourse = 0;
  minBillingSalaryVideo = 0;
  minBillingSalaryVoice = 0;
  minBillingSalaryInperson = 0;
  minBillingSalaryMediation = 0;
  minBillingSalaryCourse = 0;
  sessionTravelCost = 0;
  billingTravelCost = 0;
  qualificationSkill = [];
  examDate: number;
  certificateDate: number;
  federalCertificateDate: number;
  modulesData = {
    isModule1DateSelected: false,
    module1Date: 0,
    isModule2DateSelected: false,
    module2Date: 0,
    isModule3DateSelected: false,
    module3Date: 0,
    isModule4DateSelected: false,
    module4Date: 0,
    isModule4Selected: false,
    module4: 0,
    isModule5DateSelected: false,
    module5Date: 0,
    isModule6DateSelected: false,
    module6Date: 0,
    isModule7DateSelected: false,
    module7Date: 0,
    isModule8DateSelected: false,
    module8Date: 0,
    isModule9DateSelected: false,
    module9Date: 0,
    isModule10DateSelected: false,
    module10Date: 0
  };
  otherQualification: string;
  isTrainingCivilRegistryOffice = false;
  trainingCivilRegistryOffice: number;
  isVideoCallActive = true;
  isVoiceCallActive = true;
  isInpersonActive = true;
  isMediationActive = false;
  isCourseActive = false;
  invitationKey: string;
  fullName: string;
  id: number;
  inPersonAvailability: boolean;
  isSessionUpdate: boolean;
  notifySessionByMail: boolean;
  notifySessionBySms: boolean;
  rating: number;
  role: string;
  sessionCostPerMinInperson = 0;
  travelMode: string;
  videoCallAvailability: boolean;
  voiceCallAvailability: boolean;
}
