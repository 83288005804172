import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DownloadFileService {
  isIE = false;
  public constructor() { }

  public downloadFile(filePath, fileName) {
    const isInternetExplorer = this.isIE || !!document.DOCUMENT_NODE;
    if (isInternetExplorer) {
      window.open(filePath, fileName);
    } else {
      const link = document.createElement('a');
      link.download = fileName;
      link.href = filePath;
      link.click();
    }
  }
}
