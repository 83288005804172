import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { newSessionPath } from './create-edit-session.path';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewSessionService {
  public constructor(private readonly httpClientService: HttpClientService) { }

  public getUserData(queryParam: string): Observable<{}> {
    return this.httpClientService.getServerRequest(newSessionPath.getUrl('userAccounts') + queryParam);
  }

  public getRoleUserData(Id: number, queryString: string): Observable<{}> {
    return this.httpClientService.getServerRequest(
      `${newSessionPath.getUrl('userAccounts')}/${Id}/users${queryString}`);
  }

  public getUserPatient(Id: number, queryString: string): Observable<{}> {
    return this.httpClientService.getServerRequest(
      `${newSessionPath.getUrl('useraccount')}${Id}/patients-show-field${queryString}`);
  }

  public getLanguage(Id: number): Observable<{}> {
    return this.httpClientService.getServerRequest(`${newSessionPath.getUrl('organizations')}${Id}/patient-languages/English`);
  }

  public getInterpreter(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(
      `${newSessionPath.getUrl('interpreters')}language-based` + query);
  }

  // Get all Qaulification filter data
  public getQualifications(userAccountId: string, interpreters: object): Observable<{}> {
    return this.httpClientService.postServerRequest(
      `${newSessionPath.getUrl('interpreters')}interpreter-qualification?userAccountId=${userAccountId}`, interpreters);
  }

  // Get country list for filter data
  public getCountries(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${newSessionPath.getUrl('countries')}` + query);
  }

  // get Payment authority API call
  public getPaymentAuthority(orgId: number, queryString: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${newSessionPath.getUrl('paymentAuthority')}${orgId}${queryString}`);
  }

  // get Patient Label by ORGID API call
  public getPatientLable(orgId: number): Observable<{}> {
    return this.httpClientService.getServerRequest(`${newSessionPath.getUrl('getlabelbyorg')}${orgId}`);
  }

  // get category API call
  public getCategoryData(orgId: number, queryString: string): Observable<{}> {
    return this.httpClientService.getServerRequest(`${newSessionPath.getUrl('organization')}${orgId}/category${queryString}`);
  }

  // get patient form data
  public getPatientForm(orgId: number): Observable<{}> {
    return this.httpClientService.getServerRequest(newSessionPath.getUrl('patientForm') + orgId);
  }
  public createNewSession(newSessionData): Observable<{}> {
    return this.httpClientService.postServerRequest(newSessionPath.getUrl('createSession'), newSessionData);
  }
  public updateSession(sessionData, sessionId): Observable<{}> {
    return this.httpClientService.putServerRequest(newSessionPath.getUrl('updateSession') + sessionId, sessionData);
  }
  public followUpSession(sessionData): Observable<{}> {
    return this.httpClientService.postServerRequest(newSessionPath.getUrl('followUpSession'), sessionData);
  }
}
