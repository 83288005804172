<nz-spin [nzSpinning]="loading">
  <form (ngSubmit)="submitPatientData()" #f="ngForm" novalidate *ngIf="patientFormData.length > 0">
    <div class="row" *ngFor="let formData of patientFormData">
      <div class="col col-md-4" *ngIf="formData.fieldName === 'firstName' && formData.isSelected == true">
        <tc-form-group>
          <tc-form-label>Prefix</tc-form-label>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Choose' | translate }}" [required] ="formData.isRequired === true"
            name="prefix" #prefix="ngModel" [(ngModel)]="patientData.prefix">
            <nz-option *ngFor="let item of prefixData" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
          </nz-select>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'firstName' && formData.isSelected == true">
        <tc-form-group>
          <tc-form-label>{{formData.labelName}}<span *ngIf="formData.isRequired === true" class="req-icon">*</span>
          </tc-form-label>
          <tc-input placeholder="{{formData.labelName}}" [required] ="formData.isRequired === true"
            name="firstName" #firstName="ngModel" [(ngModel)]="patientData.firstName"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'lastName' && formData.isSelected == true">
        <tc-form-group>
          <tc-form-label>{{formData.labelName}}<span *ngIf="formData.isRequired === true" class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{formData.labelName}}" name="lastName" [required] ="formData.isRequired === true"
            #lastName="ngModel" [(ngModel)]="patientData.lastName"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'dob' && formData.isSelected == true">
        <tc-form-group>
          <tc-form-label>{{formData.labelName}}<span *ngIf="formData.isRequired === true" class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{formData.labelName}}" name="dob" [required] ="formData.isRequired === true"
            #dob="ngModel" [(ngModel)]="patientData.dob"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'category' && formData.isSelected == true">
        <tc-form-group>
          <tc-form-label>{{formData.labelName}}<span *ngIf="formData.isRequired === true" class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{formData.labelName}}" name="category" [required] ="formData.isRequired === true"
            #category="ngModel" [(ngModel)]="patientData.category"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'evamNumber' && formData.isSelected == true">
        <tc-form-group>
          <tc-form-label>{{formData.labelName}}<span *ngIf="formData.isRequired === true" class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{formData.labelName}}" name="evamNumber" [required] ="formData.isRequired === true"
            #evamNumber="ngModel" [(ngModel)]="patientData.evamNumber"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'ippNumber' && formData.isSelected == true">
        <tc-form-group>
          <tc-form-label>{{formData.labelName}}<span *ngIf="formData.isRequired === true" class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{formData.labelName}}" name="ippNumber" [required] ="formData.isRequired === true"
            #ippNumber="ngModel" [(ngModel)]="patientData.ippNumber"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'email' && formData.isSelected == true">
        <tc-form-group>
          <tc-form-label>{{formData.labelName}}<span *ngIf="formData.isRequired === true" class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{formData.labelName}}" name="email" [required] ="formData.isRequired === true"
            #email="ngModel" [(ngModel)]="patientData.email"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'telephone' && formData.isSelected == true">
        <tc-form-group>
          <tc-form-label>{{formData.labelName}}<span *ngIf="formData.isRequired === true" class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{formData.labelName}}" name="telephone" [required] ="formData.isRequired === true"
            #telephone="ngModel" [(ngModel)]="patientData.telephone"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'combinedNumber' && formData.isSelected == true">
        <tc-form-group>
          <tc-form-label>{{formData.labelName}}<span *ngIf="formData.isRequired === true" class="req-icon">*</span></tc-form-label>
          <tc-input placeholder="{{formData.labelName}}" name="combinedNumber" [required] ="formData.isRequired === true"
            #combinedNumber="ngModel" [(ngModel)]="patientData.combinedNumber"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'gender' && formData.isSelected == true">
        <tc-form-group>
          <tc-form-label>{{formData.labelName}}</tc-form-label>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{formData.labelName}}" [required] ="formData.isRequired === true"
            name="gender" #gender="ngModel" [(ngModel)]="patientData.gender">
            <nz-option nzValue="Male" nzLabel="{{'Male' | translate}}"></nz-option>
            <nz-option nzValue="Female" nzLabel="{{'Female' | translate}}"></nz-option>
            <nz-option nzValue="Divers" nzLabel="{{'Divers' | translate}}"></nz-option>
          </nz-select>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'patientGroup' && formData.isSelected == true">
        <tc-form-group>
          <tc-form-label>{{formData.labelName}}</tc-form-label>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{formData.labelName}}" [required] ="formData.isRequired === true"
            name="patientGroup" #patientGroup="ngModel" [(ngModel)]="patientData.patientGroup">
            <nz-option nzValue="Adults" nzLabel="{{'Adults' | translate}}"></nz-option>
            <nz-option nzValue="Children" nzLabel="{{'Children' | translate}}"></nz-option>
          </nz-select>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'patientInsurance' && formData.isSelected == true">
        <tc-form-group>
          <tc-form-label>{{formData.labelName}}</tc-form-label>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{formData.labelName}}" [required] ="formData.isRequired === true"
            name="patientInsurance" #patientInsurance="ngModel" [(ngModel)]="patientData.patientInsurance">
            <nz-option nzValue="AsylbLG" nzLabel="{{'AsylbLG' | translate}}"></nz-option>
            <nz-option nzValue="GKV" nzLabel="{{'GKV' | translate}}"></nz-option>
            <nz-option nzValue="Notinsured" nzLabel="{{'Notinsured' | translate}}"></nz-option>
            <nz-option nzValue="Unknown" nzLabel="{{'Unknown' | translate}}"></nz-option>
            <nz-option nzValue="Other" nzLabel="{{'Other' | translate}}"></nz-option>
          </nz-select>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'patientInsurance' && formData.isSelected == true &&
      patientData.patientInsurance === 'GKV'">
        <tc-form-group>
          <tc-form-label>{{'Name' | translate}}</tc-form-label>
          <tc-input placeholder="{{'Name' | translate}}" name="patientGkvInsurance"
            #patientGkvInsurance="ngModel" [(ngModel)]="patientData.patientGkvInsurance"></tc-input>
        </tc-form-group>
      </div>
      <div class="col col-md-6" *ngIf="formData.fieldName === 'patientInsurance' && formData.isSelected == true &&
      patientData.patientInsurance === 'Other'">
        <tc-form-group>
          <tc-form-label>{{'namely' | translate}}</tc-form-label>
          <tc-input placeholder="{{'namely' | translate}}" name="patientOtherInsurance"
            #patientOtherInsurance="ngModel" [(ngModel)]="patientData.patientOtherInsurance"></tc-input>
        </tc-form-group>
      </div>
    </div>
    <div *nzModalFooter class="actions justify-content-between">
      <button tc-button [type]="'button'" [view]="'error'" (click)="closePatientModal()"> Cancel</button>
      <button tc-button [view]="'info'" (click)="submitPatientData()" [disabled]="!f.valid">
        {{ isMode === "create" ? ("Create" | translate) : ("Update" | translate) }}
      </button>
    </div>
  </form>
</nz-spin>
