import { environment } from '../../../../environments/environment';

export const urls = {
   createInterpreter: 'interpreters',
   getInterpreter : 'admin/interpreters',
   getunassignedInterpreter : 'interpreters-unassigned',
   exportAllData : 'profile-export',
   getInterpreterEmails : 'interpretersEmailId',
   searchTextFilter: 'search-text-filter',
   preRequisiteData: ''
};

export const oldUrls = {
    createInterpreter: 'v4/interpreters',
    getInterpreter : 'v5/admin/interpreters',
    getunassignedInterpreter : 'v2/interpreters-unassigned',
    exportAllData : 'v4/profile-export',
    getInterpreterEmails : 'v4/interpretersEmailId',
    searchTextFilter: 'v4/search-text-filter',
    preRequisiteData: 'v4/'

};
export const assignInterpreter = {
    getUrl(urlName: string) {
        if (environment.backend === 'old') {
            return oldUrls[urlName];
        } else {
            return urls[urlName];
        }
    }
};
