import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly notification: NzNotificationService,
    private readonly translate: TranslateService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers;
    if (localStorage.getItem('token')) {
      headers = {
        'X-preferedLanguage': localStorage.getItem('transLang') ? localStorage.getItem('transLang') : 'fr',
        'X-XSRF-TOKEN': localStorage.getItem('token')
      }; } else { headers = {
        'X-preferedLanguage': localStorage.getItem('transLang') ? localStorage.getItem('transLang') : 'fr',
        'X-XSRF-TOKEN': 'null',
      }; }
    request = request.clone({ setHeaders: headers, });
    return next.handle(request).pipe(tap(() => { }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            // case 401:
            // case 400:
            //           this.router.navigate(['']);
            //           this.notification.success('error', err.error.error);
            //           break;
            case 404: this.router.navigate(['public/page-404']);
                      this.notification.success('error', err.error.error);
                      break;
            case 500: this.router.navigate(['public/page-500']);
                      this.notification.success('error', err.error.error);
                      break;
          } } })); } }
