<nz-spin [nzSpinning]="isLoading">
  <form (ngSubmit)="submitFormData()" #f="ngForm" novalidate>
    <nz-tabset>
      <nz-tab nzTitle="Personal Information">
        <div ngModelGroup="userAccountManager">
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Firstname" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Firstname' | translate }}" pattern="[0-9 a-zA-Z-._äâÄéÉèÈêÊöÖôÔÜüËë]+$"
                  name="firstName" #firstName="ngModel" [(ngModel)]="userAccountData.userAccountManager.firstName">
                </tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!firstName.valid && (firstName.dirty || firstName.touched)">
                  Invalid text
                </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Lastname" | translate }}<span class="req-icon">*</span></tc-form-label>
                <tc-input placeholder="{{ 'Lastname' | translate }}" required pattern="[0-9 a-zA-Z-._äâÄéÉèÈêÊöÖôÔÜüËë]+$"
                  name="lastName" #lastName="ngModel" [(ngModel)]="userAccountData.userAccountManager.lastName">
                </tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!lastName.valid && (lastName.dirty || lastName.touched)">
                  {{ "Invalid_input" | translate }}
                </tc-form-description>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Email" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Email' | translate }}" pattern="[a-zéÉèÈêÊöÖ]+[a-z0-9-._éÉèÈêÊöÖ]+@[a-z0-9-._]+\.[a-z]{2,20}$"
                  name="email" #email="ngModel" [(ngModel)]="userAccountData.userAccountManager.email">
                </tc-input>
                <tc-form-description *ngIf="!email.valid && (email.dirty || email.touched)">
                  <p> {{ "Invalid_input" | translate }} </p>
                </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <div ngModelGroup="credentials">
                <tc-form-group>
                  <tc-form-label>{{ "User_id" | translate}}<span class="req-icon">*</span></tc-form-label>
                  <tc-input placeholder="{{ 'User_id' | translate }}" pattern="[0-9 a-zA-Z-_.*]+$"
                    name="userId" #userId="ngModel" [(ngModel)]="userAccountData.userAccountManager.credentials.userId"
                  ></tc-input>
                  <tc-form-description
                    [tcColor]="'#e24d4d'" *ngIf="!userId.valid && (userId.dirty || userId.touched)">
                    <p> {{ "User_id_required" | translate }} </p>
                  </tc-form-description>
                </tc-form-group>
              </div>
            </div>
          </div>
        </div>
        <div ngModelGroup="userAccountManager">
          <div ngModelGroup="passwords" appCheckPassword #passwords="ngModelGroup">
            <div class="row">
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{ "Password" | translate}}<span class="req-icon">*</span></tc-form-label>
                  <tc-input type="password" placeholder="{{ 'Password' | translate }}" #password="ngModel" [required] = "isMode === 'create'"
                  name="password" [(ngModel)]="userAccountData.userAccountManager.credentials.password"
                  pattern="[0-9 a-zA-Z-_.*]+$"></tc-input>
                  <tc-form-description
                    [tcColor]="'#e24d4d'" *ngIf="!password.valid && (password.dirty || password.touched)">
                    <p> {{ "Password_required" | translate }}</p>
                  </tc-form-description>
                </tc-form-group>
              </div>
              <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Confirm_password" | translate}}<span class="req-icon">*</span></tc-form-label>
                <tc-input type="password" placeholder="{{ 'Confirm_password' | translate }}" [required] = "isMode === 'create'"
                  name="confirmPassword" #confirmPassword="ngModel" ngModel></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!confirmPassword.valid && (confirmPassword.dirty || confirmPassword.touched)">
                  <div *ngIf="confirmPassword.errors.required">{{'Confirm_password_required' | translate}}</div>
                </tc-form-description>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="passwords.errors?.passwordCheck && (confirmPassword.dirty || confirmPassword.touched)">
                  {{'Password_not_match' | translate}}
                </tc-form-description>
              </tc-form-group>
            </div>
          </div>
        </div>
      </div>
        <div ngModelGroup="userAccountManager">
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Address" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Address' | translate }}" (click) ="onClickAddLocation('Add Location', null)"
                  name="address" #address="ngModel" [(ngModel)]="userAccountData.userAccountManager.address">
                </tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "City" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'City' | translate }}" pattern="([a-zA-Z éÉèÈöÖ])*$"
                  name="city" #city="ngModel" [(ngModel)]="userAccountData.userAccountManager.city">
                </tc-input>
                <tc-form-description
                    [tcColor]="'#e24d4d'" *ngIf="!city.valid && (city.dirty || city.touched)">
                    <p> Invalid text </p>
                  </tc-form-description>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-2">
              <tc-form-group>
                <tc-form-label>{{ "Telephone" | translate }}<span class="req-icon">*</span></tc-form-label>
                <tc-input required name="cntryCode" #cntryCode="ngModel" [(ngModel)]="userAccountData.userAccountManager.countryCode"></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="cntryCode.touched && !cntryCode.valid">
                  <p> {{ "Is_invalid" | translate }}</p>
                </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-4">
              <tc-form-group>
                <tc-form-label>&nbsp;</tc-form-label>
                <tc-input placeholder="{{ 'Telephone' | translate }}" name="telephone" #telephone="ngModel" 
                  [(ngModel)]="userAccountData.userAccountManager.telephone"
                  pattern="\s*(?:[0-9]\s*){10,12}$" (keyup) ="changeTelephoneFormat($event)"></tc-input>
                  <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!telephone.valid && (telephone.dirty || telephone.touched)">
                  <p>{{ "Telephone" | translate }} {{ "Is_invalid" | translate }}</p>
                </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Zipcode" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Zipcode' | translate }}"
                  name="zipcode" #zipcode="ngModel" [(ngModel)]="userAccountData.userAccountManager.zipcode">
                </tc-input>
              </tc-form-group>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-6">
            <div ngModelGroup="userAccountManager">
              <div ngModelGroup="country">
                <tc-form-group>
                  <tc-form-label>{{ "Country" | translate }}</tc-form-label>
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Country' | translate }}"
                    name="iso" #iso="ngModel" [(ngModel)]="userAccountData.userAccountManager.country.iso">
                    <nz-option *ngFor="let item of countries" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
                  </nz-select>
                  <tc-form-description [tcColor]="'#e24d4d'" *ngIf="iso.touched && !iso.valid">
                    <p> {{ "Is_invalid" | translate }}</p>
                  </tc-form-description>
                </tc-form-group>
              </div>
            </div>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Institution" | translate }}</tc-form-label>
              <tc-input placeholder="{{ 'Institution' | translate }}"
                name="institution" #institution="ngModel" [(ngModel)]="userAccountData.institution">
              </tc-input>
            </tc-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Address_Key" | translate }}</tc-form-label>
              <tc-input placeholder="{{ 'Address_Key' | translate }}"
                name="addressKey" #addressKey="ngModel" [(ngModel)]="userAccountData.addressKey">
              </tc-input>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Cost_center" | translate }}</tc-form-label>
              <tc-input placeholder="{{ 'Cost_center' | translate }}"
                name="costCenter" #costCenter="ngModel" [(ngModel)]="userAccountData.costCenter">
              </tc-input>
            </tc-form-group>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Administrative Information">
        <div class="row">
          <div class="col col-md-6">
            <div ngModelGroup="organization">
              <tc-form-group>
                <tc-form-label>{{ "Organization" | translate }}<span class="req-icon">*</span></tc-form-label>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Organization' | translate }}" required
                  *ngIf="isUserAccount && isMode === 'create'" name="name" #OrgName="ngModel" [(ngModel)]="userAccountData.organization" 
                  (ngModelChange)="getDepartmentList($event)">
                  <nz-option *ngFor="let item of organizationList" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
              </nz-select>
              <tc-input *ngIf="!isUserAccount || isMode === 'update'" placeholder="{{ 'Organization' | translate }}"
                name="name" required #OrgName="ngModel" [ngModel]="userAccountData.organization.name" [disabled]="true">
              </tc-input>
              </tc-form-group>
            </div>
          </div>
          <div class="col col-md-5">
            <tc-form-group>
              <tc-form-label>{{ "Department" | translate }}</tc-form-label>
              <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Department' | translate }}" 
                name="department" #department="ngModel" [(ngModel)]="userAccountData.department.id">
                <nz-option *ngFor="let item of departmentList" [nzValue]="item.id" [nzLabel]="item.name"></nz-option>
              </nz-select>
            </tc-form-group>
          </div>
          <div class="col col-md-1">
            <tc-form-label>&nbsp;</tc-form-label>
            <button [disabled]="departmentButton" tc-button [afterIcon]="'icofont-plus'" type="button" [view]="'accent'"
              [square]="true" [outline]="true" (click)="onAddDepartment('Add Department', null)"></button>
          </div>
        </div>
        <div class="row" style="margin-top: 1rem;">
          <div class="col col-md-4">
            <tc-form-group class="mb-md-0">
              <tc-form-label>{{ "Status" | translate }}</tc-form-label>
              <nz-radio-group name="userAccountStatus" #userAccountStatus="ngModel" [(ngModel)]="userAccountData.userAccountStatus">
                <label nz-radio nzValue="ACTIVE">{{ "Active" | translate }}</label>
                <label nz-radio nzValue="INACTIVE">{{ "inActive" | translate}}</label>
              </nz-radio-group>
            </tc-form-group>
          </div>
          <div class="col col-md-4">
            <tc-form-group class="mb-md-0">
              <tc-form-label>{{ "Newsletter" | translate }}</tc-form-label>
              <nz-radio-group name="isNewsletterSelected" #isNewsletterSelected="ngModel" [(ngModel)]="userAccountData.isNewsletterSelected">
                <label nz-radio [nzValue]="true">{{ "Yes" | translate }}</label>
                <label nz-radio [nzValue]="false">{{ "No" | translate }}</label>
              </nz-radio-group>
            </tc-form-group>
          </div>
          <div class="col col-md-4">
            <tc-form-group class="mb-md-0">
              <tc-form-label>{{ "Christmas_card" | translate }}</tc-form-label>
              <nz-radio-group name="isChristmasCardSelected" #isChristmasCardSelected="ngModel" [(ngModel)]="userAccountData.isChristmasCardSelected">
                <label nz-radio [nzValue]="true">{{ "Yes" | translate }}</label>
                <label nz-radio [nzValue]="false">{{ "No" | translate }}</label>
              </nz-radio-group>
            </tc-form-group>
          </div>
        </div>
        <div class="row" style="margin-top: 1.5rem;">
          <div class="col col-md-4">
            <tc-form-group class="mb-md-0">
              <tc-form-label>{{ "default_qualification" | translate }}</tc-form-label>
              <nz-radio-group name="enableDefaultQualification" #enableDefaultQualification="ngModel" 
                [(ngModel)]="userAccountData.enableDefaultQualification">
                <label nz-radio [nzValue]="true">{{ "Yes" | translate }}</label>
                <label nz-radio [nzValue]="false">{{ "No" | translate }}</label>
              </nz-radio-group>
            </tc-form-group>
          </div>
          <div class="col col-md-4">
            <tc-form-group class="mb-md-0">
              <tc-form-label>{{"Contract_customer" | translate }}</tc-form-label>
              <nz-radio-group name="isContractCustomer" #isContractCustomer="ngModel" 
                [(ngModel)]="userAccountData.isContractCustomer">
                <label nz-radio [nzValue]="true">{{ "Yes" | translate }}</label>
                <label nz-radio [nzValue]="false">{{ "No" | translate }}</label>
              </nz-radio-group>
            </tc-form-group>
          </div>
          <div class="col col-md-4">
            <tc-form-group class="mb-md-0">
              <tc-form-label>{{ "enableSessionLimit" | translate }}</tc-form-label>
              <nz-radio-group name="isSessionLimitApplied" #isSessionLimitApplied="ngModel" 
                [(ngModel)]="userAccountData.isSessionLimitApplied">
                <label nz-radio [nzValue]="true">{{ "Yes" | translate }}</label>
                <label nz-radio [nzValue]="false">{{ "No" | translate }}</label>
              </nz-radio-group>
            </tc-form-group>
          </div>
        </div>
        <div class="row" style="margin-top: 1rem;">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "hourLimitValue" | translate }}</tc-form-label>
              <tc-form-label>{{ "Hours" | translate }}</tc-form-label>
              <tc-input type="number" placeholder="{{ 'Hours' | translate }}" pattern="\d+$" min="0"
                name="hourLimitValue" #hourLimitValue="ngModel" [(ngModel)]="userAccountData.hourLimitValue">
              </tc-input>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>&nbsp;</tc-form-label>
              <tc-form-label>{{ "Minutes" | translate }}</tc-form-label>
              <tc-input type="number" placeholder="{{ 'Minutes' | translate }}" pattern="[0-5]?[0-9]$"
                name="minuteLimitValue" #minuteLimitValue="ngModel" [(ngModel)]="userAccountData.minuteLimitValue" maxlength="2">
              </tc-input>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Branch" | translate }}</tc-form-label>
              <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Branch' | translate }}"
                    name="branch" #branch="ngModel" [(ngModel)]="userAccountData.branch.id" (ngModelChange)="onSelectBranch($event)">
                    <nz-option *ngFor="let item of branches" [nzValue]="item.id" [nzLabel]="item.label"></nz-option>
              </nz-select>
            </tc-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-6" *ngIf="userAccountData.branch.id !== 0">
            <tc-form-group>
              <tc-form-label>{{ "Selected_branch" | translate }}</tc-form-label>
              <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Selected_branch' | translate }}"
                    name="branchOption" #branchOption="ngModel" [(ngModel)]="userAccountData.branchOption.id" 
                      (ngModelChange)="onSelectSubBranch($event, userAccountData.branchOption)" required>
                    <nz-option *ngFor="let item of branchOptions" [nzValue]="item.id" [nzLabel]="item.name"></nz-option>
              </nz-select>
            </tc-form-group>
          </div>
          <div class="col col-md-6" *ngIf="showSubBranch">
            <tc-form-group>
              <tc-form-label>{{ "Selected_branch" | translate }}</tc-form-label>
              <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Selected_branch' | translate }}"
                name="branchSubOptions" #branchSubOption="ngModel" [(ngModel)]="userAccountData.branchOptionSubOption.id">
                <nz-option *ngFor="let item of branchSubOptions" [nzValue]="item.id" [nzLabel]="item.label"></nz-option>
              </nz-select>
            </tc-form-group>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Other Information">
        <!-- <div class="row" *ngIf="isMode === 'update'">
          <div
            class="col col-md-6"
            *ngIf="
              userAccountData.organization.dynamicField &&
              userAccountData.organization.dynamicField.isShow != false
            "
          >
            <div formGroupName="userAccountManager">
              <tc-form-group class="mb-md-0">
                <tc-form-label>{{
                  userAccountData.organization.dynamicField.label
                }}</tc-form-label>
                <tc-radio formControlName="isSelected">
                  <tc-radio-option [value]="'true'">{{
                    "Yes" | translate
                  }}</tc-radio-option>
                  <tc-radio-option [value]="'false'">{{
                    "No" | translate
                  }}</tc-radio-option>
                </tc-radio>
              </tc-form-group>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Customer_since" | translate }}({{"Date_format_Ig" | translate}})</tc-form-label>
              <nz-date-picker nzPlaceHolder="{{'Customer_since' | translate}}" [nzFormat]="'dd/MM/yyyy'"
                  name="customerSince" #customerSince="ngModel" [(ngModel)]="userAccountData.customerSince">
              </nz-date-picker>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Postal_address" | translate }}</tc-form-label>
              <tc-input placeholder="{{ 'Postal_address' | translate }}"
                name="postalAddress" #postalAddress="ngModel" [(ngModel)]="userAccountData.postalAddress"></tc-input>
            </tc-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "select_Canton" | translate }}</tc-form-label>
              <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'select_Canton' | translate }}"
                    name="townShip" #townShip="ngModel" [(ngModel)]="userAccountData.townShip">
                    <nz-option *ngFor="let item of canton" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
              </nz-select>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"Additional_email" | translate}}</tc-form-label>
              <tc-input placeholder="{{ 'Additional_email' | translate }}"
                name="additionalEmailAddress" #additionalEmailAddress="ngModel" [(ngModel)]="userAccountData.additionalEmailAddress">
              </tc-input>
            </tc-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "typeOfActivity" | translate }}</tc-form-label>
              <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'typeOfActivity' | translate }}"
                    name="activity" #activity="ngModel" [(ngModel)]="userAccountData.activity">
                    <nz-option nzValue="selfEmployed" nzLabel="{{ 'selfEmployed' | translate }}"></nz-option>
                    <nz-option nzValue="employed" nzLabel="{{ 'employed' | translate }}"></nz-option>
              </nz-select>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "jobTitle" | translate }}</tc-form-label>
              <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'jobTitle' | translate }}"
                    name="jobTitle" #jobTitle="ngModel" [(ngModel)]="userAccountData.jobTitle">
                    <nz-option *ngFor="let item of jobList" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
              </nz-select>
            </tc-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "inTraining" | translate }}</tc-form-label>
              <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'inTraining' | translate }}"
                    name="isTrainingApplied" #isTrainingApplied="ngModel" [(ngModel)]="userAccountData.isTrainingApplied">
                    <nz-option [nzValue] = "true" nzLabel="{{ 'Yes' | translate }}"></nz-option>
                    <nz-option [nzValue] = "false" nzLabel="{{ 'No' | translate }}"></nz-option>
              </nz-select>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "typeOfFacility" | translate }}</tc-form-label>
              <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'typeOfFacility' | translate }}"
                    name="facility" #facility="ngModel" [(ngModel)]="userAccountData.facility">
                    <nz-option nzValue="healthInsurance" nzLabel="{{ 'practiceWithHealthInsuranceapproval' | translate }}"></nz-option>
                    <nz-option nzValue="privatePractice" nzLabel="{{ 'privatePractice' | translate }}"></nz-option>
                    <nz-option nzValue="healthCareCenter" nzLabel="{{ 'healthCareCenter' | translate }}"></nz-option>
                    <nz-option nzValue="other" nzLabel="{{ 'other' | translate }}"></nz-option>
              </nz-select>
            </tc-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-6" *ngIf="userAccountData.isTrainingApplied">
            <tc-form-group>
              <tc-form-label>{{ "area" | translate }}</tc-form-label>
              <tc-input placeholder="{{ 'area' | translate }}"
                name="trainingArea" #trainingArea="ngModel" [(ngModel)]="userAccountData.trainingArea"></tc-input>
            </tc-form-group>
          </div>
          <div class="col col-md-6" *ngIf="userAccountData.facility === 'other'">
            <tc-form-group>
              <tc-form-label>{{ "namely" | translate }}</tc-form-label>
              <tc-input placeholder="{{ 'namely' | translate }}"
                name="otherFacility" #otherFacility="ngModel" [(ngModel)]="userAccountData.otherFacility"></tc-input>
            </tc-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Billed_travel_cost" | translate}}</tc-form-label>
              <tc-input type="number" placeholder="{{ 'Billed_travel_cost' | translate }}" pattern="(?:[0-9]+(?:\.[0-9]{0,2})?)?$"
                name="billedTravelCost" #billedTravelCost="ngModel" [(ngModel)]="userAccountData.billedTravelCost"
              ></tc-input>
              <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!billedTravelCost.valid && (billedTravelCost.dirty || billedTravelCost.touched)">
                    <p> {{'Invalid_input' | translate}} </p>
                  </tc-form-description>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Multiple_billing" | translate }}</tc-form-label>
              <tc-checkbox name="isMultipleBilling" #isMultipleBilling="ngModel" [(ngModel)]="userAccountData.isMultipleBilling">{{ "Multiple_billing" | translate }}</tc-checkbox>
            </tc-form-group>
          </div>
        </div>
        <tc-form-label>{{ 'Email_sent_to_user' | translate }}</tc-form-label>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-checkbox name="enableInPersonValidationEmail" #enableInPersonValidationEmail="ngModel" 
                  [(ngModel)]="userAccountData.enableInPersonValidationEmail">{{"INPERSON" | translate}}</tc-checkbox>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-checkbox name="enableVideoValidationEmail" #enableVideoValidationEmail="ngModel" 
                [(ngModel)]="userAccountData.enableVideoValidationEmail">{{"VIDEO" | translate }}</tc-checkbox>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-checkbox name="enableVoiceValidationEmail" #enableVoiceValidationEmail="ngModel" 
                [(ngModel)]="userAccountData.enableVoiceValidationEmail">{{ "TELEPHONE" | translate}}</tc-checkbox>
              </tc-form-group>
            </div>
          </div>
      </nz-tab>
      <nz-tab nzTitle="Invoicing Details">
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Invoice_recipient_name" | translate }}</tc-form-label>
              <tc-input placeholder="{{ 'Invoice_recipient_name' | translate }}"
                name="billingName" #billingName="ngModel" [(ngModel)]="userAccountData.billingName"></tc-input>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <div ngModelGroup="pdfBillingAddress">
              <tc-form-group>
                <tc-form-label>{{ "Invoice_recipient_address" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Invoice_recipient_address' | translate }}"
                  name="address" #billingaddress="ngModel" [(ngModel)]="userAccountData.pdfBillingAddress.address"
                ></tc-input>
              </tc-form-group>
            </div>
          </div>
        </div>
        <div ngModelGroup="pdfBillingAddress">
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Invoice_recipient_name_overflow" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Invoice_recipient_name_overflow' | translate }}"
                  name="nameOverFlow" #nameOverFlow="ngModel" [(ngModel)]="userAccountData.pdfBillingAddress.nameOverFlow">
                </tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{"Department" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Department' | translate }}"
                  name="department" #billingdepartment="ngModel" [(ngModel)]="userAccountData.pdfBillingAddress.department">
                </tc-input>
              </tc-form-group>
            </div>
          </div>
        </div>
        <div ngModelGroup="pdfBillingAddress">
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Invoice_recipient_address_street" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Invoice_recipient_address_street' | translate }}"
                  name="street" #street="ngModel" [(ngModel)]="userAccountData.pdfBillingAddress.street">
                </tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{"Invoice_recipient_address_house_number" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Invoice_recipient_address_house_number' | translate }}"
                  name="houseNumber" #houseNumber="ngModel" [(ngModel)]="userAccountData.pdfBillingAddress.houseNumber">
                </tc-input>
              </tc-form-group>
            </div>
          </div>
        </div>
        <div ngModelGroup="pdfBillingAddress">
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Invoice_recipient_address_postal" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Invoice_recipient_address_postal' | translate }}"
                  name="postal" #postal="ngModel" [(ngModel)]="userAccountData.pdfBillingAddress.postal">
                </tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Zipcode" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Zipcode' | translate }}" pattern="\s*(?:[0-9]\s*){0,100}$"
                  name="zipcode" #billingzipcode="ngModel" [(ngModel)]="userAccountData.pdfBillingAddress.zipCode">
                </tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!zipcode.valid && (zipcode.dirty || zipcode.touched)">
                    <p> {{'Invalid_Zipcode' | translate}} </p>
                  </tc-form-description>
              </tc-form-group>
            </div>
          </div>
        </div>
        <div ngModelGroup="pdfBillingAddress">
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{"Invoice_recipient_city" | translate }}</tc-form-label>
                <tc-input placeholder="{{ 'Invoice_recipient_city' | translate }}"
                  name="city" #billingcity="ngModel" [(ngModel)]="userAccountData.pdfBillingAddress.city">
                </tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Country" | translate }}</tc-form-label>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Country' | translate }}"
                    name="countryCode" #countryCode="ngModel" [(ngModel)]="userAccountData.pdfBillingAddress.countryCode">
                    <nz-option *ngFor="let item of countries" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
                </nz-select>
              </tc-form-group>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Email" | translate }}</tc-form-label>
              <tc-input placeholder="{{ 'Email' | translate }}" pattern="[a-zéÉèÈêÊöÖ]+[a-z0-9-._éÉèÈêÊöÖ]+@[a-z0-9-._]+\.[a-z]{2,20}"
                name="billingEmail" #billingEmail="ngModel" [(ngModel)]="userAccountData.billingEmail">
              </tc-input>
              <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!billingEmail.valid && (billingEmail.dirty || billingEmail.touched)">
                <p> {{'Invalid_email' | translate}} </p>
              </tc-form-description>
            </tc-form-group>
          </div>
        </div>
      </nz-tab>
    </nz-tabset>
    <div *nzModalFooter class="actions justify-content-between">
      <button tc-button [type]="'button'" [view]="'error'" (click)="closeModal()">
        {{ "Cancel" | translate }}
      </button>
      <button tc-button [view]="'info'" (click)="submitFormData()" [disabled]="!f.valid">
        {{ isMode === "create" ? ("Create" | translate) : ("Update" | translate) }}
      </button>
    </div>
  </form>
</nz-spin>
