import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef, NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { UtilityService } from '../../../shared/services/utility.service';
import { ModalDataService } from '../../../shared/services/modal-data.service';
import { SessionService } from '../sessions/sessions.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AddFilterComponent } from '../../userAccount/add-filter/add-filter.component';

@Component({
  selector: 'app-change-assign-interpreter',
  templateUrl: './change-assign-interpreter.component.html',
  styleUrls: ['./change-assign-interpreter.component.scss']
})
export class ChangeAssignInterpreterComponent implements OnInit, OnDestroy {
  session: any;
  loading = false;
  subscription: Subscription;
  interpreterList = [];
  selectedInterpreter: any;
  isResendSessionToAll = false;
  isVisible = false;
  role: string;
  gender: string;
  origin: string;
  qualification = [];
  allInterpretersId = [];
  resendInterpretersId = [];
  isFilterApplied = false;
  sendConfirmationEmail = {
    status: false
  };
  reasonToCancel = '';
  qualifications = [];
  isFirstTimeOpen = true;
  availableInterpreterList = [];

  constructor(
    private readonly modal: NzModalRef,
    private readonly modalDataService: ModalDataService,
    private readonly utilityService: UtilityService,
    private readonly sessionService: SessionService,
    private readonly notification: NzNotificationService,
    private readonly translateService: TranslateService,
    private readonly nzModalService: NzModalService,
  ) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    const modalData = this.modalDataService.getData();
    this.session = JSON.parse(JSON.stringify(modalData['session']));
    this.gender = this.session.sessionFilters ? this.session.sessionFilters.gender : '';
    this.origin = this.session.sessionFilters ? this.session.sessionFilters.origin : '';
    this.getInterpreters(this.session);
  }

  private getTranslations(key: string) {
    let translation;
    this.translateService.get(key).subscribe((res: string) => {
      translation = res;
    });
    return translation;
  }

  cancelModal(response?: any) {
    this.modal.destroy(response);
  }

  private getInterpreters(session: any) {
    this.loading = true;
    let queryParams;
    if (session.status === 'CONFIRMED') {
      this.checkGenderOrigin();
      queryParams = this.utilityService.createQueryParams({
        toLang: session.toLanguage.isoCode, fromLang: session.fromLanguage.isoCode,
        startTime: session.startTime, endTime: session.endTime, origin: this.origin, gender: this.gender
      });
      if (this.role === 'Admin') {
        queryParams += `&orgId=${session.organization.id}`;
      }
      queryParams += this.getValidateType(this.session.sessionType);
      if (this.isFilterApplied) {
        queryParams += '&qualificationSkillIds=' + this.qualification;
      }
    } else {
      queryParams = this.utilityService.createQueryParams({ sessionId: session.id });
    }
    this.subscription = this.sessionService.getPendingAssignInterpreters(queryParams).subscribe((resData) => {
      if (resData['isSuccess']) {
        this.interpreterList = resData['data'];
        if (session.status === 'CONFIRMED') {
          this.interpreterList.unshift({ fullName: this.getTranslations('All'), id: 0 });
        }
      } else {
        this.notification.error('Error', resData['message']);
      }
      this.loading = false;
    });
  }

  addFilter(action: string, data: any) {
    let modal;
    const interpreterIds = [];
    this.interpreterList.forEach((element) => {
      if (element.id !== 0) {
        interpreterIds.push({ id: element.id });
      }
    });
    const modalInfo = {
      action, userAccountId: this.session.userAccount.id, orgId: this.session.organization.id, toLanguage: this.session.toLanguage,
      interpreterIds, fromLanguage: this.session.fromLanguage
    };
    this.modalDataService.setData('modalInfo', modalInfo);
    this.modalDataService.setData('modalData', data);
    this.translateService.get(action).subscribe((title) => {
      modal = this.nzModalService.create({
        nzTitle: title, nzClosable: false,
        nzContent: AddFilterComponent,
        nzWrapClassName: 'vertical-center-modal', nzWidth: 1000
      });
    });
    modal.afterClose.subscribe((res) => {
      if (res) {
        this.loading = true;
        this.gender = res.gender;
        this.origin = res.origin;
        this.qualification = res.qualificationIds;
        this.getInterpreters(this.session);
      }
    });
  }

  onSelectDropdown(event: any) {
    if (typeof event !== 'number') {
      if (event[0] === 0) {
        const selectedValues = [];
        this.interpreterList.forEach((i) => {
          if (i.id !== 0) {
            selectedValues.push(i);
          }
        });
        this.allInterpretersId = selectedValues;
      }
      console.log(event);
    }
  }

  assignInterpreterToSession() {
    this.loading = true;
    this.subscription = this.sessionService.assignInterpreter(this.session.id, this.selectedInterpreter).subscribe((response) => {
      this.modalClose(response);
    });
  }

  updateInterpreterToSession() {
    this.loading = true;
    const details = {
      interpreterIds: this.allInterpretersId,
      sendMail: this.sendConfirmationEmail,
      reasonToCancel: this.reasonToCancel,
      sessionFilters: {
        qualification: this.qualification.toString(),
        gender: this.gender,
        origin: this.origin
      }
    };
    const queryParam = this.session.id + '/change-interpreter';
    this.subscription = this.sessionService.updateConfirmedInterpreter(queryParam, details).subscribe((res) => {
      this.modalClose(res);
    });
  }

  private modalClose(res: any) {
    if (res['isSuccess']) {
      this.modal.destroy(res['data']);
      this.notification.success('Success', res['message']);
    } else {
      this.notification.error('Error', res['message']);
    }
    this.loading = false;
  }

  isChangeCheckClicked() {
    this.loading = true;
    if (this.isResendSessionToAll) {
      let queryParams;
      this.checkGenderOrigin();
      queryParams = this.utilityService.createQueryParams({
        toLang: this.session.toLanguage.isoCode, fromLang: this.session.fromLanguage.isoCode,
        startTime: this.session.startTime, endTime: this.session.endTime, sessionId: this.session.id
      });
      if (this.role === 'Admin') {
        queryParams += `&orgId=${this.session.organization.id}`;
      }
      queryParams += this.getValidateType(this.session.sessionType);
      this.subscription = this.sessionService.getAvailableInterpreters(queryParams).subscribe((res) => {
        if (res['isSuccess']) {
          this.availableInterpreterList = res['data'];
          this.loading = false;
        } else {
          this.notification.error('Error', res['message']);
          this.loading = false;
        }
        this.isVisible = true;
      });
    }
  }

  handleOk(): void {
    this.loading = true;
    const details = {
      interpreterIds: this.resendInterpretersId,
      sessionFilters: {
        qualification: this.qualification.toString(),
        gender: this.gender,
        origin: this.origin
      }
    };
    this.sessionService.resendInvitationsInterpreter(this.session.id, details).subscribe((res) => {
      if (res['isSuccess']) {
        this.notification.success('Success', res['message']);
        this.isVisible = false;
        this.loading = false;
        this.modal.close(undefined);
      } else {
        this.loading = false;
        this.notification.error('Error', res['message']);
      }
    });
  }

  private checkGenderOrigin() {
    if (this.gender === 'notRelevant' || this.gender === 'all') {
      this.gender = '';
    }
    if (origin === 'notRelevant' || origin === 'all') {
      this.origin = '';
    }
  }

  private getValidateType(sessionType: string) {
    let valadityType;
    switch (sessionType) {
      case 'TELEPHONE':
        valadityType = '&voiceCallAvailability=true';
        break;
      case 'VIDEO':
        valadityType = '&videoCallAvailability=true';
        break;
      case 'INPERSON':
        valadityType = '&inPersonAvailability=true';
        break;
      case 'mediation':
        valadityType = '&mediationAvailability=true';
        break;
      case 'course':
        valadityType = '&courseAvailability=true';
        break;
    }
    return valadityType;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
