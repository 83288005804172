<!-- Warning Modal -->
<ng-container>
  <ng-template #modalBody>
    <p>Are you sure you want to delete {{'User_account' | translate}}?</p>
  </ng-template>
  <ng-template #modalFooter>
    <div class="actions justify-content-between">
      <button tc-button [view]="'error'" (click)="closeModal()">Close</button>
      <button tc-button [view]="'success'" (click)="submitWarningModalData(row)">Submit</button>
    </div>
  </ng-template>
</ng-container>
<div class="row search_row">
  <div class="col-md-7 search-col">
    <button tc-button [beforeIcon]="'icofont-plus-circle'" *ngIf="role !== 'Manager'" [view]="'accent'"
      (click)="openUserAccountFormService('Create_user_account' , null)">
      {{'Add_user_account' | translate}}
    </button>
    <button *ngIf="role !== 'Manager'" tc-button [beforeIcon]="'icofont-plus-circle'" [view]="'accent'" (click)="exportUserAccountData()">
      {{'Export_all_data' | translate}}
    </button>
    <button tc-button [beforeIcon]="'icofont-plus-circle'" *ngIf="role == 'IG Manager'"  [view]="'accent'" (click)="exportAllCustomerData()">
      {{'Xml_export_of_all_customer_data' | translate}}
    </button>
  </div>
  <div class="col-md-3 offset-md-2">
      <nz-select nzShowSearch 
      nzMode="multiple" nzPlaceHolder="'Show/Hide Table Column'" [(ngModel)] = "selectedColumns" 
      (ngModelChange)="onSelectcolumn($event)">
      <ng-container *ngFor="let item of columnList">
        <nz-option [nzValue]="item.value" [nzLabel]="item.text" [nzDisabled]="item.disabled"></nz-option>
      </ng-container>
    </nz-select>
  </div>
</div>
<nz-table
      #organizationTable
      nzShowSizeChanger
      [nzData]="userAccountData"
      [nzFrontPagination]="false"
      [nzLoading]="loading"
      [nzTotal]="totalUserAccount"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzScroll]="{ x: '1100px', y: '65vh' }"
      nzSize="small"
    >
      <thead>
        <tr>

          <th *ngIf="role === 'IG Manager' && columnList[6].selected" nzCustomFilter nzColumnKey="customerName" nzSortPriority="true" nzSortFn="true">{{ 'Customer_number' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.customerName.visible" [nzActive]="searchValue.customerName.value.length > 0" [nzDropdownMenu]="customerName">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
        
          <th *ngIf="role === 'Admin' && columnList[0].selected" nzCustomFilter nzColumnKey="firstName" nzSortPriority="true" nzSortFn="true">{{ 'Firstname' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.firstName.visible" [nzActive]="searchValue.firstName.value.length > 0" [nzDropdownMenu]="firstName">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="role === 'Admin' && columnList[1].selected" nzCustomFilter nzColumnKey="lastName" nzSortPriority="true" nzSortFn="true">{{ 'Lastname' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.lastName.visible" [nzActive]="searchValue.lastName.value.length > 0" [nzDropdownMenu]="lastName">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>

          <th *ngIf="role !== 'Admin' && columnList[7].selected" nzCustomFilter nzColumnKey="fullName" nzSortPriority="true" nzSortFn="true">{{ 'Name' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.fullName.visible" [nzActive]="searchValue.fullName.value.length > 0" [nzDropdownMenu]="fullName">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>

          <th *ngIf="role === 'Admin' && columnList[2].selected" nzCustomFilter nzColumnKey="email" nzSortPriority="true" nzSortFn="true">{{ 'Email' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.email.visible" [nzActive]="searchValue.email.value.length > 0" [nzDropdownMenu]="email">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="columnList[3].selected" nzCustomFilter nzColumnKey="organization" nzSortPriority="true" nzSortFn="true">{{ 'Organisation' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.organizationName.visible" [nzActive]="searchValue.organizationName.value.length > 0" [nzDropdownMenu]="organizationName">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>

          <th *ngIf="role !== 'Admin' && columnList[8].selected" nzCustomFilter nzColumnKey="address" nzSortPriority="true" nzSortFn="true">{{ 'Address' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.address.visible" [nzActive]="searchValue.address.value.length > 0" [nzDropdownMenu]="address">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="columnList[4].selected" nzCustomFilter nzColumnKey="department" nzSortPriority="true" nzSortFn="true">{{ 'Department' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.departmentName.visible" [nzActive]="searchValue.departmentName.value.length > 0" [nzDropdownMenu]="departmentName">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>

          <th *ngIf="role !== 'Admin' && columnList[9].selected" nzCustomFilter nzColumnKey="telephone" nzSortPriority="true" nzSortFn="true">{{ 'Telephone' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.telephone.visible" [nzActive]="searchValue.telephone.value.length > 0" [nzDropdownMenu]="telephone">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="columnList[5].selected" nzColumnKey="actions" nzRight nzWidth="150px">{{'Action' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of organizationTable.data">
          <td *ngIf="role === 'IG Manager' && columnList[6].selected">{{ data.customerNumber }}</td>
          <td *ngIf="role === 'Admin' && columnList[0].selected">{{ data.userAccountManager?.firstName }}</td>
          <td *ngIf="role === 'Admin' && columnList[1].selected">{{ data.userAccountManager.lastName }}</td>
          <td *ngIf="role !== 'Admin' && columnList[7].selected">{{ data.userAccountManager?.fullName }}</td>
          <td *ngIf="role === 'Admin' && columnList[2].selected">{{ data.userAccountManager?.email }}</td>
          <td *ngIf="columnList[3].selected">{{ data.organization?.name }}</td>
          <td *ngIf="role !== 'Admin' && columnList[8].selected">{{ data.userAccountManager?.address }}</td>
          <td *ngIf="columnList[4].selected">{{ data.department?.name }}</td>
          <td *ngIf="role !== 'Admin' && columnList[9].selected">{{ data.userAccountManager?.telephone }}</td>
          <td *ngIf="columnList[5].selected" nzRight nzWidth="150px">
            <a href="#" [routerLink] = "['/', 'user-account', 'view-user-account', data.id]" tc-button
            [afterIcon]="'icofont-info'" [view]="'info'" [square]="true" [tcShape]="500" [size]="'sm'"></a>
            <button *ngIf="role === 'Admin' || role === 'IG Manager'" tc-button [afterIcon]="'icofont-ui-edit'" [view]="'info'" [square]="true" [tcShape]="500" [size]="'sm'" (click)="openUserAccountFormService('Update_client', data)"></button>
            <button *ngIf="role === 'Admin' || role === 'IG Manager'" tc-button [afterIcon]="'icofont-ui-delete'" [view]="'error'" [square]="true" [tcShape]="500" [size]="'sm'" (click)="openWarningModalData(data)"></button>
          </td>
        </tr>
      </tbody>
</nz-table>

<nz-dropdown-menu #customerName="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.customerName.value" />
        <button nz-button nzSize="small" nzType="primary" (click)="search('customerName')" class="search-button">
          Search
        </button>
        <button nz-button nzSize="small" (click)="reset('customerName')">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #firstName="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.firstName.value" />
        <button nz-button nzSize="small" nzType="primary" (click)="search('firstName')" class="search-button">
          Search
        </button>
        <button nz-button nzSize="small" (click)="reset('firstName')">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
    <nz-dropdown-menu #lastName="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.lastName.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('lastName')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('lastName')">Reset</button>
        </div>
    </div>
</nz-dropdown-menu>
<nz-dropdown-menu #fullName="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.fullName.value" />
      <button nz-button nzSize="small" nzType="primary" (click)="search('fullName')" class="search-button">
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset('fullName')">Reset</button>
    </div>
</div>
</nz-dropdown-menu>
<nz-dropdown-menu #email="nzDropdownMenu">
    <div class="ant-table-filter-dropdown">
      <div class="search-box">
        <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.email.value" />
        <button nz-button nzSize="small" nzType="primary" (click)="search('email')" class="search-button">
          Search
        </button>
        <button nz-button nzSize="small" (click)="reset('email')">Reset</button>
      </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #organizationName="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.organizationName.value" />
      <button nz-button nzSize="small" nzType="primary" (click)="search('organizationName')" class="search-button">
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset('organizationName')">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #address="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.address.value" />
      <button nz-button nzSize="small" nzType="primary" (click)="search('address')" class="search-button">
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset('address')">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #departmentName="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.departmentName.value" />
      <button nz-button nzSize="small" nzType="primary" (click)="search('departmentName')" class="search-button">
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset('departmentName')">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #telephone="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.telephone.value" />
      <button nz-button nzSize="small" nzType="primary" (click)="search('telephone')" class="search-button">
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset('telephone')">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
