<!-- Warning Modal -->
<ng-container>
  <ng-template #modalBody>
    <p>Are you sure you want to delete {{'Session' | translate}}?</p>
  </ng-template>
  <ng-template #modalFooter>
    <div class="actions justify-content-between">
      <button tc-button [view]="'error'" (click)="closeModal()">{{'Close' | translate}}</button>
      <button tc-button [view]="'success'" (click)="submitWarningModalData()">{{'Submit' | translate}}</button>
    </div>
  </ng-template>
</ng-container>
<!-- Change Interpreter modal -->
<ng-container>
  <ng-template #changeInterpreterModalHeader>
    <h3>{{'Change_interpreter' | translate}}</h3>
  </ng-template>
  <ng-template #changeInterpreterModalBody>
    <nz-spin [nzSpinning]="assignInterpreterLoading">
      <div class="col-sm-6">
          <tc-form-label>{{'Interpreters' | translate}}</tc-form-label>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{'Select_interpreter' | translate}}" [(ngModel)] = "changedInterpreterId">
              <nz-option *ngFor="let item of changedInterpreterList" [nzLabel]="item.fullName" [nzValue]="item.id"></nz-option>
          </nz-select>
      </div>
    </nz-spin>
  </ng-template>
  <ng-template #changeInterpreterModalFooter>
    <div class="actions justify-content-between">
      <button tc-button [view]="'error'" (click)="closeModal()">{{'Close' | translate}}</button>
      <button tc-button [view]="'success'" (click)="changeInterpreter()">{{ 'Update' | translate}}</button>
    </div>
  </ng-template>
</ng-container>

<div class="row search_row">
  <div class="col-md-9 search-col">
    <button *ngIf="role !== 'Manager'" tc-button [beforeIcon]="'icofont-plus-circle'" [view]="'accent'" (click)="openSessionFormService('Create_session', null)">
      {{ "New_session" | translate }}
    </button>
    <button tc-button [beforeIcon]="'icofont-plus-circle'" [view]="'accent'" (click)="openExportModalService()">
      {{ "Export" | translate }}
    </button>
    <h5>Total Sessions = {{totalSessionCount}}</h5>
  </div>
  <div class="col-md-3">
    <nz-select nzShowSearch
      nzMode="multiple" nzPlaceHolder="'Show/Hide Table Column'" [(ngModel)] = "selectedColumns" 
      (ngModelChange)="onSelectcolumn($event)">
      <ng-container *ngFor="let item of columnList">
        <nz-option [nzValue]="item.value" [nzLabel]="item.text" [nzDisabled]="item.disabled"></nz-option>
      </ng-container>
    </nz-select>
  </div>
</div>
    <nz-table
      #sessionTable
      nzShowSizeChanger
      [nzData]="sessionData"
      [nzFrontPagination]="false"
      [nzLoading]="loading"
      [nzTotal]="totalSessionCount"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzScroll]="{ x: '1100px', y: '65vh' }"
      nzSize="small"
    >
      <thead>
        <tr>
          <th
            [nzSelections]="listOfSelection"
            [(nzChecked)]="checked"
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"
          ></th>
          <th *ngIf="columnList[0].selected" nzCustomFilter nzColumnKey="id" nzSortPriority="true" nzSortFn="true">{{ 'Session_id' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.id.visible" [nzActive]="searchValue.id.value.length > 0" [nzDropdownMenu]="id">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="role === 'IG Manager' && columnList[8].selected" nzCustomFilter nzColumnKey="createdDate" nzSortPriority="true" nzSortFn="true">{{ 'Created_date' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.createdDate.visible" [nzActive]="searchValue.createdDate.value.length > 0" [nzDropdownMenu]="createdDate">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="columnList[1].selected" nzCustomFilter nzColumnKey="bookedDate" nzSortPriority="true" nzSortFn="true">{{ 'Booked_date' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.bookedDate.visible" [nzActive]="searchValue.bookedDate.value.length > 0" [nzDropdownMenu]="bookedDate">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="role === 'Admin' && columnList[3].selected" nzCustomFilter nzColumnKey="updatedDate" nzSortPriority="true" nzSortFn="true">{{ 'Modified_date' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.modifiedDate.visible" [nzActive]="searchValue.modifiedDate.value.length > 0" [nzDropdownMenu]="modifiedDate">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="role === 'Admin' && columnList[11].selected"  nzCustomFilter nzColumnKey="fromLanguage" nzSortPriority="true" nzSortFn="true">{{ 'Language' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.fromLanguage.visible" [nzActive]="searchValue.fromLanguage.value.length > 0" [nzDropdownMenu]="fromLanguage">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="columnList[2].selected" nzCustomFilter nzColumnKey="toLanguage" nzSortPriority="true" nzSortFn="true">{{ 'Language_two' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.toLanguage.visible" [nzActive]="searchValue.toLanguage.value.length > 0" [nzDropdownMenu]="toLanguage">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="role === 'Admin' && columnList[12].selected" nzCustomFilter nzColumnKey="organization" nzSortPriority="true" nzSortFn="true">{{ 'Organization' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.organization.visible" [nzActive]="searchValue.organization.value.length > 0" [nzDropdownMenu]="organization">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="columnList[4].selected" nzCustomFilter nzColumnKey="userAccount" nzSortPriority="true" nzSortFn="true">{{ 'User_account' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.userAccount.visible" [nzActive]="searchValue.userAccount.value.length > 0" [nzDropdownMenu]="userAccount">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="columnList[5].selected" nzCustomFilter nzColumnKey="interpreter" nzSortPriority="true" nzSortFn="true">{{ 'Interpreter' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.interpreter.visible" [nzActive]="searchValue.interpreter.value.length > 0" [nzDropdownMenu]="interpreter">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="role === 'IG Manager' && columnList[11].selected" nzCustomFilter nzColumnKey="patient" nzSortPriority="true" nzSortFn="true">{{ 'Patient' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.patient.visible" [nzActive]="searchValue.patient.value.length > 0" [nzDropdownMenu]="patient">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="role === 'IG Manager' && columnList[12].selected" nzColumnKey="startTime" nzSortPriority="true" nzSortFn="true">{{ 'Start_time' | translate }}</th>
          <th *ngIf="columnList[6].selected" nzColumnKey="duration" nzSortPriority="true" nzSortFn="true">{{ 'Duration' | translate }}</th>
          <th *ngIf="role === 'IG Manager' && columnList[13].selected" nzColumnKey="sessionImage" nzSortPriority="true" nzSortFn="true">{{ 'Validation' | translate }}</th>
          <th *ngIf="columnList[7].selected" nzColumnKey="status" [nzFilters]="sessionStatus" nzFilterFn="true">{{ 'Status' | translate }}</th>
          <th *ngIf="columnList[13].selected" nzColumnKey="category" [nzFilters]="sessionTypes" nzFilterFn="true">{{ 'Type' | translate }}</th>
          <th *ngIf="columnList[9].selected" nzColumnKey="method" [nzFilters]="sessionMethods" nzFilterFn="true">{{'Method' | translate}}</th>
          <th *ngIf="columnList[10].selected" nzColumnKey="actions" nzRight nzWidth="210px">{{'Action' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of sessionTable.data">
          <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
          <td *ngIf="columnList[0].selected">{{ data.id }}</td>
          <td *ngIf="role === 'IG Manager' && columnList[8].selected">{{ data.createdDate | date }}</td>
          <td *ngIf="columnList[1].selected">{{ data.bookingDate | date}}</td>
          <td *ngIf="role === 'Admin' && columnList[3].selected">{{ data.updatedDate | date}}</td>
          <td *ngIf="role === 'Admin' && columnList[11].selected">{{ data.fromLanguage.name }}</td>
          <td *ngIf="columnList[2].selected">{{ data.toLanguage.name }}</td>
          <td *ngIf="role === 'Admin' && columnList[12].selected">{{ data.organization.name }}</td>
          <td *ngIf="columnList[4].selected">{{ data.userAccount?.userAccountManager?.firstName }} {{ data.userAccount?.userAccountManager?.lastName }}</td>
          <td *ngIf="columnList[5].selected">{{ data.interpreter?.firstName }} {{ data.firstName?.lastName }}</td>
          <td *ngIf="role === 'IG Manager' && columnList[11].selected">{{ data.patient?.isShowValue }}</td>
          <td *ngIf="role === 'IG Manager' && columnList[12].selected">{{ data.startTime }}</td>
          <td *ngIf="columnList[6].selected">{{ data.totalSessionTime | date: 'hh:mm:ss' }}</td>
          <td *ngIf="role === 'IG Manager' && columnList[13].selected">{{ data.sessionImage }}</td>
          <td *ngIf="columnList[7].selected"><tc-badge class="{{data.status}}" view="{{data.status}}">{{ data.status | translate }}</tc-badge></td>
          <td *ngIf="columnList[13].selected"><tc-badge [view]="data.sessionCategory === 'SCHEDULED' ? 'success' : 'error'">{{ data.sessionCategory | translate }}</tc-badge></td>
          <td *ngIf="columnList[9].selected"><tc-badge class="{{data.sessionType}}" view="{{data.sessionType}}">{{ data.sessionType | translate }}</tc-badge></td>
          <td nzRight nzWidth="210px" *ngIf="columnList[10].selected">
            <button tc-button [afterIcon]="'icofont-user-alt-3'" [tcColor]="'#000'" [tcBgColor]="'#fff'" [square]="true" [tcShape]="500" [size]="'sm'" (click)="openChangeInterpreterModal(data)"
            *ngIf="(data.status === 'PENDING' || data.status === 'CONFIRMED') && (data.status !== 'DELETED')"></button>
            <button tc-button [afterIcon]="'icofont-external-link'" [view]="'success'" [square]="true" [tcShape]="500" [size]="'sm'"
            (click)="openSessionFormService('View_session', data.id)"></button>
            <button tc-button [afterIcon]="'icofont-ui-edit'" [view]="'info'" [square]="true" [tcShape]="500" [size]="'sm'"
            *ngIf="(data.status === 'CONFIRMED' || data.status === 'CANCELLED' || data.status === 'COMPLETED' || data.status === 'STOPPED' || data.status === 'MISSED' || data.status === 'LATE RESCHEDULING' || data.status === 'LATE CANCELLATION') && (data.status !== 'DELETED')"
                (click)="openSessionFormService('Recalculate_sessions', data)"></button>
            <button tc-button [afterIcon]="'icofont-ui-delete'" [view]="'error'" [square]="true" [tcShape]="500" [size]="'sm'"
            *ngIf="(data.status !== 'IN PROGRESS') && (data.status !== 'TO VALIDATE') && (data.status !== 'STOPPED') && (data.status !== 'DELETED')"
                (click)="openWarningModalData(data)"></button>
            <button tc-button [afterIcon]="'icofont-rotation'" [view]="'info'" [square]="true" [tcShape]="500" [size]="'sm'"
            *ngIf="(data.status === 'CONFIRMED' || data.status === 'COMPLETED') && (data.status !== 'DELETED')"
                (click)="openSessionFormService('Follow_up_session', data)"></button>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <nz-dropdown-menu #id="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.id.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('id')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('id')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #createdDate="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.createdDate.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('createdDate')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('createdDate')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #bookedDate="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <nz-range-picker nz-input placeholder="Search" [(ngModel)]="searchValue.bookedDate.value"></nz-range-picker>
          <button nz-button nzSize="small" nzType="primary" (click)="search('bookedDate')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('bookedDate')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #modifiedDate="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <nz-range-picker nz-input placeholder="Search" [(ngModel)]="searchValue.modifiedDate.value"></nz-range-picker>
          <button nz-button nzSize="small" nzType="primary" (click)="search('modifiedDate')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('modifiedDate')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #fromLanguage="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.fromLanguage.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('fromLanguage')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('fromLanguage')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #toLanguage="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.toLanguage.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('toLanguage')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('toLanguage')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #organization="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.organization.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('organization')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('organization')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #userAccount="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.userAccount.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('userAccount')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('userAccount')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #interpreter="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.interpreter.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('interpreter')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('interpreter')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #patient="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.patient.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('patient')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('patient')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    