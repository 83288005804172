<nz-spin [nzSpinning]="loading">
  <form name="organizationForm" (ngSubmit)="f.form.valid && addOrganization()" #f="ngForm" novalidate>
    <nz-tabset>
      <nz-tab nzTitle="Personal Information">
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Organization" | translate}}<span class="req-icon">*</span></tc-form-label>
              <tc-input placeholder="{{ 'Organization' | translate }}" required
               #name="ngModel" name="name" [(ngModel)]="organizationData.name"></tc-input>
               <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!name.valid && (name.dirty || name.touched)">
                  {{ "Organizationname_required" | translate }}
                </tc-form-description>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"Internet_domain_name" | translate}}(i.e.google.com)</tc-form-label>
              <tc-input placeholder="{{'Internet_domain_name' | translate}}(i.e.google.com)"
                pattern="(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})"
                name="domainName" #domainName="ngModel" [(ngModel)]="organizationData.domainName"></tc-input>
              <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!domainName.valid && (domainName.dirty || domainName.touched)">
                {{ "Invalid_input" | translate }}
              </tc-form-description>
            </tc-form-group>
          </div>
        </div>
        <div ngModelGroup="manager">
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Firstname" | translate}}<span class="req-icon">*</span></tc-form-label>
                <tc-input placeholder="{{ 'firstName' | translate }}" required pattern = "[0-9 a-zA-Z-._äÄàÀéÉèÈöÖ]+$"
                  name="firstName" #firstName="ngModel" [(ngModel)]="organizationData.manager.firstName"
                ></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!firstName.valid && (firstName.dirty || firstName.touched)">
                    {{ "Firstname_required" | translate }}
                  </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Lastname" | translate}}<span class="req-icon">*</span></tc-form-label>
                <tc-input placeholder="{{ 'Lastname' | translate }}" required pattern = "[0-9 a-zA-Z-._äÄàÀéÉèÈöÖ]+$"
                  name="lastName" #lastName="ngModel" [(ngModel)]="organizationData.manager.lastName"
                ></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!lastName.valid && (lastName.dirty || lastName.touched)">
                  {{ "Invalid_input" | translate }}
                </tc-form-description>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Email" | translate}}<span class="req-icon">*</span></tc-form-label>
                <tc-input type="email" required email placeholder="{{ 'Email' | translate }}"
                  pattern = "\w+([-.,]?[\w-àäéèö]+)*@(\w+([-.]?\w+))\.\w{2,4}$"
                  name="email" #email="ngModel" [(ngModel)]="organizationData.manager.email"
                ></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!email.valid && (email.dirty || email.touched)">
                    {{ "Email_required" | translate }}
                </tc-form-description>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <div ngModelGroup="credentials">
                <tc-form-group>
                  <tc-form-label>{{ "User_id" | translate}}<span class="req-icon">*</span></tc-form-label>
                  <tc-input placeholder="{{ 'User_id' | translate }}" required pattern="[0-9 a-zA-Z-_.*]+$"
                    name="userId" #userId="ngModel" [(ngModel)]="organizationData.manager.credentials.userId"
                  ></tc-input>
                  <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!userId.valid && (userId.dirty || userId.touched)">
                    {{ "Email_required" | translate }}
                </tc-form-description>
                </tc-form-group>
              </div>
            </div>
          </div>
          <div ngModelGroup="passwords" appCheckPassword #passwords="ngModelGroup">
            <div class="row">
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{ "Password" | translate}}<span class="req-icon">*</span></tc-form-label>
                  <tc-input placeholder="{{ 'Password' | translate }}" type="password" [required] = "isMode === 'create'"
                    pattern="[0-9 a-zA-Z-_.*]+$" name="password" #password="ngModel"
                    [(ngModel)]="organizationData.manager.credentials.password"
                  ></tc-input>
                  <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!password.valid && (password.dirty || password.touched)">
                    {{ "Password_required" | translate }}
                </tc-form-description>
                </tc-form-group>
              </div>
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{ "Confirm_password" | translate}}<span class="req-icon">*</span></tc-form-label>
                  <tc-input type="password" placeholder="{{ 'Confirm_password' | translate }}" [required] = "isMode === 'create'"
                    name="confirmPassword" #confirmPassword="ngModel" ngModel
                  ></tc-input>
                  <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!confirmPassword.valid && (confirmPassword.dirty || confirmPassword.touched)">
                    <div *ngIf="confirmPassword.errors.required">{{'Confirm_password_required' | translate}}</div>
                  </tc-form-description>
                  <tc-form-description [tcColor]="'#e24d4d'" *ngIf="passwords.errors?.passwordCheck && (confirmPassword.dirty || confirmPassword.touched)">
                    {{'Password_not_match' | translate}}
                  </tc-form-description>
                </tc-form-group>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Address" | translate }}</tc-form-label>
                <tc-input
                  placeholder="{{ 'Address' | translate }}"
                  name="address" #address="ngModel" [(ngModel)]="organizationData.manager.address"
                ></tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "City" | translate }}</tc-form-label>
                <tc-input
                  placeholder="{{ 'City' | translate }}" pattern="([a-zA-Z éÉèÈöÖ])*$"
                  name="city" #city="ngModel" [(ngModel)]="organizationData.manager.city"
                ></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!city.valid && (city.dirty || city.touched)">
                  {{ "Invalid_input" | translate }}
                </tc-form-description>
              </tc-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Postal_code" | translate }}</tc-form-label>
                <tc-input
                  placeholder="{{ 'Postal_code' | translate }}"
                  name="zipcode" #zipcode="ngModel" [(ngModel)]="organizationData.manager.zipcode"
                ></tc-input>
              </tc-form-group>
            </div>
            <div class="col col-md-6">
              <tc-form-group>
                <tc-form-label>{{ "Telephone" | translate }}</tc-form-label>
                <tc-input type="number" placeholder="{{ 'Telephone' | translate }}" minlength="10" maxlength="10"
                  name="telephone" #telephone="ngModel" [(ngModel)]="organizationData.manager.telephone"
                ></tc-input>
                <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!telephone.valid && (telephone.dirty || telephone.touched)">
                  {{ "Invalid_input" | translate }}
                </tc-form-description>
              </tc-form-group>
            </div>
          </div>
          <div ngModelGroup="country">
            <div class="row">
              <div class="col col-md-6">
                <tc-form-group>
                  <tc-form-label>{{ "Country" | translate}}<span class="req-icon">*</span></tc-form-label>
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{ 'Country' | translate }}" required
                    name="iso" #iso="ngModel" [(ngModel)]="organizationData.manager.country.iso">
                    <nz-option *ngFor="let item of countries" [nzValue]="item.iso" [nzLabel]="item.name"></nz-option>
                  </nz-select>
                  <tc-form-description [tcColor]="'#e24d4d'" *ngIf="!iso.valid && (iso.dirty || iso.touched)">
                    <p>{{ "Country_is_required" | translate }}</p>
                  </tc-form-description>
                </tc-form-group>
              </div>
            </div>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Settings">
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group class="mb-md-0">
              <nz-radio-group name="kmsLimit" #kmsLimit="ngModel" [(ngModel)]="organizationData.kmsLimit">
                <label nz-radio nzValue="unlimitedKm">Unlimited KMs</label>
                <label nz-radio nzValue="KmLimit">KM limit</label>
              </nz-radio-group>
            </tc-form-group>
            <tc-form-group *ngIf="organizationData.kmsLimit === 'KmLimit'" style="margin-top: 1rem">
              <tc-input name="inPersonSessionDistanceLimit" #inPersonSessionDistanceLimit="ngModel" maxlength="4" pattern="\d+$"
              [(ngModel)]="organizationData.inPersonSessionDistanceLimit" type="number" required></tc-input>
              <tc-form-description [tcColor]="'#e24d4d'" 
                *ngIf="!inPersonSessionDistanceLimit.valid && (inPersonSessionDistanceLimit.dirty || inPersonSessionDistanceLimit.touched)">
                <p>{{ "Input_required" | translate }}</p>
              </tc-form-description>
            </tc-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"Show_interpreter_telephone" | translate}}</tc-form-label>
              <tc-checkbox name="isInterpreterTelephoneShow" #isInterpreterTelephoneShow="ngModel" 
              [(ngModel)]="organizationData.isInterpreterTelephoneShow">{{"Show_interpreter_telephone" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6" *ngIf="organizationData.isInterpreterTelephoneShow">
            <tc-form-group>
              <tc-form-label>{{ "Show_telephone_If_Interpreter_Available" | translate }}</tc-form-label>
              <tc-checkbox name="interpreterAvailabilityDependent" #interpreterAvailabilityDependent="ngModel" 
              [(ngModel)]="organizationData.interpreterAvailabilityDependent">{{ "Show_telephone_If_Interpreter_Available" | translate }}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Show_interpreter_address" | translate}}</tc-form-label>
              <tc-checkbox name="isInterpreterAddressShow" #isInterpreterAddressShow="ngModel" 
              [(ngModel)]="organizationData.isInterpreterAddressShow">{{"Show_interpreter_address" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "show_interpreter_email" | translate }}</tc-form-label>
              <tc-checkbox name="isInterpreterEmailShow" #isInterpreterEmailShow="ngModel" 
              [(ngModel)]="organizationData.isInterpreterEmailShow">{{ "show_interpreter_email" | translate }}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"showSessionsInUser" | translate}}</tc-form-label>
              <tc-checkbox name="isUserAllowed" #isUserAllowed="ngModel" 
              [(ngModel)]="organizationData.isUserAllowed">{{"showSessionsInUser" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"show_intercultural_mediation" | translate }}</tc-form-label>
              <tc-checkbox name="isInterculturalMediationSessionEnabled" #isInterculturalMediationSessionEnabled="ngModel" 
              [(ngModel)]="organizationData.isInterculturalMediationSessionEnabled">{{ "show_intercultural_mediation" | translate }}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"show_course_management" | translate}}</tc-form-label>
              <tc-checkbox name="isCourseManagementSessionEnabled" #isCourseManagementSessionEnabled="ngModel" 
              [(ngModel)]="organizationData.isCourseManagementSessionEnabled">{{"show_course_management" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"show_Session_Enable_Validation" | translate}}</tc-form-label>
              <tc-checkbox name="isValidationEnabled" #isValidationEnabled="ngModel" 
              [(ngModel)]="organizationData.isValidationEnabled">{{ "show_Session_Enable_Validation" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"enableFollowupMediation" | translate}}</tc-form-label>
              <tc-checkbox name="enableFollowupMediation" #enableFollowupMediation="ngModel" 
              [(ngModel)]="organizationData.enableFollowupMediation">{{ "enableFollowupMediation" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"enableFollowupCourse" | translate}}</tc-form-label>
              <tc-checkbox name="enableFollowupCourse" #enableFollowupCourse="ngModel" 
              [(ngModel)]="organizationData.enableFollowupCourse">{{ "enableFollowupCourse" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>

          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"originAndGenderRequired" | translate}}</tc-form-label>
              <tc-checkbox name="isOriginGenderRequired" #isOriginGenderRequired="ngModel" 
              [(ngModel)]="organizationData.isOriginGenderRequired">{{ "originAndGenderRequired" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>

          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"enableShortestDuration" | translate}}</tc-form-label>
              <tc-checkbox name="enableDuration" #enableDuration="ngModel" 
              [(ngModel)]="organizationData.enableDuration">{{ "enableShortestDuration" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>

          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"showNotAvailableInterpreters" | translate}}</tc-form-label>
              <tc-checkbox name="showNotAvailableInterpreters" #showNotAvailableInterpreters="ngModel" 
              [(ngModel)]="organizationData.showNotAvailableInterpreters">{{ "showNotAvailableInterpreters" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>

          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"showInterpreterExportsInOrganizationAdminDashboard" | translate}}</tc-form-label>
              <tc-checkbox name="showInterpretersExport" #showInterpretersExport="ngModel" 
              [(ngModel)]="organizationData.showInterpretersExport">{{ "showInterpreterExportsInOrganizationAdminDashboard" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"isSessionLimitAppliedUsers" | translate}}</tc-form-label>
              <tc-checkbox name="isSessionLimitAppliedUsers" #isSessionLimitAppliedUsers="ngModel" 
              [(ngModel)]="organizationData.isSessionLimitAppliedUsers">{{ "isSessionLimitAppliedUsers" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"showPatientOrigin" | translate }}</tc-form-label>
              <tc-checkbox name="isPatientOriginRequired" #isPatientOriginRequired="ngModel" 
              [(ngModel)]="organizationData.isPatientOriginRequired">{{"showPatientOrigin" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"patientOriginMandatory" | translate }}</tc-form-label>
              <tc-checkbox name="isPatientOriginMandatory" #isPatientOriginMandatory="ngModel" 
              [(ngModel)]="organizationData.isPatientOriginMandatory">{{"patientOriginMandatory" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"isFollowUpNotAllowed" | translate}}</tc-form-label>
              <tc-checkbox name="isFollowUpNotAllowed" #isFollowUpNotAllowed="ngModel" 
              [(ngModel)]="organizationData.isFollowUpNotAllowed">{{"isFollowUpNotAllowed" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"isLocationRequired" | translate }}</tc-form-label>
              <tc-checkbox name="isLocationMandatory" #isLocationMandatory="ngModel" 
              [(ngModel)]="organizationData.isLocationMandatory">{{"isLocationRequired" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"showOtherQualifications" | translate }}</tc-form-label>
              <tc-checkbox name="showOtherQualifications" #showOtherQualifications="ngModel" 
              [(ngModel)]="organizationData.showOtherQualifications">{{"showOtherQualifications" | translate}}</tc-checkbox>
            </tc-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Time_delay_in_minutes" | translate }}</tc-form-label>
              <tc-input placeholder="{{ 'Time_delay_in_minutes' | translate }}" type="number"
                name="timeDelay" #timeDelay="ngModel" [(ngModel)]="organizationData.timeDelay">
              </tc-input>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{"Decimal_point_constant" | translate }}</tc-form-label>
              <tc-input placeholder="{{ 'Decimal_point_constant' | translate }}" type="number"
                name="zConstant" #zConstant="ngModel" [(ngModel)]="organizationData.zConstant"></tc-input>
            </tc-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>MyCompany {{ "baseTariffItemNumber" | translate }}</tc-form-label>
              <tc-input type="number"
                name="mycBaseTarifNo" #mycBaseTarifNo="ngModel" [(ngModel)]="organizationData.mycBaseTarifNo">
              </tc-input>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>MyCompany {{ "reducedTariffItemNumber" | translate }}</tc-form-label>
              <tc-input type="number" name="mycReducedTarifNo" #mycReducedTarifNo="ngModel" [(ngModel)]="organizationData.mycReducedTarifNo"
              ></tc-input>
            </tc-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Address_Key" | translate }}</tc-form-label>
              <tc-input placeholder="{{ 'Address_Key' | translate }}"
                name="addressKey" #addressKey="ngModel" [(ngModel)]="organizationData.addressKey"
              ></tc-input>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-form-label>{{ "Travel_cost_number" | translate }}</tc-form-label>
              <tc-input placeholder="{{ 'Travel_cost_number' | translate }}"
              name="travelCostItemNumber" #travelCostItemNumber="ngModel" [(ngModel)]="organizationData.travelCostItemNumber"
              ></tc-input>
            </tc-form-group>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Qualification" *ngIf="qualifications.length > 0 && isMode === 'update'">
        <div class="row">
          <div class="col col-md-6">
            <tc-form-label>{{ "qualification_default" | translate }}</tc-form-label>
          </div>
          <div class="col col-md-6">
            <tc-form-label>{{ "fixedQualification" | translate }}</tc-form-label>
          </div>
        </div>
        <div class="row" *ngFor="let item of qualifications; let i=index">
          <div class="col col-md-6">
            <tc-form-group>
              <tc-checkbox name="qualifications{{i}}.isSelected" [(ngModel)]="item.isSelected">{{ item.label }}</tc-checkbox>
            </tc-form-group>
          </div>
          <div class="col col-md-6">
            <tc-form-group>
              <tc-checkbox name="qualifications{{i}}.isFixedQualification" [(ngModel)]="item.isFixedQualificationEnabled">{{ "isFixedQualification" | translate }}</tc-checkbox>
            </tc-form-group>
          </div>
        </div>
      </nz-tab>
    </nz-tabset>
    <div *nzModalFooter class="actions justify-content-between">
      <button tc-button [type]="'button'" [view]="'error'" (click)="closeModal(false)">
        {{ "Cancel" | translate }}
      </button>
      <button tc-button [view]="'info'" (click)="addOrganization()" [disabled]="!f.valid">
        {{ isMode === "create" ? ("Create" | translate) : ("Update" | translate) }}
      </button>
    </div>
  </form>
</nz-spin>
