import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ViewUserAccountService } from '../view-user-account/view-user-account.service';
import { NzModalRef, NzNotificationService } from 'ng-zorro-antd';
import { ModalDataService } from '../../../shared/services/modal-data.service';
import { Patient } from './patient.interface';

@Component({
  selector: 'app-add-new-patient',
  templateUrl: './add-new-patient.component.html',
  styleUrls: ['./add-new-patient.component.scss'],
})
export class AddNewPatientComponent implements OnInit, OnDestroy {
  @ViewChild('f', { static: false }) patientForm: NgForm;
  subscriber: Subscription;
  patientFormData: any[];
  userAccountId: number;
  isMode = 'create';
  patientData = new Patient();
  changedValues: any;
  prefixData = [
    { label: 'Mr', value: 'Mr' },
    { label: 'Mrs', value: 'Mrs' },
  ];
  loading = false;
  patientModalData: any;

  constructor(
    private readonly viewUserService: ViewUserAccountService,
    private readonly notification: NzNotificationService,
    private readonly modal: NzModalRef,
    private readonly modalDataService: ModalDataService,
  ) {}

  ngOnInit() {
    const modalData = this.modalDataService.getData();
    this.patientModalData = modalData['modalInfo']['modalData'];
    this.patientFormData = modalData['modalInfo']['formData'];
    this.userAccountId = modalData['modalInfo']['userAccountId'];
    if (modalData['modalData']) {
      this.isMode = 'update';
      this.patientData = modalData['modalData']['patient'];
    } else {
      this.isMode = 'create';
    }
  }

  // Close Location Modal
  public closePatientModal(response?: any) {
    this.modal.destroy(response);
    this.patientForm.reset();
  }

  // submit patient data
  submitPatientData() {
    this.loading = true;
    if (this.isMode === 'create') {
      const patientData = {
        patient: this.patientData,
        userAccount: { id : this.userAccountId }
      };
      this.subscriber = this.viewUserService.addPatient(patientData).subscribe(response => {
        this.responseMessage(response);
        this.loading = false;
      });
    } else {
      const patientData = {
        patient: this.patientData,
        id: this.patientData.id,
        userAccount: {id : this.userAccountId}
      };
      this.subscriber = this.viewUserService.updatePatient(patientData).subscribe(response => {
        this.responseMessage(response);
        this.loading = false;
      });
    }
  }

  private responseMessage(res: any) {
    if (res['isSuccess']) {
      this.patientData = null;
      this.notification.success('success', res['message']);
      this.closePatientModal({res: res['data'], responseFor: 'Patient'});
    }
  }

  ngOnDestroy() {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }
}
