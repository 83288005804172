import { environment } from '../../../../environments/environment';


export const urls = {
    getSession: 'bhaasha-session',
    recalculate: 'book-sessions/',
    updateRecalculate: 'updatesessiontime?totalSessionTime=',
    sessionCost : 'sessions/updatesessioncost/',
    sessionMail: 'sessions/mail/'
};

export const oldUrls = {
    getSession: 'v4/bhaasha-session',
    recalculate: 'v4/book-sessions/',
    updateRecalculate: 'updatesessiontime?totalSessionTime=',
    sessionCost : 'v4/sessions/updatesessioncost/',
    sessionMail: 'v4/sessions/mail/'

};
export const session = {
    getUrl(urlName: string) {
       if (environment.backend === 'old') {
           return oldUrls[urlName];
       } else {
           return urls[urlName];
       }
   }
};
