import { environment } from '../../../../environments/environment';

export const urls = {
    getUsers: 'user-accounts/',
    getLocation : 'user-account/',
    patientForm: 'patient/form/',
    users: 'users',
    locations: 'user-account/locations',
    getUser: 'admin/users'
};

export const oldUrls = {
    getUsers: 'v4/user-accounts/',
    getLocation : 'v4/user-account/',
    patientForm: 'v4/patient/form/',
    users: 'v4/users',
    locations: 'v4/user-account/locations',
    getUser: 'v5/admin/users'
};
export const viewUserAccount = {
    getUrl(urlName: string) {
       if (environment.backend === 'old') {
           return oldUrls[urlName];
       } else {
           return urls[urlName];
       }
   }
};
