import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { Observable } from 'rxjs';
import { assignInterpreter } from './interpreter.path';

@Injectable({
  providedIn: 'root'
})
export class AssignInterpreterService {

  public constructor(private readonly httpClientService: HttpClientService) {
  }
  // Get all Interpreters
  public getInterpreters(query: string): Observable<{}>  {
    return this.httpClientService.getServerRequest(assignInterpreter.getUrl('getInterpreter') + query);
  }

  // Get Single Interpreters
  public getInterpreter(interpreterId: string): Observable<{}>  {
    return this.httpClientService.getServerRequest(`${assignInterpreter.getUrl('createInterpreter')}/${interpreterId}`);
  }

  // Export all interpreters
  public exportAllInterpreters(exportQuery: string): Observable<{}> {
    return this.httpClientService.getServerRequest(assignInterpreter.getUrl('exportAllData') + exportQuery);
  }

  // Delete Interpreter
  public deleteInterpreter(interpreterId: number): Observable<{}> {
    return this.httpClientService.deleteServerRequest(`${assignInterpreter.getUrl('createInterpreter')}/${interpreterId}`);
  }

  // Search Filtered Text
  public searchTextFilter(query: string): Observable<{}> {
    return this.httpClientService.getServerRequest(assignInterpreter.getUrl('searchTextFilter') + query);
}

// Get Filterd interpreter list
public getFilterInterpreterList(userQuery: string): Observable<{}> {
  return this.httpClientService.getServerRequest(`${assignInterpreter.getUrl('getInterpreter')}${userQuery}`);
}

// get interpreters-pre-requisite-data
public preRequisiteData() {
  return this.httpClientService.getServerRequest(`${assignInterpreter.getUrl('preRequisiteData')}interpreters-pre-requisite-data`);
}

// create Interpreter
public createInterpreter(interpreterData: object): Observable<{}>  {
  return this.httpClientService.postServerRequest(assignInterpreter.getUrl('createInterpreter') , interpreterData);
}
public updateInterpreter(interpreterData: object , interpreterId: number): Observable<{}>  {
  return this.httpClientService.putServerRequest(`${assignInterpreter.getUrl('createInterpreter')}/${interpreterId}`, interpreterData );
}
/************************************************/
  public getUnassignedInterpreters(query: string): Observable<{}>  {
    return this.httpClientService.getServerRequest(assignInterpreter.getUrl('getunassignedInterpreter') + query);
  }
  public getEmailsOfInterpreters(): Observable<{}> {
    return this.httpClientService.getServerRequest(assignInterpreter.getUrl('getInterpreterEmails'));
}
}
