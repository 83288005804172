import { environment } from '../../../../environments/environment';


export const urls = {
    userAccounts: 'user-accounts',
    userAccount: 'user-accounts/',
    organizations: 'organizations/',
    interpreters: 'interpreters/',
    useraccount: 'user-account/',
    patientForm: 'patient/form/',
    createSession: 'session',
    paymentAuthority: 'paymentAuthorities/org/',
    organization: 'org/',
    updateSession : 'sessions/updatesessiondetails/',
    followUpSession: 'followup-session',
    getlabelbyorg: 'label/getlabelbyorg/',
    countries: 'countries',
};

export const oldUrls = {
    userAccounts: 'v4/user-accounts',
    userAccount: 'v4/user-account/',
    organizations: 'v4/organizations/',
    interpreters: 'v4/interpreters/',
    useraccount: 'v4/user-account/',
    patientForm: 'v4/patient/form/',
    createSession: 'v4/session',
    paymentAuthority: 'v4/paymentAuthorities/org/',
    organization: 'v4/org/' ,
    updateSession : 'v4/sessions/updatesessiondetails/',
    followUpSession: 'v4/followup-session',
    getlabelbyorg: 'v4/label/getlabelbyorg/',
    countries: 'v4/countries',
};
export const newSessionPath = {
    getUrl(urlName: string) {
       if (environment['backend'] === 'old') {
           return oldUrls[urlName];
       } else {
           return urls[urlName];
       }
   }
};
