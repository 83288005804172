import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: "page-sign-up",
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class PageSignUpComponent implements OnInit, OnDestroy {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  ngOnInit() {
    this.document.body.classList.add('register-page');
  }

  ngOnDestroy() {
    this.document.body.classList.remove('register-page');
  }
}
