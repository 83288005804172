export class Session {
    sessionType: string;
    status: string;
    userAccount = {
        id: 0
    };
    toLanguage = {
        isoCode: ''
    };
    interpreterList: any;
    dt = new Date();
    st = new Date(Math.ceil(new Date().getTime() / (1000 * 60 * 15)) * (1000 * 60 * 15));
    startTime: number;
    duration: number;
    formattedAddress = '';
    owner = {
        id: ''
    };
    title: string;
    commentsToInterpreter: string;
    purpose = '';
    user = {
        id: ''
    };
    patient = {
        id: ''
    };
    otherUser = {
        id: ''
    };
    doctor = {
        id: ''
    };
    paymentAuthorityOption = {
        id: 0
    };
    categoryOption = {
        id: 0
    };
    locationDetails = '';
    internalComments = '';
    personInvolvedInConversation: string;
    otherInvolvedPositions: string;
    reasonForConversation: string;
    goalOfConversation: string;
    expectationOfMediation: string;
    organization: any;
    id: number;
    longitude: number;
    latitude: number;
    bookingType = 'BOOK';
    sessionCategory = 'SCHEDULED';
    fromLanguage = {
        isoCode: 'English'
    };
    origin = '';
    gender = '';
    qualificationSkillId = [];
    totalSessionTime: number;
    endTime: any;
    paymentAuthorityOptionId: number;
    categoryOptionId: number;
    sessionStartTime: any;
    sessionEndTime: any;
    bookingDate: number;
    interpreter: any;
    sessionFilters = {
        gender: '',
        origin: '',
        qualification: ''
    };
}
