<nz-spin [nzSpinning]="loading">
  <div class="row">
    <div class="col-sm-6">
      <tc-form-label>{{ "Gender" | translate }}</tc-form-label>
      <nz-select nzPlaceHolder='{{ "Gender" | translate }}' [(ngModel)] = "gender">
        <nz-option [nzValue]="" nzLabel="{{'All' | translate}}"></nz-option>
        <nz-option nzValue="FEMALE" nzLabel="{{'Female' | translate}}"></nz-option>
        <nz-option nzValue="MALE" nzLabel="{{'Male' | translate}}"></nz-option>
      </nz-select>
    </div>
    <div class="col-sm-6">
      <tc-form-label>{{ "Origin" | translate }}</tc-form-label>
      <nz-select nzPlaceHolder='{{ "Origin" | translate }}' [(ngModel)] = "selectedOrigin">
        <nz-option [nzValue]="" nzLabel="{{'All' | translate}}"></nz-option>
        <ng-container *ngFor="let item of origin">
          <nz-option [nzValue]="item.iso" [nzLabel]="item.name"></nz-option>
        </ng-container>
      </nz-select>
    </div>
  </div>
  <div class="row" style="margin-top: 1rem;">
    <div class="col-md-12">
      <tc-form-label>{{ "Qualifications" | translate }}</tc-form-label>
    </div>
  </div>
  <div class="row" *ngFor="let item of qualifications; let i=index">
    <div class="col col-md-12">
      <tc-form-group>
        <tc-checkbox name="item.id" [(ngModel)]="item.isSelected">{{ item.label }}</tc-checkbox>
      </tc-form-group>
    </div>
  </div>
  <div *nzModalFooter>
    <button tc-button [type]="'button'" [view]="'error'" (click)="closeModal()">{{ "Cancel" | translate }}</button>
    <button tc-button [view]="'info'" (click)="filterInterpreters()">{{ "Filter" | translate }}</button>
  </div>
</nz-spin>
