import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from '../../../shared/services/common.service';
import { map } from 'rxjs/operators';
import { DataServiceService } from '../../../shared/services/data-share.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { NewUserAccountService } from './add-user-account.service';
import { NzModalRef, NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { TranslateService } from '@ngx-translate/core';
import { ModalDataService } from '../../../shared/services/modal-data.service';
import { UserAccount } from './add-user-account.interface';
import { AddLocationComponent } from '../add-location/add-location.component';

@Component({
  selector: 'app-add-user-account',
  templateUrl: './add-user-account.component.html',
  styleUrls: ['./add-user-account.component.scss'],
})
export class AddUserAccountComponent implements OnInit, OnDestroy {
  isLoading = false;
  @ViewChild('f', { static: false }) userAccount: NgForm;
  userAccountData = new UserAccount();
  countries = [];
  canton = [];
  departmentList = [];
  branches = [];
  branchOptions = [];
  branchSubOptions = [];
  subscriber: Subscription;
  isMode = 'create';
  departmentButton = false;
  isUserAccount = true;
  localLanguage: string;
  organizationList = [];
  orgData: any;
  changedValues: any;
  role: string;
  jobList = [];
  showSubBranch = false;
  locationData: any;
  selectedUserData = { orgId: '', userId: '' };

  constructor(
    private readonly commonService: CommonService,
    private readonly dataShareService: DataServiceService,
    private readonly utilityService: UtilityService,
    private readonly userAccountService: NewUserAccountService,
    private readonly notification: NzNotificationService,
    private readonly translateService: TranslateService,
    private readonly modal: NzModalRef,
    private readonly modalDataService: ModalDataService,
    private readonly nzModalService: NzModalService,
  ) { }

  ngOnInit() {
    const userAccount = this.modalDataService.getData();
    this.onInitFunctions();
    if (userAccount['modalData'].orgData) {
      this.isUserAccount = false;
      this.userAccountData.organization = userAccount['modalData'].orgData;
    }
    let departmentObs: Observable<any>;
    this.isLoading = true;
    if (userAccount['addEditUserAccount']) {
      this.isMode = 'update';
      this.userAccountData = JSON.parse(JSON.stringify(userAccount['addEditUserAccount']));
      this.onEditInitValues();
      departmentObs = this.getDepartment(this.userAccountData.organization['id']);
      this.departmentButton = false;
    } else {
      this.isMode = 'create';
      if (userAccount['modalData'].orgData) {
        departmentObs = this.getDepartment(userAccount['modalData'].orgData.id);
      } else {
        this.departmentButton = true;
        this.getOrganizationList();
      }
    }
    if (userAccount['modalData'].orgData) {
      this.orgData = userAccount['modalData'].orgData;
    }
    if (this.isMode === 'update' || userAccount['modalData'].orgData) {
      this.subscriber = departmentObs.subscribe((response) => {
        this.departmentList = response.data;
        this.isLoading = false;
      });
    } else {
      this.isLoading = false;
    }
  }

  private onInitFunctions() {
    this.localLanguage = localStorage.getItem('transLang');
    this.role = localStorage.getItem('role');
    this.getListOfCountries();
    this.getCountryCode();
    this.getCantonData();
    this.getBranches();
    this.fillJobList();
  }

  private onEditInitValues() {
    if (this.userAccountData.branchOption) {
      this.userAccountData.branch = this.userAccountData.branchOption['branch'];
    } else {
      Object.assign(this.userAccountData, { branch: { id: 0 } });
      Object.assign(this.userAccountData, { branchOption: { id: 0 } });
    }
    if (!this.userAccountData.branchOptionSubOption) {
      Object.assign(this.userAccountData, { branchOptionSubOption: { id: 0 } });
    }
    if (!this.userAccountData.userAccountManager.country) {
      Object.assign(this.userAccountData.userAccountManager, { country: { iso: '' } });
    }
    if (!this.userAccountData.department) { Object.assign(this.userAccountData, { department: { id: '' } }); }
    if (!this.userAccountData.pdfBillingAddress) { Object.assign(this.userAccountData, { pdfBillingAddress: { address: '' } }); }
    if (this.userAccountData.branchOption) { this.onSelectBranch(this.userAccountData.branch.id, true); }
    if (this.userAccountData.branchOptionSubOption) {
      this.onSelectSubBranch(this.userAccountData.branchOption.id, this.userAccountData.branchOption, true);
    }
  }

  private fillJobList() {
    this.jobList = [{ value: 'ophthalmology', label: this.getTranslations('ophthalmology') },
    { value: 'surgery', label: this.getTranslations('surgery') }, { value: 'obstetrics', label: this.getTranslations('obstetrics') },
    { value: 'otorhinolaryngology', label: this.getTranslations('otorhinolaryngology') },
    { value: 'dermatology', label: this.getTranslations('dermatology') },
    { value: 'internalMedicine', label: this.getTranslations('internalMedicine') },
    { value: 'paediatrics', label: this.getTranslations('paediatrics') },
    { value: 'pulmonary', label: this.getTranslations('pulmonary') },
    { value: 'microbiology', label: this.getTranslations('microbiology') }, { value: 'oral', label: this.getTranslations('oral') },
    { value: 'neurosurgery', label: this.getTranslations('neurosurgery') },
    { value: 'neurology', label: this.getTranslations('neurology') },
    { value: 'orthopaedics', label: this.getTranslations('orthopaedics') },
    { value: 'psychosomatic', label: this.getTranslations('psychosomatic') },
    { value: 'radiology', label: this.getTranslations('radiology') }, { value: 'disorders', label: this.getTranslations('disorders') },
    { value: 'radiotherapy', label: this.getTranslations('radiotherapy') },
    { value: 'urology', label: this.getTranslations('urology') }, { value: 'psychiatrist', label: this.getTranslations('psychiatrist') },
    { value: 'psychologist', label: this.getTranslations('psychologist') },
    { value: 'practitioner', label: this.getTranslations('practitioner') },
    { value: 'psychologicalPsychotherapis', label: this.getTranslations('psychologicalPsychotherapis') },
    { value: 'psychologistTraining', label: this.getTranslations('psychologistTraining') },
    { value: 'physicianNeurology', label: this.getTranslations('physicianNeurology') },
    { value: 'physicianMedicine', label: this.getTranslations('physicianMedicine') },
    ];
  }
  private getTranslations(key: string) {
    let translation;
    this.translateService.get(key).subscribe((res: string) => {
      translation = res;
    });
    return translation;
  }
  // get country code
  private getCountryCode() {
    this.subscriber = this.commonService.getCountryCode().subscribe((res) => {
      this.userAccountData.userAccountManager['countryCode'] = res[0].value;
    });
  }
  // get Canton Data
  private getCantonData() {
    this.subscriber = this.commonService.getCantonData().pipe(map((resData) => {
      return {
        response: resData[this.localLanguage].map((res) => ({ label: res.townName, value: res.townId }))
      };
    })).subscribe((res) => {
      this.canton = res.response;
    });
  }
  // get country list
  public getListOfCountries() {
    this.subscriber = this.commonService.getCountry().pipe(map((resData) => {
      return {
        response: resData[this.localLanguage].map((res) => ({ label: res.countryName, value: res.countryId })),
      };
    })
    ).subscribe((res) => {
      this.countries = res.response;
    });
  }
  getDepartmentList(orgData: any) {
    this.isLoading = true;
    if (orgData) {
      let departmentObs: Observable<any>;
      this.orgData = orgData;
      departmentObs = this.getDepartment(orgData.id);
      this.subscriber = departmentObs.subscribe((response) => {
        this.departmentList = response.data;
        this.isLoading = false;
      });
    }
  }
  // get department by organization
  private getDepartment(orgId: number) {
    const query = this.utilityService.createQuery({ limit: -1, offset: 0 });
    return this.userAccountService.getDepartmentsByOrgnization(orgId, query).pipe(map((resData) => {
      return {
        data: resData['data']['departments'].map((res) => ({ name: res.name, id: res.id }))
      };
    }));
  }
  // Get List Of Organization list
  private getOrganizationList() {
    this.isLoading = true;
    const query = this.utilityService.createQuery({ limit: -1, offset: 1 });
    this.subscriber = this.userAccountService.getOrganizationsList(query).pipe(map((resData) => {
      return { data: resData['data']['organizations'].map((res) => ({ label: res.name, value: res })) };
    })).subscribe((res) => {
      this.organizationList = res.data;
      this.isLoading = false;
    });
  }
  private getTranslatedName(res: any) {
    let language;
    switch (this.localLanguage) {
      case 'en':
        language = res.englishName;
        break;
      case 'fr':
        language = res.frenchName;
        break;
      case 'de':
        language = res.germanName;
        break;
      default:
        language = res.italianName;
    }
    return language;
  }
  // get branches
  private getBranches() {
    this.isLoading = true;
    this.subscriber = this.userAccountService.getBranches().pipe(map((resData) => {
      return {
        data: resData['data'].map((res) => ({ label: this.getTranslatedName(res), value: res, id: res.id }))
      };
    })).subscribe((res) => {
      this.branches = res.data;
      this.isLoading = false;
    });
  }
  public changeTelephoneFormat(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      this.userAccountData.userAccountManager.telephone = event.target['value'];
    } else {
      if (event.target['value'].length === 3 || event.target['value'].length === 7 || event.target['value'].length === 10) {
        this.userAccountData.userAccountManager.telephone = event.target['value'] + ' ';
      }
    }
  }
  onSelectBranch(branch: any, init?: boolean): void {
    if (branch) {
      this.isLoading = true;
      this.subscriber = this.userAccountService.getBranchOptions(branch).pipe(map((resData) => {
        return {
          data: resData['data'].map((res) => {
            return {
              name: this.getTranslatedName(res),
              value: res,
              id: res.id
            };
          }),
        };
      })
      ).subscribe((resData) => {
        if (!init) {
          this.userAccountData.branchOption.id = 0;
          this.userAccountData.branchOptionSubOption.id = 0;
          this.showSubBranch = false;
        }
        this.branchOptions = resData.data;
        this.isLoading = false;
      });
    }
  }
  onSelectSubBranch(subbranch: any, branchOption?: any, init?: boolean): void {
    this.branchOptions.forEach((i) => {
      if (i.id === subbranch) {
        branchOption = i.value;
      }
    });
    if (!branchOption.name) {
      this.branchOptions.forEach((i) => {
        if (branchOption.id === i.id) {
          branchOption = i;
        }
      });
    }
    if ((subbranch) && (branchOption.name === 'Hospital' || branchOption.name === 'Psychiatry / psychotherapy')) {
      this.isLoading = true;
      this.showSubBranch = true;
      this.subscriber = this.userAccountService.getBranchSubOptions(subbranch).pipe(map((resData) => {
        return {
          data: resData['data'].map((res) => {
            return {
              label: this.getTranslatedName(res),
              value: res,
              id: res.id
            };
          }),
        };
      })).subscribe((resData) => {
        this.branchSubOptions = resData.data;
        if (!init) {
          this.userAccountData.branchOptionSubOption.id = 0;
        }
        this.isLoading = false;
      });
    } else {
      this.showSubBranch = false;
      if (this.userAccountData.branchOptionSubOption) {
        this.userAccountData.branchOptionSubOption.id = 0;
      }
    }
  }
  // close modal window
  public closeModal(response?: any) {
    this.modal.destroy(response);
    this.userAccount.reset();
  }
  onAddDepartment(action: string, data: any) {
    let orgId: string;
    if (this.orgData) {
      orgId = this.orgData.id;
    }
    const modalData = { orgData: null, action };
    this.modalDataService.setData('addEditUserAccount', data);
    this.modalDataService.setData('modalData', modalData);
    this.translateService.get(action).subscribe((title) => {
      const modal = this.nzModalService.create({
        nzTitle: title,
        nzClosable: false,
        nzContent: AddUserAccountComponent,
        nzWrapClassName: 'vertical-center-modal',
        nzWidth: 1200
      });
      modal.afterClose.subscribe((res) => {
        if (res) {
          this.getDepartment(+orgId);
          this.userAccount.form.patchValue({
            department: res.res
          });
        }
      });
    });
  }
  // On Click Location Modal
  onClickAddLocation(action: string, data: any) {
    let modal;
    const modalInfo = { action };
    const selectedData = this.selectedUserData;
    this.modalDataService.setData('modalInfo', modalInfo);
    this.modalDataService.setData('selectedData', selectedData);
    this.modalDataService.setData('modalData', data);
    this.translateService.get(action).subscribe((title) => {
      modal = this.nzModalService.create({
        nzTitle: title,
        nzClosable: false,
        nzContent: AddLocationComponent,
        nzWrapClassName: 'vertical-center-modal',
        nzWidth: 1000
      });
    });
    modal.afterClose.subscribe((res) => {
      if (res && res.responseFor === 'Location') {
        this.locationData = res.res;
        this.userAccount.form.get('userAccountManager').patchValue({
          address: res.res.formattedAddress
        });
        this.userAccountData.latitude = res.res.latitude;
        this.userAccountData.longitude = res.res.longitude;
      }
    });
  }
  private changeCheckboxes(changedValues: object) {
    const value = changedValues;
    Object.keys(changedValues).forEach((i) => {
      if (changedValues[i] && (changedValues[i] === 'true' || changedValues[i] === 'false')) {
        value[i] = JSON.parse(changedValues[i]);
      }
    });
    return value;
  }
  private checkSubmitData() {
    if (this.departmentList.length > 0) {
      this.departmentList.forEach((i) => {
        if (i.value === this.userAccountData.department.id) {
          this.userAccountData.department = i;
        }
      });
    }
    if (this.userAccountData.branch.id !== 0) {
      this.branches.forEach((i) => {
        if (i.id === this.userAccountData.branch.id) {
          this.userAccountData.branch = i.value;
        }
      });
    }
    if (this.userAccountData.branchOption.id !== 0) {
      this.branchOptions.forEach((i) => {
        if (i.id === this.userAccountData.branchOption.id) {
          this.userAccountData.branchOption = i.value;
        }
      });
    }
    if (this.userAccountData.branchOptionSubOption.id !== 0) {
      this.branchSubOptions.forEach((i) => {
        if (i.id === this.userAccountData.branchOptionSubOption.id) {
          this.userAccountData.branchOptionSubOption = i.value;
        }
      });
    }
  }
  submitFormData() {
    this.isLoading = true;
    this.checkSubmitData();
    this.userAccountData.customerSince = new Date(this.userAccountData.customerSince).getTime();
    this.changeCheckboxes(this.userAccountData);
    if (this.userAccountData.organization && !this.userAccountData.organization.dynamicField) {
      delete this.userAccountData.userAccountManager.isSelected;
    }
    const userRequestData = { ...this.userAccountData };
    if (this.userAccountData.branch.id === 0) {
      userRequestData.branch = undefined;
      userRequestData.branchOption = undefined;
    }
    if (this.userAccountData.branchOptionSubOption.id === 0) {
      userRequestData.branchOptionSubOption = undefined;
    }
    if (!this.userAccountData.department.id) {
      userRequestData.department = undefined;
    }
    if (this.isMode === 'create') {
      this.subscriber = this.userAccountService.createUserAccount(userRequestData).subscribe((res) => {
        this.getResponse(res);
      });
    } else if (this.isMode === 'update') {
      this.subscriber = this.userAccountService.updateUserAccount(userRequestData, userRequestData.id).subscribe((res) => {
        this.getResponse(res);
      });
    }
  }
  private getResponse(res: any) {
    if (res['isSuccess']) {
      this.closeModal({ res: res['data'] });
      this.dataShareService.resposeData({ res: res['data'], responseFor: 'UserAccount' });
      this.notification.success('success', res['message']);
    } else {
      this.notification.error('error', res['error']);
    }
    this.isLoading = false;
    this.userAccount.reset();
  }

  ngOnDestroy() {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }
}
