import { environment } from '../../../../environments/environment';


export const urls = {
    getSessions: 'search-filter-book-sessions',
    getBookedSessions: 'v4/book-sessions',
    userAccounts: 'user-accounts',
    organizations: 'organizations/',
    userAccount: 'user-accounts/',
    useraccount: 'user-account/',
    interpreters: 'interpreters/',
    searchTextFilter: 'search-text-filter',
    assignInterpreter: 'confirm-session',
    changeInterpreterList: 'change-interpreter-list',
    interpreterQualification: 'interpreters/interpreter-qualification',
    availableInterpreters: 'available-interpreters',
    resendInvitations: 'resend-invitations'
};

export const oldUrls = {
    getSessions: 'v5/search-filter-book-sessions',
    getChangedInterpreters: 'v4/interpreters/language-based',
    getBookedSessions: 'v4/book-sessions',
    userAccounts: 'v4/user-accounts',
    organizations: 'v4/organizations/',
    userAccount: 'v4/user-accounts/',
    useraccount: 'v4/user-account/',
    interpreters: 'v4/interpreters/',
    searchTextFilter: 'v4/search-text-filter',
    assignInterpreter: 'v4/confirm-session',
    changeInterpreterList: 'v4/change-interpreter-list',
    interpreterQualification: 'v4/interpreters/interpreter-qualification',
    availableInterpreters: 'v4/available-interpreters',
    resendInvitations: 'v4/resend-invitations'
};
export const sessionsPath = {
    getUrl(urlName: string) {
       if (environment.backend === 'old') {
           return oldUrls[urlName];
       } else {
           return urls[urlName];
       }
   }
};
