import { environment } from '../../../../environments/environment';

export const urls = {
  getuserData: 'admin/user-accounts',
  exportAllData: 'profile-export',
  deleteUserAccount: 'user-accounts/',
  deleteDepartment: 'department/',
  searchTextFilter: 'search-text-filter',
  exportFormat: 'export-format/customer',
  customerXmlExport: 'customer-xml-export',
};

export const oldUrls = {
  getuserData: 'v5/admin/user-accounts',
  exportAllData: 'v4/profile-export',
  deleteUserAccount: 'v4/user-accounts/',
  deleteDepartment: 'v4/department/',
  searchTextFilter: 'v4/search-text-filter',
  exportFormat: 'v4/export-format/customer',
  customerXmlExport: 'v4/customer-xml-export',
};
export const userAccountUrls = {
  getUrl(urlName: string) {
    if (environment.backend === 'old') {
      return oldUrls[urlName];
    } else {
      return urls[urlName];
    }
  },
};
