import { environment } from '../../../../environments/environment';


export const urls = {
    exportSession: 'sessions-export',
    pdfFormats: 'pdf-formats',
    organizations: 'organizations',
    userAccounts: 'export/user-account-list',
    interpreters: 'export/interpreter-list',
    export: 'sessions/export',
    xmlExport: 'xml-export',
    myComapnyExportFormat: 'show/my-company-export-format',
    csvExportFormat: 'export-format/csv',
    xmlExportFormat: 'xml-export-formats',
    pdfExportFormat: 'pdf-formats'
};

export const oldUrls = {
    exportSession: 'v5/sessions-export',
    pdfFormats: 'v4/pdf-formats',
    organizations: 'v4/organizations',
    userAccounts: 'v4/export/user-account-list',
    interpreters: 'v4/export/interpreter-list',
    export: 'v4/sessions/export',
    xmlExport: 'v4/xml-export',
    myComapnyExportFormat: 'v4/show/my-company-export-format',
    csvExportFormat: 'v4/export-format/csv',
    xmlExportFormat: 'v4/xml-export-formats',
    pdfExportFormat: 'v4/pdf-formats'
};
export const sessionExport = {
    getUrl(urlName: string) {
       if (environment.backend === 'old') {
           return oldUrls[urlName];
       } else {
           return urls[urlName];
       }
   }
};
