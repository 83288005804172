<!-- Warning Modal -->
<ng-container>
  <ng-template #modalBody>
    <p>Are you sure you want to delete {{'Interpreter' | translate}}?</p>
  </ng-template>

  <ng-template #modalFooter>
    <div class="actions justify-content-between">
      <button tc-button [view]="'error'" (click)="closeModal()">Close</button>
      <button tc-button [view]="'success'" (click)="submitWarningModalData(row)">Submit</button>
    </div>
  </ng-template>
</ng-container>
<!-- <div [hidden]="role == 'Manager'"> -->
  <div class="row search_row">
    <div  class="col-md-7 search-col">
      <button
        *ngIf="role !== 'Manager'"
        tc-button
        [beforeIcon]="'icofont-plus-circle'"
        [view]="'accent'"
        (click)="openInterpreterModalService('Create_interpreter', null)">
        {{'New_interpreter_from_ig' | translate}}
      </button>
      <button
        *ngIf="role !== 'Manager'"
        tc-button
        [beforeIcon]="'icofont-plus-circle'"
        [view]="'accent'"
        (click)="exportInterpreterData()"
      >{{ "Export_all_data" | translate }}
      </button>
      <button *ngIf="role == 'IG Manager'" tc-button
        [beforeIcon]="'icofont-plus-circle'"
        [view]="'accent'"
        (click)="copyEmailOnClipboard()"
      >{{ "Copy_all_emails" | translate }}
      </button>
    </div>
    <div class="col-md-3 offset-md-2">
      <nz-select
        nzShowSearch 
        nzMode="multiple"
        nzPlaceHolder="'Show/Hide Table Column'"
        [(ngModel)] = "selectedColumns"
        (ngModelChange)="onSelectcolumn($event)"
      >
        <ng-container *ngFor="let item of columnList">
          <nz-option [nzValue]="item.value" [nzLabel]="item.label" [nzDisabled]="item.disabled"></nz-option>
        </ng-container>
    </nz-select>
    </div>
  </div>
<!-- </div> -->
<nz-table
      #organizationTable
      nzShowSizeChanger
      [nzData]="interpretersList"
      [nzFrontPagination]="false"
      [nzLoading]="loading"
      [nzTotal]="totalInterpreters"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzScroll]="{ x: '1100px', y: '65vh' }"
      nzSize="small"
    >
      <thead>
        <tr>
          <th>{{ "Photo" | translate }}</th>
          <th *ngIf="role !== 'Manager' && columnList[0].selected" nzCustomFilter nzColumnKey="idNumber" nzSortPriority="true" nzSortFn="true">{{ 'ID_number' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.idNumber.visible" [nzActive]="searchValue.idNumber.value.length > 0" [nzDropdownMenu]="idNumber">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="columnList[1].selected" nzCustomFilter nzColumnKey="firstName" nzSortPriority="true" nzSortFn="true">{{ 'Firstname' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.firstName.visible" [nzActive]="searchValue.firstName.value.length > 0" [nzDropdownMenu]="firstName">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="columnList[2].selected"  nzCustomFilter nzColumnKey="lastName" nzSortPriority="true" nzSortFn="true">{{ 'Lastname' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.lastName.visible" [nzActive]="searchValue.lastName.value.length > 0" [nzDropdownMenu]="lastName">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="columnList[3].selected"  nzCustomFilter nzColumnKey="languageTwo" nzSortPriority="true" nzSortFn="true">{{ 'Language_two' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.languageTwo.visible" [nzActive]="searchValue.languageTwo.value.length > 0" [nzDropdownMenu]="languageTwo">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="((role === 'Admin' || role === 'IG Manager') || managerData.isInterpreterTelephoneShow) && columnList[4].selected"
          nzCustomFilter nzColumnKey="telephone" nzSortPriority="true" nzSortFn="true">{{ 'Telephone' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.telephone.visible" [nzActive]="searchValue.telephone.value.length > 0" [nzDropdownMenu]="telephone">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="((role === 'Admin' || role === 'IG Manager') || managerData.isInterpreterEmailShow) && columnList[5].selected"
          nzCustomFilter nzColumnKey="email" nzSortPriority="true" nzSortFn="true">{{ 'Email' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.email.visible" [nzActive]="searchValue.email.value.length > 0" [nzDropdownMenu]="email">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="role === 'Admin' && columnList[6].selected" nzColumnKey="gender" [nzFilters]="gender" nzFilterFn="true">{{ 'Gender' | translate }}</th>
          <th *ngIf="((role === 'Admin' || role === 'IG Manager') || managerData.isInterpreterAddressShow) && columnList[7].selected" nzCustomFilter nzColumnKey="address" nzSortPriority="true" nzSortFn="true">{{ 'Address' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.address.visible" [nzActive]="searchValue.address.value.length > 0" [nzDropdownMenu]="formattedAddress">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th  *ngIf="(role === 'IG Manager' || role === 'Manager') && columnList[8].selected" nzCustomFilter nzColumnKey="origin" nzSortPriority="true" nzSortFn="true">{{ 'Origin' | translate }}
            <nz-filter-trigger [(nzVisible)]="searchValue.origin.visible" [nzActive]="searchValue.origin.value.length > 0" [nzDropdownMenu]="origin">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th *ngIf="role !== 'Manager' && columnList[9].selected" nzColumnKey="actions" nzRight nzWidth="200px">{{'Action' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of organizationTable.data">
          <td><tc-avatar [src]="data?.profilePicName"></tc-avatar></td>
          <td *ngIf="role !== 'Manager' && columnList[0].selected">{{ data.idNumber }}</td>
          <td *ngIf="columnList[1].selected">{{ data.firstName }}</td>
          <td *ngIf="columnList[2].selected">{{ data.lastName }}</td>
          <td *ngIf="columnList[3].selected" nzWidth="100px">
            <strong *ngFor="let i of data.languagesSkills">{{ i.languageTwo.name }}, </strong>
          </td>
          <td *ngIf="((role === 'Admin' || role === 'IG Manager') || managerData.isInterpreterTelephoneShow) && columnList[4].selected">{{ data.telephone }}</td>
          <td *ngIf="((role === 'Admin' || role === 'IG Manager') || managerData.isInterpreterEmailShow) && columnList[5].selected">{{ data.email }}</td>
          <td *ngIf="role === 'Admin' && columnList[6].selected">{{ data.gender }}</td>
          <td *ngIf="((role === 'Admin' || role === 'IG Manager') || managerData.isInterpreterAddressShow) && columnList[7].selected">{{ data.formattedAddress }}</td>
          <td *ngIf="(role === 'IG Manager' || role === 'Manager') && columnList[8].selected">{{ data.country?.name }}</td>
          <td *ngIf="role !== 'Manager' && columnList[9].selected" nzRight nzWidth="200px">
            <a *ngIf="role === 'Admin'" href="#" [routerLink] = "['/', 'interpreter', 'view-interpreter', data.id]" tc-button
            [afterIcon]="'icofont-info'" [view]="'info'" [square]="true" [tcShape]="500" [size]="'sm'"></a>
            <button *ngIf="role === 'Admin'" tc-button [afterIcon]="'icofont-external-link'" [view]="'success'" [square]="true"
            [tcShape]="500" [size]="'sm'" (click)="openInterpreterModalService('View_interpreter', data)"></button>
            <button tc-button [afterIcon]="'icofont-ui-edit'" [view]="'info'" [square]="true" [tcShape]="500" [size]="'sm'" (click)="openInterpreterModalService('Update_interpreter', data)"></button>
            <button *ngIf="role === 'Admin'" tc-button [afterIcon]="'icofont-ui-delete'" [view]="'error'" [square]="true" [tcShape]="500" [size]="'sm'" (click)="openWarningModalData(data)"></button>
          </td>
        </tr>
      </tbody>
</nz-table>
<nz-dropdown-menu #idNumber="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.idNumber.value" />
        <button nz-button nzSize="small" nzType="primary" (click)="search('idNumber')" class="search-button">
          Search
        </button>
        <button nz-button nzSize="small" (click)="reset('idNumber')">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #firstName="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.firstName.value" />
        <button nz-button nzSize="small" nzType="primary" (click)="search('firstName')" class="search-button">
          Search
        </button>
        <button nz-button nzSize="small" (click)="reset('firstName')">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
    <nz-dropdown-menu #lastName="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.lastName.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('lastName')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('lastName')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #languageTwo="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.languageTwo.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('languageTwo')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('languageTwo')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #telephone="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.telephone.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('telephone')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('telephone')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #email="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.email.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('email')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('email')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #formattedAddress="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.address.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('address')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('address')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #origin="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue.origin.value" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('origin')" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="reset('origin')">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
