import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzNotificationService } from 'ng-zorro-antd';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { DownloadFileService } from '../../../shared/services/download-file.service';
import { ModalDataService } from '../../../shared/services/modal-data.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { SessionExportService } from './session-export.service';

@Component({
  selector: 'app-session-export',
  templateUrl: './session-export.component.html',
  styleUrls: ['./session-export.component.scss']
})
export class SessionExportComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = false;
  isLoading = false;
  isShowMyCompanyExport = false;
  subscription: Subscription;
  sessionExportData: any;
  csvExportFormat: any;
  XmlFormatOptions = [];
  pdfFormatOptions = [];
  role: string;
  docType: string;
  exportType: string;
  exportFormat: string;
  selectType = '';
  timePeriod: Date[] = [new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)];
  organizations = [];
  organizationId: number;
  userAccounts = [];
  userAccountId: number;
  interpreters = [];
  interpreterId: number;
  filterQueryParams: Array<string>;
  searchChange$ = new BehaviorSubject('');
  searchType: string;
  filteredInterpretes = [];
  filterdUserAccounts = [];
  iGManager = 'IG Manager';
  abacusXMLFR = 'AbacusXML-FR';

  constructor(
    private readonly modal: NzModalRef,
    private readonly modalDataService: ModalDataService,
    private readonly utilityService: UtilityService,
    private readonly sessionExport: SessionExportService,
    private readonly downloadFileService: DownloadFileService,
    private readonly notification: NzNotificationService,
    private readonly translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    const sessionExportData = this.modalDataService.getData();
    this.sessionExportData = sessionExportData['sessionExportData'];
    this.filteredInterpretes = sessionExportData['interpreterList'];
    this.filterdUserAccounts = sessionExportData['userAccountList'];
    this.getFilterQueryUrl();
    const isFilterApplied: Array<string> = this.isFilterApplied();
    if (isFilterApplied.length > 0) {
      this.exportType = 'new';
      this.userAccounts = this.filterdUserAccounts;
      this.interpreters = this.filteredInterpretes;
    } else {
      this.exportType = 'old';
    }
    this.docType = 'pdf';
    if (this.role === this.iGManager) {
      this.getMyCompanyExportFormat();
    }
  }
  onclickDocType(event: any) {
    if (event === this.abacusXMLFR && this.role === this.iGManager && this.selectType === 'interpreter') {
      this.getXmlExportFormats(this.interpreterId);
    }
    if (event.toUpperCase() === 'PDF' && (this.role === this.iGManager || this.role === 'Manager')) {
      this.getPdfExportFormats(this.interpreterId);
    }
  }
  onclickExportType(event: any) {
    if (event === 'new') {
      this.userAccounts = this.filterdUserAccounts;
      this.interpreters = this.filteredInterpretes;
    } else {
      this.onclickSelectType(this.selectType);
    }
  }
  onSearchSelect(value: string, type: string) {
    this.searchType = type;
    this.searchChange$.next(value);
  }
  ngAfterViewInit() {
    this.searchChange$.asObservable().pipe(
      filter(Boolean), debounceTime(1500), distinctUntilChanged(),
      tap((event) => {
        if (this.searchType === 'userAccount') {
          this.getUserAccountList(event);
        } else if (this.searchType === 'interpreter') {
          this.getInterpreterList(event);
        }
      })
    ).subscribe();
  }
  onclickSelectType(event: any) {
    this.docType = undefined;
    if (event === 'client' && this.exportType === 'old') {
      this.getUserAccountList();
    }
    if (event === 'interpreter' && this.exportType === 'old') {
      this.getInterpreterList();
      if (this.role === this.iGManager) {
        this.getcsvExportFormat();
      }
    }
    if (event === 'organization' && this.role === this.iGManager && this.exportType === 'old') {
      this.getOrganizations();
    }
  }
  onSelectDropdown(event: any) {
    console.log(event);
  }
  onClickExport() {
    if (this.role !== 'Admin' && this.role !== 'Manager' && this.selectType === 'interpreter' && this.docType === this.abacusXMLFR) {
      this.notification.info('success', this.getTranslations('exportAssigned'));
      return;
    }
    if ((this.role === 'Interpreter' || this.role === 'Admin') && this.selectType === 'interpreter' && this.docType === 'pdf') {
      this.exportFormat = 'MultipleSession';
    }
    if (this.role !== 'Admin' && (this.docType === 'pdf' && this.exportFormat === 'SingleSession')) {
      alert('Single Session');
      // exportSingleSession
    } else if (this.role !== 'Admin' && (this.docType === 'pdf' && this.exportFormat === 'InterpreterProfile')) {
      // exportNeSession
      alert('exportNeSession');
    } else if (this.role !== 'Admin' && (this.docType === 'pdf' && this.exportFormat === 'AbacusSalary')) {
      // exportAbacusSession
      alert('exportAbacusSession');
    } else if (this.docType === 'pdf' && (this.exportFormat === 'Salary (Time/Travel)' || this.exportFormat === 'MultipleSession')) {
      this.getPdfCsvExport();
    } else if ((this.role === this.iGManager || this.role === 'Manager' || this.role === 'Admin') && (this.exportFormat === this.abacusXMLFR
      || this.exportFormat === 'AbacusXML-BE' || this.exportFormat === 'AbacusXML-Invoice' || this.docType === 'AbacusXML-Invoice' ||
      this.docType === this.abacusXMLFR)) {
      this.getAbacusXMLInvoice();
    } else if (this.role === this.iGManager && this.docType === 'SwissSalary') {
      // getSwissSalaryData
      alert('getSwissSalaryData');
    } else if (this.role === this.iGManager && this.docType === 'SalarySummary' && this.csvExportFormat.selected) {
      // getSalarySummaryData
      alert('getSalarySummaryData');
    } else if (this.role === this.iGManager && this.docType === 'implingord') {
      // exportCompanyExport
      alert('exportCompanyExport');
    } else {
      this.getPdfCsvExport();
    }
  }
  // Helper Functions
  private isFilterApplied() {
    let filterString;
    const searchValues = { ...this.sessionExportData.searchValues };
    if (searchValues.bookedDate) {
      delete searchValues.bookedDate;
    }
    if (searchValues.modifiedDate) {
      delete searchValues.modifiedDate;
    }
    const searchQueryData = this.utilityService.searchFilterQueryParam(searchValues);
    if (this.sessionExportData.filter.filter.find(item => item.value.length > 0)) {
      filterString = this.utilityService.dropdownFilterQueryParam(this.sessionExportData.filter.filter);
    }
    let queryParamArray: Array<string> = [searchQueryData, filterString];
    queryParamArray = queryParamArray.filter((x) => {
      if (x) {
        return x.length > 0;
      }
    });
    return queryParamArray;
  }
  private getFilterQueryUrl() {
    let filterString;
    const searchValues = { ...this.sessionExportData.searchValues };
    delete searchValues.organization;
    delete searchValues.userAccount;
    delete searchValues.interpreter;
    delete searchValues.bookingDate;
    delete searchValues.updatedDate;
    if (searchValues.bookedDate) {
      if (searchValues.bookedDate.value.length > 0) {
        this.timePeriod[0] = new Date(searchValues.bookedDate.value[0]);
        this.timePeriod[1] = new Date(searchValues.bookedDate.value[1]);
      }
      delete searchValues.bookedDate;
    }
    if (searchValues.modifiedDate) {
      delete searchValues.modifiedDate;
    }
    const searchQueryData = this.utilityService.searchFilterQueryParam(searchValues);
    if (this.sessionExportData.filter.filter.find(item => item.value.length > 0)) {
      filterString = this.utilityService.dropdownFilterQueryParam(this.sessionExportData.filter.filter);
    }
    let queryParamArray: Array<string> = [searchQueryData, filterString];
    queryParamArray = queryParamArray.filter((x) => {
      if (x) {
        return x.length > 0;
      }
    });
    this.filterQueryParams = queryParamArray;
  }
  // Get Abacus-XML invoice
  private getAbacusXMLInvoice() {
    this.loading = true;
    let status;
    const userAccountId = this.userAccountId;
    const xmlFormatName = this.docType;
    const fromDate = this.timePeriod[0].getTime();
    const toDate = this.timePeriod[1].getTime();
    const filterStatuses = this.sessionExportData['filter']['filter'][0].value;
    const exportFor = this.selectType;
    status = filterStatuses.join(',');
    if (status === '') {
      status = 'CANCELLED,COMPLETED,RESCHEDULED';
    }
    const queryParam = this.utilityService.createQuery({ fromDate, toDate, status, exportFor, userAccountId, xmlFormatName });
    this.subscription = this.sessionExport.getXmlExport(queryParam).subscribe((resData) => {
      if (resData['isSuccess']) {
        this.notification.success('success', resData['message']);
        this.downloadFileService.downloadFile(resData['data']['filePath'], resData['data']['fileName']);
        this.modal.destroy();
      } else {
        this.notification.error('error', resData['message']);
      }
      this.loading = false;
    });
  }
  // Get PDF, CSV Export
  private getPdfCsvExport() {
    this.loading = true;
    let queryParam;
    let userAccountId;
    let interpreterId;
    let exportFor;
    let status;
    let exportFormat;
    const fromDate = this.timePeriod[0].getTime();
    const toDate = this.timePeriod[1].getTime();
    const filterStatuses = this.sessionExportData['filter']['filter'][0].value;
    status = filterStatuses.join(',');
    if (status === '') {
      status = 'CANCELLED,COMPLETED,RESCHEDULED';
    }
    const docType = this.docType.toUpperCase();
    const email = false;
    const download = true;
    if (this.selectType === 'client') {
      exportFor = this.selectType;
      userAccountId = this.userAccountId;
      queryParam = this.utilityService.createQuery({ fromDate, toDate, status, userAccountId, docType, email, download, exportFor });
    } else if (this.selectType === 'interpreter') {
      exportFor = this.selectType;
      interpreterId = this.interpreterId;
      exportFormat = this.exportFormat;
      if (this.interpreterId === 0) {
        queryParam = this.utilityService.createQuery({ fromDate, toDate, status, docType, email, download, exportFormat, exportFor });
      } else {
        queryParam = this.utilityService.createQuery(
          { fromDate, toDate, status, interpreterId, docType, email, download, exportFormat, exportFor });
      }
    }
    if (this.exportType === 'new' && this.filterQueryParams.length > 0) {
      let queryParamArray: Array<string> = this.filterQueryParams;
      queryParamArray = queryParamArray.filter((x) => {
        if (x) {
          return x.length > 0;
        }
      });
      queryParam = `${queryParam}&${queryParamArray.join('&')}`;
    }
    this.subscription = this.sessionExport.getExportData(queryParam).subscribe((resData) => {
      if (resData['isSuccess']) {
        this.notification.success('success', resData['message']);
        this.downloadFileService.downloadFile(resData['data']['filePath'], resData['data']['fileName']);
        this.modal.destroy();
      } else {
        this.notification.error('error', resData['message']);
      }
      this.loading = false;
    });
  }
  // Get Interpreter List
  private getInterpreterList(searchString?: any) {
    this.isLoading = true;
    let searchText;
    if (searchString) {
      searchText = searchString;
    } else {
      searchText = '';
    }
    const queryParam = this.utilityService.createQuery({ searchText, limit: 25, offset: 1 });
    this.sessionExport.getInterpreters(queryParam).pipe(map((resData) => {
      return {
        data: resData['data']['interpreters'].map((res) => {
          return {
            id: res.id,
            name: res.fullName
          };
        })
      };
    })).subscribe((response) => {
      if (response) {
        this.interpreters = response.data;
        this.interpreters.unshift({ id: 0, name: 'All' });
      }
      this.isLoading = false;
    });
  }
  // Get User Account List
  private getUserAccountList(searchString?: any) {
    this.isLoading = true;
    let searchText;
    if (searchString) {
      searchText = searchString;
    } else {
      searchText = '';
    }
    const queryParam = this.utilityService.createQuery({ searchText, limit: 25, offset: 1 });
    this.sessionExport.getUserAccounts(queryParam).pipe(map((resData) => {
      return {
        data: resData['data']['userAccounts'].map((res) => {
          return {
            id: res.id,
            name: res.userAccountManager.fullName
          };
        })
      };
    })).subscribe((response) => {
      this.isLoading = false;
      if (response) {
        this.userAccounts = response.data;
      }
    });
  }
  // Get Organizations
  private getOrganizations() {
    this.isLoading = true;
    const queryParam = this.utilityService.createQuery({ limit: -1, offset: 1 });
    this.sessionExport.getOrganizations(queryParam).pipe(map((resData) => {
      return {
        data: resData['data']['organizations'].map((res) => {
          return {
            id: res.id,
            name: res.name
          };
        })
      };
    })).subscribe((response) => {
      this.organizations = response.data;
      this.interpreters.unshift({ id: 0, name: 'All' });
      this.isLoading = false;
    });
  }
  // Get My Company Export Format For IG-Admin
  private getMyCompanyExportFormat() {
    this.loading = true;
    this.sessionExport.getMyCompanyExportFormat().subscribe((res) => {
      this.isShowMyCompanyExport = res['data']['isShowMyCompanyExport'];
      this.loading = false;
    });
  }
  // Get CSV Export Format Data For
  private getcsvExportFormat() {
    const interpreterGroupId = localStorage.getItem('id');
    if (interpreterGroupId) {
      this.loading = true;
      this.sessionExport.getCSVExportFormat(interpreterGroupId).subscribe((res) => {
        this.csvExportFormat = res['data'][0];
        this.loading = false;
      });
    }
  }
  // Get XML Export Format Data
  private getXmlExportFormats(interpreterId: number) {
    let interpreterGroupId;
    this.loading = true;
    if (interpreterId && interpreterId !== 0) {
      interpreterGroupId = localStorage.getItem('id');
    }
    this.sessionExport.getXMLExportFormat(interpreterGroupId).subscribe((res) => {
      const xmlFormats = [];
      res['data'].forEach((e, i) => {
        if (res['data'][i].isShow) {
          xmlFormats.push(res['data'][i]);
        }
      });
      this.XmlFormatOptions = xmlFormats;
      this.loading = false;
    });
  }
  // Get PDF Export Format Data
  private getPdfExportFormats(interpreterId: number) {
    let interpreterGroupId;
    this.loading = true;
    if (interpreterId && interpreterId !== 0) {
      interpreterGroupId = localStorage.getItem('id');
    }
    let query;
    if (this.selectType === 'organization') {
      query = this.utilityService.createQuery({ orgId: this.organizationId, selectType: 'Organization' });
    } else if (!interpreterGroupId) {
      query = this.utilityService.createQuery({ selectType: 'Interpreters' });
    } else {
      query = this.utilityService.createQuery({ interpreterGroupId, selectType: 'Interpreters' })
    }
    this.sessionExport.getPdfExportFormat(query).subscribe((res) => {
      const pdfFormats = [];
      res['data'].forEach((e, i) => {
        if (res['data'][i].isShow) {
          pdfFormats.push(res['data'][i]);
        }
      });
      this.pdfFormatOptions = pdfFormats;
      console.log(this.pdfFormatOptions);
      this.loading = false;
    });
  }
  private getTranslations(key: string) {
    let translation;
    this.translateService.get(key).subscribe((res: string) => {
      translation = res;
    });
    return translation;
  }
  // close modal
  cancelModal() {
    this.modal.destroy();
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
