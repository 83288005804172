import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { ModalDataService } from '../../../shared/services/modal-data.service';
import { SessionModalService } from '../session-model/session-model.service';

@Component({
  selector: 'app-send-emails-model',
  templateUrl: './send-emails-model.component.html',
  styleUrls: ['./send-emails-model.component.scss']
})
export class SendEmailsModelComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  sessionData: any;
  user = 'true';
  interpreter = 'true';
  loading = false;

  constructor(
    private readonly modal: NzModalRef,
    private readonly modalDataService: ModalDataService,
    private readonly sessionMailService: SessionModalService
  ) { }

  ngOnInit() {
    const modalData = this.modalDataService.getData();
    this.sessionData = modalData['sessionServiceData'];
  }

  onClickSend() {
    let option;
    if (this.user === 'true' && this.interpreter === 'true') {
      option = { option: 'both' }
    } else if (this.interpreter === 'true') {
      option = { option: 'interpreter' }
    } else {
      option = { option: 'user' }
    }
    this.sessionMailService.sendSessionMail(this.sessionData.id, option).subscribe((res) => {
      this.modal.destroy(res);
    });
  }


  cancelModal(response?: any) {
    this.modal.destroy(response);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
