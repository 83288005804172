import { Component, ContentChild, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { IOption } from 'src/app/ui/interfaces/option';
import { TCTableComponent } from '../table.component';

@Component({
  selector: 'tc-table-col',
  templateUrl: './col.component.html',
  styleUrls: ['./col.component.scss']
})
export class TCTableColComponent implements OnInit {
  @ContentChild('tableTDTemplate', { static: true }) tdTemplate: TemplateRef<any>;
  @ContentChild('headerBodyTemplate', { static: true }) headerTemplate: TemplateRef<any>;

  @Input() columnTitle: string;
  @Input() columnName: string;
  @Input() enableFiltering: boolean;
  @Input() isInputSelect: boolean;
  @Input() isDateRange: boolean;
  @Input() selectDropdownData: IOption[];
  @Input() enableSorting: boolean;
  @Input() show: boolean;

  config: any;

  constructor(private table: TCTableComponent) {
    this.enableFiltering = false;
    this.isInputSelect = false;
    this.isDateRange = false;
    this.selectDropdownData = [];
    this.enableSorting = false;
    this.show = true;
    this.columnName = '';
    this.columnTitle = '';

    this.config = {
      title: '',
      name: '',
      sort: '',
      enableSorting: this.enableSorting,
      show: this.show,
      filter: this.enableFiltering,
      select: this.isInputSelect,
      date: this.isDateRange,
      selectData: this.selectDropdownData,
      filtering: {
        filterString: '',
        columnName: name
      },
      filterString: ''
    };
  }

  ngOnInit() {
    this.setConfig();
    this.table.addColumn(this);
  }

  setConfig() {
    this.config.name = this.columnName;
    this.config.show = this.show;
    this.config.title = this.columnTitle;
    this.config.filtering.columnName = this.columnName;
    this.config.enableSorting = this.enableSorting;

    if (this.columnName === '') {
      this.config.filtering = false;
    }
  }
}
