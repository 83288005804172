import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { NzModalRef, NzNotificationService } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { SessionModalService } from '../session-model/session-model.service';
import { ModalDataService } from '../../../shared/services/modal-data.service';
import { SessionRecalucalte } from './session-recalculate-modal.interface';

@Component({
  selector: 'app-session-recalculate-model',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './session-recalculate-model.component.html',
  styleUrls: ['./session-recalculate-model.component.scss']
})
export class SessionRecalculateModelComponent implements OnInit {
  @ViewChild('f', { static: false }) sessionRecalucalte: NgForm;
  editType: boolean;
  subscription: Subscription;
  modalName: string;
  role: string;
  recalculateSessionForm: FormGroup;
  session = new SessionRecalucalte();
  loading = false;
  sessionEditType: string;

  constructor(
    private readonly notification: NzNotificationService,
    private readonly sessionModalService: SessionModalService,
    private readonly modal: NzModalRef,
    private readonly modalDataService: ModalDataService,
  ) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    const modalData = this.modalDataService.getData();
    this.modalName = 'Edit Sessions';
    this.session = JSON.parse(JSON.stringify(modalData['sessionServiceData']));
    if (this.role === 'Admin' && this.session.status === 'CONFIRMED' || this.session.status === 'STOPPED') {
      this.session.editType = 'editConfirmedDuration';
    } else if (this.session.status === 'CANCELLED' || this.session.status === 'RESCHEDULED') {
      this.session.editType = 'editCost';
    } else {
      this.session.editType = 'editDuration';
    }
    if (!this.session.totalSessionTime) {
      const totalDuration = new Date(this.session.endTime - this.session.startTime).getTime();
      this.session.totalSessionTime = totalDuration;
      this.session.totalSessionHour = '00';
      this.session.totalSessionMinutes = '00';
      this.session.totalSessionSeconds = '00';
    } else {
      this.session.totalSessionHour = this.getFormattedHours(this.session.totalSessionTime);
      this.session.totalSessionMinutes = this.getFormattedMinutes(this.session.totalSessionTime);
      this.session.totalSessionSeconds = this.getFormattedSeconds(this.session.totalSessionTime);
    }
    this.session.createdHour = new Date(this.session.createdDate).getHours();
    this.session.bookingHour = new Date(this.session.bookingDate).getHours();
    this.session.createdMinutes = new Date(this.session.createdDate).getMinutes();
    this.session.bookingMinutes = new Date(this.session.bookingDate).getMinutes();
  }

  getFormattedHours(totalSessionTime) {
    const hrs = Math.floor(totalSessionTime / (1000 * 3600));
    return hrs < 10 ? `0${hrs}` : `${hrs}`;
  }
  getFormattedMinutes(totalSessionTime) {
    const hrs = Math.floor(totalSessionTime / (1000 * 3600));
    const mins = Math.floor(totalSessionTime / (1000 * 60) - hrs * 60);
    return mins < 10 ? `0${mins}` : `${mins}`;
  }
  getFormattedSeconds(totalSessionTime) {
    const seconds = Math.floor(totalSessionTime / 1000);
    const second = Math.floor((seconds % 60));
    if (second < 10) {
      return `0${second}`;
    } else {
      return `${second}`;
    }
  }

  changeToMiliSeconds(millseconds) {
    let timeString;
    if (millseconds) {
      timeString = millseconds.split(':');
      const h = Number(timeString[0]) * 3600;
      const m = Number(timeString[1]) * 60;
      const s = Number(timeString[2]);
      timeString = (h + m + s) * 1000;
    }
    return timeString;
  }

  updateRecalculate() {
    this.loading = true;
    let totalSessionTime;
    const bookedDate =
      `${new Date(this.session.bookingDate).toLocaleDateString()}
      ${this.session.bookingHour}:${this.session.bookingMinutes}:${new Date(this.session.bookingDate).getSeconds()}`;
    const createDate =
      `${new Date(this.session.createdDate).toLocaleDateString()}
      ${this.session.createdHour}:${this.session.createdMinutes}:${new Date(this.session.createdDate).getSeconds()}`;
    const bookingDate = new Date(bookedDate).getTime();
    const createdDate = new Date(createDate).getTime();
    const date = { bookingDateTime: bookingDate, createdDateTime: createdDate };
    if (this.session.editType === 'editDuration' || this.session.editType === 'editConfirmedDuration') {
      totalSessionTime = this.changeToMiliSeconds(`${this.session.totalSessionHour}:${this.session.totalSessionMinutes}:${this.session.totalSessionSeconds}`);
    } else {
      totalSessionTime = '';
    }
    this.sessionModalService.updateRecalculateSession(totalSessionTime, date, this.session.id).subscribe((res) => {
      this.requestResponse(res);
    }, error => {
      console.log(error);
    });
  }

  updateSessionCost() {
    this.loading = true;
    const data = {
      sessionTravelCost: this.session.sessionTravelCost,
      sessionTravelCostForUser: this.session.sessionTravelCostForUser,
      supplementaryCostForUser: this.session.supplementaryCostForUser,
      supplementaryCostForInterpreter: this.session.sessionTravelCost,
      sessionCostForUser: this.session.sessionCostForUser,
      sessionCostForInterpreter: this.session.sessionCostForInterpreter,
      billedAmountForUnusedTime: this.session.billedAmountForUnusedTime,
      salaryAmountForUnusedTime: this.session.salaryAmountForUnusedTime
    };
    this.sessionModalService.updateSessionCost(this.session.id, data).subscribe((res) => {
      this.requestResponse(res);
    }, error => {
      console.log(error);
    });
  }

  private requestResponse(res: any) {
    if (res['isSuccess']) {
      this.notification.success('Success', res['message']);
    } else {
      this.notification.error('Error', res['error']);
    }
    this.loading = false;
    this.closeModal(res);
  }

  // close modal window
  closeModal(response?: any) {
    this.sessionRecalucalte.reset();
    this.modal.destroy(response);
  }
}
