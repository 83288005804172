<nz-spin [nzSpinning]="loading">
  <div class="col-md-12">
    <h4>{{ modalName }}</h4>
  </div>
  <div class="col-md-12" *ngIf="!loading">
    <div class="card-container">
      <nz-tabset [nzTabPosition]="'top'" [nzType]="'card'" id="content">
        <!-- LATE Cancelled, Late Reschedule -->
        <nz-tab *ngIf="sessionData.status === 'REFUSED' || sessionData.status === 'CANCELLED' || 
          sessionData.status === 'COMPLETED' || sessionData.status === 'CONFIRMED' || sessionData.status === 'MISSED' 
          || sessionData.status === 'RESCHEDULED' || sessionData.status === 'PENDING' || sessionData.status === 'STOPPED'" 
          nzTitle="{{'Booking_details' | translate}}">
          <p><strong>{{'Created_date' | translate}}</strong><span> : {{ sessionData.createdDate | date:'dd-MM-yyyy'}}</span></p>
          <p><strong>{{'Session_id' | translate}}</strong><span> : {{sessionData.id}}</span></p>
          <p><strong>{{'Session_user_account' | translate}}</strong><span> : {{sessionData.userAccount.userAccountManager.fullName}}</span></p>
          <p><strong>{{'Language' | translate}}</strong><span> : {{sessionData.toLanguage.name | translate}}</span></p>
          <p><strong>{{'Session_type' | translate}}</strong><span> : {{sessionData.sessionType | translate}}</span></p>
          <p><strong>{{'Reserved_by' | translate}}</strong><span *ngIf="!sessionData.followUpDetails.bookedBy"> : N/A</span>
            <span *ngIf="sessionData.followUpDetails.bookedBy"> : {{sessionData.followUpDetails.bookedBy}}</span></p>
          <p *ngIf="sessionData.bookingType == 'FOLLOWUP'"><strong>{{'agreedWith' | translate}}</strong>
            <span *ngIf="!sessionData.purpose"> : N/A</span><span *ngIf="sessionData.purpose"> : {{sessionData.purpose}}</span></p>
            <p  *ngIf="sessionData?.sessionFilters"><strong>{{'Filters' | translate}} :</strong></p>
            <p *ngIf="sessionData?.sessionFilters"><strong>{{'Gender' | translate}}</strong>
              <span *ngIf="!sessionData?.sessionFilters?.gender"> : {{'notRelevant' | translate}}</span>
              <span *ngIf="sessionData?.sessionFilters?.gender"> : {{sessionData?.sessionFilters?.gender | translate}}</span>
            </p>
            <p *ngIf="sessionData.sessionFilters"><strong>{{'Origin' | translate}}</strong>
              <span *ngIf="!sessionData?.sessionFilters?.origin"> : {{'notRelevant' | translate}}</span>
              <span *ngIf="sessionData?.sessionFilters?.origin"> : {{sessionData?.sessionFilters?.origin | translate}}</span>
            </p>
            <p *ngIf="sessionData?.sessionFilters"><strong>{{'Qualifications' | translate}}</strong>
              <span *ngIf="!sessionData?.sessionFilters?.qualification"> : {{'notRelevant' | translate}}</span>
              <span *ngIf="sessionData?.sessionFilters?.qualification"> : {{sessionData?.sessionFilters?.qualification}}</span>
            </p>
        </nz-tab>
        <!-- LATE Cancelled, Late Reschedule -->
        <nz-tab *ngIf="sessionData.status === 'REFUSED' || sessionData.status === 'CANCELLED' || 
        sessionData.status === 'COMPLETED' || sessionData.status === 'CONFIRMED' || sessionData.status === 'MISSED' 
        || sessionData.status === 'RESCHEDULED' || sessionData.status === 'PENDING' || sessionData.status === 'STOPPED'" 
         nzTitle="{{'Session_details' | translate}}">
          <p><strong>{{'Booked_date' | translate}}</strong><span> : {{sessionData.bookingDate | date:'dd-MM-yyyy'}}</span></p>
          <p><strong>{{'Start_end_time' | translate}}</strong>
            <span *ngIf="sessionData.sessionCategory == 'IMMEDIATE'"> : N/A</span>
            <span *ngIf="sessionData.sessionCategory != 'IMMEDIATE'"> : {{sessionData.startTime | date:'HH:mm'}}</span>
            <span>-</span>
            <span *ngIf="sessionData.sessionCategory == 'IMMEDIATE'"> N/A</span>
            <span *ngIf="sessionData.sessionCategory != 'IMMEDIATE'"> {{sessionData.endTime | date:'HH:mm'}}</span>
          </p>
          <p><strong>{{'Subject' | translate}}</strong><span> : {{sessionData.title}}</span></p>
          <p><strong>{{'Medical_professional' | translate}}</strong>
            <span *ngIf="!userDetailsData?.firstName"> : N/A</span>
            <span *ngIf="userDetailsData?.firstName"> : {{userDetailsData.fullName}}</span>
          </p>
          <p>
            <strong *ngIf="!userSessionData.paymentAuthorityOptionsData?.name">{{'Payment_authority' | translate}}</strong>
            <strong *ngIf="userSessionData.paymentAuthorityOptionsData?.name">{{userSessionData.paymentAuthorityOptionsData?.name}}</strong>
            <span *ngIf="!userSessionData.paymentAuthorityOptionsData?.paymentOption"> : N/A</span>
            <span *ngIf="userSessionData.paymentAuthorityOptionsData?.paymentOption"> :
              {{userSessionData.paymentAuthorityOptionsData?.paymentOption}}</span>
          </p>
          <p>
            <strong>{{'Category' | translate}}</strong><span *ngIf="!userSessionData.categoryOptionsData?.categoryOption"> : N/A</span>
            <span *ngIf="userSessionData.categoryOptionsData?.categoryOption"> : {{userSessionData.categoryOptionsData?.categoryOption}}</span>
          </p>
          <p *ngIf="(sessionData.sessionType == 'INPERSON') || (sessionData.sessionType == 'COURSEMANAGEMENT') || (sessionData.sessionType == 'INTERCULTURALMEDIATION')">
            <strong>{{'Location' | translate}}</strong><span> : {{sessionData.formattedAddress}}</span>
          </p>
          <p *ngIf="(sessionData.sessionType == 'INPERSON') || (sessionData.sessionType == 'COURSEMANAGEMENT') || (sessionData.sessionType == 'INTERCULTURALMEDIATION')">
            <strong>{{'Location_other' | translate}}</strong><span *ngIf="!sessionData.locationDetails"> : N/A</span>
              <span *ngIf="sessionData.locationDetails"> : {{sessionData.locationDetails}}</span>
          </p>
          <p>
            <strong *ngIf="preferredLanguage == 'en'">
              <span *ngIf="sessionData.patientData">{{sessionData.patientData?.orgPatientLabel.labelNameEn}}</span>
            </strong>
            <strong *ngIf="preferredLanguage == 'fr'">
              <span *ngIf="sessionData.patientData">{{sessionData.patientData?.orgPatientLabel.labelNameFr}}</span>
            </strong>
            <strong *ngIf="preferredLanguage == 'de'">
              <span *ngIf="sessionData.patientData || !sessionData.patientData?.orgPatientLabel.labelNameDe">
                {{sessionData.patientData.orgPatientLabel.labelNameDe}}
              </span>
            </strong>
            <strong *ngIf="preferredLanguage == 'it'">
              <span *ngIf="!sessionData.patientData || !sessionData.patientData?.orgPatientLabel.labelNameIt">
                {{sessionData.patientData.orgPatientLabel.labelNameIt}}
              </span>
              </strong>
            <strong>
              <span *ngIf="!sessionData.patientData?.orgPatientLabel.labelNameFr && !sessionData.patientData?.orgPatientLabel.labelNameEn &&
               !sessionData.patientData?.orgPatientLabel.labelNameDe && !sessionData.patientData?.orgPatientLabel.labelNameIt">
               {{sessionData.patientData?.orgPatientLabel}}
              </span>
            </strong>
            <span *ngIf="!sessionData.patientData?.migrantLabelValue"> : N/A</span>
            <span *ngIf="sessionData.patientData?.migrantLabelValue"> : {{sessionData.patientData?.migrantLabelValue}}</span>
          </p>
          <p *ngIf="sessionData.bookingType != 'FOLLOWUP'"><strong>{{'Comments_to_interpreter' | translate}}</strong><span
            *ngIf="!sessionData.purpose"> : N/A</span><span *ngIf="sessionData.purpose"> : {{sessionData.purpose}}</span></p>
        </nz-tab>
        <nz-tab *ngIf="sessionData.status === 'REFUSED' || sessionData.status === 'CANCELLED' || 
          sessionData.status === 'COMPLETED' || sessionData.status === 'CONFIRMED' || sessionData.status === 'MISSED' 
          || sessionData.status === 'RESCHEDULED' || sessionData.status === 'PENDING' || sessionData.status === 'STOPPED'"
          nzTitle="{{'User' | translate}}">
          <p><strong>{{'User_name' | translate}}</strong><span> : {{sessionData.owner.fullName}}</span></p>
          <p><strong>{{'User_telephone' | translate}}</strong><span> : {{sessionData.owner.telephone}}</span></p>
          <p><strong>{{'User_email' | translate}}</strong><span> : {{sessionData.owner.email}}</span></p>
        </nz-tab>
        <nz-tab *ngIf="sessionData.status === 'REFUSED' || sessionData.status === 'CANCELLED' || 
          sessionData.status === 'COMPLETED' || sessionData.status === 'CONFIRMED' || sessionData.status === 'MISSED' 
          || sessionData.status === 'RESCHEDULED' || sessionData.status === 'PENDING' || sessionData.status === 'STOPPED'"
        nzTitle="{{'Interpreter_details' | translate}}">
          <p>
            <strong>{{'Interpreter_name' | translate}}</strong>
            <span *ngIf="!interpreterDetailsData?.firstName"> : N/A</span>
            <span *ngIf="interpreterDetailsData?.firstName"> : {{interpreterDetailsData?.firstName}} {{interpreterDetailsData?.lastName}}</span>
          </p>
          <p *ngIf="interpreterDetailsData?.email">
            <strong>{{'Email' | translate}}</strong><span *ngIf="!interpreterDetailsData?.email"> : N/A</span>
            <span *ngIf="interpreterDetailsData?.email"> : {{interpreterDetailsData?.email}}</span>
          </p>
          <p *ngIf="interpreterDetailsData?.telephone"><strong>{{'Telephone' | translate}}</strong>
            <span *ngIf="!interpreterDetailsData?.telephone"> : N/A</span>
            <span *ngIf="interpreterDetailsData?.telephone"> : {{interpreterDetailsData?.telephone}}</span>
          </p>
          <p *ngIf="interpreterDetailsData?.formattedAddress">
            <strong>{{'Address' | translate}}</strong>
            <span *ngIf="!interpreterDetailsData?.formattedAddress"> : N/A</span>
            <span *ngIf="interpreterDetailsData?.formattedAddress"> : {{interpreterDetailsData?.formattedAddress}}</span>
          </p>
        </nz-tab>
        <nz-tab nzTitle="{{'Validation_photo' | translate}}"
        *ngIf="(sessionData.sessionType === 'INPERSON' || sessionData.sessionType === 'INTERCULTURALMEDIATION' || sessionData.sessionType === 'COURSEMANAGEMENT')
					 && (sessionData.status === 'RESCHEDULED' || sessionData.status === 'CANCELLED' || sessionData.status === 'COMPLETED') 
           && (role === 'IG Manager' || role === 'Admin')">
            <p *ngIf="sessionData.interculturalDetails">
              <span *ngIf="!sessionData.sessionImageUrl && !uploadme">{{'No_image_available' | translate}}</span>
              <span>
                <img id="srcImg" *ngIf="!uploadme" src="{{sessionData.sessionImageUrl}}"
                    style="margin: 0px;position: relative;bottom: 21px;width: 70%;height: 170px;vertical-align: middle" />
              </span>
            </p>
            <img *ngIf="sessionData.interculturalDetails && uploadme" src="{{uploadme}}"
              alt="Image preview..." style="max-width: 100% ; margin: 0px;position: relative;bottom: 21px;width: 70%;height: 170px;right: 30px">
            <p *ngIf="!sessionData.interculturalDetails">
              <span *ngIf="!sessionData.sessionImageUrl && !uploadme">{{'No_image_available' | translate}}</span>
              <span>
                <img id="srcImg" *ngIf="!uploadme" src="{{sessionData.sessionImageUrl}}" style=" max-width: 100%;max-height: 380px;" />
              </span>
            </p>
            <img *ngIf="!sessionData.interculturalDetails && uploadme" src="{{uploadme}}"
              alt="Image preview..." style="max-width: 100%;max-height: 380px;">
            <p class="removed-pdf" style="margin-bottom: 30px;display: inline-block;"
              *ngIf= "role === 'Admin' || role === 'IG Manager'"> {{'Upload_image' | translate}}:
            <!-- <button class="btn btn-default choose-file-btn" type="button"> {{'Choose_file' | translate}}
              <input type="file" fileread="uploadme">
            </button>
            <button type="button" class="btn btn-primary"
                ng-click="uploadImage(uploadme)">{{'Upload_image' | translate}}</button> -->
        </p>
        </nz-tab>
        <nz-tab *ngIf="sessionData.status === 'COMPLETED'" nzTitle="{{'Feedback_comments' | translate}}">
          <p *ngIf="((sessionData.sessionFeedBack?.submittedBy == 'USER') && (sessionData.sessionType == 'VIDEO' || sessionData.sessionType == 'TELEPHONE'))">
            <strong>{{'Feedback_to_interpreter' | translate}}</strong><span *ngIf="!sessionData.sessionFeedBack?.comment"> : N/A</span>
            <span *ngIf="sessionData.sessionFeedBack?.comment"> : {{sessionData.sessionFeedBack?.comment}}</span>
          </p>
          <p *ngIf="(sessionData.sessionFeedBack?.submittedBy == 'INTERPRETER')"><strong>{{'Feedback_from_interpreter' | translate}}</strong>
            <span *ngIf="!sessionData.sessionFeedBack?.comment"> : N/A</span>
            <span *ngIf="sessionData.sessionFeedBack?.comment"> : {{sessionData.sessionFeedBack?.comment}}</span>
          </p>
          <p *ngIf="role != 'Interpreter'"><strong>{{'Other_comments' | translate}}</strong><span> : 
            <a href="#" editable-text="sessionData.sessionFeedBack?.adminComment" title="{{'Click_to_edit' | translate}}"
              >{{ sessionData.sessionFeedBack?.adminComment || 'Click_to_edit' | translate}}</a></span>
          </p>
        </nz-tab>
        <nz-tab *ngIf="sessionData.interculturalDetails" nzTitle="{{'Intercultural_Details' | translate}}">
          <p><strong>{{'person_Involved_In_Conversation' | translate}}</strong><span
            *ngIf="!sessionData.interculturalDetails.personInvolvedInConversation"> : N/A</span><span
            *ngIf="sessionData.interculturalDetails.personInvolvedInConversation"> :
            {{sessionData.interculturalDetails.personInvolvedInConversation}}</span></p>
          <p><strong>{{'other_Involved_Positions' | translate}}</strong><span
            *ngIf="!sessionData.interculturalDetails.otherInvolvedPositions"> :
            N/A</span><span *ngIf="sessionData.interculturalDetails.otherInvolvedPositions">
            : {{sessionData.interculturalDetails.otherInvolvedPositions}}</span></p>
          <p><strong>{{'reason_For_Coversation' | translate}}</strong><span
            *ngIf="!sessionData.interculturalDetails.reasonForConversation">
            : N/A</span><span *ngIf="sessionData.interculturalDetails.reasonForConversation"> :
            {{sessionData.interculturalDetails.reasonForConversation}}</span></p>
          <p><strong>{{'goal_of_conversation' | translate}}</strong><span
            *ngIf="!sessionData.interculturalDetails.goalOfConversation"> :
            N/A</span><span *ngIf="sessionData.interculturalDetails.goalOfConversation"> :
            {{sessionData.interculturalDetails.goalOfConversation}}</span></p>
          <p><strong>{{'expectations_of_intercultural_mediation' | translate}}</strong><span
            *ngIf="!sessionData.interculturalDetails.expectationOfMediation"> :
            N/A</span><span *ngIf="sessionData.interculturalDetails.expectationOfMediation">
            : {{sessionData.interculturalDetails.expectationOfMediation}}</span></p>
        </nz-tab>
        <!-- In Progress, To Validate -->
        <nz-tab *ngIf="sessionData.status === 'MISSED' || sessionData.status === 'PENDING' || sessionData.status === 'REFUSED'
          || sessionData.status === 'RESCHEDULED' || sessionData.status === 'STOPPED'" 
          nzTitle="{{'Medical Professional Details' | translate}}">
            <p>
              <strong>Medical Professional</strong><span> : {{userDetailsData?.firstName}} {{userDetailsData?.lastName}}</span>
            </p>
            <p><strong *ngIf="!userSessionData.paymentAuthorityOptionsData?.name">{{'Payment_authority' | translate}}</strong>
                <strong *ngIf="userSessionData.paymentAuthorityOptionsData?.name">{{userSessionData.paymentAuthorityOptionsData?.name}}</strong>
                <span *ngIf="!userSessionData.paymentAuthorityOptionsData?.paymentOption"> : N/A</span>
                <span *ngIf="userSessionData.paymentAuthorityOptionsData?.paymentOption"> : {{userSessionData.paymentAuthorityOptionsData?.paymentOption}}</span>
              </p>
            <p>
              <strong>{{'Category' | translate}}</strong><span *ngIf="!userSessionData.categoryOptionsData?.categoryOption"> : N/A</span>
              <span *ngIf="userSessionData.categoryOptionsData?.categoryOption"> : {{userSessionData.categoryOptionsData?.categoryOption}}</span>
            </p>
            <p><strong>Email</strong><span> : {{userDetailsData?.email}}</span></p>
            <p><strong>Mobile</strong><span> : {{userDetailsData?.telephone}}</span></p>
            <p><strong>Address</strong><span> : {{userDetailsData?.address}}</span></p>
            <p><strong>City</strong><span> : {{userDetailsData?.city}}</span></p>
        </nz-tab>
        <!-- In Progress, To Validate -->
        <nz-tab 
        *ngIf="sessionData.status === 'MISSED' || sessionData.status === 'PENDING' || sessionData.status === 'REFUSED'
          || sessionData.status === 'RESCHEDULED' || sessionData.status === 'STOPPED'"
        nzTitle="{{'Language Details' | translate}}">
        <p><strong>From Language</strong><span> : {{sessionData.fromLanguage.name}}</span></p>
        <p><strong>To Language</strong><span> : {{sessionData.toLanguage.name}}</span></p>
        </nz-tab>
        <!-- In Progress, To Validate -->
        <nz-tab 
        *ngIf="(sessionData.status === 'MISSED' || sessionData.status === 'PENDING' || sessionData.status === 'REFUSED'
          || sessionData.status === 'RESCHEDULED' || sessionData.status === 'STOPPED') && sessionData.sessionType == 'INPERSON'"
        nzTitle="{{'Location Details' | translate}}">
          <p><strong>Location Name</strong><span> : {{locationDetailsData?.name}}</span></p>
          <p><strong>Address</strong><span> : {{locationDetailsData?.address}}</span></p>
          <p><strong>Street</strong><span> : {{locationDetailsData?.street}}</span></p>
          <p><strong>City</strong><span> : {{locationDetailsData?.city}}</span></p>
          <p><strong>Zipcode</strong><span> : {{locationDetailsData?.zipcode}}</span></p>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
  <div *nzModalFooter class="actions justify-content-end">
    <button
      tc-button
      [view]="'info'"
      *ngIf="!loading &&sessionData.status === 'CONFIRMED'"
      (click)="openSendEmailModal('sendMailTo')"
      >
      {{ "Send_Email" | translate }}
    </button>
    <button tc-button [view]="'info'" (click) ="onExportSessionDetail()">{{ "Exporter" | translate }}</button>
    <button
      tc-button
      [view]="'info'"
      *ngIf="!loading && sessionData.status !== 'REFUSED'"
      (click)="openEditSessionModal('Edit_sessions')"
    >
      {{ "editSession" | translate }}
    </button>
    <button tc-button [type]="'button'" [view]="'error'" (click)="closeModal()">
      {{ "Cancel" | translate }}
    </button>
  </div>
</nz-spin>
