import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { UtilityService } from '../../../shared/services/utility.service';
import { ModalDataService } from '../../../shared/services/modal-data.service';
import { NewSessionService } from '../../sessions/create-edit-session/create-edit-session.service';

@Component({
  selector: 'app-add-filter',
  templateUrl: './add-filter.component.html',
  styleUrls: ['./add-filter.component.scss']
})
export class AddFilterComponent implements OnInit, OnDestroy {
  subscriber: Subscription;
  localLanguage: string;
  origin = [];
  qualifications = [];
  toLang: any;
  fromLanguage: string;
  orgId: number;
  gender: any;
  selectedOrigin: any;
  loading = false;

  constructor(
    private readonly sessionService: NewSessionService,
    private readonly modal: NzModalRef,
    private readonly modalDataService: ModalDataService,
    private readonly utilityService: UtilityService
  ) { }


  ngOnInit() {
    this.localLanguage = localStorage.getItem('transLang');
    const modalData = this.modalDataService.getData();
    this.toLang = modalData['modalInfo'].toLanguage['isoCode'];
    this.orgId = modalData['modalInfo'].orgId;
    this.fromLanguage = modalData['modalInfo'].fromLanguage['isoCode'];
    this.getListOfCountries();
    this.getQualification(modalData['modalInfo'].userAccountId, modalData['modalInfo'].interpreterIds);
  }

  // get country list
  public getListOfCountries() {
    this.loading = true;
    const query = this.utilityService.createQuery(
      { orgId: this.orgId, fromLang: this.fromLanguage, toLang: this.toLang, transLang: this.localLanguage });
    this.subscriber = this.sessionService.getCountries(query).subscribe((res) => {
      this.origin = res['data']['countries'];
      this.loading = false;
    });
  }

  public getQualification(userAccountId, interpreters) {
    this.loading = true;
    const interpretersId = {
      interpreterIds: interpreters
    };
    this.sessionService.getQualifications(userAccountId, interpretersId).subscribe((res) => {
      this.qualifications = res['data'];
      this.loading = false;
    });
  }

  public filterInterpreters() {
    const qualificationIds = [];
    this.qualifications.forEach((i) => {
      if (i.isSelected === true) {
        qualificationIds.push(i.id);
      }
    });
    const response = {
      origin: this.selectedOrigin,
      gender: this.gender,
      qualificationIds
    };
    this.closeModal(response);
  }
  // Close User Modal
  closeModal(response?: any) {
    this.modal.destroy(response);
  }

  ngOnDestroy() {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }
}
