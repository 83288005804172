import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  lang: string;
  public constructor(private readonly translate: TranslateService) {
    this.lang = localStorage.getItem('transLang');
    if (this.lang !== null) {
        this.translate.use(this.lang);
        this.translate.setDefaultLang(this.lang);
    } else {
        this.translate.use('fr');
        this.translate.setDefaultLang('fr');
    }
}
 }
