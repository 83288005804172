import { environment } from '../../../../environments/environment';

export const urls = {
    getDataUrl: 'organizations',
    searchTextFilter: 'search-text-filter'
};

export const oldUrls = {
    getDataUrl: 'v5/organizations',
    searchTextFilter: 'v4/search-text-filter'
};
export const organizationPath = {
    getUrl(urlName: string) {
       if (environment.backend === 'old') {
           return oldUrls[urlName];
       } else {
           return urls[urlName];
       }
   }
};
