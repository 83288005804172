import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { newOrganization } from './new-organization.path';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewOrganizationService {

  public constructor(private readonly httpClientService: HttpClientService) {
  }

  public sendFormData(newOrgData: any): Observable<{}> {
    return this.httpClientService.postServerRequest(newOrganization.getUrl('postDatURL'), newOrgData);
  }
  public updateFormData(newOrgData: any, orgId: number): Observable<{}> {
    return this.httpClientService.putServerRequest(newOrganization.getUrl('updateDataURL') + orgId, newOrgData);
  }
  public getQualifications(orgId: number): Observable<{}> {
    return this.httpClientService.getServerRequest(`${newOrganization.getUrl('getQualification')}${orgId}/qualifications`);
  }
}
