import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { session } from './session-model.path';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SessionModalService {
  constructor(private readonly httpClientService: HttpClientService) {}

  // service session table filter data
  public getSession(id: number): Observable<{}> {
    return this.httpClientService.getServerRequest(`${session.getUrl('getSession')}/${id}`);
  }
   // service session table filter data
   public updateRecalculateSession(totalSessionTime , recalculateData , sessionId): Observable<{}> {
    return this.httpClientService.putServerRequest(
      `${session.getUrl('recalculate')}${sessionId}/${session.getUrl('updateRecalculate')}` + totalSessionTime , recalculateData);
  }
   // service session table filter data
   public updateSessionCost(sessionId , recalculateData): Observable<{}> {
    return this.httpClientService.postServerRequest(`${session.getUrl('sessionCost')}` +  sessionId, recalculateData);
  }

  // Session Mail Send
  public sendSessionMail(sessionId, mailData): Observable<{}> {
    return this.httpClientService.postServerRequest(`${session.getUrl('sessionMail')}${sessionId}`, mailData);
  }
}
